import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { FormListActionType, ProFormColumnsType } from '@ant-design/pro-form';
import { Button, Col, ColProps, Divider, Modal, Row, RowProps, Space, Typography } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { ProductLotSelect, ProductSelect } from 'components/GSelect';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { InventoryResponse, ProductResponse } from 'services/api/client/src';
import BarcodePalletLayout from '../LabelForm/BarcodePalletLayout';
import styles from './index.module.less';
import { ColumnsType, DataItem, LabelColumnFnExtended } from './typings';

const rowProps: RowProps = { gutter: [0, 0] };
const colProps24: ColProps = { xs: 24, sm: 24, md: 24, lg: 24 };
// const hiddenColProps: ColProps = { xs: 0, sm: 0, md: 0, lg: 0 };

export const AddCardColumns: LabelColumnFnExtended = ({ handlePrintOption, modalFormRef }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'add_header_footer_modal' });

  return [
    {
      valueType: 'group',
      colProps: colProps24,
      rowProps,
      columns: [
        {
          valueType: 'group',
          colProps: colProps24,
          columns: [
            {
              valueType: 'dependency',
              fieldProps: {
                name: [
                  'formFieldList',
                  'gtin',
                  'labelName',
                  'sscc',
                  'content',
                  'product',
                  'ssccName',
                  'fieldSSCC',
                  'existingSSCC',
                  'printOptions',
                ],
              },
              columns: ({
                formFieldList,
                gtin,
                labelName,
                sscc,
                content,
                product,
                ssccName,
                fieldSSCC,
                existingSSCC,
                printOptions,
              }) => [
                {
                  title: () => null,
                  dataIndex: 'previewCard',
                  renderFormItem: () => {
                    const formData = {
                      gtin,
                      labelName,
                      sscc,
                      content,
                      product,
                      ssccName,
                      fieldSSCC,
                      existingSSCC,
                      printOptions,
                    };

                    return (
                      <BarcodePalletLayout
                        data={formData}
                        sections={formFieldList}
                        sscc={existingSSCC === 'Yes' ? sscc : fieldSSCC}
                        gtin={gtin}
                        t={t}
                        handlePrintOption={handlePrintOption}
                        modalFormRef={modalFormRef}
                      />
                    );
                  },
                },
              ],
            },
            {
              dataIndex: 'formFieldList',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
            },
            {
              dataIndex: 'product',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
            },
            {
              dataIndex: 'gtin',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
            },
            {
              dataIndex: 'content',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
            },
            {
              dataIndex: 'sscc',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
            },
            {
              dataIndex: 'ssccName',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
            },
            {
              dataIndex: 'labelName',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
            },
            {
              dataIndex: 'fieldSSCC',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
            },
            {
              dataIndex: 'existingSSCC',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
            },
            {
              dataIndex: 'printOptions',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
            },
          ],
        },
      ],
    },
  ];
};

export const AddFormColumns = ({
  modalCardRef,
}: ColumnsType): Array<ProFormColumnsType<DataItem>> => {
  const actionRef = useRef<FormListActionType<{ section: string }>>();
  const { t } = useTranslation('pages', { keyPrefix: 'labels.add_pallet' });

  const onDeleteSection = useCallback(
    async (index: number) => {
      const values: DataItem = modalCardRef?.current?.getFieldsValue();
      const obj = values?.formFieldList?.[index];
      const isEmpty = !(obj?.content || obj?.data || obj?.position || obj?.title);
      const displayIndex = (index || 0) + 1;

      if (isEmpty) {
        actionRef?.current?.remove(index);
      } else {
        Modal.warning({
          title: t('delete_confirm.title', { index: displayIndex }),
          content: t('delete_confirm.desc'),
          centered: true,
          okCancel: true,
          okText: t('delete_confirm.remove'),
          cancelText: t('delete_confirm.cancel'),
          okButtonProps: {
            type: 'primary',
            shape: 'round',
          },
          cancelButtonProps: {
            type: 'primary',
            ghost: true,
            shape: 'round',
          },
          onOk: () => actionRef?.current?.remove(index),
        });
      }
    },
    [modalCardRef, t],
  );

  return [
    {
      valueType: 'group',
      colProps: colProps24,
      rowProps,
      columns: [
        {
          valueType: 'group',
          colProps: colProps24,
          title: () => (
            <Space direction="vertical">
              <Typography.Text>{t?.('pallet_label')}</Typography.Text>
              <Typography.Text className={styles.labeldesc}>{t?.('pallet_desc')}</Typography.Text>
            </Space>
          ),
          rowProps,
          columns: [
            {
              title: t('form_fields.label_name_col'),
              dataIndex: 'labelName',
              colProps: colProps24,
              valueType: 'text',
              fieldProps: {
                placeholder: t('form_fields.label_name_col'),
              },
              formItemProps: {
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: t?.('form_fields.required_message', {
                      fieldName: t?.('form_fields.label_name_col'),
                    }),
                  },
                  {
                    validator: (_, value) => {
                      const emojiRegex =
                        /([\u203C-\u3299]|\uD83C[\uD000-\uDFFF]|\uD83D[\uD000-\uDFFF]|\uD83E[\uD000-\uDFFF])/;
                      if (value && emojiRegex.test(value)) {
                        return Promise.reject(
                          t?.('form_fields.required_message', {
                            fieldName: t?.('form_fields.label_name_col'),
                          }),
                        );
                      }
                      if (value.length > 50) {
                        return Promise.reject(t?.('form_fields.label_name_too_long'));
                      }
                      return Promise.resolve();
                    },
                  },
                ],
                hasFeedback: false,
              },
            },
            {
              dataIndex: 'gtin',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
            },
          ],
        },
        {
          valueType: 'divider',
          fieldProps: {
            style: {
              margin: 10,
            },
          },
        },
        {
          valueType: 'formList',
          dataIndex: 'formFieldList',
          colProps: colProps24,
          fieldProps: {
            actionRef,
            alwaysShowItemLabel: true,
            copyIconProps: false,
            creatorButtonProps: {
              creatorButtonText: t?.('form_fields.add_section_btn'),
              type: 'primary',
              shape: 'round',
              icon: null,
              style: {
                width: 'unset',
              },
            },
            itemRender: (dom: any, param: any) => (
              <Col>
                <Row justify="space-between" align="middle">
                  <Typography.Text strong>
                    {t?.('form_fields.section_title', { number: (param?.index || 0) + 1 })}
                  </Typography.Text>
                  <Button onClick={() => onDeleteSection(param?.index || 0)} type="link">
                    <Space>
                      <DeleteOutlined />
                      <Typography.Link>{t?.('form_fields.delete_section_btn')}</Typography.Link>
                    </Space>
                  </Button>
                </Row>
                {dom?.listDom}
                <Divider />
              </Col>
            ),
          },
          columns: [
            {
              valueType: 'group',
              columns: [
                {
                  title: t('form_fields.title_col'),
                  dataIndex: 'title',
                  colProps: colProps24,
                  valueType: 'text',
                  fieldProps: {
                    placeholder: t('form_fields.title_col'),
                  },
                  formItemProps: {
                    rules: [
                      {
                        required: true,
                        message: t?.('form_fields.required_message', {
                          fieldName: t?.('form_fields.title_col'),
                        }),
                      },
                    ],
                    hasFeedback: false,
                  },
                },
                {
                  dataIndex: 'content',
                  title: t('form_fields.section_content_col'),
                  valueType: 'select',
                  fieldProps: {
                    allowClear: false,
                  },
                  valueEnum: {
                    text: {
                      text: t('form_fields.options.text'),
                      status: 'Default',
                    },
                    barcode: {
                      text: t('form_fields.options.barcode'),
                      status: 'Default',
                    },
                    qrcode: {
                      text: t('form_fields.options.qrcode'),
                      status: 'Default',
                    },
                    datamatrix: {
                      text: t('form_fields.options.data_matrix'),
                      status: 'Default',
                    },
                  },
                  formItemProps: {
                    rules: [
                      {
                        required: true,
                        message: t?.('form_fields.required_message', {
                          fieldName: t?.('form_fields.section_content_col'),
                        }),
                      },
                    ],
                    hasFeedback: false,
                  },
                  colProps: colProps24,
                },
                {
                  title: t('form_fields.data_col'),
                  dataIndex: 'data',
                  colProps: colProps24,
                  valueType: 'textarea',
                  fieldProps: {
                    placeholder: t('form_fields.data_col'),
                    rows: 4,
                  },
                  formItemProps: {
                    rules: [
                      {
                        required: true,
                        message: t?.('form_fields.required_message', {
                          fieldName: t?.('form_fields.data_col'),
                        }),
                      },
                    ],
                    hasFeedback: false,
                  },
                  tooltip: {
                    icon: <QuestionCircleOutlined />,
                    placement: 'bottom',
                    title: (
                      <>
                        {t('data_tooltip')}
                        <a href="https://www.gs1us.org/upcs-barcodes-prefixes/gs1-128">
                          {' '}
                          {t('data_tooltip_here')}
                          {'\n\n'}
                        </a>
                        {t('data_tooltip_desc')}
                      </>
                    ),
                    key: null,
                    type: '',
                    props: null,
                  },
                },
              ],
            },
          ],
        },
        {
          valueType: 'divider',
          fieldProps: {
            style: {
              margin: 10,
            },
          },
        },
        {
          valueType: 'group',
          colProps: colProps24,
          title: t?.('form_fields.barcode'),
          rowProps,
          columns: [
            {
              title: t?.('form_fields.sscc_question'),
              valueType: 'radio',
              initialValue: 'No',
              dataIndex: 'existingSSCC',
              valueEnum: {
                Yes: {
                  text: t('yes'),
                  status: 'default',
                },
                No: {
                  text: t('no'),
                  status: 'default',
                },
              },
            },
            {
              colProps: colProps24,
              valueType: 'dependency',
              fieldProps: {
                name: ['existingSSCC', 'product'],
              },
              columns: ({ existingSSCC, product }) => [
                {
                  title: t('form_fields.product_title'),
                  dataIndex: 'product',
                  formItemProps: {
                    rules: [
                      {
                        required: true,
                        message: t?.('form_fields.required_message', {
                          fieldName: t?.('form_fields.product_title'),
                        }),
                      },
                    ],
                  },
                  renderFormItem: (_row, record, form) => (
                    <ProductSelect
                      placeholder={t('form_fields.product_title')}
                      onChange={(value, option) => {
                        const currentProduct = (option as DefaultOptionType)
                          ?.itemProps as ProductResponse;
                        const fieldVals = {
                          lotID: undefined,
                          product: value,
                          unitOfMeasure: currentProduct?.simpleUnitOfMeasurement,
                          gtin: currentProduct?.gtin,
                        };
                        form?.setFieldsValue(fieldVals);
                        modalCardRef?.current?.setFieldsValue(fieldVals);
                      }}
                    />
                  ),
                  colProps: colProps24,
                  hideInForm: existingSSCC === 'No',
                },

                {
                  title: t('form_fields.sscc_title'),
                  dataIndex: 'sscc',
                  hideInForm: existingSSCC === 'No',
                  renderFormItem: (_row, record, form) => (
                    <ProductLotSelect
                      placeholder={t('form_fields.sscc_title')}
                      productId={product || ''}
                      filterItem={(item) => item?.container !== null}
                      onChange={(value, option) => {
                        const inventory = (option as DefaultOptionType)
                          ?.itemProps as InventoryResponse;
                        form?.setFieldsValue({
                          ssccName: inventory?.container?.containerIdentifier,
                          sscc: value,
                        });
                        modalCardRef?.current?.setFieldsValue({
                          ssccName: inventory?.container?.containerIdentifier,
                          sscc: value,
                        });
                      }}
                      disabled={!product}
                    />
                  ),
                  colProps: colProps24,
                },
                {
                  title: t('form_fields.field_sscc_col'),
                  dataIndex: 'fieldSSCC',
                  colProps: colProps24,
                  valueType: 'text',
                  hideInForm: existingSSCC === 'Yes',
                  fieldProps: {
                    placeholder: t('form_fields.field_sscc_col'),
                    disabled: !product,
                  },
                },
                {
                  dataIndex: 'ssccName',
                  fieldProps: {
                    className: 'no-display',
                  },
                  formItemProps: {
                    className: 'no-display',
                  },
                  colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
                },
                {
                  dataIndex: 'printOptions',
                  fieldProps: {
                    className: 'no-display',
                  },
                  formItemProps: {
                    className: 'no-display',
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ];
};
