import { Col, Image, Row, Space, Typography } from 'antd';
import wholechainLogo from 'assets/images/wholechainLogoBarcode.png';
import { format, parse } from 'date-fns';
import { forwardRef, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getHideInFormSetting } from '../components/AddLabel.fields';
import BarcodeGenerator, {
  generateGS1String,
  generateVoicePickCode,
} from '../components/BarcodeGenerator';
import {
  BarcodeLabelLayoutProps,
  BarcodePreviewFields,
  countryCodes,
  DataItem,
  FIELD_CONFIG,
} from '../components/typings';
import { transformBarcodeType } from '../helper';
import styles from './index.module.less';

const { Title, Text } = Typography;

function getProperPackDate(data: DataItem | undefined) {
  if (!data) return null;

  let currentPackDate = null;
  if (data?.packDate && typeof data.packDate === 'string') {
    currentPackDate = parse(
      `${data.packDate}T00:00:00.000Z`,
      "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
      new Date(),
    );
  } else if (data?.packDate) {
    currentPackDate = new Date(data.packDate);
  }
  return currentPackDate;
}

const ptiFields = [
  'packaging',
  'grownIn',
  'harvestDate',
  'grade',
  'responsibleParty',
  'plu',
  'upc',
];

const chunkSections = (items: BarcodePreviewFields[] | undefined | null, size: number) => {
  if (!items?.length) {
    return [];
  }
  const chunks = [];
  for (let i = 0; i < items.length; i += size) {
    chunks.push(items.slice(i, i + size));
  }
  return chunks;
};
const BarcodeLabelLayoutPrint = forwardRef<HTMLDivElement, BarcodeLabelLayoutProps>(
  ({ data, t, printOption, applicationIdentifierLookup }, ref) => {
    const { gs1String = '' } =
      generateGS1String(data, undefined, applicationIdentifierLookup) || {};

    const hasVoicePickCode =
      (data?.fieldInfo && getHideInFormSetting(data?.fieldInfo, 'voicePickCode')?.visible) ||
      data?.layoutType === 'PTI';

    const hasWholechainLogo =
      data?.fieldInfo && getHideInFormSetting(data?.fieldInfo, 'wcLogo')?.visible;

    const barcodeType = transformBarcodeType(data);
    const hasLogos = useMemo(
      () => Boolean(data?.brandLogoURL || data?.certLogoURL || data?.logoURL),
      [data?.brandLogoURL, data?.certLogoURL, data?.logoURL],
    );
    const hasCertOrCompanyLogos = Boolean(data?.certLogoURL || data?.logoURL);
    const isGS1andDataMatrix = data?.barcodeType === 'GS1 and Data Matrix';
    const isPTI = data?.layoutType === 'PTI';

    // Generate voice pick code if available
    const utcDate = getProperPackDate(data);
    const voicePickCodeData =
      data?.gtin14 && data?.batchLotID && hasVoicePickCode
        ? generateVoicePickCode(
            data.gtin14,
            data.batchLotID,
            utcDate ? format(utcDate, 'yyMMdd') : null,
          )
        : null;

    const colSpan = useMemo(() => (hasLogos ? 6 : 4), [hasLogos]); // Adjust colSpan for smaller size

    // Determine UPC data and requirements
    const getUPCField = () => {
      let field = data?.newFieldInfo?.find(
        (c) => c.dataIndex?.toLocaleLowerCase().trim() === 'upc',
      )?.value;
      if (data && 'upc' in data) {
        field = (data.upc as string | undefined) ?? '';
      }
      if (isPTI && field) {
        return field;
      }
      return null;
    };
    const UPCField = getUPCField();
    const isValidUPC = (UPCField?.length ?? 0) === 12;

    const renderFieldValue = (
      value: string | undefined,
      isRequired: boolean,
      key: string | undefined,
    ) => {
      if (!value && !isRequired) {
        return null;
      }
      let fieldValue = null;
      const validKey = key?.split('-')[0] ?? '';
      const isValidDate = ['bestBeforeDate', 'packDate', 'sellByDate'].some((c) => c === validKey);
      if (value && isValidDate) {
        fieldValue = format(new Date(value), 'yyyy-MM-dd');
      } else {
        fieldValue = value;
      }
      if (['countryOfOrigin'].some((c) => c === validKey)) {
        fieldValue = countryCodes.find((c) => c.value === value)?.label ?? '';
      }
      return (
        <div
          className={
            data?.layoutType === 'PTI'
              ? styles['case-pti-label-printable-field-value']
              : styles['printable-field-value']
          }
        >
          {fieldValue?.toLocaleUpperCase() || '-'}
        </div>
      );
    };

    // Filter and sort fields to display based on visibility and if it's required
    const fieldsToDisplayData = useMemo(() => {
      // Process standard fields from fieldInfo
      const standardFields = (data?.fieldInfo || [])
        .filter((field) => {
          // Show only specific fields for PTI layout
          if (data?.layoutType === 'PTI') {
            return [
              'quantity',
              'unitOfMeasure',
              'packaging',
              'packDate',
              'grownIn',
              'grade',
              'responsibleParty',
              'plu',
              'gtin14',
              'batchLotID',
            ].includes(field.dataIndex);
          }
          return field.visible && !field.isOnlyLabel && !ptiFields.includes(field.dataIndex);
        })
        .map((field, index) => {
          const value = field.dataIndex ? data?.[field.dataIndex as keyof typeof data] : undefined;
          const isRequired = field.required;
          const shouldDisplay =
            isRequired || (value !== undefined && value !== null && value !== '');

          if (!shouldDisplay) return null;

          const fieldConfig = FIELD_CONFIG.find((f) => f.key === field.dataIndex);
          const order = fieldConfig?.order ?? FIELD_CONFIG.length + 1;

          return {
            key: `${field.dataIndex}-${index}`,
            title: field.title,
            required: isRequired,
            value: value ?? '-',
            isCustom: false,
            order,
          };
        })
        .filter(
          (
            field,
          ): field is {
            key: string;
            title: string;
            required: boolean;
            value: any;
            isCustom: boolean;
            order: number;
          } => field !== null,
        );
      // Process custom fields from newFieldInfo
      const customFields = (data?.newFieldInfo || [])
        .filter((field) => field.visible)
        .map((field, index) => {
          const isRequired = field.required;
          const { value } = field;
          const shouldDisplay =
            isRequired || (value !== undefined && value !== null && value !== '');

          if (!shouldDisplay) return null;
          return {
            key: `custom-${field.dataIndex}-${index}`,
            title: field.title,
            required: isRequired,
            value: value ?? '-',
            isCustom: true,
            order: FIELD_CONFIG.length + 1,
          };
        })
        .filter(
          (
            field,
          ): field is {
            key: string;
            title: string;
            required: boolean;
            value: any;
            isCustom: boolean;
            order: number;
          } => field !== null,
        );
      // Combine and sort
      return [...standardFields, ...customFields].sort((a, b) => a.order - b.order);
    }, [data]);

    const renderField = (field: any) => (
      <Col span={colSpan} className={styles['printable-field-container']} key={field.key}>
        <Text
          className={
            data?.layoutType === 'PTI'
              ? styles['printable-field-label-pti']
              : styles['printable-field-label']
          }
        >
          {field.title}
        </Text>
        {renderFieldValue(field.value?.toString(), field.required, field.key)}
      </Col>
    );

    const rowsOfFields = chunkSections(fieldsToDisplayData, 5);

    if (data?.layoutType === 'PTI') {
      return (
        <div ref={ref} className={styles['case-pti-label-wrapper-printable']}>
          <div className={styles['case-pti-label-container-printable']}>
            {/* Header Section */}
            <div className={styles['case-pti-label-content-header-printable']}>
              <Row gutter={2}>
                <Col span={data?.brandLogoURL ? 16 : 20}>
                  <h3 className={styles['case-pti-label-product-name-printable']}>
                    {data?.productName ?? ''}
                  </h3>
                  {data?.productDescription && (
                    <Title level={5} className={styles['case-label-product-description']}>
                      {data?.fieldInfo?.find((c) => c.dataIndex === 'productDescription')
                        ?.visible && data?.productDescription}
                    </Title>
                  )}
                  {data?.processedInUSA && (
                    <Text
                      type="secondary"
                      className={styles['case-label-product-description-processed-usa']}
                    >
                      {t('form_fields.processed_in_usa')} {' - '}
                      {data.usStateOfProcessing}
                    </Text>
                  )}
                </Col>
              </Row>
            </div>

            <div className={styles['case-pti-label-content-body-printable']}>
              {rowsOfFields.map((rowFields) => (
                <Row gutter={[0, 0]} className={styles['printable-fields-grid-pti']} key={uuidv4()}>
                  {rowFields.map(renderField)}
                </Row>
              ))}
            </div>

            {/* Barcode and voice pick code section */}
            <div className={styles['case-pti-label-footer']}>
              <div
                className={
                  gs1String
                    ? styles['case-pti-label-card-barcode-container-printable']
                    : styles['case-label-card-barcode-container-empty']
                }
              >
                {!gs1String && (
                  <Text type="secondary">{t('empty_case_label_barcode_selection_text')}</Text>
                )}
                {gs1String && (
                  <BarcodeGenerator
                    value={gs1String}
                    options={{
                      format: 'CODE128', // GS1-compatible barcode type
                      ean128: true, // Enable GS1 compatibility
                      text: gs1String, // Add human-readable text below
                      width: 0.95, // Width of bars
                      height: 70, // Height of the barcode
                      textMargin: 2, // Space between text and barcode
                      fontSize: 5, // Font size of the text
                      margin: 5, // Margin around the barcode
                      displayValue: true, //
                    }}
                    handlePrint={() => {}}
                    voicePickCode={data?.voicePickCode}
                    type={barcodeType}
                    height={128} // height for datamatrix (if selected)
                    typeOption="case"
                  />
                )}

                {(barcodeType === 'barcode' || barcodeType === 'datamatrix') && (
                  <div className={styles['case-pti-label-voice-pick-code-container-printable']}>
                    {isPTI && UPCField && isValidUPC && (
                      <BarcodeGenerator
                        value={UPCField}
                        options={{
                          format: 'upc',
                          width: 0.5,
                          height: 15,
                          displayValue: true,
                          font: 'monospace',
                          textAlign: 'center',
                          textPosition: 'bottom',
                          textMargin: 2,
                          fontSize: 7,
                          background: '#ffffff',
                          lineColor: '#000000',
                          margin: 2,
                        }}
                        type="barcode"
                        handlePrint={() => {}}
                        typeOption="case"
                      />
                    )}

                    {!isGS1andDataMatrix && hasVoicePickCode && (
                      <div className={styles['case-pti-label-card-voice-pick-code-printable']}>
                        <Text
                          className={styles['case-pti-label-card-voice-pick-code-text-printable']}
                        >
                          {voicePickCodeData?.toString().substring(0, 2)}
                          <span
                            className={
                              styles[
                                'case-pti-label-card-voice-pick-code-text-special-digit-printable'
                              ]
                            }
                          >
                            {voicePickCodeData?.toString().substring(2, 4)}
                          </span>
                        </Text>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div ref={ref}>
        {printOption === '4x6' && (
          <div ref={ref} className={styles['case-label-wrapper-printable']}>
            <div className={styles['case-label-container-printable']}>
              <div className={styles['case-label-content-printable']}>
                {/* Header Section */}
                <div className={styles['case-label-content-header-printable']}>
                  <Row gutter={2}>
                    {data?.brandLogoURL && (
                      <Col span={4}>
                        <div className={styles['case-label-brand-logo-printable']}>
                          <Image src={data?.brandLogoURL} preview={false} />
                        </div>
                      </Col>
                    )}
                    <Col span={data?.brandLogoURL ? 16 : 20}>
                      <h5 className={styles['case-label-product-name-printable']}>
                        {data?.productName ?? ''}
                      </h5>
                      {data?.productDescription && (
                        <h6 className={styles['case-label-product-description-printable']}>
                          {data?.fieldInfo?.find((c) => c.dataIndex === 'productDescription')
                            ?.visible && data?.productDescription}
                        </h6>
                      )}
                      {data?.processedInUSA && (
                        <Text
                          type="secondary"
                          className={
                            styles['case-label-product-description-processed-usa-printable']
                          }
                        >
                          {t('form_fields.processed_in_usa')} {' - '}
                          {data.usStateOfProcessing}
                        </Text>
                      )}
                    </Col>
                    {hasCertOrCompanyLogos && (
                      <Col span={4}>
                        <Space
                          direction="vertical"
                          size={16}
                          className={styles['case-label-logos-container']}
                        >
                          {data?.certLogoURL && (
                            <div className={styles['case-label-brand-logo-printable']}>
                              <Image src={data?.certLogoURL} preview={false} />
                            </div>
                          )}
                          {data?.logoURL && (
                            <div className={styles['case-label-brand-logo-printable']}>
                              <Image src={data?.logoURL} preview={false} />
                            </div>
                          )}
                        </Space>
                      </Col>
                    )}
                  </Row>
                </div>

                {/* Fields Grid */}
                <div className={styles['case-label-content-body-printable']}>
                  <Row gutter={[0, 7]} className={styles['printable-fields-grid']}>
                    {fieldsToDisplayData.map(renderField)}
                  </Row>
                </div>

                {/* Barcode and voice pick code section */}
                <Row gutter={[7, 0]} align="bottom">
                  <Col span={barcodeType === 'gs1-datamatrix' ? 24 : 18}>
                    <div
                      className={
                        gs1String
                          ? styles['case-label-card-barcode-container']
                          : styles['case-label-card-barcode-container-empty']
                      }
                    >
                      {!gs1String && (
                        <Text type="secondary">{t('empty_case_label_barcode_selection_text')}</Text>
                      )}
                      {gs1String && (
                        <BarcodeGenerator
                          value={gs1String}
                          options={{
                            format: 'CODE128', // GS1-compatible barcode type
                            ean128: true, // Enable GS1 compatibility
                            text: gs1String, // Add human-readable text below
                            width: barcodeType === 'gs1-datamatrix' ? 1 : 1.3, // Width of bars
                            height: 128, // Height of the barcode
                            textMargin: 5, // Space between text and barcode
                            fontSize: 12, // Font size of the text
                            margin: 10, // Margin around the barcode
                            displayValue: true, //
                          }}
                          handlePrint={() => {}}
                          voicePickCode={data?.voicePickCode}
                          type={barcodeType}
                          height={128} // height for datamatrix (if selected)
                          typeOption="case"
                        />
                      )}
                    </div>
                  </Col>
                  {(barcodeType === 'barcode' || barcodeType === 'datamatrix') && (
                    <Col span={5}>
                      <div className={styles['case-label-voice-pick-code-container-printable']}>
                        {!isGS1andDataMatrix && !isPTI && hasWholechainLogo && (
                          <div className={styles['case-label-wholechain-logo']}>
                            <img src={wholechainLogo} alt="wholechain" />
                          </div>
                        )}

                        {isPTI && UPCField && isValidUPC && (
                          <BarcodeGenerator
                            value={UPCField}
                            options={{
                              format: 'upc',
                              width: 2,
                              height: 100,
                              displayValue: true,
                              font: 'monospace',
                              textAlign: 'center',
                              textPosition: 'bottom',
                              textMargin: 2,
                              fontSize: 12,
                              background: '#ffffff',
                              lineColor: '#000000',
                              margin: 10,
                            }}
                            type="barcode"
                            handlePrint={() => {}}
                          />
                        )}

                        {!isGS1andDataMatrix && hasVoicePickCode && (
                          <div className={styles['case-label-card-voice-pick-code-printable']}>
                            <Text
                              className={styles['case-label-card-voice-pick-code-text-printable']}
                            >
                              {voicePickCodeData?.toString().substring(0, 2)}
                              <span
                                className={
                                  styles['case-label-card-voice-pick-code-text-special-digit']
                                }
                              >
                                {voicePickCodeData?.toString().substring(2, 4)}
                              </span>
                            </Text>
                          </div>
                        )}
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            </div>
          </div>
        )}
        {printOption === '4x12' && (
          <Space direction="horizontal" size="small">
            <div className={styles['case-label-wrapper-printable']}>
              <div className={styles['case-label-container-printable']}>
                <div className={styles['case-label-content-printable']}>
                  {/* Header Section */}
                  <div className={styles['case-label-content-header-printable']}>
                    <Row gutter={2}>
                      {data?.brandLogoURL && (
                        <Col span={4}>
                          <div className={styles['case-label-brand-logo-printable']}>
                            <Image src={data?.brandLogoURL} preview={false} />
                          </div>
                        </Col>
                      )}
                      <Col span={data?.brandLogoURL ? 16 : 20}>
                        <h5 className={styles['case-label-product-name-printable']}>
                          {data?.productName ?? ''}
                        </h5>
                        {data?.productDescription && (
                          <h6 className={styles['case-label-product-description-printable']}>
                            {data?.fieldInfo?.find((c) => c.dataIndex === 'productDescription')
                              ?.visible && data?.productDescription}
                          </h6>
                        )}
                        {data?.processedInUSA && (
                          <Text
                            type="secondary"
                            className={styles['case-label-product-description-processed-USA']}
                          >
                            {t('form_fields.processed_in_usa')} {' - '}
                            {data.usStateOfProcessing}
                          </Text>
                        )}
                      </Col>
                      {hasCertOrCompanyLogos && (
                        <Col span={4}>
                          <Space
                            direction="vertical"
                            size={16}
                            className={styles['case-label-logos-container']}
                          >
                            {data?.certLogoURL && (
                              <div className={styles['case-label-brand-logo-printable']}>
                                <Image src={data?.certLogoURL} preview={false} />
                              </div>
                            )}
                            {data?.logoURL && (
                              <div className={styles['case-label-brand-logo-printable']}>
                                <Image src={data?.logoURL} preview={false} />
                              </div>
                            )}
                          </Space>
                        </Col>
                      )}
                    </Row>
                  </div>

                  {/* Fields Grid */}
                  <div className={styles['case-label-content-body-printable']}>
                    <Row gutter={[0, 7]} className={styles['printable-fields-grid']}>
                      {fieldsToDisplayData.map(renderField)}
                    </Row>
                  </div>

                  {/* Barcode and voice pick code section */}
                  <Row gutter={[7, 0]} align="bottom">
                    <Col span={barcodeType === 'gs1-datamatrix' ? 24 : 18}>
                      <div
                        className={
                          gs1String
                            ? styles['case-label-card-barcode-container']
                            : styles['case-label-card-barcode-container-empty']
                        }
                      >
                        {!gs1String && (
                          <Text type="secondary">
                            {t('empty_case_label_barcode_selection_text')}
                          </Text>
                        )}
                        {gs1String && (
                          <BarcodeGenerator
                            value={gs1String}
                            options={{
                              format: 'CODE128', // GS1-compatible barcode type
                              ean128: true, // Enable GS1 compatibility
                              text: gs1String, // Add human-readable text below
                              width: barcodeType === 'gs1-datamatrix' ? 1 : 1.3, // Width of bars
                              height: 120, // Height of the barcode
                              textMargin: 5, // Space between text and barcode
                              fontSize: 10, // Font size of the text
                              margin: 8, // Margin around the barcode
                              displayValue: true, //
                            }}
                            handlePrint={() => {}}
                            voicePickCode={data?.voicePickCode}
                            type={barcodeType}
                            height={110} // height for datamatrix (if selected)
                            typeOption="case"
                          />
                        )}
                      </div>
                    </Col>
                    {(barcodeType === 'barcode' || barcodeType === 'datamatrix') && (
                      <Col span={5}>
                        <div className={styles['case-label-voice-pick-code-container-printable']}>
                          {!isGS1andDataMatrix && !isPTI && hasWholechainLogo && (
                            <div className={styles['case-label-wholechain-logo']}>
                              <img src={wholechainLogo} alt="wholechain" />
                            </div>
                          )}

                          {isPTI && UPCField && isValidUPC && (
                            <BarcodeGenerator
                              value={UPCField}
                              options={{
                                format: 'upc',
                                width: 2,
                                height: 100,
                                displayValue: true,
                                font: 'monospace',
                                textAlign: 'center',
                                textPosition: 'bottom',
                                textMargin: 2,
                                fontSize: 12,
                                background: '#ffffff',
                                lineColor: '#000000',
                                margin: 10,
                              }}
                              type="barcode"
                              handlePrint={() => {}}
                            />
                          )}

                          {!isGS1andDataMatrix && hasVoicePickCode && (
                            <div className={styles['case-label-card-voice-pick-code-printable']}>
                              <Text
                                className={styles['case-label-card-voice-pick-code-text-printable']}
                              >
                                {voicePickCodeData?.toString().substring(0, 2)}
                                <span
                                  className={
                                    styles['case-label-card-voice-pick-code-text-special-digit']
                                  }
                                >
                                  {voicePickCodeData?.toString().substring(2, 4)}
                                </span>
                              </Text>
                            </div>
                          )}
                        </div>
                      </Col>
                    )}
                  </Row>
                </div>
              </div>
            </div>

            <div className={styles['case-label-wrapper-printable']}>
              <div className={styles['case-label-container-printable']}>
                <div className={styles['case-label-content-printable']}>
                  {/* Header Section */}
                  <div className={styles['case-label-content-header-printable']}>
                    <Row gutter={2}>
                      {data?.brandLogoURL && (
                        <Col span={4}>
                          <div className={styles['case-label-brand-logo-printable']}>
                            <Image src={data?.brandLogoURL} preview={false} />
                          </div>
                        </Col>
                      )}
                      <Col span={data?.brandLogoURL ? 16 : 20}>
                        <h5 className={styles['case-label-product-name-printable']}>
                          {data?.productName ?? ''}
                        </h5>
                        {data?.productDescription && (
                          <h6 className={styles['case-label-product-description-printable']}>
                            {data?.fieldInfo?.find((c) => c.dataIndex === 'productDescription')
                              ?.visible && data?.productDescription}
                          </h6>
                        )}
                        {data?.processedInUSA && (
                          <Text
                            type="secondary"
                            className={styles['case-label-product-description-processed-USA']}
                          >
                            {t('form_fields.processed_in_usa')} {' - '}
                            {data.usStateOfProcessing}
                          </Text>
                        )}
                      </Col>
                      {hasCertOrCompanyLogos && (
                        <Col span={4}>
                          <Space
                            direction="vertical"
                            size={16}
                            className={styles['case-label-logos-container']}
                          >
                            {data?.certLogoURL && (
                              <div className={styles['case-label-brand-logo-printable']}>
                                <Image src={data?.certLogoURL} preview={false} />
                              </div>
                            )}
                            {data?.logoURL && (
                              <div className={styles['case-label-brand-logo-printable']}>
                                <Image src={data?.logoURL} preview={false} />
                              </div>
                            )}
                          </Space>
                        </Col>
                      )}
                    </Row>
                  </div>

                  {/* Fields Grid */}
                  <div className={styles['case-label-content-body-printable']}>
                    <Row gutter={[0, 7]} className={styles['printable-fields-grid']}>
                      {fieldsToDisplayData.map(renderField)}
                    </Row>
                  </div>

                  {/* Barcode and voice pick code section */}
                  <Row gutter={[7, 0]} align="bottom">
                    <Col span={barcodeType === 'gs1-datamatrix' ? 24 : 18}>
                      <div
                        className={
                          gs1String
                            ? styles['case-label-card-barcode-container']
                            : styles['case-label-card-barcode-container-empty']
                        }
                      >
                        {!gs1String && (
                          <Text type="secondary">
                            {t('empty_case_label_barcode_selection_text')}
                          </Text>
                        )}
                        {gs1String && (
                          <BarcodeGenerator
                            value={gs1String}
                            options={{
                              format: 'CODE128', // GS1-compatible barcode type
                              ean128: true, // Enable GS1 compatibility
                              text: gs1String, // Add human-readable text below
                              width: barcodeType === 'gs1-datamatrix' ? 1 : 1.3, // Width of bars
                              height: 120, // Height of the barcode
                              textMargin: 5, // Space between text and barcode
                              fontSize: 10, // Font size of the text
                              margin: 8, // Margin around the barcode
                              displayValue: true, //
                            }}
                            handlePrint={() => {}}
                            voicePickCode={data?.voicePickCode}
                            type={barcodeType}
                            height={110} // height for datamatrix (if selected)
                            typeOption="case"
                          />
                        )}
                      </div>
                    </Col>
                    {(barcodeType === 'barcode' || barcodeType === 'datamatrix') && (
                      <Col span={5}>
                        <div className={styles['case-label-voice-pick-code-container-printable']}>
                          {!isGS1andDataMatrix && !isPTI && hasWholechainLogo && (
                            <div className={styles['case-label-wholechain-logo']}>
                              <img src={wholechainLogo} alt="wholechain" />
                            </div>
                          )}

                          {isPTI && UPCField && isValidUPC && (
                            <BarcodeGenerator
                              value={UPCField}
                              options={{
                                format: 'upc',
                                width: 2,
                                height: 100,
                                displayValue: true,
                                font: 'monospace',
                                textAlign: 'center',
                                textPosition: 'bottom',
                                textMargin: 2,
                                fontSize: 12,
                                background: '#ffffff',
                                lineColor: '#000000',
                                margin: 10,
                              }}
                              type="barcode"
                              handlePrint={() => {}}
                            />
                          )}

                          {!isGS1andDataMatrix && hasVoicePickCode && (
                            <div className={styles['case-label-card-voice-pick-code-printable']}>
                              <Text
                                className={styles['case-label-card-voice-pick-code-text-printable']}
                              >
                                {voicePickCodeData?.toString().substring(0, 2)}
                                <span
                                  className={
                                    styles['case-label-card-voice-pick-code-text-special-digit']
                                  }
                                >
                                  {voicePickCodeData?.toString().substring(2, 4)}
                                </span>
                              </Text>
                            </div>
                          )}
                        </div>
                      </Col>
                    )}
                  </Row>
                </div>
              </div>
            </div>
          </Space>
        )}
      </div>
    );
  },
);

export default BarcodeLabelLayoutPrint;
