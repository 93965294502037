import { FormInstance } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { UseModalVisibilityReturnType } from 'hooks';

export interface AddBrandProps {
  modal: UseModalVisibilityReturnType;
}

export interface UploadCropImageProps {
  title: string;
  form: FormInstance<any>;
  formKey: string;
  getImageURL?: (url?: string) => void;
  initialURL?: string;
  width?: number;
  height?: number;
  isFixedCropper?: boolean;
  initialObj?: RcFile;
}

export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px',
    borderRadius: '10px 14px',
  },
  overlay: {
    backgroundColor: 'rgba(0 0 0 / 30%)',
    zIndex: 5000,
  },
};

export const cropperCardStyles = { paddingBottom: '15px' };
