import { EditableFormInstance, ProColumns } from '@ant-design/pro-table';
import { FormInstance } from 'antd';
import { MutableRefObject } from 'react';
import { TFunction } from 'react-i18next';
import { CSVTemplate } from 'utils';
import { DataItem } from '../CompanyLocation.fields';

export interface CustomDataTemplateProps {
  form: FormInstance<any>;
  editableTableFormRef?: MutableRefObject<EditableFormInstance<any> | undefined>;
  t?: TFunction<'pages', 'network.add_network.form_fields'>;
}

type ColumnsType = {
  t: TFunction<'pages', 'network.add_network.form_fields'>;
  isVessel: boolean;
};

export const locationTemplate = ({ t, isVessel }: ColumnsType): CSVTemplate => ({
  companyName: {
    title: t('company_name'),
    dataIndex: 'companyName',
  },
  pgln: {
    title: t('pgln'),
    dataIndex: 'pgln',
  },
  connectionType: {
    title: t('connection_type'),
    dataIndex: 'connectionType',
  },
  duns: {
    title: t('duns'),
    dataIndex: 'duns',
  },
  tradepartnerNoId: {
    title: t('tradepartner_number'),
    dataIndex: 'tradepartnerNoId',
  },
  // digitalLink: {
  //   title: t('digital_link'),
  //   dataIndex: 'digitalLink',
  // },
  // apiKey: {
  //   title: t('api_key'),
  //   dataIndex: 'apiKey',
  // },
  locationName: {
    title: isVessel ? t('vessel_name') : t('location_name'),
    dataIndex: 'locationName',
  },
  address: {
    title: t('address'),
    dataIndex: 'address',
  },
  geoCoordinates: {
    title: t('geo_coordinates'),
    dataIndex: 'geoCoordinates',
  },
  email: {
    title: t('email'),
    dataIndex: 'email',
  },
  phoneCode: {
    title: t('phone_code'),
    dataIndex: 'phoneCode',
  },
  phoneNumber: {
    title: t('phone_number'),
    dataIndex: 'phoneNumber',
  },
  gln: {
    title: t('gln'),
    dataIndex: 'gln',
  },
  locNumberId: {
    title: t('location_number'),
    dataIndex: 'locNumberId',
  },
  ...(isVessel
    ? {
        vesselName: {
          title: t('vessel_name'),
          dataIndex: 'vesselName',
        },
        /*  vesselId: {
          title: t('vessel_id'),
          dataIndex: 'vesselId',
        }, */
        vesselState: {
          title: t('vessel_state'),
          dataIndex: 'vesselState',
        },
        imoNumber: {
          title: t('imo_number'),
          dataIndex: 'imoNumber',
        },
        vesselAuth: {
          title: t('vessel_auth'),
          dataIndex: 'vesselAuth',
        },
        vesselLink: {
          title: t('vessel_link'),
          dataIndex: 'vesselLink',
        },
        vesselReg: {
          title: t('vessel_reg'),
          dataIndex: 'vesselReg',
        },
        captainsName: {
          title: t('captains_name'),
          dataIndex: 'captainsName',
        },
      }
    : {}),
  contact: {
    title: t('contact'),
    dataIndex: 'contact',
  },
  extension: {
    title: t('extension'),
    dataIndex: 'extension',
  },
  locDuns: {
    title: t('dun_no'),
    dataIndex: 'locDuns',
  },
  polygon: {
    title: t('polygon'),
    dataIndex: 'polygon',
  },
});

type CSVColumnsType = {
  editableTableFormRef?: MutableRefObject<EditableFormInstance<DataItem> | undefined>;
  t: TFunction<'pages', 'network.add_network.form_fields'>;
  isVessel?: string;
};
export type CSVTblColumnsFn = (props: CSVColumnsType) => Array<ProColumns<DataItem>>;
