import { DeleteOutlined, InboxOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, ColProps, Form, Space, Tooltip, Typography, Upload, message } from 'antd';
import { useWatch } from 'antd/lib/form/Form';
import { UploadProps } from 'antd/lib/upload/interface';
import useListLocation from 'hooks/useListLocation';
import { useEventsStore } from 'pages/Events/hooks';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useAttributes, useProducts, useTemplateById, useTradePartners } from 'services/api';
import { TemplateCertificationResponse } from 'services/api/client/src';
import {
  CSVTemplate,
  certificationMappings,
  coreBizStep,
  coreDispositions,
  filterHiddenAttributes,
  getBizStepByIdentifier,
  getCompanyIdByName,
  getDispositionByIdentifier,
  getLocationIdByName,
  getProductByName,
  getProductsT,
  getTimezoneByOffsetorText,
  getUom,
  keysToSampleObject,
  msofficeHelpLink,
  readNewReceiveExcelTemplate,
  titletoKeys,
} from 'utils';
import { getDefaultValues } from 'utils/locations';
import XLSX from 'xlsx';
import { getTranslatedCSVTemplate } from '../CSVTemplates';
import { DataItem } from '../Forms/AllReceiveForm.fields';
import styles from './index.module.less';
import { CustomDataTemplateProps } from './typings';

const certificationKeyMappings: {
  [key: string]: string;
} = {
  type: 'certificationType',
  standard: 'certificationStandard',
  agency: 'certificationAgency',
  value: 'certificationValue',
  identification: 'certificationIdentification',
  id: 'id',
};

const uploadColProps: ColProps = { xs: 24, sm: 24, md: 12, lg: 12 };

const { Text, Link } = Typography;
const { Dragger } = Upload;

export const CustomDataTemplate: FC<CustomDataTemplateProps> = () => {
  const template = useEventsStore((state) => state.template);
  const { t } = useTranslation('pages', { keyPrefix: 'products' });
  return (
    <div>
      <Text className="ant-pro-form-group-title">{t('custom_data_template')}</Text>
      {(!template || !template?.templateAttributes) && (
        <div className={styles.content}>
          <Text type="secondary">{t('no_template')}</Text>
        </div>
      )}
    </div>
  );
};

export const CSVUpload: FC<CustomDataTemplateProps> = ({ form }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'products.data_template' });
  const { title } = useEventsStore();
  const { setFieldsValue } = form;
  const templateId = Form.useWatch('customData', form);
  const { data: template } = useTemplateById(templateId);
  const { data: locations } = useListLocation();
  const { data: attributes } = useAttributes();
  const { data: tradePartners } = useTradePartners({ types: ['Self', 'NonWholechain'] });
  const { data: preProducts } = useProducts({ pageNumber: 1, pageSize: 20 });
  const { data: dataProducts } = useProducts({ pageSize: preProducts?.totalItems });
  const newReceiveFileUpload: boolean = Form.useWatch?.('newReceiveFileUpload', form) || false;
  const locationId = Form.useWatch?.('location', form) || undefined;
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultValues = useMemo(
    () => getDefaultValues({ template, attributes, locationId }),
    [locationId, template, attributes],
  );

  const templateAttributes = useMemo(
    () =>
      template?.templateAttributes?.filter(filterHiddenAttributes)?.reduce((acc, templateObj) => {
        if (
          templateObj?.attribute?.fieldProperties?.fields?.propertyName === 'bizStep' ||
          templateObj?.attribute?.fieldProperties?.fields?.propertyName === 'disposition'
        ) {
          if (templateObj?.attribute?.fieldProperties?.values?.defaultValue) {
            const initialValue =
              templateObj?.attribute?.fieldProperties?.fields?.propertyName === 'bizStep'
                ? coreBizStep()?.find?.(
                    (el) =>
                      el.urn === templateObj?.attribute?.fieldProperties?.values?.defaultValue,
                  )?.name || undefined
                : coreDispositions()?.find?.(
                    (el) =>
                      el.urn === templateObj?.attribute?.fieldProperties?.values?.defaultValue,
                  )?.name || undefined;
            acc[String(templateObj?.attribute?.fieldProperties?.fields?.propertyName || '')] = {
              dataIndex: templateObj?.attribute?.fieldProperties?.fields?.propertyName || '',
              title: templateObj?.attribute.name || '',
              initialValue,
            };
          }
        } else {
          acc[String(templateObj?.attribute?.fieldProperties?.fields?.propertyName || '')] = {
            dataIndex: templateObj?.attribute?.fieldProperties?.fields?.propertyName || '',
            title: templateObj?.attribute?.name || '',
            ...(defaultValues?.[
              templateObj?.attribute?.fieldProperties?.fields?.propertyName || ''
            ] && {
              initialValue:
                defaultValues?.[
                  templateObj?.attribute?.fieldProperties?.fields?.propertyName || ''
                ],
            }),
          };
        }

        return acc;
      }, {} as CSVTemplate),
    [template, defaultValues],
  );
  const certificationAttributes = useMemo(() => {
    const certificationFields = template?.templateCertifications || [];
    if ((certificationFields?.length || 0) > 0) {
      return certificationFields.reduce((acc, field, idx) => {
        Object.keys(field)
          ?.filter((key) => key !== 'id' && key !== 'eventTemplateId', [])
          ?.forEach((key) => {
            const dataIndex = `${key} ${idx + 1}`;
            acc[dataIndex] = {
              dataIndex: `${key} ${idx + 1}`,
              title: `${getProductsT(certificationMappings[key])} ${idx + 1}`,
              initialValue: field?.[key as keyof TemplateCertificationResponse] || '',
            };
          });

        return acc;
      }, {} as CSVTemplate);
    }
    return {};
  }, [template]);

  const overallTemplateReceive: CSVTemplate = useMemo(
    () => ({
      ...certificationAttributes,
      ...templateAttributes,
    }),
    [certificationAttributes, templateAttributes],
  );

  const setFormData = useCallback(
    (value: DataItem) => {
      setFieldsValue(value);
    },
    [setFieldsValue],
  );

  // function to download upload csv template with already filled in data
  const downloadNewReceiveXLSXTemplate = useCallback(
    (sheetData1: any, sheetData2: any) => {
      const workbook = XLSX.utils.book_new();
      const sheet1 = XLSX.utils.json_to_sheet(sheetData1);
      XLSX.utils.book_append_sheet(workbook, sheet1, 'Receive Event Details');
      const sheet2 = XLSX.utils.json_to_sheet(sheetData2);
      XLSX.utils.book_append_sheet(workbook, sheet2, 'Receive Item Details');
      XLSX.writeFile(workbook, `${title}.xlsx`);
    },
    [title],
  );
  const isMultipleProducts: boolean = useWatch('isMultipleProducts', form) || false;

  const onDownloadCSVTemplate = useCallback(() => {
    const sampleDataObj = keysToSampleObject(getTranslatedCSVTemplate(overallTemplateReceive));
    const sheet1 = {
      'Receive To company': '',
      'Receive To Location': '',
      'PO Number': '',
      ...(isMultipleProducts ? { SSCC: '' } : { Product: '' }),
      Date: '',
      Timezone: '(UTC-11:00) Coordinated Universal Time-11',
      'Business Step': 'Commissioning (CBV)',
      Disposition: 'Active (CBV)',
      ...sampleDataObj,
    };
    const sheet2 = {
      ...(isMultipleProducts && { Product: '' }),
      'Primary ID': '',
      Quantity: '',
    };
    return downloadNewReceiveXLSXTemplate([sheet1], [sheet2]);
  }, [downloadNewReceiveXLSXTemplate, isMultipleProducts, overallTemplateReceive]);

  const getCSVDataByTemplate = (rawData: any) => {
    const certificationTable =
      template?.templateCertifications?.map((field, k) => {
        const keys = Object.keys(field).filter(
          (key) => key !== 'id' && key !== 'eventTemplateId',
          [],
        );
        const row: any = {};
        keys?.forEach((key) => {
          const keyField = certificationKeyMappings[key];
          const value =
            rawData?.receiveEventDetails?.[0]?.[
              `${getProductsT(certificationMappings[key])} ${k + 1}`
            ];
          row[keyField] = value;
        });

        return row;
      }, []) || [];

    const receiveEventDetails = rawData?.receiveEventDetails?.map((item: any) => {
      const product = getProductByName(item?.Product, dataProducts?.results);
      return {
        customProperties: titletoKeys(overallTemplateReceive, item),
        certificationList: certificationTable,
        date: item?.Date,
        time: item?.Time,
        location: getLocationIdByName(item?.['Receive To Location'], locations),
        companyName: item?.['Receive To company'],
        company: getCompanyIdByName(item?.['Receive To company'], tradePartners?.data),
        locationName: item?.['Receive To Location'],
        poNumber: item?.['PO Number'],
        bizStep: getBizStepByIdentifier(item?.['Business Step']),
        disposition: getDispositionByIdentifier(item?.Disposition),
        timeZone: getTimezoneByOffsetorText(item?.Timezone),
        ...(isMultipleProducts
          ? { sscc: item?.SSCC }
          : {
              parentProductId: product?.id,
              productIdentifierType: product?.productIdentifierType,
              productName: product?.name || '',
              isAssignedProduct: true,
              productProps: product,
              unitOfMeasure: getUom({ product }) || '',
              currentInventory: product?.currentInventory,
              currentInventoryTotal: product?.currentInventoryTotal,
              prodExtIdentifier: product?.externalIdentifier,
            }),
      };
    }, [])?.[0];
    const newParams = new URLSearchParams(searchParams);
    if (receiveEventDetails?.location) newParams.set('locationId', receiveEventDetails.location);
    if (receiveEventDetails?.company) newParams.set('tradePartnerId', receiveEventDetails.company);
    setSearchParams(newParams);
    const receiveItemDetails =
      rawData?.receiveItemDetails?.map((item: any, i: number) => {
        const product = getProductByName(item?.Product, dataProducts?.results);
        return {
          ...(isMultipleProducts
            ? {
                parentProductId: product?.id,
                productIdentifierType: product?.productIdentifierType,
                productName: product?.name || '',
                isAssignedProduct: true,
                productProps: product,
                unitOfMeasure: getUom({ product }) || '',
                currentInventory: product?.currentInventory,
                currentInventoryTotal: product?.currentInventoryTotal,
                prodExtIdentifier: product?.externalIdentifier,
              }
            : {
                productIdentifierType: receiveEventDetails?.productIdentifierType,
              }),
          id: `${item?.Product || ''}${item?.['Primary ID'] || ''}${i}`,
          quantity: item?.Quantity,
          primaryId: item?.['Primary ID'],
        };
      }, []) || [];

    return {
      ...receiveEventDetails,
      inboundShipments: receiveItemDetails,
      newReceiveFileUpload: true,
    };
  };
  const onCSVUpload: UploadProps['onChange'] = async (info) => {
    const { file } = info;
    file.status = 'done';
    if (!file.url && !file.preview && file.originFileObj) {
      const data = await readNewReceiveExcelTemplate(file.originFileObj);
      const newData: DataItem = getCSVDataByTemplate(data);
      setFormData(newData);
    }
  };
  const uploadProps: UploadProps = {
    name: 'csvfile',
    multiple: false,
    maxCount: 1,
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    onChange: onCSVUpload,
    beforeUpload: async (file) => {
      const isCSV =
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      if (!isCSV) {
        message.error(t('only_xlsx_upload'));
      }
      // check if csv file contains data
      const data = await readNewReceiveExcelTemplate(file);
      const newData: DataItem = getCSVDataByTemplate(data);
      if (!newData?.inboundShipments) {
        message.error(t('no_csv'));
      }

      return true;
    },
    showUploadList: false,
  };

  const onRemoveFile = useCallback(() => {
    setFormData({
      customProperties: [],
      certificationList: [],
      date: undefined,
      time: undefined,
      location: undefined,
      companyName: undefined,
      company: undefined,
      locationName: undefined,
      poNumber: undefined,
      inboundShipments: [],
      newReceiveFileUpload: false,
      ...(isMultipleProducts ? { sscc: undefined } : { parentProductId: undefined }),
    });
  }, [isMultipleProducts, setFormData]);

  return (
    <>
      <Space size="large" direction="vertical" className={styles.csvbtn}>
        <Space>
          <Space direction="vertical">
            {!newReceiveFileUpload && (
              <Typography.Text>
                <Typography.Text strong>Step 1:</Typography.Text> Download template and input
                receive details.{' '}
                <Tooltip
                  title={
                    <div className={styles.tooltiplink}>
                      {t('upload_tooltip')}
                      &nbsp;
                      <Link target="_blank" href={msofficeHelpLink} rel="noreferrer">
                        {t('upload_tooltip_href')}
                      </Link>
                    </div>
                  }
                >
                  <Typography.Text>
                    <InfoCircleOutlined className={styles.tooltip} />
                  </Typography.Text>
                </Tooltip>
              </Typography.Text>
            )}
          </Space>
        </Space>
        {!newReceiveFileUpload && (
          <Button shape="round" type="primary" onClick={onDownloadCSVTemplate}>
            {t('download_csv_template')}
          </Button>
        )}
        {!newReceiveFileUpload && (
          <Typography.Text>
            <Typography.Text strong>Step 2:</Typography.Text> Upload template to add receive details
            to each shipment.
          </Typography.Text>
        )}
      </Space>

      {newReceiveFileUpload ? (
        <div>
          <Button
            icon={<DeleteOutlined />}
            type="primary"
            shape="round"
            ghost
            onClick={onRemoveFile}
            className={styles.removebtn}
          >
            {t('remove_file')}
          </Button>
        </div>
      ) : (
        <Col {...uploadColProps}>
          <Dragger {...uploadProps}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{t('upload_desc')}</p>
          </Dragger>
        </Col>
      )}
    </>
  );
};

const DataTemplate: FC<CustomDataTemplateProps> = ({
  form,
  editableTableFormRef,
  eventProducts,
}) => (
  <CSVUpload
    form={form}
    editableTableFormRef={editableTableFormRef}
    eventProducts={eventProducts}
  />
);

export default React.memo(DataTemplate);
