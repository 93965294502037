import { FormInstance, ProFormColumnsType, ProFormInstance } from '@ant-design/pro-form';
import type { SelectProps } from 'antd/es/select';
import { RcFile } from 'antd/lib/upload';
import { UseModalVisibilityReturnType } from 'hooks';
import JsBarcode from 'jsbarcode';
import { MutableRefObject } from 'react';
import { TFunction } from 'react-i18next';
import { GetApplicationIdentifiersResponse, Gs1DataModel } from 'services/api/client/src';
import { getT } from 'utils';
import countryData from 'assets/countries.json';

export type PrintOptions = '4x6' | '4x12' | '4x2';

export interface Country {
  name: {
    common: string;
    official: string;
    native: {
      [key: string]: {
        official: string;
        common: string;
      };
    };
  };
  tld: string[];
  cca2: string;
  ccn3: string;
  cca3: string;
  cioc: string;
  independent: boolean;
  status: string;
  currencies: {
    [key: string]: {
      name: string;
      symbol: string;
    };
  };
  idd: {
    root: string;
    suffixes: string[];
  };
  capital: string[];
  altSpellings: string[];
  region: string;
  subregion: string;
  languages: {
    [key: string]: string;
  };
  translations: {
    [key: string]: {
      official: string;
      common: string;
    };
  };
  latlng: number[];
  landlocked: boolean;
  borders: string[];
  area: number;
  flag: string;
  demonyms: {
    eng: {
      f: string;
      m: string;
    };
    fra: {
      f: string;
      m: string;
    };
  };
}

export interface ColumnsType {
  t: TFunction<'pages', 'dashboard.add_brand_modal'>;
  isEdit?: boolean;
  onLabelEdit?: () => void;
  onBarcodeEdit?: () => void;
  modalCardRef?: MutableRefObject<ProFormInstance | undefined>;
  modalFormRef?: MutableRefObject<ProFormInstance | undefined>;
}

export interface SectionProps {
  title?: string;
  content?: string;
  data?: string;
  position?: number;
}

export interface FieldItem {
  applicationIdentifier?: Gs1DataModel;
  dataIndex: string;
  title: string;
  visible: boolean;
  required?: boolean;
  isOnlyLabel?: boolean; // this is only for barcode (left side only)
  hideRequired?: boolean;
  isCustom?: boolean;
  hideVisible?: boolean;
  appId?: string;
  value?: string;
  id?: string;
}

export type DataItem = {
  id?: string;
  product?: string;
  labelName?: string;
  gtin?: string;
  formFieldList?: Array<SectionProps>;
  existingSSCC?: string;
  fieldSSCC?: string;
  ssccName?: string;
  sscc?: string;
  fieldInfo?: FieldItem[];
  newFieldInfo?: FieldItem[];
  barcodeFieldInfo?: FieldItem[];
  newBarcodeFieldInfo?: FieldItem[];
  layoutType?: string;
  brandLogo?: string | Blob | RcFile;
  logo?: string | Blob | RcFile;
  certificationLogo?: string | Blob | RcFile;
  productDescription?: string;
  processedInUSA?: string;
  usStateOfProcessing?: string;
  quantity?: number;
  unitOfMeasure?: string;
  harvestMethod?: string;
  itemNumber?: string;
  coolInformation?: string;
  vendorID?: string;
  supplierInformation?: string;
  supplierStockNumber?: string;
  sellingUnitUPCA?: string;
  uniquePackageID?: string;
  barcodeType?: string;
  gtin14?: string;
  batchLotID?: string;
  packDate?: Date;
  countryOfOrigin?: string;
  sellByDate?: Date;
  bestBeforeDate?: Date;
  harvestDate?: Date;
  productId?: string;
  productName?: string;
  logoURL?: string;
  certLogoURL?: string;
  brandLogoURL?: string;
  voicePickCode?: string;
  printOptions?: string;
  ogNewFieldInfo?: FieldItem[];
  ogNewBarcodeFieldInfo?: FieldItem[];
};

export type LabelColumnsFn = (props: ColumnsType) => Array<ProFormColumnsType<DataItem>>;
export type LabelColumnFnExtended = (props: {
  isEdit?: boolean;
  handlePrintOption?: (option: PrintOptions) => void;
  applicationIdentifierLookup?: GetApplicationIdentifiersResponse;
  modalFormRef?: MutableRefObject<ProFormInstance | undefined>;
}) => Array<ProFormColumnsType<DataItem>>;

export interface AddEditProps {
  t: TFunction<'pages', 'dashboard.add_brand_modal'>;
  modal?: UseModalVisibilityReturnType;
  labelId?: string;
}

export interface AddModalProps {
  modal?: UseModalVisibilityReturnType;
  brandId?: string;
  logoURL?: string;
  accentColor?: string;
  onCSSFinish?: (css: string) => void;
  css?: string;
  onHeaderFooterFinish?: (values?: DataItem) => void;
  loading?: boolean;
  isEdit?: boolean;
  initialData?: DataItem | undefined;
}

export type addSubmitTypeFn = (formData: DataItem) => Promise<void>;

export interface EditLabelInfoModalProps {
  modal: UseModalVisibilityReturnType;
  newAPIKey?: string;
  t: TFunction<'pages', 'network.add_network.form_fields.inbound_key'>;
  modalTitle?: string;
  onSave?: (apiKey: string) => void;
  formRef: MutableRefObject<FormInstance | undefined>;
  cardRef: MutableRefObject<FormInstance | undefined>;
}

export interface EditInfoFormProps {
  records?: FieldItem[];
  setRecords?: (records: FieldItem[]) => void;
}

export const defaultFieldInfoObject = (): FieldItem[] => {
  const t = (key: string) => getT(`labels.add_label.edit_label_info.table.form_fields.${key}`);

  return [
    {
      title: t?.('brand_logo'),
      dataIndex: 'brandLogo',
      visible: true,
      required: false,
      isOnlyLabel: true,
    },
    {
      title: t?.('product_name'),
      dataIndex: 'productName',
      visible: true,
      required: false,
      hideRequired: true,
      isOnlyLabel: true,
    },
    {
      title: t?.('product_description'),
      dataIndex: 'productDescription',
      visible: false,
      required: false,
      hideRequired: true,
      isOnlyLabel: true,
    },
    {
      title: t?.('processed_in_usa'),
      dataIndex: 'processedInUSA',
      visible: true,
      required: false,
      hideRequired: true,
      isOnlyLabel: true,
    },
    {
      title: t?.('cert_logo_level_indicator'),
      dataIndex: 'certificationLogo',
      visible: true,
      required: true,
      isOnlyLabel: true,
    },
    {
      title: t?.('logo'),
      dataIndex: 'logo',
      visible: false,
      required: false,
      isOnlyLabel: true,
    },
    {
      title: t?.('quantity'),
      dataIndex: 'quantity',
      visible: true,
      required: true,
    },
    {
      title: t?.('unit_of_measure'),
      dataIndex: 'unitOfMeasure',
      visible: true,
      required: true,
    },
    {
      title: t?.('item_number'),
      dataIndex: 'itemNumber',
      visible: false,
      required: true,
    },
    {
      title: t?.('cool_information'),
      dataIndex: 'coolInformation',
      visible: true,
      required: true,
    },
    {
      title: t?.('harvest_method'),
      dataIndex: 'harvestMethod',
      visible: true,
      required: true,
    },
    {
      title: t?.('supplier_information'),
      dataIndex: 'supplierInformation',
      visible: false,
      required: false,
    },
    {
      title: t?.('supplier_stock_number'),
      dataIndex: 'supplierStockNumber',
      visible: false,
      required: false,
    },
    {
      title: t?.('vendor_id'),
      dataIndex: 'vendorID',
      visible: true,
      required: true,
    },
    {
      title: t?.('voice_pick_code'),
      dataIndex: 'voicePickCode',
      visible: false,
      required: true,
      isOnlyLabel: true,
      hideRequired: true,
    },
    {
      title: t?.('wc_logo'),
      dataIndex: 'wcLogo',
      visible: true,
      required: true,
      isOnlyLabel: true,
      hideRequired: true,
    },
    {
      title: t?.('unique_package_id'),
      dataIndex: 'uniquePackageID',
      visible: true,
      required: true,
    },
    {
      title: t?.('selling_unit_upca'),
      dataIndex: 'sellingUnitUPCA',
      visible: false,
      required: false,
    },
    {
      title: t?.('gtin_14'),
      dataIndex: 'gtin14',
      visible: true,
      required: false,
      isOnlyLabel: false,
    },
    {
      title: t?.('countryOfOrigin'),
      dataIndex: 'countryOfOrigin',
      visible: true,
      required: false,
      isOnlyLabel: false,
    },
    {
      title: t?.('batchLotID'),
      dataIndex: 'batchLotID',
      visible: true,
      required: false,
      isOnlyLabel: false,
    },
    {
      title: t?.('packDate'),
      dataIndex: 'packDate',
      visible: true,
      required: false,
      isOnlyLabel: false,
    },
    {
      title: t?.('packaging'),
      dataIndex: 'packaging',
      visible: true,
      required: true,
      isOnlyLabel: false,
    },
    {
      title: t?.('grown_in'),
      dataIndex: 'grownIn',
      visible: true,
      required: true,
      isOnlyLabel: false,
    },
    {
      title: t?.('grade'),
      dataIndex: 'grade',
      visible: true,
      required: true,
      isOnlyLabel: false,
    },
    {
      title: t?.('responsible_party'),
      dataIndex: 'responsibleParty',
      visible: true,
      required: true,
      isOnlyLabel: false,
    },
    {
      title: t?.('plu'),
      dataIndex: 'plu',
      visible: true,
      required: true,
      isOnlyLabel: false,
    },
    {
      title: t?.('upc'),
      dataIndex: 'upc',
      visible: true,
      required: true,
      isOnlyLabel: true,
    },
    {
      title: t?.('harvest_date'),
      dataIndex: 'harvestDate',
      visible: true,
      required: false,
      isOnlyLabel: true,
    },
  ];
};

export const defaultBarcodeFields = (): FieldItem[] => {
  const t = (key: string) => getT(`labels.add_label.edit_label_info.table.barcode_fields.${key}`);
  return [
    {
      title: t?.('barcode_type'),
      dataIndex: 'barcodeType',
      visible: true,
      required: true,
      hideRequired: true,
      hideVisible: true,
    },
    {
      title: t?.('gtin_14'),
      dataIndex: 'gtin14',
      visible: true,
      required: false,
      hideRequired: true,
      hideVisible: true,
      appId: '01',
    },
    {
      title: t?.('batch_lot_id'),
      dataIndex: 'batchLotID',
      visible: true,
      required: true,
      hideRequired: true,
      hideVisible: true,
      appId: '10',
    },
    {
      title: t?.('country_of_origin'),
      dataIndex: 'countryOfOrigin',
      visible: true,
      required: true,
      hideVisible: true,
      appId: '422',
    },
    {
      title: t?.('sell_by_date'),
      dataIndex: 'sellByDate',
      visible: false,
      required: false,
      appId: '16',
    },
    {
      title: t?.('best_before_date'),
      dataIndex: 'bestBeforeDate',
      visible: false,
      required: false,
      appId: '15',
    },
    {
      title: t?.('pack_date'),
      dataIndex: 'packDate',
      visible: true,
      required: true,
      appId: '13',
    },
    {
      title: t?.('harvest_date'),
      dataIndex: 'harvestDate',
      visible: true,
      required: false,
      appId: '7007',
    },
  ];
};

export const barcodePredefinedFields = [
  '10-BATCH/LOT',
  '422-ORIGIN',
  '16-SELL BY',
  '15-BEST BEFORE or BEST BY',
  '13-PACK DATE',
  '01-GTIN',
];

export interface BarcodePalletLayoutProps {
  sections: SectionProps[] | undefined | null;
  sscc: string;
  gtin: string | undefined | null;
  t: (key: string) => string;
  printOptions?: PrintOptions;
  handlePrintOption?: (option: PrintOptions) => void | undefined;
  data?: DataItem;
  modalFormRef?: MutableRefObject<ProFormInstance | undefined>;
}

export interface BarcodeLabelLayoutProps {
  data: DataItem | undefined;
  t: (key: string) => string;
  brandLogo?: string | undefined | null;
  certificationLogo: string | undefined | null;
  companyLogo?: string | undefined | null;
  handlePrintOption?: (option: PrintOptions) => void | undefined;
  printOption?: PrintOptions;
  modalCardRef?: MutableRefObject<ProFormInstance | undefined>;
  modalFormRef?: MutableRefObject<ProFormInstance | undefined>;
  applicationIdentifierLookup?: GetApplicationIdentifiersResponse;
}

export type BarcodeType = 'text' | 'barcode' | 'qrcode' | 'datamatrix' | 'gs1-datamatrix';
export type BarcodeTypeOption = 'case' | 'pallet';
export type BarcodeGeneratorProps = {
  type: BarcodeType;
  value: string | undefined | null;
  voicePickCode?: string | undefined | null;
  options: JsBarcode.Options;
  handlePrint: () => void;
  width?: number;
  height?: number;
  typeOption?: BarcodeTypeOption;
};

// Define field configurations with their order and requirements
export const FIELD_CONFIG = [
  { key: 'quantity', required: true, order: 1 },
  { key: 'gtin14', required: true, order: 2 },
  { key: 'batchLotID', required: true, order: 3 },
  { key: 'unitOfMeasure', required: true, order: 4 },
  { key: 'itemNumber', required: false, order: 5 },
  { key: 'countryOfOrigin', required: true, order: 6 },
  { key: 'packDate', required: true, order: 7 },
  { key: 'sellByDate', required: false, order: 8 },
  { key: 'bestBeforeDate', required: false, order: 9 },
  { key: 'vendorID', required: false, order: 10 },
  { key: 'harvestMethod', required: false, order: 11 },
  { key: 'coolInformation', required: false, order: 13 },
  { key: 'supplierInformation', required: false, order: 14 },
  { key: 'supplierStockNumber', required: false, order: 15 },
  { key: 'uniquePackageID', required: false, order: 16 },
  { key: 'sellingUnitUPCA', required: false, order: 17 },
  { key: 'packaging', required: false, order: 18 },
  { key: 'grownIn', required: false, order: 19 },
  { key: 'grade', required: false, order: 20 },
  { key: 'harvestDate', required: false, order: 21 },
] as const;

export type StateSelectProps = Omit<SelectProps, 'options'> & {
  // you can add any additional props specific to StateSelect
};
export type GS1CountrySelectProps = Omit<SelectProps, 'options'> & {
  // you can add any additional props specific to GS1CountrySelect
};

export const states = [
  { value: 'Alabama', label: 'Alabama' },
  { value: 'Alaska', label: 'Alaska' },
  { value: 'Arizona', label: 'Arizona' },
  { value: 'Arkansas', label: 'Arkansas' },
  { value: 'California', label: 'California' },
  { value: 'Colorado', label: 'Colorado' },
  { value: 'Connecticut', label: 'Connecticut' },
  { value: 'Delaware', label: 'Delaware' },
  { value: 'Florida', label: 'Florida' },
  { value: 'Georgia', label: 'Georgia' },
  { value: 'Hawaii', label: 'Hawaii' },
  { value: 'Idaho', label: 'Idaho' },
  { value: 'Illinois', label: 'Illinois' },
  { value: 'Indiana', label: 'Indiana' },
  { value: 'Iowa', label: 'Iowa' },
  { value: 'Kansas', label: 'Kansas' },
  { value: 'Kentucky', label: 'Kentucky' },
  { value: 'Louisiana', label: 'Louisiana' },
  { value: 'Maine', label: 'Maine' },
  { value: 'Maryland', label: 'Maryland' },
  { value: 'Massachusetts', label: 'Massachusetts' },
  { value: 'Michigan', label: 'Michigan' },
  { value: 'Minnesota', label: 'Minnesota' },
  { value: 'Mississippi', label: 'Mississippi' },
  { value: 'Missouri', label: 'Missouri' },
  { value: 'Montana', label: 'Montana' },
  { value: 'Nebraska', label: 'Nebraska' },
  { value: 'Nevada', label: 'Nevada' },
  { value: 'New Hampshire', label: 'New Hampshire' },
  { value: 'New Jersey', label: 'New Jersey' },
  { value: 'New Mexico', label: 'New Mexico' },
  { value: 'New York', label: 'New York' },
  { value: 'North Carolina', label: 'North Carolina' },
  { value: 'North Dakota', label: 'North Dakota' },
  { value: 'Ohio', label: 'Ohio' },
  { value: 'Oklahoma', label: 'Oklahoma' },
  { value: 'Oregon', label: 'Oregon' },
  { value: 'Pennsylvania', label: 'Pennsylvania' },
  { value: 'Rhode Island', label: 'Rhode Island' },
  { value: 'South Carolina', label: 'South Carolina' },
  { value: 'South Dakota', label: 'South Dakota' },
  { value: 'Tennessee', label: 'Tennessee' },
  { value: 'Texas', label: 'Texas' },
  { value: 'Utah', label: 'Utah' },
  { value: 'Vermont', label: 'Vermont' },
  { value: 'Virginia', label: 'Virginia' },
  { value: 'Washington', label: 'Washington' },
  { value: 'West Virginia', label: 'West Virginia' },
  { value: 'Wisconsin', label: 'Wisconsin' },
  { value: 'Wyoming', label: 'Wyoming' },
];

export interface CountryCode {
  label: string; // Country name
  value: string; // ISO 3166-1 numeric code (3 digits) for AI 422
}

const countries: Country[] = countryData as unknown as Country[];

// GS1 (application identifier codes)
export const countryCodes = countries.map((country: Country) => ({
  label: country.name.common,
  value: country.ccn3,
}));

export const countryCodesOld = [
  { label: 'United States of America', value: '840' },
  { label: 'Canada', value: '124' },
  { label: 'Mexico', value: '484' },
  { label: 'United Kingdom', value: '826' },
  { label: 'France', value: '250' },
  { label: 'Germany', value: '276' },
  { label: 'Italy', value: '380' },
  { label: 'Spain', value: '724' },
  { label: 'Japan', value: '392' },
  { label: 'China', value: '156' },
  { label: 'Australia', value: '036' },
  { label: 'Brazil', value: '076' },
  { label: 'Argentina', value: '032' },
  { label: 'Chile', value: '152' },
  { label: 'Uruguay', value: '858' },
  { label: 'Peru', value: '604' },
  { label: 'Colombia', value: '170' },
  { label: 'Venezuela', value: '862' },
  { label: 'Ecuador', value: '218' },
  { label: 'Bolivia', value: '068' },
  { label: 'Paraguay', value: '600' },
  { label: 'India', value: '356' },
  { label: 'Russia', value: '643' },
  { label: 'South Africa', value: '710' },
  { label: 'Egypt', value: '818' },
  { label: 'Saudi Arabia', value: '682' },
  { label: 'United Arab Emirates', value: '784' },
  { label: 'Singapore', value: '702' },
  { label: 'South Korea', value: '410' },
  { label: 'Netherlands', value: '528' },
  { label: 'Sweden', value: '752' },
  { label: 'Switzerland', value: '756' },
  { label: 'Poland', value: '616' },
  { label: 'Belgium', value: '056' },
  { label: 'Norway', value: '578' },
  { label: 'Denmark', value: '208' },
  { label: 'Finland', value: '246' },
  { label: 'Ireland', value: '372' },
  { label: 'New Zealand', value: '554' },
];

export type BarcodePreviewFields = {
  key: string;
  title: string;
  required: boolean;
  value: any;
  isCustom: boolean;
  order: number;
};
