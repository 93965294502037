import { InfoCircleOutlined } from '@ant-design/icons';
import { ActionType, ProColumns } from '@ant-design/pro-table';
import { RecordKey } from '@ant-design/pro-utils/lib/useEditableArray';
import { Form, message, Typography } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { ProductSelect } from 'components';
import ActionButtons from 'components/ActionButtons';
import ProductLotSelect from 'components/GSelect/ProductLotSelect';
import GTable from 'components/GTable';
import moment, { Moment } from 'moment';
import useProductStore from 'pages/Products/useProductStore';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { ProductResponse } from 'services/api/client/src';
import { useGetProductInstanceById, useMultipleProductInstances } from 'services/api/useProducts';
import {
  dateFormat,
  disableExistingLotLocations,
  getIdentifier,
  getInventoryPropsAddon,
  getIsSerial,
  getLotUomAddon,
  getMaxEventDate,
  getProductsT,
  getQuantityAddon,
  hiddencol,
  mapInvDataItem,
  onChangeFieldsPropInstance,
  onChangeFieldsPropProduct,
} from 'utils';
import { AggregateProductItem } from '../AggregationForm.fields';
import { useEventActions } from '../hooks';
import { ShipProductsProps } from './typings';

type ColumnsType = {
  onEditRow: (actionPayload?: AggregateProductItem) => void;
  onDelRow: (actionPayload?: AggregateProductItem) => void;
  onChangeProduct: (
    value: string,
    option: DefaultOptionType | DefaultOptionType[],
    totalInventoryAtSameLocation: Number,
  ) => void;
  locationId?: string;
  product?: ProductResponse;
  defaultUom?: string;
  isSerial?: boolean;
  showEdit?: boolean;
  showDelete?: boolean;
  identifier?: string;
  products?: AggregateProductItem[];
  eventTime?: Moment;
  t?: TFunction<'pages', 'events.aggregate.form_fields'>;
  instanceByProductId?: any;
  locationValue: string;
};
const columns = ({
  onEditRow,
  onDelRow,
  onChangeProduct,
  t,
  locationId,
  defaultUom,
  products,
  eventTime,
  identifier,
  showDelete,
  showEdit,
  instanceByProductId,
  locationValue,
}: ColumnsType): Array<ProColumns<AggregateProductItem>> => [
  {
    title: t?.('tbl_col_product_title'),
    dataIndex: 'parentProductId',
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('tbl_col_product_req'),
        },
      ],
      hasFeedback: false,
    },
    renderFormItem: (_, config, form) => (
      <ProductSelect
        showExternalIdentifier
        isInsideTable
        key="aggselect"
        allowLotSerial
        size="small"
        onChange={async (value, option: any) => {
          // fetch instance when a product is changed
          const productInventoryData = await instanceByProductId.mutateAsync({
            id: option?.itemProps?.id || '',
            params: {
              locationIds: [locationValue],
            },
          });

          // get total Item at a location
          const totalInventoryAtSameLocation = Number(
            productInventoryData?.results?.[0]?.productInstance?.totalInventoryAtSameLocation ||
              productInventoryData?.results?.[0]?.container?.containerItems?.[0]
                ?.totalInventoryAtSameLocation ||
              0,
          );
          onChangeFieldsPropProduct(form, config, option, {}, totalInventoryAtSameLocation);
          onChangeProduct(value, option, totalInventoryAtSameLocation);
        }}
      />
    ),
    render: (text, record) => (
      <div>
        {record.externalIdentifier ? (
          <Typography.Text>{`${record?.externalIdentifier} `}</Typography.Text>
        ) : (
          ''
        )}
        <Typography.Text type="secondary">{record?.name}</Typography.Text>
      </div>
    ),
  },
  {
    title: t?.('tbl_col_primary_id_title'),
    dataIndex: 'primaryId',
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('tbl_col_primary_id_req'),
        },
      ],
      hasFeedback: false,
    },

    renderFormItem: (_, { recordKey, record }, form) => (
      <ProductLotSelect
        productId={record?.parentProductId}
        disabledItem={(item) => disableExistingLotLocations(item, products, locationId, eventTime)}
        size="small"
        onChange={(value, option) => onChangeFieldsPropInstance(form, { recordKey }, option)}
      />
    ),
    render: (text, record) => getIdentifier(record, identifier),
  },
  {
    title: t?.('tbl_col_quantity_title'),
    dataIndex: 'quantity',
    valueType: 'digit',
    fieldProps: (form, config) =>
      getLotUomAddon(
        form,
        config,
        defaultUom,
        {},
        products,
        getIsSerial(products, config.rowKey, form),
      ),

    formItemProps: (form, { rowKey }) => ({
      rules: [
        {
          required: !getIsSerial(products, rowKey, form),
          message: t?.('tbl_col_quantity_req'),
        },
      ],
      hasFeedback: false,
    }),
    render: (dom, record) =>
      record?.unitOfMeasure !== 'item'
        ? `${record?.quantity || ''} ${getQuantityAddon(record, defaultUom)}`
        : '1 item',
  },
  {
    title: t?.('tbl_col_current_inventory_title'),
    dataIndex: 'currentInventory',
    tooltip: {
      title: t?.('tbl_col_current_inventory_tooltip'),
      icon: <InfoCircleOutlined />,
    },
    fieldProps: (form, config) =>
      getInventoryPropsAddon(
        form,
        config,
        defaultUom,
        {
          disabled: true,
          placeholder: t?.('tbl_col_current_inventory_placeholder'),
        },
        getIsSerial(products, config.rowKey, form),
      ),
    render: (dom, record) =>
      `${record.currentInventory || 0} ${
        record?.unitOfMeasure !== 'item' ? record?.unitOfMeasure : 'items'
      }`,
  },
  {
    dataIndex: 'instanceInventory',
    ...hiddencol,
  },
  {
    dataIndex: 'eventDate',
    ...hiddencol,
  },
  {
    dataIndex: 'externalIdentifier',
    ...hiddencol,
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    render: (_text, record) => (
      <ActionButtons
        record={record}
        onEdit={onEditRow}
        onDelete={onDelRow}
        showEdit={showEdit}
        showDelete={showDelete}
      />
    ),
  },
];
const AggregateProducts: FC<ShipProductsProps> = ({
  form,
  date,
  time,
  identifier,
  isSerial,
  selectedInstances,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'events.aggregate.form_fields' });
  const [fetched, setFetched] = useState<boolean>(false);

  const { getFieldValue, setFieldsValue } = form;
  const elocationId = getFieldValue('location');

  const queryClient = useQueryClient();
  const instanceByProductId = useGetProductInstanceById(queryClient);
  const locationValue: string = getFieldValue('location');

  const eventTime = useMemo(
    () => (date && time ? moment(`${dateFormat(date)} ${time}`, 'YYYY-MM-DD HH:mm:ss') : undefined),
    [date, time],
  );
  const showEdit = useMemo(() => (selectedInstances?.length || 0) === 0, [selectedInstances]);
  const showDelete = useMemo(() => (selectedInstances?.length || 0) === 0, [selectedInstances]);

  const sactionRef = useRef<ActionType>();
  const {
    product: parentProduct,
    productId,
    productIdArray,
    locationId,
    defaultUom,
    setLastEventDate,
  } = useEventActions({
    locationId: elocationId,
  });
  const { paramsStore } = useProductStore();
  const {
    data: dataProducts,
    isLoading,
    isSuccess,
  } = useMultipleProductInstances(productIdArray, [], paramsStore);

  const [tmpProduct, setTmpProduct] = useState<AggregateProductItem>();

  const products: Array<AggregateProductItem> = Form?.useWatch('eventProducts', form) || [];

  const setProducts = useCallback(
    (value: Array<AggregateProductItem>) => {
      setFieldsValue({
        eventProducts: value,
      });
    },
    [setFieldsValue],
  );
  useEffect(() => {
    if (isSuccess && !fetched) {
      const modifiedProducts = dataProducts?.map(mapInvDataItem) || [];

      setProducts(modifiedProducts);
      setFetched(true);
    }

    if ((selectedInstances?.length || 0) > 0) {
      const initProducts =
        selectedInstances?.map((p) => ({
          id: p?.id || '0',
          parentProductId: p?.productId,
          name: p?.name || '',
          primaryId: p?.id || '0',
          lotSerial: p?.lotSerial || '',
          quantity: p?.quantity || 0,
          currentInventory: p?.currentInventory || 0,
          unitOfMeasure: p?.unitOfMeasure,
          instanceInventory: p?.quantity || 0,
          productIdentifierType: p?.productIdentifierType || 'Lot',
          externalIdentifier: p?.externalIdentifier,
        })) || [];
      setProducts(initProducts);
    }
  }, [
    dataProducts,
    isSuccess,
    setProducts,
    productId,
    parentProduct,
    isSerial,
    selectedInstances,
    fetched,
  ]);
  const onChangeProduct: ColumnsType['onChangeProduct'] = (
    value,
    option,
    totalInventoryAtSameLocation,
  ) => {
    if (value && option) {
      // @ts-ignore
      const selProd: ProductResponse = option?.itemProps;

      setTmpProduct({
        id: value || '0',
        name: selProd?.name || '',
        parentProductId: productId || '0',
        lotSerial: '',
        primaryId: '',
        currentInventory: Number(totalInventoryAtSameLocation || 0),
        quantity: Number(selProd?.shippedInventory || 0),
        unitOfMeasure: selProd?.simpleUnitOfMeasurement || '',
        externalIdentifier: selProd?.externalIdentifier,
      });
    }
  };
  const onEditRow = (actionPayload?: AggregateProductItem) => {
    sactionRef.current?.startEditable(actionPayload?.id || 0);
    setTmpProduct(actionPayload);
  };
  const onDelRow = async (actionPayload?: AggregateProductItem) => {
    if (actionPayload && products.length > 1) {
      setProducts(products.filter((p) => p.id !== actionPayload.id));
    } else {
      message.error(getProductsT('delete_last_product'));
    }
  };
  const updateEventDate = (records?: Array<AggregateProductItem>) => {
    const maxEventDate = getMaxEventDate(records);

    setLastEventDate(maxEventDate);
  };

  const onSaveProduct = async (rowKey: RecordKey, data: AggregateProductItem) => {
    const isSer = getIsSerial(products, String(rowKey), form);
    sactionRef.current?.cancelEditable(data?.id || 0);

    const existingProduct = products.find((product) => product.id === data?.id);
    if (existingProduct) {
      existingProduct.name = tmpProduct?.name || data?.name || '';
      existingProduct.quantity = isSer ? 1 : data?.quantity;
      existingProduct.instanceInventory = data?.instanceInventory || 0;
      existingProduct.currentInventory =
        data?.currentInventory || tmpProduct?.currentInventory || 0;
      existingProduct.primaryId = data?.primaryId || '';
      existingProduct.lotSerial = data?.lotSerial || '';
      existingProduct.parentProductId = data?.parentProductId || tmpProduct?.parentProductId;
      existingProduct.unitOfMeasure = data?.unitOfMeasure || '';
      existingProduct.productIdentifierType = data?.productIdentifierType || '';
      existingProduct.externalIdentifier = tmpProduct?.externalIdentifier;

      // update product
      const updatedProducts = products.map((p) =>
        p.id === existingProduct.id ? existingProduct : p,
      );
      setProducts(updatedProducts);
      updateEventDate(updatedProducts);
    } else {
      const productData = {
        id: data?.id || '0',
        parentProductId: data?.parentProductId || tmpProduct?.parentProductId,
        name: tmpProduct?.name || '',
        lotSerial: data?.lotSerial || '',
        primaryId: data?.primaryId || '0',
        currentInventory: Number(data?.currentInventory || 0),
        quantity: isSer ? 1 : Number(data?.quantity) || 0,
        unitOfMeasure: data?.unitOfMeasure || '',
        instanceInventory: Number(data?.instanceInventory || 0),
        eventDate: data?.eventDate || '',
        productIdentifierType: data?.productIdentifierType || '',
        externalIdentifier: data?.externalIdentifier,
      };
      // add product
      const newProducts = [productData, ...products];
      setProducts(newProducts);
      updateEventDate(newProducts);
    }
  };
  return (
    <GTable<AggregateProductItem>
      key="sactionRef"
      actionRef={sactionRef}
      columns={columns({
        onEditRow,
        onDelRow,
        onChangeProduct,
        t,
        locationId,
        product: parentProduct,
        defaultUom,
        products,
        eventTime,
        identifier,
        isSerial,
        showEdit,
        showDelete,
        instanceByProductId,
        locationValue,
      })}
      editable={{
        onSave: (rowKey, data) => onSaveProduct(rowKey, data),
        onCancel: async (_rowKey, data) => {
          sactionRef.current?.cancelEditable(data?.id || 0);
        },
      }}
      options={{
        reload: false,
        setting: false,
      }}
      actionsRenderOptions={{
        save: true,
        cancel: true,
      }}
      recordCreatorProps={false}
      onAddRecordClick={() => {
        sactionRef.current?.addEditRecord?.(
          {
            id: Date.now().toString(),
          },
          {
            position: 'top',
          },
        );
      }}
      loading={isLoading}
      value={products}
      enableRecordCreator
      scroll={{ y: '50vh' }}
      pagination={{
        pageSize: 10,
        showSizeChanger: true,
      }}
    />
  );
};
export default React.memo(AggregateProducts);
