import { Space, Typography } from 'antd';
import type { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';
import { useCallback, useState } from 'react';
import { ListTradePartnersOptionalParams } from 'services/api/client/src';
import useTradePartners from 'services/api/useTradePartners';
import GSelect from './GSelect';
import { FetchDataFn, TradePartnerSelectProps } from './typings';

const TradePartnerSelect = <
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>({
  initParams,
  disabledItem,
  assignUrnAsValue,
  filterItem,
  showExtID,
  ...props
}: TradePartnerSelectProps<ValueType, OptionType>) => {
  const [params] = useState<ListTradePartnersOptionalParams>({ ...initParams });
  const { data, isLoading } = useTradePartners(params);

  const filterOption = useCallback(
    (option?: DefaultOptionType) => {
      if (!filterItem) {
        return true;
      }
      return filterItem?.(option?.itemProps as OptionType['itemProps']);
    },
    [filterItem],
  );

  // we create here the list with the shape the select is expecting for
  const options: Array<DefaultOptionType> =
    data?.data
      ?.map((option) => ({
        label:
          showExtID && option?.externalIdentifier ? (
            <Space>
              <Typography.Text>{option.name}</Typography.Text>
              <Typography.Text type="secondary">{option.externalIdentifier}</Typography.Text>
            </Space>
          ) : (
            String(option?.name || '')
          ),
        value: String(assignUrnAsValue ? option?.urn : option?.id),
        searchCol: showExtID
          ? `${String(option?.name || '')} ${String(option?.externalIdentifier || '')}`
          : String(option?.name || ''),
        itemProps: option,
        disabled: disabledItem?.(option) || false,
      }))
      .filter(filterOption) || [];

  const fetchData: FetchDataFn<ValueType> = useCallback(() => {}, []);

  return (
    <GSelect<ValueType, OptionType>
      placeholder="Select TradePartner"
      {...props}
      loading={isLoading}
      options={options as OptionType[]}
      fetchData={fetchData}
      allowFilterOption
      optionFilterProp="searchCol"
    />
  );
};

export default TradePartnerSelect;
