import { InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { ActionType } from '@ant-design/pro-table';
import { RecordKey } from '@ant-design/pro-utils/lib/useEditableArray';
import { PhoneCodeSelect } from 'components/GSelect';
import GTable from 'components/GTable';
import { AsYouType, CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import AddressSelectInput from 'pages/Network/components/AddressSelectInput';
import ConnectionTypeSelect from 'pages/Network/components/ConnectionTypeSelect';
import React, { FC, Key, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { checkIfCodeEnteredInPhoneNumber, PhoneCode, validatePolygon } from 'utils';
import { LocationCols } from '../LocationTable/typings';
import styles from './index.module.less';
import { CSVTblColumnsFn, CustomDataTemplateProps } from './typings';

const Columns: CSVTblColumnsFn = ({ t, isVessel, editableTableFormRef }) => [
  {
    title: t('company_name'),
    dataIndex: 'companyName',
    fixed: 'left',
    width: 265,
    fieldProps: {
      placeholder: t('company_name'),
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: t('validation_text', {
            field: t('company_name').toLocaleLowerCase(),
          }),
        },
        {
          whitespace: true,
          message: t('validation_text', {
            field: t('company_name').toLocaleLowerCase(),
          }),
        },
      ],
    },
  },
  {
    title: t('connection_type'),
    dataIndex: 'connectionType',
    width: 265,
    valueType: 'select',
    renderFormItem: () => <ConnectionTypeSelect />,
    fieldProps: {
      placeholder: t('connection_type'),
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: t('validation_text', {
            field: t('connection_type').toUpperCase(),
          }),
        },
        {
          pattern: /[SUPPLIER|BUYER|OTHER]/,
          message: t('validate_connection_type'),
        },
      ],
    },
  },
  {
    title: t('pgln'),
    dataIndex: 'pgln',
    width: 265,
    fieldProps: {
      placeholder: t('pgln'),
    },
  },
  {
    title: t('tradepartner_number'),
    dataIndex: 'tradepartnerNoId',
    width: 265,
    fieldProps: {
      placeholder: t('tradepartner_number'),
    },
  },
  {
    title: t('hq_duns'),
    dataIndex: 'duns',
    width: 265,
    fieldProps: {
      placeholder: t('hq_duns_placeholder'),
      maxLength: 9,
      minLength: 9,
      onInput: (e: React.FormEvent<HTMLInputElement>) => {
        // Replace any non-digit characters immediately
        e.currentTarget.value = e.currentTarget.value.replace(/\D/g, '');
      },
    },
    tooltip: {
      icon: <InfoCircleOutlined />,
      title: t('duns_note'),
      key: null,
      type: '',
      props: null,
    },
    formItemProps: {
      rules: [
        {
          min: 9,
          message: t('validation_min_length', {
            field: t('hq_duns').toLocaleLowerCase(),
            minLength: 9,
          }),
        },
        {
          max: 9,
          message: t('validation_max_length', {
            field: t('hq_duns').toLocaleLowerCase(),
            maxLength: 9,
          }),
        },
        {
          pattern: /^\d*$/,
          message: t('validation_hq_duns_length'),
        },
      ],
    },
  },
  {
    title: t('location_number'),
    dataIndex: 'locNumberId',
    width: 265,
    fieldProps: {
      placeholder: t('location_number'),
    },
    tooltip: {
      icon: <InfoCircleOutlined />,
      placement: 'top',
      title: t('loc_number_tooltip'),
      key: null,
      type: '',
      props: null,
    },
  },
  {
    title: isVessel === 'No' ? t('location_name') : t('vessel_name'),
    dataIndex: 'locationName',
    width: 265,
    fieldProps: {
      placeholder: isVessel === 'No' ? t('location_name') : t('vessel_name'),
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: t('validation_text', {
            field: isVessel
              ? t('vessel_name').toLocaleLowerCase()
              : t('location_name').toLocaleLowerCase(),
          }),
        },
        {
          whitespace: true,
          message: t('validation_text', {
            field: isVessel
              ? t('vessel_name').toLocaleLowerCase()
              : t('location_name').toLocaleLowerCase(),
          }),
        },
      ],
    },
  },
  {
    title: t('address'),
    dataIndex: 'address',
    renderFormItem: (row, { recordKey }) => (
      <AddressSelectInput editableFormRef={editableTableFormRef} recordKey={recordKey} />
    ),
    width: 265,
    fieldProps: {
      placeholder: t('address'),
    },
  },
  {
    title: t('geo_coordinates'),
    dataIndex: 'geoCoordinates',
    width: 265,
    formItemProps: {
      rules: [
        {
          required: true,
          message: t('validation_text', {
            field: t('geo_coordinates').toLocaleLowerCase(),
          }),
        },
        {
          pattern:
            /^([-+]?(?:[0-8]?\d(?:\.\d+)?|90(?:\.0+)?)),\s*([-+]?(?:1[0-7]\d(?:\.\d+)?|180(?:\.0+)?|[0-9]{1,2}(?:\.\d+)?))$/,
          message: t('validate_coordinate'),
        },
      ],
    },
    fieldProps: {
      placeholder: t('geo_coordinates'),
    },
  },
  {
    title: t('contact'),
    dataIndex: 'contact',
    width: 265,
    fieldProps: {
      placeholder: t('contact'),
    },
  },
  {
    title: t('phone_number'),
    dataIndex: 'number',
    key: 'number',
    width: 265,
    fieldProps: (form, { rowKey, entity, isEditable }) => ({
      placeholder: t('phone_number'),
      onChange: (e: any) => {
        const code =
          editableTableFormRef?.current?.getFieldValue(String(rowKey))?.phoneCode || 'US';
        let value = new AsYouType(code as CountryCode).input(e.target.value);
        value = checkIfCodeEnteredInPhoneNumber(value);
        editableTableFormRef?.current?.setFieldsValue({
          [String(rowKey)]: {
            number: value,
          },
        });
      },
      addonBefore: (
        <PhoneCodeSelect
          value={isEditable ? undefined : entity?.phoneCode}
          defaultValue={entity?.phoneCode || 'US'}
          onChange={(val, option) => {
            // @ts-ignore
            const item: PhoneCode = option?.itemProps;
            editableTableFormRef?.current?.setFieldsValue({
              [String(rowKey)]: {
                phoneCode: val,
                phonePrefix: String(item?.code || ''),
              },
            });
          }}
        />
      ),
    }),
    formItemProps: (form, { rowKey }) => {
      const code = editableTableFormRef?.current?.getFieldValue(String(rowKey))?.phoneCode || 'US';
      const number = editableTableFormRef?.current?.getFieldValue(String(rowKey))?.number;
      const isValid = isValidPhoneNumber(String(number), code as CountryCode);
      return {
        hasFeedback: true,
        validateStatus: !number?.trim?.() || isValid ? 'success' : 'error',
        initialValue: number,
        rules: [
          {
            message: t?.('validate_phone_number'),
            validator: () => {
              if (!number?.trim?.() || isValid) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t?.('validate_phone_number')));
            },
          },
        ],
      };
    },
  },
  {
    title: t('extension'),
    dataIndex: 'extension',
    width: 265,
    fieldProps: {
      placeholder: t('extension'),
      onInput: (e: React.FormEvent<HTMLInputElement>) => {
        // Replace any non-digit characters immediately
        e.currentTarget.value = e.currentTarget.value.replace(/[^0-9*#+-]/g, '');
      },
    },
    formItemProps: {
      rules: [
        {
          pattern: /^[\d*#+-]+$/,
          message: t('validate_extension'),
        },
      ],
    },
  },
  {
    title: t('email'),
    dataIndex: 'email',
    width: 265,
    formItemProps: {
      rules: [
        {
          type: 'email',
          message: t('validate_email'),
        },
      ],
    },
    fieldProps: {
      placeholder: t('email'),
    },
  },
  {
    title: t('gln'),
    dataIndex: 'gln',
    width: 265,
    fieldProps: {
      placeholder: t('gln'),
    },
    tooltip: {
      icon: <InfoCircleOutlined />,
      title: t('gln_tooltip'),
      key: null,
      type: '',
      props: null,
    },
  },
  {
    title: t('dun_no'),
    dataIndex: 'locDuns',
    width: 265,
    fieldProps: {
      placeholder: t('dun_no_placeholder'),
      maxLength: 13,
      minLength: 9,
      showCount: true,
    },

    formItemProps: {
      rules: [
        {
          validator: async (_, value) => {
            if (!value) return; // Allow empty value if not required
            // Check if the value is exactly 9 or 13 digits
            if (value.length !== 9 && value.length !== 13) {
              throw new Error(t('validation_duns_length'));
            }
          },
          message: t('validation_duns_length', {
            field: t('hq_duns').toLocaleLowerCase(),
            minLength: 9,
          }),
        },
      ],
    },
  },
  // {
  //   title: t('vessel_name'),
  //   dataIndex: 'vesselName',
  //   width: 265,
  //   hideInTable: isVessel === 'No',
  //   fieldProps: {
  //     placeholder: t('vessel_name'),
  //   },
  // },
  {
    title: t('captains_name'),
    dataIndex: 'captainsName',
    width: 265,
    hideInTable: isVessel === 'No',
    fieldProps: {
      placeholder: t('captains_name'),
    },
  },
  {
    title: t('vessel_state'),
    dataIndex: 'vesselState',
    hideInTable: isVessel === 'No',
    width: 265,
    fieldProps: {
      placeholder: t('vessel_state'),
    },
  },
  {
    title: t('imo_number'),
    dataIndex: 'imoNumber',
    hideInTable: isVessel === 'No',
    width: 300,
    fieldProps: {
      placeholder: t('imo_number'),
    },
  },
  {
    title: t('vessel_reg'),
    dataIndex: 'vesselReg',
    hideInTable: isVessel === 'No',
    width: 300,
    fieldProps: {
      placeholder: t('vessel_reg'),
    },
  },
  {
    title: t('vessel_auth'),
    dataIndex: 'vesselAuth',
    hideInTable: isVessel === 'No',
    width: 300,
    fieldProps: {
      placeholder: t('vessel_auth'),
    },
  },
  {
    title: t('vessel_link'),
    dataIndex: 'vesselLink',
    hideInTable: isVessel === 'No',
    width: 300,
    fieldProps: {
      placeholder: t('vessel_link'),
    },
  },
  {
    title: t('polygon'),
    dataIndex: 'polygon',
    width: 265,
    valueType: 'textarea',
    render: (value) =>
      value && value !== '-' ? <div className={styles.polytext}>{value}</div> : '-',
    fieldProps: (form, { rowKey }) => ({
      placeholder: t('polygon'),
      onChange: (e: any) => {
        const parsedJSON = JSON.parse(e.target.value);
        form?.setFieldsValue({
          [String(rowKey)]: {
            polygon: JSON.stringify(parsedJSON, null, 2),
          },
        });
      },
    }),
    tooltip: {
      icon: <QuestionCircleOutlined />,
      placement: 'top',
      title: (
        <>
          {t('polygon_tooltip')}{' '}
          <a
            href="https://geojson.io/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.textcolor}
          >
            {t('here')}.
          </a>
        </>
      ),
      key: null,
      type: '',
      props: null,
    },
    formItemProps: {
      rules: [
        {
          validator: validatePolygon,
          message: t('invalid_polygon'),
        },
      ],
    },
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    width: 100,
    fixed: 'right',
    render: () => null,
  },
  {
    dataIndex: 'phoneCode',
    fieldProps: {
      className: 'no-display-tbl',
    },
    formItemProps: {
      className: 'no-display-tbl',
    },
  },
];

const CSVTable: FC<CustomDataTemplateProps> = ({ form, editableTableFormRef }) => {
  const { getFieldValue, setFieldsValue } = form;
  const { t } = useTranslation('pages', { keyPrefix: 'network.add_network.form_fields' });
  const tableData: Array<LocationCols> = getFieldValue('csvData');
  const flattenedTableData = tableData?.map((item) => ({
    ...item,
    address: item.address || {
      line1: '',
      line2: '',
      city: '',
      state: '',
      country: '',
      postalCode: '',
      displayAddress: item?.address || '',
    },
  }));
  const sactionRef = useRef<ActionType>();
  const isVessel: string = getFieldValue('isVessel');

  useEffect(() => {
    if (editableTableFormRef?.current) {
      setTimeout(() => {
        editableTableFormRef?.current?.validateFields();
      }, 500);
    }
  }, [tableData, editableTableFormRef]);

  const setTableData = useCallback(
    (value: Array<LocationCols>) => {
      setFieldsValue({
        csvData: value,
      });
    },
    [setFieldsValue],
  );
  const onDelRow = async (key: RecordKey, actionPayload?: LocationCols) => {
    if (actionPayload) {
      setTableData(tableData.filter((p) => p.id !== actionPayload.id));
    }
  };
  return (
    <GTable<LocationCols>
      key="sactionRef"
      editableFormRef={editableTableFormRef}
      actionRef={sactionRef}
      columns={Columns({ t, editableTableFormRef, isVessel })}
      editable={{
        type: 'multiple',
        onlyAddOneLineAlertMessage: undefined,
        editableKeys: tableData.map((p) => p.id as Key),
        onDelete: onDelRow,
        onValuesChange: (record, recordList) => {
          setTableData(recordList);
        },
      }}
      options={{
        reload: false,
        setting: false,
      }}
      actionsRenderOptions={{
        save: false,
        cancel: false,
        delete: true,
      }}
      recordCreatorProps={false}
      addBtnText="Add Row"
      onAddRecordClick={() => {
        sactionRef.current?.addEditRecord?.({
          id: Date.now().toString(),
        });
      }}
      value={flattenedTableData}
      scroll={{ y: '50vh', x: 2500 }}
      pagination={{
        pageSize: 10,
        showSizeChanger: true,
      }}
    />
  );
};

export default React.memo(CSVTable);
