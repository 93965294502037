import { BetaSchemaForm } from '@ant-design/pro-form';
import GCardDrawer from 'components/GCardDrawer';
import { useModalVisibility } from 'hooks';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import {
  useGetApplicationIdentifiers,
  useGetLabelById,
  useGetLabelImages,
} from 'services/api/useLabels';
import { createBlobFromImageUrl } from 'utils';
import { getPrintingOption } from '../helper';
import BarcodeLabelLayoutPrint from '../LabelForm/BarcodeLabelLayoutPrint';
import { AddCardColumns, AddFormColumns } from './AddLabel.fields';
import EditBarcode from './EditBarcode';
import EditLabelInfo from './EditLabelInfo';
import {
  AddModalProps,
  addSubmitTypeFn,
  DataItem,
  defaultBarcodeFields,
  defaultFieldInfoObject,
  PrintOptions,
} from './typings';
import useAddEditActions from './useAddEditActions';

const EditLabel: FC<AddModalProps> = ({ modal }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'labels.edit_label' });
  const { labelId = '1' } = useParams();
  const {
    contextHolder,
    onDrawerClose,
    modalFormRef,
    modalCardRef,
    onSaveAsDraft,
    setIsLoading,
    isLoading,
    onAdd,
    setBothFormValues,
    printConfirm,
  } = useAddEditActions({
    t,
    modal,
    labelId,
  });
  const editLabelInfoModal = useModalVisibility(false);
  const editBarcodeModal = useModalVisibility(false);
  const [printData, setPrintData] = useState<DataItem | undefined | null>(null);
  const printRef = useRef(null);
  const [printSetting, setPrintSetting] = useState<PrintOptions>('4x6');
  const [isSave, setIsSave] = useState(false);

  const { data } = useGetLabelById(labelId);
  const { data: images } = useGetLabelImages(labelId);
  const { data: appIdData } = useGetApplicationIdentifiers();

  useEffect(() => {
    const mappedData = {
      fieldInfo: defaultFieldInfoObject()?.map((item) => {
        const field = data?.labelFieldSettings?.find(
          (i) => i?.fieldIdentifier === item?.dataIndex,
          [],
        );
        return {
          ...item,
          id: field?.id,
          visible: Boolean(field?.isVisible),
          required: field?.isRequired,
        };
      }, []),
      barcodeFieldInfo: defaultBarcodeFields()?.map((item) => {
        const field = data?.labelFieldSettings?.find((i) => i?.fieldIdentifier === item?.appId, []);
        return {
          ...item,
          id: field?.id,
          visible: field?.isVisible || false,
          required: field?.isRequired || false,
        };
      }, []),
      id: data?.id,
      product: data?.product?.id,
      productName: data?.product?.name,
      labelName: data?.labelName,
      gtin: data?.gtin14,
      newFieldInfo: data?.labelCustomValues?.map((item) => {
        const field = data?.labelFieldSettings?.find((i) => i?.fieldIdentifier === item?.name, []);

        return {
          id: field?.id,
          dataIndex: item?.name || '',
          title: item?.name || '',
          visible: field?.isVisible || false,
          required: field?.isRequired || false,
          isCustom: true,
          value: item?.value,
        };
      }, []),
      newBarcodeFieldInfo: data?.barcodeCustomValues?.map((item) => {
        const field = data?.labelFieldSettings?.find(
          (i) =>
            i?.fieldIdentifier ===
            appIdData?.find(
              (app) => `${app?.label} (AI ${app?.applicationIdentifier})` === item?.name,
              [],
            )?.applicationIdentifier,
          [],
        );

        return {
          id: field?.id || item?.id,
          dataIndex: item?.name || '',
          title: item?.name || '',
          visible: field?.isVisible || false,
          required: field?.isRequired || false,
          isCustom: true,
          value: item?.value,
        };
      }, []),

      layoutType: data?.layout,
      productDescription: data?.product?.description,
      processedInUSA: data?.processedInUSA ? 'processed_in_usa' : undefined,
      usStateOfProcessing: data?.usStateOfProcessing,
      quantity: data?.quantity,
      unitOfMeasure: data?.product?.simpleUnitOfMeasurement,
      harvestMethod: data?.harvestMethod,
      itemNumber: data?.itemNumber,
      coolInformation: data?.coolInformation,
      vendorID: data?.vendorID,
      supplierInformation: data?.supplierInformation,
      supplierStockNumber: data?.supplierStockNumber,
      sellingUnitUPCA: data?.sellingUnitUpca,
      uniquePackageID: data?.uniquePackageID,
      barcodeType: data?.barcodeType,
      gtin14: data?.gtin14,
      batchLotID: data?.lotSerial,
      packDate: data?.packDate,
      countryOfOrigin: data?.countryOfOrigin,
      sellByDate: data?.sellByDate,
      bestBeforeDate: data?.bestBeforeDate,
      productId: data?.productId,
      printOptions: data?.printSetting || '4x6',
    };

    const fieldObj: DataItem = {
      ...mappedData,
      ogNewFieldInfo: mappedData?.newFieldInfo,
      ogNewBarcodeFieldInfo: mappedData?.newBarcodeFieldInfo,
    };
    setBothFormValues(fieldObj);
  }, [modalFormRef, t, data, setBothFormValues, appIdData]);

  const imagesLoaded = useRef(false);

  const initImages = useCallback(async () => {
    if (!imagesLoaded.current && images) {
      const brandLogo = await createBlobFromImageUrl(images?.brandLogo || '');
      const logo = await createBlobFromImageUrl(images?.logo || '');
      const certificationLogo = await createBlobFromImageUrl(images?.certificationLogo || '');
      const mappedData = {
        brandLogo,
        logo,
        certificationLogo,
        logoURL: images?.logo || undefined,
        certLogoURL: images?.certificationLogo || undefined,
        brandLogoURL: images?.brandLogo || undefined,
      };
      setBothFormValues(mappedData);
      imagesLoaded.current = true;
    }
  }, [images, setBothFormValues]);

  useEffect(() => {
    initImages();
  }, [initImages, images]);

  // Create print styles based on selected format
  const getPageStyle = () => `
    @page {
      size: ${getPrintingOption(printSetting)};
      margin: 0;
    }
  `;

  const handlePrintOption = (e: PrintOptions) => {
    setPrintSetting(e);
  };

  const handlePrint = useReactToPrint({
    contentRef: printRef,
    onAfterPrint: () => {
      // Cleanup after printing
      document.body.classList.remove('printing');
      setPrintData(null);
      return true;
    },
    onBeforePrint: async () => {
      // Add any pre-print preparations
      document.body.classList.add('printing');
    },
    pageStyle: getPageStyle(),
  });

  const onValueChange = useCallback(() => {
    setTimeout(() => {
      const values: DataItem = modalFormRef?.current?.getFieldsValue();
      modalCardRef?.current?.setFieldsValue(values);
    }, 500);
  }, [modalCardRef, modalFormRef]);

  const onFormFinish = useCallback<addSubmitTypeFn>(
    async (formData) => {
      try {
        const cardValues = modalCardRef.current?.getFieldsValue();
        const formValues = formData as unknown as DataItem;
        const dataValues = {
          ...formValues,
          ...cardValues,
        };
        await onSaveAsDraft();

        // Set print data first
        setPrintData(dataValues);

        // Wait for state update and DOM to be ready
        await new Promise<void>((resolve) => {
          requestAnimationFrame(() => {
            requestAnimationFrame(() => resolve());
          });
        });

        if (printRef.current) {
          handlePrint();
        }
      } finally {
        setIsLoading(false);
      }
    },
    [modalCardRef, onSaveAsDraft, handlePrint, setIsLoading],
  );

  const handleSavePrint = useCallback(async () => {
    try {
      setIsSave(true);

      await modalFormRef.current?.validateFields();

      await onAdd();
    } catch (error) {
      setIsSave(false);
    }
  }, [modalFormRef, onAdd]);

  const onLabelEdit = useCallback(() => {
    editLabelInfoModal?.show();
  }, [editLabelInfoModal]);

  const onBarcodeEdit = useCallback(() => {
    editBarcodeModal?.show();
  }, [editBarcodeModal]);

  return (
    <>
      {contextHolder}
      {editLabelInfoModal.visible && (
        <EditLabelInfo
          formRef={modalFormRef}
          modal={editLabelInfoModal}
          t={t}
          cardRef={modalCardRef}
        />
      )}
      {editBarcodeModal.visible && (
        <EditBarcode formRef={modalFormRef} modal={editBarcodeModal} t={t} cardRef={modalCardRef} />
      )}
      <GCardDrawer
        t={t}
        DrawerProps={{
          ...modal,
          visible: modal ? modal?.visible : true,
          onClose: () => onDrawerClose(),
        }}
        saveButtonProps={{
          onClick: () => {
            handleSavePrint();
          },
          loading: isSave,
        }}
        cancelButtonProps={{
          onClick: onSaveAsDraft,
          loading: isLoading,
        }}
        leftChildern={
          <BetaSchemaForm<DataItem>
            formRef={modalCardRef}
            layoutType="Form"
            grid
            submitter={{
              render: () => null,
            }}
            columns={AddCardColumns({
              isEdit: true,
              handlePrintOption,
              modalFormRef,
              applicationIdentifierLookup: appIdData,
            })}
            //  initialValues={initialValues}
          />
        }
        rightChildern={
          <BetaSchemaForm<DataItem>
            formRef={modalFormRef}
            layoutType="Form"
            grid
            onFinish={onFormFinish}
            onValuesChange={onValueChange}
            onFinishFailed={() => printConfirm()}
            submitter={{
              render: () => null,
            }}
            //  initialValues={initialValues}
            columns={AddFormColumns({
              t,
              isEdit: true,
              onLabelEdit,
              onBarcodeEdit,
              modalCardRef,
              modalFormRef,
            })}
          />
        }
      />
      {printData && (
        <BarcodeLabelLayoutPrint
          ref={printRef}
          data={printData}
          applicationIdentifierLookup={appIdData}
          t={t}
          brandLogo={null}
          certificationLogo={null}
          companyLogo={null}
          printOption={printSetting}
        />
      )}
    </>
  );
};

export default React.memo(EditLabel);
