import { InfoCircleOutlined } from '@ant-design/icons';
import { Card, Descriptions, Space, Tooltip, Typography } from 'antd';
import HashCard from 'pages/Products/Inventory/EventDetails/DetailsModal/HashCard';
import React, { FC } from 'react';
import { useBlockchainDetailsById } from 'services/api';
import { featureFlag } from 'utils';
import styles from '../index.module.less';
import { DetailsDataProps } from '../typings';
import DataTitle from './DataTitle';

const CardTitle: FC<DetailsDataProps> = ({ t, activeEvent }) =>
  featureFlag?.validateHash ? (
    <div className={styles.titleflex}>
      <Descriptions size="small" className={styles.cardtitle}>
        <Space size="small">
          <Typography.Text strong>{t?.('event_data')}</Typography.Text>
        </Space>
      </Descriptions>
    </div>
  ) : (
    <div className={styles.titleflex}>
      <Descriptions size="small" className={styles.cardtitle}>
        <Descriptions.Item
          label={
            <Space size="small">
              <Typography.Text strong>{t?.('hash_title')}</Typography.Text>
              <Tooltip placement="bottom" title={t?.('hash_tooltip')}>
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
          }
        >
          <Typography.Paragraph copyable ellipsis>
            {activeEvent?.blockchain?.hashedEventData || ''}
          </Typography.Paragraph>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
const DataContent: FC<DetailsDataProps> = ({
  event,
  activeEvent,
  isPretty,
  productInstance,
  isSerial,
  identifier,
  t,
}) => {
  const { data: blockchainData } = useBlockchainDetailsById(
    featureFlag?.validateHash ? activeEvent?.id || '' : '',
  );

  const displayData =
    featureFlag?.validateHash && blockchainData?.verified && blockchainData.event
      ? JSON.parse(blockchainData.event)
      : activeEvent;
  return (
    <>
      <DataTitle
        activeEvent={activeEvent}
        productInstance={productInstance}
        isSerial={isSerial}
        identifier={identifier}
        t={t}
      />
      {featureFlag?.validateHash && (
        <div className={styles.mrb20}>
          <HashCard activeEvent={activeEvent} t={t} />
        </div>
      )}
      <Card title={<CardTitle event={event} activeEvent={activeEvent} t={t} />}>
        {isPretty && <pre className={styles.jsonbox}>{JSON.stringify(displayData, null, 2)}</pre>}
        {!isPretty && <p className={styles.jsonbox}>{JSON.stringify(displayData)}</p>}
      </Card>
    </>
  );
};
export default React.memo(DataContent);
