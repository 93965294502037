import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Col, message, Modal, Row, Typography } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import classNames from 'classnames';
import { useWindowSize } from 'hooks';
import { FC, useCallback, useMemo } from 'react';
import { copyToClipboard } from 'utils';
import styles from '../index.module.less';
import { HashValidationModalProps } from '../typings';

const HashValidationModal: FC<HashValidationModalProps> = ({
  t,
  modalProps,
  blockchainDetails,
  key,
  closeAllModal,
}) => {
  const [width] = useWindowSize();
  const modalWidth = useMemo(() => {
    if (width < 992) {
      return '95%';
    }
    return '40%';
  }, [width]);

  const handleCancel = useCallback(() => {
    closeAllModal?.();
    modalProps?.hide();
  }, [closeAllModal, modalProps]);
  return (
    <Modal
      {...modalProps}
      title={t?.('hash_validation.check_hash_validation_heading')}
      key={key}
      onOk={handleCancel}
      onCancel={handleCancel}
      width={modalWidth || '70%'}
      className={styles.hashvalidationmodal}
      centered
      okText={t?.('hash_validation.done')}
      okButtonProps={{ shape: 'round', type: 'primary' }}
      cancelButtonProps={{
        type: 'primary',
        ghost: true,
        shape: 'round',
      }}
    >
      <Paragraph>
        <Typography.Text>{t?.('hash_validation.verify_hash_gs1_explorer_heading')}</Typography.Text>
        &nbsp;
        <Typography.Link
          target="_blank"
          href={
            blockchainDetails?.apiDocsUrl ||
            'https://support.wholechain.com/article/328-hash-validation'
          }
        >
          {t?.('hash_validation.here_link')}
        </Typography.Link>
      </Paragraph>
      <div className={styles.hashvalidateitem}>
        <div>{t?.('hash_validation.epcis_pre_hash_serial')}&nbsp;</div>
        <div>
          <Typography.Text>{t?.('hash_validation.copy_epcis_json_data')}</Typography.Text>
        </div>{' '}
      </div>
      <Row className={classNames(styles.mrt5, styles.mrb20)} gutter={[16, 16]}>
        <Col>
          <Button
            type="primary"
            shape="round"
            onClick={(e) => {
              e.preventDefault();
              copyToClipboard(blockchainDetails?.event || '');
              message.success(t?.('hash_validation.epcis_json_id_copied'));
            }}
          >
            {t?.('hash_validation.copy_epcis_json_id')}
          </Button>
        </Col>
        {/* <Col>
          <Button
            shape="round"
            type="primary"
            ghost
            onClick={(e) => {
              e.preventDefault();
              copyToClipboard(blockchainDetails?.preHashEvent || '');
              message.success(t?.('hash_validation.pre_hash_data_copied'));
            }}
          >
            {t?.('hash_validation.copy_pre_hash_data')}
          </Button>
        </Col> */}
      </Row>
      <div className={styles.hashvalidateitem}>
        <div>{t?.('hash_validation.visit_gs1_event_hash_serial')}&nbsp;</div>
        <div>
          <Typography.Text>{t?.('hash_validation.visit_gs1_event_hash')}</Typography.Text>
        </div>
      </div>
      <div className={classNames(styles.mrt5, styles.mrb20, styles.mrl10)}>
        <Typography.Link href={blockchainDetails?.hashGeneratorUrl} target="_blank">
          {t?.('hash_validation.visit_gs1_event_hash_generator')}&nbsp;
          <ArrowRightOutlined />
        </Typography.Link>
      </div>
      <div className={styles.hashvalidateitem}>
        <div>{t?.('hash_validation.algorand_blockchain_serial_no')}&nbsp;</div>
        <div>
          <Typography.Text>{t?.('hash_validation.algorand_blockchain')}</Typography.Text>
        </div>
      </div>{' '}
      <Paragraph className={classNames(styles.mrt5)}>
        <Button
          type="primary"
          shape="round"
          onClick={(e) => {
            e.preventDefault();
            copyToClipboard(blockchainDetails?.transactionAddress || '');
            message.success(t?.('hash_validation.copy_transaction_id_success'));
          }}
        >
          {t?.('hash_validation.copy_transaction_id')}
        </Button>
      </Paragraph>
      <div className={classNames(styles.mrt5, styles.mrb0, styles.mrl10)}>
        <Typography.Link href={blockchainDetails?.blockExplorerUrl} target="_blank">
          {t?.('hash_validation.check_algorand_blockchain')}&nbsp;
          <ArrowRightOutlined />
        </Typography.Link>
      </div>
    </Modal>
  );
};
export default HashValidationModal;
