import { ActionType, ProColumns } from '@ant-design/pro-table';
import { Form, FormInstance, Modal } from 'antd';
import { ActionButtons } from 'components';
import GTable from 'components/GTable';
import React, { FC, useRef } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { camelize } from 'utils';
import { CustomArributeTableProps, CustomAttribute } from './typings';

type ColumnsType = {
  onEditRow: (actionPayload?: CustomAttribute) => void;
  onDelRow: (actionPayload?: CustomAttribute) => void;
  hasAdvanceSettings?: boolean;
  t?: TFunction<'pages', 'products.settings.form_fields'>;
};

/**
 * Generates columns for the CustomAttributeTable component.
 * @param onEditRow Callback function for editing a row.
 * @param onDelRow Callback function for deleting a row.
 * @param hasAdvanceSettings Indicates whether advanced settings are enabled.
 * @param t Translation function for i18n.
 * @returns An array of ProColumns<CustomAttribute>.
 */
const columns = ({
  onEditRow,
  onDelRow,
  hasAdvanceSettings,
  t,
}: ColumnsType): Array<ProColumns<CustomAttribute>> => [
  {
    title: t?.('col_attribute_title'),
    dataIndex: 'attribute',
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('col_attribute_req'),
        },
        {
          whitespace: true,
          message: t?.('col_attribute_req'),
        },
      ],
      hasFeedback: false,
    },
    fieldProps: (form: FormInstance<CustomAttribute>, { rowKey }) => ({
      placeholder: t?.('col_attribute_placeholder'),
      size: 'small',
      onChange: (e: any) => {
        form.setFieldsValue({
          [String(rowKey)]: {
            attribute: e?.target?.value,
            elementId: camelize(e?.target?.value),
          },
        });
      },
    }),
  },
  {
    title: t?.('col_element_id_title'),
    dataIndex: 'elementId',
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('col_element_id_req'),
        },
      ],
      hasFeedback: false,
    },
    fieldProps: {
      placeholder: t?.('col_element_id_placeholder'),
      size: 'small',
      disabled: false,
    },
  },
  {
    title: t?.('col_value_title'),
    dataIndex: 'value',
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('col_value_req'),
        },
      ],
      hasFeedback: false,
    },
    fieldProps: {
      placeholder: t?.('col_value_placeholder'),
      size: 'small',
    },
  },
  {
    title: t?.('col_namespace_title'),
    dataIndex: 'namespace',
    valueType: 'select',
    initialValue: 'Custom',
    valueEnum: {
      Custom: t?.('custom'),
      GDST: t?.('gdst'),
      CBV: t?.('cbv'),
    },
    formItemProps: {
      rules: [
        {
          required: false,
          message: t?.('col_namespace_req'),
        },
      ],
      hasFeedback: false,
    },
    fieldProps: {
      placeholder: t?.('col_namespace_placeholder'),
      size: 'small',
    },
    hideInTable: !hasAdvanceSettings,
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    width: '7%',
    render: (text, record) => (
      <ActionButtons record={record} onEdit={onEditRow} onDelete={onDelRow} />
    ),
  },
];

/**
 * CustomAttributeTable component for managing custom attributes.
 */
const CustomAttributeTable: FC<CustomArributeTableProps> = ({ form, hasAdvanceSettings }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'products.settings.form_fields' });
  const attributes: Array<CustomAttribute> = Form.useWatch?.('customAttributes', form) || [];
  const setAttributes = (value: Array<CustomAttribute>) =>
    form?.setFieldsValue({
      customAttributes: value,
    });
  const actionRef = useRef<ActionType>();

  const onEditRow = (actionPayload?: CustomAttribute) => {
    actionRef.current?.startEditable(actionPayload?.id || 0);
  };
  const onDeleteAttributeWarning = async (name: string) =>
    new Promise((resolve, reject) => {
      Modal.warning({
        title: t('del_atr_title'),
        content: t('del_atr_desc', { name }),
        centered: true,
        okCancel: true,
        okText: t('del_atr_confirm'),
        cancelText: t('del_atr_cancel'),
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        cancelButtonProps: {
          type: 'primary',
          ghost: true,
          shape: 'round',
        },
        onOk: () => resolve(true),
        onCancel: () => reject(new Error('USER_CANCEL')),
      });
    });
  const onDelRow = async (actionPayload?: CustomAttribute) => {
    await onDeleteAttributeWarning(actionPayload?.attribute || '');
    if (actionPayload) setAttributes(attributes.filter((item) => item.id !== actionPayload.id));
  };
  return (
    <GTable<CustomAttribute>
      actionRef={actionRef}
      columns={columns({ onEditRow, onDelRow, hasAdvanceSettings, t })}
      editable={{
        onCancel: async (_rowKey, data) => {
          actionRef.current?.cancelEditable(data?.id || 0);
        },
      }}
      options={{
        reload: false,
        setting: false,
      }}
      actionsRenderOptions={{
        save: true,
        cancel: true,
      }}
      recordCreatorProps={false}
      onAddRecordClick={() => {
        actionRef.current?.addEditRecord?.({
          id: `newField${Date.now().toString()}`,
          namespace: 'Custom',
        });
      }}
      value={attributes}
      onChange={setAttributes}
      enableRecordCreator
    />
  );
};

export default React.memo(CustomAttributeTable);
