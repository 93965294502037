import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Image, Space, Typography, Upload, UploadProps } from 'antd';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { ComponentType, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FixedCropper, FixedCropperRef, ImageRestriction } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { errorHandler } from 'utils';
import styles from './index.module.less';
import { UploadCropImageProps, cropperCardStyles, customStyles } from './typings';

type OnUploadCallback = NonNullable<UploadProps['customRequest']>;

// eslint-disable-next-line no-undef
const ModalSafeForReact18 = Modal as ComponentType<ReactModal['props']>;

const UploadCropImage: FC<UploadCropImageProps> = ({
  formKey,
  form,
  getImageURL,
  initialURL,
  initialObj,
  isFixedCropper,
  width,
  height,
  title,
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [preview, setPreview] = useState(false);
  const { t } = useTranslation('pages', { keyPrefix: 'settings.profile' });
  const cropperRef = useRef<FixedCropperRef>(null);
  const [cropModal, setCropModal] = useState<boolean>(false);

  const onCrop = useCallback(() => {
    setCropModal(false);
    if (cropperRef.current) {
      const imageURL = cropperRef.current.getCanvas()?.toDataURL();
      const canvas = cropperRef.current?.getCanvas();
      if (canvas) {
        canvas.toBlob((blob) => {
          if (blob) {
            const file = blob && new File([blob], 'brandLogo.png', { type: blob.type });
            form.setFieldsValue({
              [`${formKey}`]: file,
            });
            setFileList([
              {
                uid: '1',
                name: 'brandLogo.png',
                status: 'done',
                url: imageURL,
                originFileObj: file as RcFile,
              },
            ]);
            if (getImageURL) {
              getImageURL(imageURL);
            }
          }
        }, 'image/png');
      }
    }
  }, [form, formKey, getImageURL]);

  useEffect(() => {
    setFileList(
      initialURL
        ? [
            {
              url: initialURL,
              uid: '123',
              name: 'sample',
              originFileObj: initialObj,
            },
          ]
        : [],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialURL]);

  const onUpload = useCallback<OnUploadCallback>(
    async ({ file, onProgress, onSuccess, onError }) => {
      const fileObject = file as RcFile;
      const blobUrl = URL.createObjectURL(fileObject);
      if (fileObject && fileObject?.type === 'image/svg+xml') {
        const svgImage = document.createElement('img');
        document.body.appendChild(svgImage);
        svgImage.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = svgImage.clientWidth;
          canvas.height = svgImage.clientHeight;
          const canvasCtx = canvas.getContext('2d');
          canvasCtx?.drawImage(svgImage, 0, 0);
          const imgData = canvas.toDataURL('image/png');
          onProgress?.({ percent: 0 });
          setTimeout(() => onProgress?.({ percent: 50 }), 1000);
          onProgress?.({ percent: 100 });
          setFileList([
            {
              uid: '1',
              name: 'brandLogo.png',
              status: 'done',
              url: imgData,
              originFileObj: fileObject,
            },
          ]);
          setCropModal(true);
          onSuccess?.(file);
        };
        svgImage.src = blobUrl;
      } else {
        try {
          onProgress?.({ percent: 0 });
          setTimeout(() => onProgress?.({ percent: 50 }), 1000);
          onProgress?.({ percent: 100 });
          setFileList([
            {
              uid: '1',
              name: 'brandLogo.png',
              status: 'done',
              url: blobUrl,
              originFileObj: fileObject,
            },
          ]);
          setCropModal(true);
          onSuccess?.(file);
        } catch (error) {
          onError?.({
            name: 'upload-error',
            message: errorHandler(error),
          });
        }
      }
    },
    [],
  );

  const onRemove = useCallback(() => {
    setFileList([]);
    form.setFieldsValue({
      [`${formKey}`]: undefined,
    });
    if (getImageURL) {
      getImageURL(undefined);
    }
  }, [form, formKey, getImageURL]);

  const onCropCancel = useCallback(() => {
    setCropModal(false);
    setFileList([]);
  }, []);

  const onPreview = useCallback(() => {
    setPreview(true);
  }, []);

  const uploadButton = useMemo(
    () => (
      <div>
        <PlusOutlined className={styles.uploadtext} />
        <div className={styles.uploadtext}>{t('upload')}</div>
      </div>
    ),
    [t],
  );

  return (
    <>
      <Upload
        listType="picture-card"
        accept="image/png, image/jpeg"
        fileList={fileList}
        onRemove={onRemove}
        onPreview={onPreview}
        customRequest={onUpload}
        maxCount={1}
        className={styles.imgcrop}
      >
        {fileList.length > 0 ? null : uploadButton}
      </Upload>
      <Image
        className={styles.imagepreview}
        src={fileList?.[0]?.url}
        preview={{
          visible: preview,
          src: fileList?.[0]?.url,
          destroyOnClose: true,
          onVisibleChange: (value) => {
            setPreview(value);
          },
        }}
      />

      <ModalSafeForReact18
        isOpen={cropModal}
        onRequestClose={onCrop}
        style={customStyles}
        ariaHideApp={false}
      >
        <Card bodyStyle={cropperCardStyles}>
          <Space className={styles.cropperheader}>
            <Typography.Title className={styles.font16}>
              {title || t('edit_image')}
            </Typography.Title>
            <CloseOutlined className={styles.closeicon} onClick={onCropCancel} />
          </Space>

          {isFixedCropper ? (
            <FixedCropper
              ref={cropperRef}
              src={fileList?.[0]?.url}
              className={styles.cropper}
              imageRestriction={ImageRestriction.stencil}
              stencilSize={{
                width: width || 200,
                height: height || 200,
              }}
            />
          ) : (
            <FixedCropper
              ref={cropperRef}
              src={fileList?.[0]?.url}
              className={styles.cropper}
              imageRestriction={ImageRestriction.none}
              stencilSize={{
                width: width || 200,
                height: height || 200,
              }}
            />
          )}
          <Space className={styles.cropperbutton}>
            <Button onClick={onCropCancel} ghost type="primary" shape="round">
              {t('cancel')}
            </Button>
            <Button onClick={onCrop} type="primary" shape="round">
              {t('save')}
            </Button>
          </Space>
        </Card>
      </ModalSafeForReact18>
    </>
  );
};

export default UploadCropImage;
