import { InfoCircleOutlined } from '@ant-design/icons';
import { ProColumns } from '@ant-design/pro-table';
import { Button, message, Space, TablePaginationConfig, Typography } from 'antd';
import { GTable } from 'components';
import { ColumnProps, SsccContainerTableProps } from 'pages/Products';
import { FC, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useGetShipmentProductContainerInstancesById } from 'services/api';
import {
  ContainerProductInstanceResponse,
  GetContainerInventoryResponse,
  GetPaginatedOtherSystemShipmentsOptionalParams,
} from 'services/api/client/src';
import { errorHandler, getUom } from 'utils';
import styles from './index.module.less';

type ColumnsType = {
  outbound?: boolean;
  t?: any;
  columnProps?: ColumnProps;
  isInventory?: boolean;
};
/* ${t?.('lot')} */
const Columns = ({
  t,
  columnProps,
  isInventory,
}: ColumnsType): ProColumns<ContainerProductInstanceResponse>[] => [
  {
    title: (
      <div className={isInventory ? styles.margin60 : styles.margin30}>
        {t('col_product_title')}
      </div>
    ),
    dataIndex: 'productName',
    width: 200,
    ellipsis: true,
    render: (_, record) => {
      const marginClass = isInventory ? styles.margin60 : styles.margin30;
      return <span className={marginClass}>{record?.aliasProductName || record?.productName}</span>;
    },
    ...columnProps?.productName,
  },
  {
    title: t('tbl_col_quantity'),
    dataIndex: 'quantity',
    width: 180,
    ellipsis: true,
    render: (text, record) => `${record?.quantity || ''} ${getUom({ product: record }) || 'LBS'}`,
    ...columnProps?.quantity,
  },
  {
    title: t('tbl_col_primary_id'),
    dataIndex: 'lotSerial',
    render: (text, record) => <div>Lot: {record?.lotSerial || ''}</div>,
    hideInSetting: true,
    ellipsis: true,
    width: 260,
    ...columnProps?.lotSerial,
  },

  {
    title: 'Primary ID',
    dataIndex: 'primaryId',
    sorter: true,
    ...columnProps?.primaryId,
  },
  {
    title: t('col.gtin'),
    dataIndex: 'gtin',
    ellipsis: true,
    width: 152,
    render: (text, record: any) => record?.gtin || record?.productGtin || '',
    ...columnProps?.gtin,
  },
  {
    title: t?.('tbl_col_current_inventory_title'),
    dataIndex: 'currentInventory',
    tooltip: {
      title: t?.('tbl_col_current_inventory_tooltip'),
      icon: <InfoCircleOutlined />,
    },
    ellipsis: true,
    render: (text, record) => record?.totalInventoryAtLocation,
    ...columnProps?.currentInventory,
  },
  {
    title: t('tbl_col_event_name'),
    dataIndex: 'eventName',
    width: 150,
    ellipsis: true,
    render: () => ``,
    ...columnProps?.eventName,
  },
  {
    title: t('tbl_col_location'),
    dataIndex: 'locationAddress',
    width: '30vw',
    ellipsis: true,
    render: () => '',
    ...columnProps?.locationAddress,
  },
  {
    title: t('tbl_col_purchase_order'),
    dataIndex: 'purchaseOrder',
    ellipsis: true,
    width: 150,
    render: () => '',
    ...columnProps?.purchaseOrder,
  },
  {
    title: t('tbl_col_event_date'),
    dataIndex: 'eventDate',
    sorter: true,
    ellipsis: true,
    width: 150,
    render: () => '',
    ...columnProps?.eventDate,
  },
  {
    title: t('tbl_col_logged_date'),
    dataIndex: 'loggedDate',
    sorter: true,
    defaultSortOrder: 'descend',
    render: () => '',
    ellipsis: true,
    width: 150,
    ...columnProps?.loggedDate,
  },
  {
    title: t('tbl_col_blockchain'),
    dataIndex: 'blockchain',
    ellipsis: true,
    render: () => '',
    width: 120,
    ...columnProps?.blockchain,
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    width: 120,
    hideInSetting: true,
    render: () => '',
    ...columnProps?.actions,
  },
];
const SsccShipmentPaginatedTable: FC<SsccContainerTableProps> = ({
  shipmentId,
  containerId,
  columnProps,
  showHeader,
  t,
  isInventory,
}) => {
  const [containerInstances, setContainerInstances] = useState<GetContainerInventoryResponse>({});
  const [params, setParams] = useState<GetPaginatedOtherSystemShipmentsOptionalParams>({
    pageNumber: 1,
    pageSize: 20,
    sortBy: '-eventDate',
  });
  const queryClient = useQueryClient();
  const shipmentContainerItemsByContainerId =
    useGetShipmentProductContainerInstancesById(queryClient);

  useEffect(() => {
    let isMounted = true; // To prevent state updates on unmounted components

    const getContainerInventoryResponse = async (
      id: string,
      apiParams: GetPaginatedOtherSystemShipmentsOptionalParams,
    ) => {
      try {
        const productInventoryData = await shipmentContainerItemsByContainerId.mutateAsync({
          shipmentId: shipmentId || '',
          containerId: id || '',
          params: apiParams,
        });

        if (isMounted) {
          setContainerInstances((prevInstances) => {
            // Prevent duplicate entries
            const existingIds = new Set(prevInstances?.results?.map((item) => item.id) || []);
            const newResults = productInventoryData?.results?.filter(
              (item) => !existingIds.has(item.id),
            );

            return {
              ...prevInstances,
              results: [...(prevInstances?.results || []), ...(newResults || [])],
              totalItems: productInventoryData?.totalItems || prevInstances?.totalItems,
            };
          });
        }
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    };

    if (containerId) {
      getContainerInventoryResponse(containerId, params);
    }

    return () => {
      isMounted = false; // Cleanup flag
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerId, params]);
  const onTableChange = (pagination: TablePaginationConfig, sorter: any) => {
    if (pagination?.current === params?.pageNumber && pagination?.pageSize === params?.pageSize) {
      setParams({
        ...params,
        sortBy: sorter?.field
          ? `${sorter.order === 'ascend' ? '+' : '-'}${sorter.field}`
          : undefined,
        pageNumber: 1,
      });
    } else {
      setParams({
        ...params,
        pageNumber: pagination?.current,
        pageSize: pagination?.pageSize,
        sortBy: sorter?.field
          ? `${sorter.order === 'ascend' ? '+' : '-'}${sorter.field}`
          : undefined,
      });
    }
  };
  const loadMoreItems = () => {
    if ((containerInstances?.results?.length || 0) < (containerInstances?.totalItems || 20)) {
      setParams((prevParams) => ({
        ...prevParams,
        pageNumber: 1,
        pageSize: containerInstances?.totalItems || 20,
      }));
    }
  };
  return (
    <div>
      <Space className={styles.marginleft}>
        <Typography.Text type="secondary">
          {containerInstances?.totalItems || 0} {t('itemstotal')}
        </Typography.Text>
      </Space>
      <GTable<ContainerProductInstanceResponse>
        columns={Columns({ t, columnProps, isInventory })}
        options={{
          setting: false,
          reload: false,
        }}
        value={containerInstances?.results}
        showHeader={showHeader}
        pagination={false}
        onTableChange={onTableChange}
        scroll={{ y: '40vh', x: '100%' }}
      />
      {/* Load more items button */}
      {(containerInstances?.results?.length || 0) > 0 &&
        (containerInstances?.results?.length || 0) < (containerInstances?.totalItems || 20) && (
          <Space className={styles.marginleft01}>
            <Button type="link" onClick={loadMoreItems}>
              {t('loaditems')}
            </Button>
          </Space>
        )}
    </div>
  );
};
export default SsccShipmentPaginatedTable;
