import { InfoCircleOutlined } from '@ant-design/icons';
import { ProColumns } from '@ant-design/pro-table';
import { Button, message, Space, TablePaginationConfig, Typography } from 'antd';
import { GTable } from 'components';
import { ColumnProps, SsccContainerTableProps } from 'pages/Products';
import { FC, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useGetProductContainerInstancesById } from 'services/api';
import {
  ContainerProductInstanceResponse,
  GetContainerInventoryResponse,
  GetPaginatedOtherSystemShipmentsOptionalParams,
} from 'services/api/client/src';
import { errorHandler, getProductsT, hiddencol, mapContainer } from 'utils';
import styles from '../index.module.less';

type ColumnsType = {
  outbound?: boolean;
  t?: any;
  columnProps?: ColumnProps;
  multipleDates?: boolean;
  identifier?: string;
};
/* ${t?.('lot')} */
const Columns = ({
  t,
  multipleDates,
  identifier,
}: ColumnsType): ProColumns<ContainerProductInstanceResponse>[] => [
  {
    dataIndex: 'select',
    ...hiddencol,
    width: '30px',
  },
  {
    title: t?.('tbl_col_primary_id_title', {
      identifier: getProductsT(identifier),
    }),
    dataIndex: 'primaryId',
    render: (text, record) => (
      <span>
        {`${getProductsT(record?.productIdentifierType || identifier)}:`} {record?.lotSerial || ''}
      </span>
    ),
    ellipsis: true,
    width: multipleDates ? '20%' : '33%',
  },
  {
    title: t?.('tbl_col_quantity_title'),
    dataIndex: 'quantity',
    render: (text, record) => {
      const updatedQuantity = `${record?.quantity || 0} ${record?.simpleUnitOfMeasurement || ''}`;
      const quantityUom = updatedQuantity;
      return record?.simpleUnitOfMeasurement !== 'item' ? quantityUom : `1 ${getProductsT('item')}`;
    },
    ellipsis: true,
    width: multipleDates ? '20%' : '33%',
  },
  {
    title: t?.('tbl_col_current_inventory_title'),
    dataIndex: 'currentInventory',
    tooltip: {
      title: t?.('tbl_col_current_inventory_tooltip'),
      icon: <InfoCircleOutlined />,
    },
    ellipsis: true,
    render: (text, record) => record?.totalInventoryAtLocation,
    width: multipleDates ? '20%' : '33%',
  },
  {
    title: t?.('tbl_col_date_title'),
    dataIndex: 'date',
    valueType: 'date',
    hideInTable: !multipleDates,
    render: () => '',
    ellipsis: true,
  },
  {
    title: t?.('tbl_col_time_title'),
    dataIndex: 'time',
    valueType: 'time',
    hideInTable: !multipleDates,
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('tbl_col_time_req'),
        },
      ],
      hasFeedback: false,
    },
    ellipsis: true,
    render: () => '',
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    fixed: 'right',
    width: 80,
  },
];
const DecommissionProductsSSCC: FC<SsccContainerTableProps> = ({
  containerId,
  columnProps,
  showHeader,
  t,
  multipleDates,
  identifier,
  parentProducts,
  parentRowId,
  parentForm,
}) => {
  const [containerInstances, setContainerInstances] = useState<GetContainerInventoryResponse>({});
  const [params, setParams] = useState<GetPaginatedOtherSystemShipmentsOptionalParams>({
    pageNumber: 1,
    pageSize: 20,
    sortBy: '-eventDate',
  });
  const queryClient = useQueryClient();
  const containerItemsByContainerId = useGetProductContainerInstancesById(queryClient);

  useEffect(() => {
    let isMounted = true; // To prevent state updates on unmounted components

    const getContainerInventoryResponse = async (
      id: string,
      apiParams: GetPaginatedOtherSystemShipmentsOptionalParams,
    ) => {
      try {
        const productInventoryData = await containerItemsByContainerId.mutateAsync({
          containerId: id || '',
          params: apiParams,
        });

        if (isMounted) {
          setContainerInstances((prevInstances) => {
            // Prevent duplicate entries
            const existingIds = new Set(prevInstances?.results?.map((item) => item.id) || []);
            const newResults = productInventoryData?.results?.filter(
              (item: any) => !existingIds.has(item.id),
            );
            const updatedParentRows = parentProducts?.map(
              (p) =>
                p?.id === parentRowId
                  ? {
                      ...p,
                      containerItems: newResults?.map((item: any) => mapContainer(item)),
                    }
                  : p,
              [],
            );

            parentForm?.setFieldsValue({
              eventProducts: updatedParentRows,
            });
            return {
              ...prevInstances,
              results: [...(prevInstances?.results || []), ...(newResults || [])],
              totalItems: productInventoryData?.totalItems || prevInstances?.totalItems,
            };
          });
        }
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    };

    if (containerId) {
      getContainerInventoryResponse(containerId, params);
    }

    return () => {
      isMounted = false; // Cleanup flag
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerId, params]);
  const onTableChange = (pagination: TablePaginationConfig, sorter: any) => {
    if (pagination?.current === params?.pageNumber && pagination?.pageSize === params?.pageSize) {
      setParams({
        ...params,
        sortBy: sorter?.field
          ? `${sorter.order === 'ascend' ? '+' : '-'}${sorter.field}`
          : undefined,
        pageNumber: 1,
      });
    } else {
      setParams({
        ...params,
        pageNumber: pagination?.current,
        pageSize: pagination?.pageSize,
        sortBy: sorter?.field
          ? `${sorter.order === 'ascend' ? '+' : '-'}${sorter.field}`
          : undefined,
      });
    }
  };
  const loadMoreItems = () => {
    if ((containerInstances?.results?.length || 0) < (containerInstances?.totalItems || 20)) {
      setParams((prevParams) => ({
        ...prevParams,
        pageNumber: 1,
        pageSize: containerInstances?.totalItems || 20,
      }));
    }
  };
  return (
    <div>
      <Space className={styles.marginleft}>
        <Typography.Text type="secondary">
          {containerInstances?.totalItems || 0} {t?.('itemstotal')}
        </Typography.Text>
      </Space>
      <GTable<ContainerProductInstanceResponse>
        columns={Columns({ t, columnProps, multipleDates, identifier })}
        options={{
          setting: false,
          reload: false,
        }}
        value={containerInstances?.results}
        showHeader={showHeader}
        pagination={false}
        onTableChange={onTableChange}
        scroll={{ y: '30vh' }}
      />
      {/* Load more items button */}
      {(containerInstances?.results?.length || 0) < (containerInstances?.totalItems || 20) && (
        <Space className={styles.marginleft01}>
          <Button type="link" onClick={loadMoreItems}>
            {t?.('loaditems')}
          </Button>
        </Space>
      )}
    </div>
  );
};
export default DecommissionProductsSSCC;
