import { ProColumns } from '@ant-design/pro-table';
import { EditableProTableProps } from '@ant-design/pro-table/lib/components/EditableTable';
import { GTable } from 'components';
import { ShipNewProductItem } from 'pages/Events';
import { FC, ReactText, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GetInboundShipmentDetailsOptionalParams } from 'services/api/client/src';
import { getContainerSum, getProductsT, getShipmentQuantityAddon, getUom } from 'utils';
import useShipmentInstanceFilters from '../hooks/useShipmentInstanceFilter';
import SsccShipmentPaginatedTable from '../SsccShipmentPaginatedTable';
import { ShipmentColumnFunction, ShipmentDetailsTableProps, ShipmentSSCCProps } from '../typings';

/**
 * Generates an array of columns for the inventory table based on the provided parameters.
 * @param t The translation function.
 * @param primaryIdSearchfilter Search filter for the primary ID column.
 * @param gtinSearchfilter Search filter for the purchase order column.
 * @returns An array of columns for the inventory table.
 */
const Columns: ShipmentColumnFunction = ({
  t,
  primaryIdSearchfilter,
  gtinSearchfilter,
}): ProColumns<ShipNewProductItem>[] => [
  {
    dataIndex: 'id',
    hideInTable: true,
  },
  {
    title: t('col_product_title'),
    dataIndex: 'productName',
    width: 200,
    ellipsis: true,
    render: (_, record) => (
      <span>
        {record?.isContainer ? `${getProductsT('SSCC')}: ` : ' '}
        {record?.isContainer && !record?.isSameContainerProducts
          ? t('col_product_title_multiple_products')
          : record?.displayName}
      </span>
    ),
  },
  {
    title: t('col_quantity_title'),
    dataIndex: 'quantity',
    width: 200,
    ellipsis: true,
    sorter: true,
    render: (text, record) => {
      const updatedQuantity = !record?.isChildren
        ? `${record?.quantity || 0} ${getShipmentQuantityAddon(record)}`
        : `${record?.quantity || 0} ${
            getUom({
              product: record,
            }) ||
            record?.unitOfMeasure ||
            ''
          }`;
      const quantityUom = !record?.isContainer
        ? updatedQuantity
        : updatedQuantity || getContainerSum(record);
      if (record?.hideMultipleProductsDifferentUOMQuantity) {
        return '-';
      }
      return record?.productIdentifierType !== 'Serial'
        ? quantityUom
        : `${record?.quantity} ${
            (record?.quantity || 0) > 1 ? getProductsT('items') : getProductsT('item')
          }`;
    },
  },
  {
    title: t('col_primary_id_title'),
    dataIndex: 'instanceId',
    width: 200,
    ellipsis: true,
    render: (_, record) => (
      <span>
        {record?.isContainer
          ? `${getProductsT('SSCC')}:`
          : `${getProductsT(record?.productIdentifierType)}:`}{' '}
        {record?.lotSerial || ''}
      </span>
    ),
    ...primaryIdSearchfilter,
  },
  {
    title: t('col_gtin_title'),
    dataIndex: 'productGtin',
    width: 200,
    ellipsis: true,
    ...gtinSearchfilter,
  },
];
const ShipmentDetailsTable: FC<ShipmentDetailsTableProps> = ({ shipmentId }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'shipments.inbound.tbl' });

  const {
    setParams,
    records,
    isLoading,
    filters: [primaryIdSearchfilter, gtinSearchfilter],
    getSortField,
  } = useShipmentInstanceFilters(shipmentId, {
    sortBy: '-eventDate',
  });

  const recordList: ShipNewProductItem[] = useMemo(() => records || [], [records]);

  const onTableChange: EditableProTableProps<ShipNewProductItem, ReactText[]>['onTableChange'] = (
    pagination,
    filters: any,
    sorter: any,
  ) => {
    const apiFilters: GetInboundShipmentDetailsOptionalParams = {
      primaryId: filters.instanceId?.length ? filters.instanceId[0] : '',
      gtin: filters.productGtin?.length ? filters.productGtin[0] : '',
    };
    setParams({
      ...apiFilters,
      sortBy: sorter?.field
        ? `${sorter.order === 'ascend' ? '+' : '-'}${getSortField(sorter)}`
        : undefined,
    });
  };
  const expandedRowRender = useCallback(
    (rowData: ShipNewProductItem) => (
      <SsccShipmentPaginatedTable
        shipmentId={shipmentId}
        containerId={rowData?.id}
        showHeader={false}
        t={t}
        columnProps={ShipmentSSCCProps}
      />
    ),
    [shipmentId, t],
  );
  const rowExpandable = useCallback(
    (rowData: ShipNewProductItem) => rowData?.isContainer || false,
    [],
  );
  return (
    <GTable<ShipNewProductItem>
      columns={Columns({
        t,
        primaryIdSearchfilter,
        gtinSearchfilter,
      })}
      loading={isLoading}
      options={{
        setting: false,
        reload: false,
      }}
      value={recordList}
      onTableChange={onTableChange}
      showHeader
      scroll={{ y: '50vh', x: 980 }}
      expandable={{ expandedRowRender, rowExpandable, columnWidth: 30 }}
    />
  );
};
export default ShipmentDetailsTable;
