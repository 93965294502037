import { BetaSchemaForm, ProFormColumnsType, ProFormInstance } from '@ant-design/pro-form';
import { ActionType, ProColumns } from '@ant-design/pro-table';
import { Modal, Switch } from 'antd';
import { ActionButtons, GTable } from 'components';
import AppIdentifierSelect from 'components/GSelect/AppIdentifierSelect';
import { TFunction } from 'i18next';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Gs1DataModel } from 'services/api/client/src';
import {
  barcodePredefinedFields,
  DataItem,
  EditInfoFormProps,
  EditLabelInfoModalProps,
  FieldItem,
} from './typings';

type ColumnsType = {
  t: TFunction<'pages', 'products'>;
  onEditRow: (actionPayload?: FieldItem) => void;
  onDeleteRow?: (actionPayload?: FieldItem) => void;
  onSettingChange?: (type?: string, actionPayload?: FieldItem, val?: boolean) => void;
  records?: FieldItem[];
};
const columns = ({
  t,
  onEditRow,
  onDeleteRow,
  onSettingChange,
  records,
}: ColumnsType): Array<ProColumns<FieldItem>> => [
  {
    title: t?.('col_field_name'),
    dataIndex: 'title',
    ellipsis: true,
    hideInSetting: true,
    width: 150,
    renderFormItem: (_, { recordKey }, form) => (
      <AppIdentifierSelect
        filterItem={(item) =>
          !barcodePredefinedFields?.includes(`${item?.applicationIdentifier}-${item?.label}`)
        }
        disabledItem={(item) =>
          records?.some((i) => i?.title === `${item?.label} (AI ${item?.applicationIdentifier})`)
        }
        onChange={(value, option) => {
          const { setFieldsValue } = form;
          // @ts-ignore
          const item: Gs1DataModel = option.itemProps;
          setFieldsValue({
            [String(recordKey)]: {
              title: `${item?.label} (AI ${item.applicationIdentifier})`,
              appId: item?.applicationIdentifier,
            },
          });
        }}
      />
    ),
    formItemProps: {
      rules: [
        {
          required: true,
          message:
            t?.('validation_text', {
              field: t?.('location_name').toLocaleLowerCase(),
            }) || '',
        },
        {
          whitespace: true,
          message:
            t?.('validation_text', {
              field: t?.('location_name').toLocaleLowerCase(),
            }) || '',
        },
      ],
    },
  },
  {
    title: t?.('col_visible'),
    dataIndex: 'visible',
    valueType: 'switch',
    render: (text, record) =>
      !record?.hideVisible ? (
        <Switch
          defaultChecked={record?.visible}
          onChange={(val) => onSettingChange?.('visible', record, val)}
        />
      ) : null,
    ellipsis: true,
    width: 50,
  },
  {
    title: t?.('col_collections'),
    dataIndex: 'required',
    valueType: 'switch',
    render: (text, record) =>
      !record?.hideRequired ? (
        <Switch
          defaultChecked={record?.required}
          onChange={(val) => onSettingChange?.('required', record, val)}
        />
      ) : null,
    ellipsis: true,
    width: 50,
  },
  {
    dataIndex: 'actions',
    fixed: 'right',
    hideInTable: false,
    valueType: 'option',
    render: (text, record) => (
      <ActionButtons
        record={record}
        showDelete={record?.isCustom === true}
        showEdit={record?.isCustom === true}
        onEdit={onEditRow}
        onDelete={(payload) => onDeleteRow?.(payload)}
      />
    ),
    width: 30,
  },
];
const FieldTable: FC<EditInfoFormProps> = ({ records, setRecords }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'labels.add_label.edit_barcode_info.table' });
  const tactionRef = useRef<ActionType>();

  const onEditRow = (item?: FieldItem) => {
    tactionRef.current?.startEditable(item?.dataIndex || 0);
  };

  const onDeleteRow = useCallback(
    async (payload?: FieldItem) => {
      const filters =
        records?.filter?.((item: FieldItem) => item?.title !== payload?.title, []) || [];
      setRecords?.([...filters]);
    },
    [records, setRecords],
  );

  const onSettingChange = useCallback(
    (type?: string, actionPayload?: FieldItem, val?: boolean) => {
      const recordItem = records?.find((i) => i?.dataIndex === actionPayload?.dataIndex, []);
      if (recordItem) {
        const updatedItems = records?.map(
          (item) =>
            recordItem?.dataIndex === item?.dataIndex
              ? {
                  ...item,
                  dataIndex: actionPayload?.dataIndex || '',
                  title: actionPayload?.title || '',
                  visible: (type === 'visible' ? val : actionPayload?.visible) || false,
                  required: (type === 'required' ? val : actionPayload?.required) || false,
                  appId: actionPayload?.appId,
                }
              : { ...item },
          [],
        );
        setRecords?.([...(updatedItems || [])]);
      }
    },
    [records, setRecords],
  );

  const onAddRow = useCallback(() => {
    // Modal.warning({
    //   title: t('field_limit.title'),
    //   content: t('field_limit.content'),
    //   centered: true,
    //   okCancel: false,
    //   width: 500,
    //   okText: t('field_limit.done'),
    //   okButtonProps: {
    //     type: 'primary',
    //     shape: 'round',
    //   },
    //   onOk: () => {

    //   },
    // const title = Date.now().toString();
    tactionRef.current?.addEditRecord?.({
      dataIndex: `new${Date.now().toString()}`,
    });
  }, []);

  return (
    <GTable<FieldItem>
      actionRef={tactionRef}
      columns={columns({ t, onEditRow, onDeleteRow, onSettingChange, records })}
      onAddRecordClick={onAddRow}
      editable={{
        onSave: async (rowKey, data) => {
          const recordItem = records?.find((i) => i?.dataIndex === data?.dataIndex, []);
          if (recordItem) {
            const updatedItems = records?.map(
              (item) =>
                recordItem?.dataIndex === item?.dataIndex
                  ? {
                      ...item,
                      dataIndex: data?.dataIndex,
                      title: data?.title,
                      visible: data?.visible || false,
                      required: data?.required || false,
                      appId: data?.appId,
                    }
                  : { ...item },
              [],
            );
            setRecords?.([...(updatedItems || [])]);
          } else {
            setRecords?.([
              ...(records || []),
              {
                dataIndex: data?.dataIndex,
                title: data?.title,
                visible: data?.visible || false,
                required: data?.required || false,
                isCustom: true,
                appId: data?.appId,
              },
            ]);
          }
        },
        onCancel: async (_rowKey, data) => {
          tactionRef.current?.cancelEditable(data?.dataIndex || 0);
        },
      }}
      rowKey="dataIndex"
      value={records}
      scroll={{ y: 300 }}
      enableRecordCreator
      addBtnText={t('add')}
      addBtnPosition="top"
      addBtnLeft
      actionsRenderOptions={{
        save: true,
        cancel: true,
      }}
      // scroll={{ y: '75vh', x: 980 }}
      options={{
        setting: false,
        reload: false,
      }}
    />
  );
};

export type ColumnsFormType = {
  t: TFunction<'pages', 'network.invite'>;
  records?: FieldItem[];
  setRecords?: (records: FieldItem[]) => void;
};

export const FieldColumns = ({
  t,
  records,
  setRecords,
}: ColumnsFormType): ProFormColumnsType<DataItem>[] => [
  {
    valueType: 'group',
    columns: [
      {
        title: t('table.desc'),
        dataIndex: 'barcodeFieldInfo',
        renderFormItem: () => <FieldTable records={records} setRecords={setRecords} />,
      },
      {
        dataIndex: 'newBarcodeFieldInfo',
        fieldProps: {
          className: 'no-display',
        },
        formItemProps: {
          className: 'no-display',
        },
        colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
      },
    ],
  },
];

const EditBarcode: FC<EditLabelInfoModalProps> = ({ modal, modalTitle, formRef, cardRef }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'labels.add_label.edit_barcode_info' });
  const modalFormRef = useRef<ProFormInstance>();
  const [records, setRecords] = useState<Array<FieldItem>>();

  useEffect(() => {
    setRecords([
      ...(formRef?.current?.getFieldValue('barcodeFieldInfo') || []),
      ...(formRef?.current?.getFieldValue('newBarcodeFieldInfo') || []),
    ]);
  }, [formRef]);

  const closeModal = useCallback(() => {
    Modal.warning({
      title: t('exit_warn.title'),
      content: t('exit_warn.content'),
      centered: true,
      okCancel: true,
      width: 500,
      okText: t('exit_warn.done'),
      cancelText: t('exit_warn.cancel'),
      okButtonProps: {
        type: 'primary',
        shape: 'round',
      },
      cancelButtonProps: {
        type: 'primary',
        shape: 'round',
        ghost: true,
      },
      onOk: () => {
        modalFormRef.current?.resetFields();
        modal.hide();
      },
    });
  }, [modal, t]);

  const onFinish = async () => {
    if (records) {
      const formVals = {
        barcodeFieldInfo: records?.filter((item) => !item?.isCustom, []),
        newBarcodeFieldInfo: records?.filter((item) => item?.isCustom === true, []) || [],
      };
      formRef?.current?.setFieldsValue(formVals);
      cardRef?.current?.setFieldsValue(formVals);
      modal.hide();
    } else {
      modalFormRef.current?.resetFields();
      modal.hide();
    }
  };

  return (
    <BetaSchemaForm<DataItem>
      layoutType="ModalForm"
      title={modalTitle || t('title')}
      formRef={modalFormRef}
      columns={FieldColumns({
        t,
        records,
        setRecords,
      })}
      grid
      visible={modal.visible}
      autoFocusFirstInput
      modalProps={{
        destroyOnClose: true,
        maskClosable: false,
        width: '50%',
        ...modal,
        onCancel: closeModal,
      }}
      submitter={{
        searchConfig: {
          submitText: t('done'),
        },
        submitButtonProps: {
          shape: 'round',
          type: 'primary',
        },
        resetButtonProps: {
          shape: 'round',
          type: 'primary',
          ghost: true,
        },
      }}
      onFinish={onFinish}
    />
  );
};

export default EditBarcode;
