import { InfoCircleOutlined } from '@ant-design/icons';
import ProForm, { ProFormDependency } from '@ant-design/pro-form';
import { Col, ColProps, Form, Row, RowProps, Typography } from 'antd';
import { TradePartnerSelect } from 'components/GSelect';
import LocationSelect from 'components/GSelect/LocationSelect';
import useListLocation from 'hooks/useListLocation';
import useWatchValues from 'pages/AllShipments/hooks/useWatchValues';
import { useEventsStore } from 'pages/Events/hooks';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getEventsT } from 'utils';
import styles from './index.module.less';
import { HeaderProps } from './typings';

const rowProps: RowProps = { gutter: [32, 32] };
const col1Props: ColProps = { xs: 24, sm: 24, md: 5, lg: 3 };
const col2Props: ColProps = { xs: 24, sm: 24, md: 18, lg: 21 };
const col21Props: ColProps = { xs: 24, sm: 24, md: 6, lg: 6 };

const Header: FC<HeaderProps> = ({
  t,
  title,
  form,
  onInfoClick,
  locationDisabled,
  onChangeLocation,
  isManual,
  locationUrn,
  locationId,
  currentForm,
}) => {
  const multipleLocations = Form?.useWatch('isMultipleLocations', form) || false;
  const newReceiveFileUpload = Form?.useWatch?.('newReceiveFileUpload', form) || false;
  const [initDone, setInitDone] = useState<boolean>(false);
  const { dataEntryMethod } = useEventsStore();
  const { multiReceiveObjects } = useWatchValues(form);
  const [queryParams] = useSearchParams();

  const { data: locations } = useListLocation({
    tradePartnerStatus: ['Self', 'NonWholechain'],
  });
  const hideForm = useMemo(
    () => (dataEntryMethod === 'csvUpload' ? !newReceiveFileUpload : multipleLocations),
    [dataEntryMethod, multipleLocations, newReceiveFileUpload],
  );

  useEffect(() => {
    if (dataEntryMethod === 'csvUpload') {
      currentForm?.setFieldsValue({
        location: queryParams?.get('locationId')?.trim()
          ? queryParams?.get('locationId')?.trim()
          : undefined,
        company: queryParams?.get('tradePartnerId')?.trim()
          ? queryParams?.get('tradePartnerId')?.trim()
          : undefined,
      });
    }
  }, [currentForm, dataEntryMethod, form, queryParams]);

  const initForm = useCallback(() => {
    if (!initDone && locations?.length) {
      const locObj = locations?.find(
        (item) => item?.urn === locationUrn || item?.id === locationId,
        [],
      );
      currentForm?.setFieldsValue({
        company: locObj?.tradePartnerId || undefined,
        location: locObj?.id || undefined,
      });
      form?.setFieldsValue({
        company: locObj?.tradePartnerId || undefined,
        location: locObj?.id || undefined,
      });
      setInitDone(true);
    }
  }, [currentForm, form, initDone, locationId, locationUrn, locations, setInitDone]);

  useEffect(() => {
    initForm();
  }, [initForm]);

  return (
    <Row {...rowProps}>
      <Col {...col1Props}>
        <Typography.Title level={4} className={styles.header}>
          {getEventsT(title)}&nbsp;
          <small>
            <InfoCircleOutlined onClick={onInfoClick} width={12} />
          </small>
        </Typography.Title>
      </Col>
      <Col {...col2Props}>
        <ProForm
          form={currentForm}
          layout="inline"
          colProps={col21Props}
          submitter={{ render: () => null }}
        >
          <ProForm.Item
            name="company"
            className={styles.headerinput1}
            hidden={hideForm}
            rules={
              !hideForm ? [{ required: true, message: 'Please select a company.' }] : undefined
            }
          >
            <TradePartnerSelect
              showExtID
              className={styles.location}
              disabled={locationDisabled}
              placeholder="Receive Company"
              onChange={(value) => {
                form?.setFieldsValue({
                  company: value,
                });
              }}
              initParams={{
                types: ['Self', 'NonWholechain'],
              }}
            />
          </ProForm.Item>

          <ProFormDependency name={['company']}>
            {({ company }) => (
              <ProForm.Item
                name="location"
                className={styles.headerinput1}
                rules={!hideForm ? [{ required: true, message: t?.('location_req') }] : undefined}
                hidden={hideForm}
              >
                <LocationSelect
                  showExtID
                  className={styles.location}
                  disabled={locationDisabled}
                  searchValue={
                    !(!isManual && title === 'Observe') ? undefined : t?.('locations_csv')
                  }
                  initParams={{
                    tradePartnerStatus: ['Self', 'NonWholechain'],
                  }}
                  placeholder="Receive Location"
                  filterItem={(val) => (company ? val?.tradePartnerId === company : true)}
                  onChange={(val) => {
                    form?.setFieldsValue({
                      location: val,
                    });
                    onChangeLocation?.(form, val, multiReceiveObjects?.length > 0);
                  }}
                />
              </ProForm.Item>
            )}
          </ProFormDependency>
        </ProForm>
      </Col>
    </Row>
  );
};

export default React.memo(Header);
