import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { ActionType, EditableFormInstance, ProColumns } from '@ant-design/pro-table';
import { RecordKey } from '@ant-design/pro-utils/lib/useEditableArray';
import { FormInstance, TablePaginationConfig, Tooltip, message } from 'antd';
import ActionButtons from 'components/ActionButtons';
import { PhoneCodeSelect } from 'components/GSelect';
import GTable from 'components/GTable';
import { useTranslationContext } from 'contexts/TranslationProvider';
import { useModalVisibility } from 'hooks';
import useListLocation from 'hooks/useListLocation';
import { GetLocationsOptionalParams } from 'hooks/useListLocation/typings';
import { AsYouType, CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import useAddEditActions from 'pages/Network/hook/useAddEditActions';
import React, { FC, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { LocationResponse } from 'services/api/client/src';
import {
  PhoneCode,
  checkIfCodeEnteredInPhoneNumber,
  errorHandler,
  getPhoneNumber,
  validatePolygon,
} from 'utils';
import AddressSelectInput from '../../../components/AddressSelectInput';
import UpgradeModal from '../UpgradeModal';
import styles from './index.module.less';
import { CustomDataTemplateProps, EditableFormProps, LocationCols } from './typings';

type ColumnsType = {
  onEditRow: (actionPayload?: LocationCols) => void;
  onDeleteRow?: (actionPayload?: LocationCols) => void;
  editableFormRef?: MutableRefObject<EditableFormInstance<EditableFormProps> | undefined>;
  t: TFunction<'pages', 'network.add_network.form_fields'>;
  isEdit?: boolean;
  isVessel?: string;
  form: FormInstance<any>;
  hideGoogleMap?: boolean;
};

const columns = ({
  onEditRow,
  onDeleteRow,
  isEdit = true,
  t,
  isVessel,
  editableFormRef,
  hideGoogleMap,
}: ColumnsType): Array<ProColumns<LocationCols>> => [
  {
    title: t('location_number'),
    dataIndex: 'locNumberId',
    fieldProps: {
      placeholder: t('location_number'),
    },
    tooltip: {
      icon: <InfoCircleOutlined />,
      placement: 'top',
      title: t('loc_number_tooltip'),
      key: null,
      type: '',
      props: null,
    },
  },
  {
    title: isVessel === 'No' ? t('location_name') : t('vessel_name'),
    dataIndex: 'locationName',
    fieldProps: {
      placeholder: isVessel === 'No' ? t('location_name') : t('vessel_name'),
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: t('validation_text', {
            field: isVessel
              ? t('vessel_name').toLocaleLowerCase()
              : t('location_name').toLocaleLowerCase(),
          }),
        },
        {
          whitespace: true,
          message: t('validation_text', {
            field: isVessel
              ? t('vessel_name').toLocaleLowerCase()
              : t('location_name').toLocaleLowerCase(),
          }),
        },
      ],
    },
    render: (value, record) => (
      <span>
        {value || ''}
        {!isEdit && !record?.isShared && (
          <Tooltip title={t('location_name_tooltip')}>
            <ExclamationCircleOutlined className={styles.locationtooltip} />
          </Tooltip>
        )}
      </span>
    ),
  },
  {
    title: t('address'),
    dataIndex: 'address',
    renderFormItem: (row, { recordKey }) => (
      <AddressSelectInput
        editableFormRef={editableFormRef}
        recordKey={recordKey}
        disable={hideGoogleMap}
      />
    ),
    renderText: (value) => <span>{value?.displayAddress || ''}</span>,
    ellipsis: true,
    fieldProps: {
      placeholder: t('address'),
    },
  },
  {
    title: t('geo_coordinates'),
    dataIndex: 'geoCoordinates',
    formItemProps: {
      rules: [
        {
          required: true,
          message: t('validation_text', {
            field: t('geo_coordinates'),
          }),
        },
        {
          pattern:
            /^([-+]?(?:[0-8]?\d(?:\.\d+)?|90(?:\.0+)?)),\s*([-+]?(?:1[0-7]\d(?:\.\d+)?|180(?:\.0+)?|[0-9]{1,2}(?:\.\d+)?))$/,
          message: t('validate_coordinate'),
        },
      ],
    },
    fieldProps: {
      placeholder: t('geo_coordinates'),
    },
  },
  {
    title: t('contact'),
    dataIndex: 'contact',
    fieldProps: {
      placeholder: t('contact'),
    },
  },

  {
    title: t('phone_number'),
    dataIndex: ['phonePrefix', 'phoneCode', 'phoneNumber'],
    key: 'number',
    render: (text, row) => <div>{row.number}</div>,
    fieldProps: (form, { rowKey, entity, isEditable }) => ({
      placeholder: t('phone_number'),
      onChange: (e: any) => {
        const code = editableFormRef?.current?.getFieldValue(String(rowKey))?.phoneCode || 'US';
        let value = new AsYouType(code as CountryCode).input(e.target.value);
        value = checkIfCodeEnteredInPhoneNumber(value);
        editableFormRef?.current?.setFieldsValue({
          [String(rowKey)]: {
            number: value,
          },
        });
      },
      addonBefore: (
        <PhoneCodeSelect
          value={isEditable ? undefined : entity.phoneCode}
          defaultValue={entity.phoneCode || 'US'}
          onChange={(val, option) => {
            // @ts-ignore
            const item: PhoneCode = option?.itemProps;
            editableFormRef?.current?.setFieldsValue({
              [String(rowKey)]: {
                phoneCode: val,
                phonePrefix: String(item?.code || ''),
              },
            });
          }}
        />
      ),
    }),
    formItemProps: (form, { rowKey }) => {
      const code = editableFormRef?.current?.getFieldValue(String(rowKey))?.phoneCode || 'US';
      const number = editableFormRef?.current?.getFieldValue(String(rowKey))?.number;
      const isValid = isValidPhoneNumber(String(number), code as CountryCode);
      return {
        hasFeedback: true,
        validateStatus: !number?.trim?.() || isValid ? 'success' : 'error',
        initialValue: number,
        rules: [
          {
            message: t?.('validate_phone_number'),
            validator: () => {
              if (!number?.trim?.() || isValid) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t?.('validate_phone_number')));
            },
          },
        ],
      };
    },
  },

  {
    title: t('extension'),
    dataIndex: 'extension',
    fieldProps: (form, { rowKey }) => ({
      placeholder: t('extension'),
      onChange: (e: any) => {
        const newValue = e.target.value?.replace(/[^0-9*#+-]/g, '');
        editableFormRef?.current?.setFieldsValue({
          [String(rowKey)]: {
            extension: newValue,
          },
        });
      },
    }),
  },
  {
    title: t('email'),
    dataIndex: 'email',
    formItemProps: {
      rules: [
        {
          type: 'email',
          message: t('validate_email'),
        },
      ],
    },
    fieldProps: {
      placeholder: t('email'),
    },
  },
  {
    title: t('gln'),
    dataIndex: 'gln',
    fieldProps: {
      placeholder: t('gln'),
    },
    tooltip: {
      icon: <InfoCircleOutlined />,
      title: t('gln_tooltip'),
      key: null,
      type: '',
      props: null,
    },
  },

  // {
  //   title: t('vessel_name'),
  //   dataIndex: 'vesselName',
  //   hideInTable: isVessel === 'No',
  //   fieldProps: {
  //     placeholder: t('vessel_name'),
  //   },
  // },
  {
    title: t('dun_no'),
    dataIndex: 'locDuns',
    fieldProps: {
      placeholder: t('dun_no_placeholder'),
      maxLength: 13,
      minLength: 9,
      showCount: true,
    },
    formItemProps: {
      rules: [
        {
          validator: async (_, value) => {
            if (!value) return; // Allow empty value if not required
            // Check if the value is exactly 9 or 13 digits
            if (value.length !== 9 && value.length !== 13) {
              throw new Error(t('validation_duns_length'));
            }
          },
          message: t('validation_duns_length', {
            field: t('hq_duns').toLocaleLowerCase(),
            minLength: 9,
          }),
        },
      ],
    },
  },
  {
    title: t('captains_name'),
    dataIndex: 'captainsName',
    hideInTable: isVessel === 'No',
    fieldProps: {
      placeholder: t('captains_name'),
    },
  },
  {
    title: t('vessel_state'),
    dataIndex: 'vesselState',
    hideInTable: isVessel === 'No',
    fieldProps: {
      placeholder: t('vessel_state'),
    },
  },
  {
    title: t('imo_number'),
    dataIndex: 'imoNumber',
    hideInTable: isVessel === 'No',
    fieldProps: {
      placeholder: t('imo_number'),
    },
  },
  {
    title: t('vessel_reg'),
    dataIndex: 'vesselReg',
    hideInTable: isVessel === 'No',
    fieldProps: {
      placeholder: t('vessel_reg'),
    },
  },
  {
    title: t('vessel_auth'),
    dataIndex: 'vesselAuth',
    hideInTable: isVessel === 'No',
    fieldProps: {
      placeholder: t('vessel_auth'),
    },
  },
  {
    title: t('vessel_link'),
    dataIndex: 'vesselLink',
    hideInTable: isVessel === 'No',
    fieldProps: {
      placeholder: t('vessel_link'),
    },
  },
  {
    title: t('polygon'),
    dataIndex: 'polygon',
    valueType: 'textarea',
    render: (value) =>
      value && value !== '-' ? <div className={styles.polytext}>{value}</div> : '-',
    fieldProps: (form, { rowKey }) => ({
      placeholder: t('polygon'),
      onChange: (e: any) => {
        const parsedJSON = JSON.parse(e.target.value);
        form?.setFieldsValue({
          [String(rowKey)]: {
            polygon: JSON.stringify(parsedJSON, null, 2),
          },
        });
      },
    }),
    tooltip: {
      icon: <QuestionCircleOutlined />,
      placement: 'top',
      title: (
        <>
          {t('polygon_tooltip')}{' '}
          <a
            href="https://geojson.io/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.textcolor}
          >
            {t('here')}.
          </a>
        </>
      ),
      key: null,
      type: '',
      props: null,
    },
    formItemProps: {
      rules: [
        {
          validator: validatePolygon,
          message: t('invalid_polygon'),
        },
      ],
    },
  },
  {
    dataIndex: 'actions',
    hideInTable: !isEdit,
    fixed: 'right',
    valueType: 'option',
    render: (text, record) => (
      <ActionButtons
        record={record}
        onEdit={onEditRow}
        showDelete={!!onDeleteRow}
        onDelete={onDeleteRow}
      />
    ),
    width: 120,
  },
];
const LocationTable: FC<CustomDataTemplateProps> = ({
  form,
  isVessel,
  selectedCompany,
  userType,
  fieldValue,
  allowAddNewRecord,
  onDeleteLocation,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'network.add_network.form_fields' });
  const { setFieldsValue, getFieldValue } = form;
  const tactionRef = useRef<ActionType>();
  const isEdit = userType !== 'WC';
  const { updateLocationData, createNewLocation } = useAddEditActions();
  const attributes: Array<LocationCols> = getFieldValue(fieldValue);
  const [loading, setLoading] = useState<boolean>(true);
  const [addMode, setAddMode] = useState<boolean>(false);
  const locationPath = useLocation();
  const { hideGoogleMap } = useTranslationContext();

  const setAttributes = useCallback(
    (value: Array<LocationCols>) =>
      setFieldsValue({
        [fieldValue]: value,
      }),
    [fieldValue, setFieldsValue],
  );
  const editableFormRef = useRef<EditableFormInstance<any>>();
  const upgradeModal = useModalVisibility(false);
  const [statePageSize, setStatePageSize] = useState<number>(10);
  const [params, setParams] = useState<GetLocationsOptionalParams>();

  const { data: locationData, totalItems } = useListLocation(params);

  useEffect(
    () => {
      setAttributes([]);
      setLoading(true);
      setParams(
        selectedCompany?.id
          ? {
              partnerIds: [selectedCompany?.id],
              isVessel: isVessel === 'Yes',
              pageNumber: 1,
              pageSize: 10,
            }
          : undefined,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isVessel, selectedCompany?.id],
  );

  useEffect(() => {
    if (locationData) {
      if (locationData?.length) {
        const data =
          locationData?.map((loc: LocationResponse) => {
            const numberObj = getPhoneNumber(loc?.contact?.phone);
            return {
              id: loc?.id,
              gln: loc.gln,
              locationName: loc.name || '',
              address: loc.address ? loc.address : {},
              geoCoordinates: `${loc?.address?.geoCoordinates?.latitude},${loc?.address?.geoCoordinates?.longitude}`,
              number: numberObj?.number || '',
              phoneNumber: loc?.contact?.phone || '',
              phoneCode: numberObj?.iso,
              phonePrefix: numberObj?.code,
              email: loc?.contact?.email,
              vesselName: loc?.vessel?.vesselName,
              vesselLink: loc?.vessel?.vesselPublicRegistryLink,
              vesselAuth: loc?.vessel?.vesselSatelliteTrackingAuthority,
              imoNumber: loc?.vessel?.imoNumber,
              vesselState: loc?.vessel?.vesselFlagState,
              vesselReg: loc?.vessel?.vesselRegistration,
              isShared: loc?.sharedWith?.length !== 0,
              locNumberId: loc?.externalIdentifier,
              extension: loc?.extension,
              captainsName: loc?.captainsName,
              locDuns: loc?.duns,
              contact: loc?.contact?.name,
              polygon: loc?.geoJson,
            };
          }) || [];
        setAttributes(data);
        setLoading(false);
      } else {
        setAttributes([]);
        setLoading(false);
      }
    }
  }, [locationData, setAttributes]);

  const onEditRow = (loc?: LocationCols) => {
    tactionRef.current?.startEditable(loc?.id || 0);
  };

  const onSaveAttribute = async (rowKey: RecordKey, rawData: LocationCols) => {
    try {
      const formData = {
        ...rawData,
        isVessel,
        phoneNumber:
          rawData.number && rawData.phoneCode
            ? `${rawData.phoneCode},${rawData.number}`
            : undefined,
      };
      const addEditLocationData = locationData?.find(
        (item: LocationResponse) => item?.id === formData.id,
      );
      if (addEditLocationData) {
        const res = await updateLocationData(
          String(addEditLocationData?.id),
          formData,
          isVessel === 'Yes',
        );
        if (res?.name) {
          message.success(t('success.update_loc', { location: res.name }));
        }
      } else {
        setAddMode(false);
        const res = await createNewLocation(formData, selectedCompany, isVessel === 'Yes');
        if (res?.tradePartnerName) {
          message.success(t('success.add_loc', { company: res?.tradePartnerName }));
          setStatePageSize(statePageSize - 1);
        } else {
          // eslint-disable-next-line no-throw-literal
          throw 'error';
        }
      }
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };

  const addNewRecord = useCallback(() => {
    if (allowAddNewRecord) {
      setAddMode(true);
      setStatePageSize(statePageSize + 1);
      tactionRef.current?.addEditRecord?.({
        id: Date.now().toString(),
      });
    } else {
      upgradeModal?.show();
    }
  }, [allowAddNewRecord, statePageSize, upgradeModal]);

  const onTableChange = (pagination: TablePaginationConfig) => {
    setStatePageSize(pagination?.pageSize || statePageSize);
    setParams({
      ...params,
      pageNumber: pagination?.current,
      pageSize: pagination?.pageSize,
    });
  };

  const onDeleteRow = useCallback(
    async (record?: LocationCols) => {
      onDeleteLocation?.(record, selectedCompany?.name || '');
    },
    [onDeleteLocation, selectedCompany?.name],
  );

  return (
    <>
      <UpgradeModal modal={upgradeModal} tkey="your_loc." />
      <GTable<LocationCols>
        key="sttribt"
        editableFormRef={editableFormRef}
        scroll={{ x: isVessel === 'No' ? 2000 : 3000 }}
        value={attributes}
        actionRef={tactionRef}
        loading={loading}
        columns={columns({
          onEditRow,
          onDeleteRow: !locationPath?.pathname?.includes('add_location') ? onDeleteRow : undefined,
          t,
          editableFormRef,
          isEdit,
          isVessel,
          form,
          hideGoogleMap,
        })}
        editable={{
          onSave: (rowKey, data) => onSaveAttribute(rowKey, data),
          onCancel: async (_rowKey, data) => {
            if (addMode) {
              setAddMode(false);
              setStatePageSize(statePageSize - 1);
              setParams({
                ...params,
                pageSize: (params?.pageSize || 0) - 1,
              });
            }

            tactionRef.current?.cancelEditable(data?.locationName || 0);
          },
          onChange: (rowKey, data) => {
            const phoneNumber = Array.isArray(data) ? data[0]?.phoneNumber : data.phoneNumber;
            const numberObj = getPhoneNumber(phoneNumber);
            editableFormRef?.current?.setFieldsValue({
              [String(rowKey)]: {
                number: numberObj?.tenDigits,
                phoneCode: numberObj?.iso,
                phonePrefix: numberObj?.code,
              },
            });
          },
        }}
        options={{
          reload: false,
          setting: false,
        }}
        actionsRenderOptions={{
          save: true,
          cancel: true,
        }}
        recordCreatorProps={false}
        onAddRecordClick={addNewRecord}
        enableRecordCreator={isEdit}
        addBtnText={t('add')}
        pagination={{
          pageSize: statePageSize,
          total: totalItems,
          showTotal: (total, range) =>
            t('location_table_pagi', { range0: range[0], range1: range[1], total }),
        }}
        onTableChange={onTableChange}
      />
    </>
  );
};

export default React.memo(LocationTable);
