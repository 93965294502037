import { BetaSchemaForm } from '@ant-design/pro-form';
import { useModalVisibility } from 'hooks';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { useGetApplicationIdentifiers } from 'services/api/useLabels';
import GCardDrawer from '../../../components/GCardDrawer';
import { getPrintingOption } from '../helper';
import BarcodeLabelLayoutPrint from '../LabelForm/BarcodeLabelLayoutPrint';
import { AddCardColumns, AddFormColumns } from './AddLabel.fields';
import EditBarcode from './EditBarcode';
import EditLabelInfo from './EditLabelInfo';
import {
  AddModalProps,
  addSubmitTypeFn,
  DataItem,
  defaultBarcodeFields,
  defaultFieldInfoObject,
  PrintOptions,
} from './typings';
import useAddEditActions from './useAddEditActions';

const AddLabel: FC<AddModalProps> = ({ modal }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'labels.add_label' });
  const {
    contextHolder,
    onAdd,
    modalFormRef,
    modalCardRef,
    onSaveAsDraft,
    setIsLoading,
    isLoading,
    onDrawerClose,
    printConfirm,
  } = useAddEditActions({
    t,
    modal,
  });

  const { data: appIdData } = useGetApplicationIdentifiers();
  const editLabelInfoModal = useModalVisibility(false);
  const editBarcodeModal = useModalVisibility(false);
  const [printData, setPrintData] = useState<DataItem | undefined | null>(null);
  const printRef = useRef(null);
  const [printSetting, setPrintSetting] = useState<PrintOptions>('4x6');
  const [isSave, setIsSave] = useState(false);

  // Create print styles based on selected format
  const getPageStyle = () => `
    @page {
      size: ${getPrintingOption(printSetting)};
      margin: 0;
    }
  `;

  const handlePrintOption = (e: PrintOptions) => {
    setPrintSetting(e);
  };

  const handlePrint = useReactToPrint({
    contentRef: printRef,
    onAfterPrint: () => {
      // Cleanup after printing
      document.body.classList.remove('printing');
      return true;
    },
    onBeforePrint: async () => {
      // Add any pre-print preparations
      document.body.classList.add('printing');
    },
    pageStyle: getPageStyle(),
  });

  useEffect(() => {
    modalFormRef?.current?.setFieldsValue({
      fieldInfo: defaultFieldInfoObject(),
      barcodeFieldInfo: defaultBarcodeFields(),
    });
  }, [modalFormRef, t]);

  const onValueChange = useCallback(() => {
    setTimeout(() => {
      const values: DataItem = modalFormRef?.current?.getFieldsValue();
      modalCardRef?.current?.setFieldsValue(values);
    }, 100);
  }, [modalCardRef, modalFormRef]);

  const onFormFinish = useCallback<addSubmitTypeFn>(
    async (formData) => {
      try {
        const dataValues = formData as unknown as DataItem;
        setPrintData(dataValues);

        await new Promise<void>((resolve) => {
          requestAnimationFrame(() => {
            requestAnimationFrame(() => resolve());
          });
        });
        if (printRef.current) {
          handlePrint();
        }
        onSaveAsDraft();
      } finally {
        setIsLoading(false);
      }
    },
    [handlePrint, onSaveAsDraft, setIsLoading],
  );

  const onLabelEdit = useCallback(() => {
    editLabelInfoModal?.show();
  }, [editLabelInfoModal]);

  const onBarcodeEdit = useCallback(() => {
    editBarcodeModal?.show();
  }, [editBarcodeModal]);

  const handleSavePrint = useCallback(async () => {
    try {
      setIsSave(true);

      await modalFormRef.current?.validateFields();

      await onAdd();
    } catch (error) {
      setIsSave(false);
    }
  }, [modalFormRef, onAdd]);

  return (
    <>
      {contextHolder}
      {editLabelInfoModal.visible && (
        <EditLabelInfo
          formRef={modalFormRef}
          modal={editLabelInfoModal}
          t={t}
          cardRef={modalCardRef}
        />
      )}
      {editBarcodeModal.visible && (
        <EditBarcode formRef={modalFormRef} modal={editBarcodeModal} t={t} cardRef={modalCardRef} />
      )}
      <GCardDrawer
        t={t}
        DrawerProps={{
          ...modal,
          visible: modal ? modal?.visible : true,
          onClose: () => onDrawerClose(),
        }}
        saveButtonProps={{
          onClick: handleSavePrint,
          loading: isSave,
        }}
        cancelButtonProps={{
          onClick: onSaveAsDraft,
          loading: isLoading,
        }}
        leftChildern={
          <BetaSchemaForm<DataItem>
            formRef={modalCardRef}
            layoutType="Form"
            grid
            submitter={{
              render: () => null,
            }}
            columns={AddCardColumns({
              handlePrintOption,
              modalFormRef,
              applicationIdentifierLookup: appIdData,
            })}
            //  initialValues={initialValues}
          />
        }
        rightChildern={
          <BetaSchemaForm<DataItem>
            formRef={modalFormRef}
            layoutType="Form"
            grid
            onFinish={onFormFinish}
            onValuesChange={onValueChange}
            onFinishFailed={() => printConfirm()}
            submitter={{
              render: () => null,
            }}
            //  initialValues={initialValues}
            columns={AddFormColumns({
              t,
              isEdit: false,
              onLabelEdit,
              onBarcodeEdit,
              modalCardRef,
            })}
          />
        }
      />
      {printData && (
        <BarcodeLabelLayoutPrint
          applicationIdentifierLookup={appIdData}
          ref={printRef}
          data={printData}
          t={t}
          brandLogo={null}
          certificationLogo={null}
          companyLogo={null}
          printOption={printSetting}
        />
      )}
    </>
  );
};

export default React.memo(AddLabel);
