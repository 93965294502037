import { GoogleMap, InfoWindow, Marker, PolygonF } from '@react-google-maps/api';
import { GoogleMarkerSvg, MyGoogleMarkerSvg } from 'assets';
import classNames from 'classnames';
import { useEventContext } from 'pages/Products';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { google } from 'google-maps';
import Polylines from './Polylines';
import styles from './index.module.less';
import { GMapProps, MarkerItemProps, Polygons } from './typings';

const restriction: google.maps.MapRestriction = {
  latLngBounds: {
    north: 85,
    south: -85,
    west: -175,
    east: 175,
  },
  strictBounds: false,
};

const defaultCenter = {
  lat: 0,
  lng: 0,
};

const defaultContainerStyle = {
  width: '100%',
  height: '388px',
};

const GMap: FC<GMapProps> = ({
  markers,
  renderInfoWindow,
  containerStyle,
  isRestrict,
  showPolylines = false,
  options,
  initActiveMarker,
  allowFitBounds,
  edges,
  geoPolygonJSON,
  ...props
}) => {
  const [mapInstance, setMapInstance] = useState<google.maps.Map>();
  const [activeMarker, setActiveMarker] = useState<MarkerItemProps>();
  const [InfoWindowOpen, setInfoWindowOpen] = useState<boolean>(false);
  const [zoom, setZoom] = useState<number>(1);

  const { showAllProducts } = useEventContext();

  const onMapLoad = useCallback((map: google.maps.Map) => {
    setTimeout(() => setMapInstance(map), 500);
  }, []);
  const onClickMarker = useCallback(
    (e: google.maps.MapMouseEvent, marker: MarkerItemProps) => {
      const { disabled = false } = marker;
      setActiveMarker(marker);
      setInfoWindowOpen(false);
      if (mapInstance) {
        mapInstance.panTo(marker.position);
        setZoom(15);
      }

      if (marker?.onClick) {
        marker?.onClick(e);
      }
      if (!disabled) {
        setInfoWindowOpen(true);
      }
    },
    [mapInstance],
  );

  const getIcon = useCallback((marker: MarkerItemProps) => {
    if (Object.prototype.hasOwnProperty.call(marker, 'isMyLocation')) {
      if (marker.isMyLocation === true) {
        return MyGoogleMarkerSvg;
      }
      return GoogleMarkerSvg;
    }
    return MyGoogleMarkerSvg;
  }, []);

  const fitMarkerBounds = useCallback(() => {
    if (allowFitBounds && mapInstance && (markers?.length || 0) > 1) {
      const bounds = new window.google.maps.LatLngBounds();
      markers?.forEach((m) => {
        // @ts-ignore
        bounds.extend(new window.google.maps.LatLng(m.position?.lat, m.position?.lng));
      });
      mapInstance?.fitBounds(bounds);
    }
  }, [mapInstance, markers, allowFitBounds]);

  useEffect(() => {
    if (initActiveMarker && (markers?.length || 0) > 0) {
      setActiveMarker(initActiveMarker);
      setInfoWindowOpen(true);
    }
  }, [initActiveMarker, markers]);

  useEffect(fitMarkerBounds, [fitMarkerBounds]);
  useEffect(() => {
    setInfoWindowOpen(false);
    setTimeout(() => {
      setInfoWindowOpen(true);
    }, 500);
  }, [showAllProducts]);

  const polygons: Polygons[] =
    geoPolygonJSON?.features?.map(
      (feature, index) => ({
        index,
        coordinates: feature?.geometry?.coordinates?.[0]?.map(
          (coord) => ({ lat: coord?.[1], lng: coord?.[0] }), // Convert to google.maps.LatLng and flatten
        ),
      }),
      [],
    ) || [];
  const getPolygonOptions = () => ({
    fillColor: 'orange',
    fillOpacity: 0.4,
    strokeColor: '#000',
    strokeOpacity: 1,
    strokeWeight: 1,
  });

  return (
    <GoogleMap
      mapContainerClassName={classNames(styles.mapcontainer)}
      mapContainerStyle={{ ...defaultContainerStyle, ...containerStyle }}
      center={markers ? markers[0]?.position : defaultCenter}
      zoom={zoom}
      onLoad={onMapLoad}
      onZoomChanged={() => {
        setZoom(mapInstance?.getZoom() || 2);
      }}
      {...props}
      options={{
        controlSize: 25,
        ...(isRestrict && { restriction }),
        minZoom: 2,
        streetViewControl: false,
        ...options,
      }}
    >
      <>
        {mapInstance &&
          markers?.map((marker) => (
            <Marker
              icon={getIcon(marker)}
              key={marker.id}
              position={marker.position}
              onClick={(e) => onClickMarker(e, marker)}
              label={
                Number(marker?.label || '0') > 1
                  ? { text: marker?.label || '', color: '#fff' }
                  : undefined
              }
            />
          ))}
        {mapInstance && activeMarker && renderInfoWindow && InfoWindowOpen && (
          <InfoWindow
            position={activeMarker.position}
            options={{ pixelOffset: new window.google.maps.Size(0, -40) }}
            onCloseClick={() => setInfoWindowOpen(false)}
          >
            {renderInfoWindow(activeMarker)}
          </InfoWindow>
        )}
        {mapInstance && showPolylines && <Polylines markers={markers} edges={edges} />}
        {mapInstance &&
          geoPolygonJSON &&
          polygons?.map?.(
            (polygon) => (
              <PolygonF
                key={polygon?.index}
                path={polygon?.coordinates}
                options={getPolygonOptions()}
              />
            ),
            [],
          )}
      </>
    </GoogleMap>
  );
};
export default React.memo(GMap);
