import { QueryClient, useMutation, useQuery } from 'react-query';
import {
  CreateCaseLabelOptionalParams,
  CreateCaseLabelResponse,
  CreatePalletLabelRequest,
  DeleteCaseLabelFieldSettingsOptionalParams,
  DeleteCaseLabelOptionalParams,
  DeletePalletLabelOptionalParams,
  DuplicateLabelOptionalParams,
  DuplicateLabelResponse,
  GetApplicationIdentifiersOptionalParams,
  GetApplicationIdentifiersResponse,
  GetCaseLabelImagesOptionalParams,
  GetCaseLabelImagesResponse,
  GetCaseLabelOptionalParams,
  GetCaseLabelResponse,
  GetLabelsOptionalParams,
  GetLabelsResponse,
  GetPalletLabelOptionalParams,
  GetPalletLabelResponse,
  SaveCaseLabelFieldSettingsOptionalParams,
  UpdateCaseLabelOptionalParams,
  UpdateCaseLabelResponse,
  UpdatePalletLabelOptionalParams,
  UpdatePalletLabelResponse,
  UploadLogo$multipartOptionalParams,
  UploadLogoResponse,
} from 'services/api/client/src';
import { HttpClient } from 'services/utils/security';
import httpClient from 'services/utils/security/HttpClient';

/** @param options The options parameters. */
export const getApplicationIdentifiers = (
  options?: GetApplicationIdentifiersOptionalParams,
): Promise<GetApplicationIdentifiersResponse> => HttpClient.getApplicationIdentifiers(options);

export const useGetApplicationIdentifiers = (options?: GetApplicationIdentifiersOptionalParams) =>
  useQuery(['getApplicationIdentifiers', options], () => getApplicationIdentifiers(options));

export const getLabels = (options?: GetLabelsOptionalParams): Promise<GetLabelsResponse> =>
  httpClient.getLabels(options);

export const useGetLabels = (options?: GetLabelsOptionalParams) =>
  useQuery(['getLabels', options], () => getLabels(options));

export const deleteCaseLabel = (
  id: string,
  options?: DeleteCaseLabelOptionalParams,
): Promise<void> => httpClient.deleteCaseLabel(id, options);

export const useDeleteCaseLabel = (queryClient: QueryClient) =>
  useMutation(
    ({ id, options }: { id: string; options?: DeleteCaseLabelOptionalParams }) =>
      deleteCaseLabel(id, options),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getLabels');
      },
    },
  );

export const deletePalletLabel = (
  id: string,
  options?: DeletePalletLabelOptionalParams,
): Promise<void> => httpClient.deletePalletLabel(id, options);

export const useDeletePalletLabel = (queryClient: QueryClient) =>
  useMutation(
    ({ id, options }: { id: string; options?: DeletePalletLabelOptionalParams }) =>
      deletePalletLabel(id, options),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getLabels');
      },
    },
  );

export const deleteCaseLabelFieldSettings = (
  labelId: string,
  settingId: string,
  options?: DeleteCaseLabelFieldSettingsOptionalParams,
): Promise<void> => httpClient.deleteCaseLabelFieldSettings(labelId, settingId, options);

export const useDeleteCaseLabelFieldSettings = (queryClient: QueryClient) =>
  useMutation(
    ({
      labelId,
      settingId,
      options,
    }: {
      labelId: string;
      settingId: string;
      options?: DeleteCaseLabelFieldSettingsOptionalParams;
    }) => deleteCaseLabelFieldSettings(labelId, settingId, options),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getLabels');
      },
    },
  );

export const duplicateLabel = (
  options?: DuplicateLabelOptionalParams,
): Promise<DuplicateLabelResponse> => httpClient.duplicateLabel(options);

export const useDuplicateLabel = (queryClient: QueryClient) =>
  useMutation(
    ({ options }: { options?: DuplicateLabelOptionalParams }) => duplicateLabel(options),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getLabels');
      },
    },
  );

const createCaseLabel = (
  options?: CreateCaseLabelOptionalParams,
): Promise<CreateCaseLabelResponse> => httpClient.createCaseLabel(options);

export const useCreateCaseLabel = (queryClient: QueryClient) =>
  useMutation(
    ({ options }: { options: CreateCaseLabelOptionalParams }) => createCaseLabel(options),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('getLabels');
        queryClient.invalidateQueries(['getLabelImages', data?.body]);
      },
    },
  );

const uploadLogo = async (
  id: string,
  options?: UploadLogo$multipartOptionalParams,
): Promise<UploadLogoResponse> => HttpClient.uploadLogo(id, 'multipart/form-data', options);

export const useUploadLogo = (queryClient: QueryClient) =>
  useMutation(
    ({ id, options }: { id: string; options: UploadLogo$multipartOptionalParams }) =>
      uploadLogo(id, options),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('getLabels');
        queryClient.invalidateQueries(['getLabelImages', data?.body]);
      },
    },
  );

export const getCaseLabel = (
  id: string,
  options?: GetCaseLabelOptionalParams,
): Promise<GetCaseLabelResponse> => httpClient.getCaseLabel(id, options);

export const useGetLabelById = (id: string, options?: GetCaseLabelOptionalParams) =>
  useQuery(['getLabelById', id, options], () => getCaseLabel(id, options));

export const getCaseLabelImages = (
  id: string,
  options?: GetCaseLabelImagesOptionalParams,
): Promise<GetCaseLabelImagesResponse> => httpClient.getCaseLabelImages(id, options);

export const useGetLabelImages = (id: string, options?: GetCaseLabelImagesOptionalParams) =>
  useQuery(['getLabelImages', id, options], () => getCaseLabelImages(id, options));

const saveCaseLabelFieldSettings = (
  labelId: string,
  options?: SaveCaseLabelFieldSettingsOptionalParams,
): Promise<void> => httpClient.saveCaseLabelFieldSettings(labelId, options);

export const useSaveFieldSettings = (queryClient: QueryClient) =>
  useMutation(
    ({
      labelId,
      options,
    }: {
      labelId: string;
      options?: SaveCaseLabelFieldSettingsOptionalParams;
    }) => saveCaseLabelFieldSettings(labelId, options),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getLabels');
      },
    },
  );

const updateCaseLabel = (
  id: string,
  options?: UpdateCaseLabelOptionalParams,
): Promise<UpdateCaseLabelResponse> => httpClient.updateCaseLabel(id, options);

export const useUpdateCaseLabel = (queryClient: QueryClient) =>
  useMutation(
    ({ id, options }: { id: string; options: UpdateCaseLabelOptionalParams }) =>
      updateCaseLabel(id, options),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('getLabels');
        queryClient.invalidateQueries(['getLabelById', data?.id]);
        queryClient.invalidateQueries(['getLabelImages', data?.id]);
      },
    },
  );

export const createPalletLabel = async (body: CreatePalletLabelRequest) =>
  HttpClient.createPalletLabel({ body });

export const useCreatePalletLabel = (queryClient: QueryClient) =>
  useMutation(({ body }: { body: CreatePalletLabelRequest }) => createPalletLabel(body), {
    onSuccess: () => {
      queryClient.invalidateQueries('getLabels');
    },
  });

const getPalletLabel = (
  id: string,
  options?: GetPalletLabelOptionalParams,
): Promise<GetPalletLabelResponse> => httpClient.getPalletLabel(id, options);

export const useGetPalletById = (id: string, options?: GetPalletLabelOptionalParams) =>
  useQuery(['getPalletLabel', id, options], () => getPalletLabel(id, options));

export const updatePalletLabel = (
  id: string,
  options?: UpdatePalletLabelOptionalParams,
): Promise<UpdatePalletLabelResponse> => httpClient.updatePalletLabel(id, options);

export const useUpdatePalletLabel = (queryClient: QueryClient) =>
  useMutation(
    ({ id, options }: { id: string; options: UpdatePalletLabelOptionalParams }) =>
      updatePalletLabel(id, options),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('getLabels');
        queryClient.invalidateQueries(['getPalletLabel', data?.id]);
      },
    },
  );
