import { BetaSchemaForm } from '@ant-design/pro-form';
import { EditableFormInstance } from '@ant-design/pro-table';
import { message, RowProps, Segmented, Space, Typography } from 'antd';
import { useModalVisibility } from 'hooks';
import useListLocation from 'hooks/useListLocation';
import useAddEditActions from 'pages/Network/hook/useAddEditActions';
import { SegmentedValue } from 'rc-segmented';
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQueryDigitalLink, useTradePartnerById } from 'services/api';
import useAccount from 'services/api/useAccount';
import { hasUnsavedChanges } from 'utils';
import errorHandler from 'utils/errorHandler';
import { useCompanySelectStore } from '../../components/CompanySelectInput';
import useLocationStore from '../../hook/useLocationStore';
import { AddLocationColumns, DataItem } from './CompanyLocation.fields';
import useDataEntryMethodStore from './DataEntryMethod/useDataEntryStore';
import UpgradeModal from './UpgradeModal';

const rowProps: RowProps = { gutter: [32, 24] };

const AddCompanyLocationForm = () => {
  const dataEntryMethod = useDataEntryMethodStore((state) => state.dataEntryMethod);
  const { isCompanyEmpty, selectedCompany: storedCompanyData, userType } = useCompanySelectStore();
  const setLocationData = useLocationStore((state) => state.setLocationData);
  const { data: selectedCompany } = useTradePartnerById(storedCompanyData?.id || '');
  const editableTableFormRef = useRef<EditableFormInstance<DataItem>>();
  const {
    formRef,
    setAttributes,
    closeModal,
    onNoMultiLoc,
    visible,
    onNoVessel,
    addEditLocationData,
    addEditVesselLocationData,
    setAddEditLocationData,
    setLocationFieldValues,
    updateCompanyData,
    updateLocationData,
    updateVesselLocation,
    createNewLocation,
    createNewVesselLocation,
    bulkAddEdit,
    loading,
    t,
    setVesselLocationFieldValues,
    setAddEditVesselLocationData,
    updateTraceabilityData,
    addTraceabilityData,
    traceSwitch,
    setTraceSwitch,
    onTraceSwitchOff,
    upgradeTraceModal,
    allowInternalLocation,
    allowExternalLocation,
    digitalLinkInvalid,
    queryClient,
    ...restDrawerVisibility
  } = useAddEditActions();
  const tabs = [t('details'), t('default_template_attibutes')];
  const validateDigitalLink = useQueryDigitalLink();
  const [tabType, setTabType] = useState<string | number>(tabs[0]);
  const { data: accountData } = useAccount();
  const vesselEnabled = useMemo(() => accountData?.vesselEnabled, [accountData?.vesselEnabled]);
  const myCompanyName = useMemo(() => accountData?.name || '', [accountData?.name]);
  const { data: dataLocations } = useListLocation(
    selectedCompany?.id
      ? {
          partnerIds: [selectedCompany?.id],
          pageNumber: 1,
          pageSize: 5,
          isVessel: false,
        }
      : undefined,
  );
  const { data: vesselLoc } = useListLocation(
    selectedCompany?.id
      ? {
          partnerIds: [selectedCompany?.id],
          pageNumber: 1,
          pageSize: 5,
          isVessel: true,
        }
      : undefined,
  );

  const locationData = useMemo(() => dataLocations || [], [dataLocations]);
  const vesselLocations = useMemo(() => vesselLoc || [], [vesselLoc]);

  const upgradeModal = useModalVisibility(false);

  const allowAddNewRecord = useMemo(
    () => (selectedCompany?.isOwnerAccount ? allowInternalLocation : allowExternalLocation),
    [allowExternalLocation, allowInternalLocation, selectedCompany?.isOwnerAccount],
  );

  const resetFields = useCallback(() => {
    // reset location data
    formRef?.current?.resetFields([
      'locationName',
      'address',
      'geoCoordinates',
      'email',
      'phoneNumber',
      'gln',
      'vesselName',
      'vesselId',
      'vesselState',
      'imoNumber',
      'vesselAuth',
      'vesselLink',
      'vesselReg',
      'vLocationName',
      'vAddress',
      'vGeoCoordinates',
      'vEmail',
      'vPhoneNumber',
      'vGln',
      'vVesselName',
      'vVesselId',
      'vVesselState',
      'vImoNumber',
      'vVesselAuth',
      'vVesselLink',
      'vVesselReg',
      'locNumberId',
      'extension',
      'vlocNumberId',
      'vExtension',
      'captainsName',
      'tradepartnerNoId',
      'locDuns',
      'vLocDuns',
      'contact',
      'vContact',
      'polygon',
      'vPolygon',
    ]);
    setAddEditLocationData(undefined);
    setAddEditVesselLocationData(undefined);
  }, [formRef, setAddEditLocationData, setAddEditVesselLocationData]);

  useEffect(() => {
    if (selectedCompany) {
      resetFields();
      setTraceSwitch(!!selectedCompany?.digitalLinkUrl);
      const data = {
        pgln: selectedCompany?.pgln,
        duns: selectedCompany?.duns,
        companyId: selectedCompany?.id,
        companyName: selectedCompany?.name || '',
        connectionType: selectedCompany?.tradeRelationship,
        digitalLink: selectedCompany?.digitalLinkUrl,
        apiKey: selectedCompany?.digitalLinkUrl,
        tradepartnerNoId: selectedCompany?.externalIdentifier,
      };
      setAttributes(data);
    } else {
      resetFields();
      setTraceSwitch(false);
      const data = {
        companyId: undefined,
      };
      setAttributes(data);
    }
  }, [resetFields, selectedCompany, setAttributes, setTraceSwitch]);

  useEffect(() => {
    const data = {
      isMultipleLocations: locationData?.length !== 0 ? 'multi_loc' : undefined,
      isMultipleVessels: vesselLocations.length !== 0 ? 'multi_vessel' : undefined,
      isVessel: vesselEnabled && vesselLocations.length ? 'Yes' : 'No',
    };
    setAttributes(data);
  }, [locationData?.length, setAttributes, vesselEnabled, vesselLocations.length]);

  const onCSVSubmit = async (formData: DataItem) => {
    try {
      if (!loading) {
        const rawData = formData?.csvData;
        const values =
          rawData?.map((data) => ({
            ...data,
            isVessel: formData?.isVessel,
            phoneNumber:
              data.number && data.phoneCode ? `${data.phoneCode},${data.number}` : undefined,
          })) || [];
        if (values?.length) {
          const uniqueValues = new Set(values.map((v) => v.locationName));
          if (uniqueValues.size < values.length) {
            message.error(t('csv_upload_process.duplicate_locations'));
            return;
          }
          await editableTableFormRef.current?.validateFields();
          const hide = message.loading(t('csv_upload_process.loading'), 0);
          bulkAddEdit(values).then(() => {
            message.success(t('csv_upload_process.success', { count: values.length }));
            closeModal();
            hide();
          });
        } else {
          throw new Error(t('no_data_to_submit'));
        }
      }
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.log('🚀 ~ CSV upload commission onFormSubmit ~ error', error);

      throw error;
    }
  };

  const onFinishFailed = useCallback(() => {
    message.error(t('validate_fail'));
  }, [t]);

  const onFormSubmit = async (formData: DataItem) => {
    hasUnsavedChanges();
    if (traceSwitch) {
      if (!selectedCompany?.digitalLinkUrl) {
        const res = await validateDigitalLink.mutateAsync({
          params: { url: formData?.digitalLink, apiKey: formData?.apiKey },
        });
        if (res?.result === 'OwnApi' || res?.result === 'InvalidLink') {
          digitalLinkInvalid();
          return;
        }
        await addTraceabilityData(
          selectedCompany?.id || '',
          formData.digitalLink || '',
          formData.apiKey || '',
        );
      } else {
        const traceabilityReq = {
          tradePartnerId: selectedCompany?.id || '',
          digitalLinkUrl: formData.digitalLink || '',
        };
        await updateTraceabilityData(selectedCompany?.id || '', traceabilityReq);
        // await addTraceabilityData(
        //   selectedCompany?.id || '',
        //   formData.digitalLink || '',
        //   formData.apiKey || '',
        // );
      }
    }
    await updateCompanyData(formData, selectedCompany);
    if (
      formData?.vLocationName &&
      String(formData?.isMultipleVessels || '') !== 'multi_vessel' &&
      String(formData?.isVessel || '') !== 'No'
    ) {
      const isVesselEditMode = addEditVesselLocationData?.id !== undefined;
      if (isVesselEditMode) {
        const vesselRes = await updateVesselLocation(
          String(addEditVesselLocationData?.id),
          formData,
        );
        message.success(t('success.update_vessel', { location: vesselRes.name }));
      } else if (allowAddNewRecord) {
        const vesselRes = await createNewVesselLocation(formData, selectedCompany);
        if (vesselRes?.id) {
          message.success(t('success.add_vessel', { company: selectedCompany?.name }));
        }
      } else {
        upgradeModal?.show();
      }
    }
    if (formData?.locationName && String(formData?.isMultipleLocations || '') !== 'multi_loc') {
      const isEditMode = addEditLocationData?.id !== undefined;
      if (isEditMode) {
        const res = await updateLocationData(String(addEditLocationData?.id), formData, false);
        if (res) {
          const props = {
            id: res.id || '',
            name: res.name,
            company: res.tradePartnerName,
            locationAddress: `${res.address?.addressLine1}`,
            lat: Number(res.address?.geoCoordinates?.latitude),
            lng: Number(res.address?.geoCoordinates?.longitude),
            companyId: res.tradePartnerId,
            contact: res?.contact,
          };
          setLocationData({ ...props });
          message.success(t('success.update_loc', { location: res.name }));
          closeModal();
        }
      } else if (allowAddNewRecord) {
        const res = await createNewLocation(formData, selectedCompany, false);
        if (res?.id) {
          message.success(t('success.add_loc', { company: selectedCompany?.name }));
          closeModal();
        }
      } else {
        upgradeModal?.show();
      }
    } else {
      message.success(
        t('success.update_comp', {
          company: formData.companyName ? formData.companyName : selectedCompany?.name,
        }),
      );
      closeModal();
    }
  };

  const onFinish = async (formData: DataItem) => {
    try {
      if (dataEntryMethod === 'manual') {
        await onFormSubmit(formData);
      } else {
        await onCSVSubmit(formData);
      }
    } catch (error: any) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };

  const changeType = useCallback(
    (value: SegmentedValue) => {
      setTabType(value);
      queryClient.resetQueries('locationList');
    },
    [queryClient],
  );

  const Title: ReactElement = (
    <Space>
      <Typography.Title level={4}>{t('add_modal_title')}</Typography.Title>
      {!isCompanyEmpty && dataEntryMethod === 'manual' && (
        <Segmented options={tabs} value={tabType} onChange={changeType} />
      )}
    </Space>
  );

  // const onCompanyChange = useCallback(
  //   async (value: any) => {
  //     const fetchLocation = vesselLoc?.find((item) => item?.tradePartnerId === value, []);
  //     console.log('🚀 ~ fetchLocation:', fetchLocation);
  //     const data =
  //       {
  //        ,
  //       } || {};
  //     setAttributes(data);
  //   },
  //   [setAttributes, vesselEnabled, vesselLoc],
  // );

  return (
    <>
      <UpgradeModal modal={upgradeModal} tkey="your_loc." />
      <UpgradeModal modal={upgradeTraceModal} tkey="trace_switch." />
      <BetaSchemaForm<DataItem>
        layoutType="DrawerForm"
        formRef={formRef}
        columns={AddLocationColumns(
          dataEntryMethod,
          isCompanyEmpty,
          false,
          locationData,
          vesselLocations,
          formRef,
          onNoVessel,
          onNoMultiLoc,
          setLocationFieldValues,
          setVesselLocationFieldValues,
          myCompanyName,
          selectedCompany,
          undefined,
          editableTableFormRef,
          undefined,
          undefined,
          tabType,
          userType,
          vesselEnabled,
          traceSwitch,
          onTraceSwitchOff,
          undefined,
          allowAddNewRecord,
          undefined,
          undefined,
          undefined,
        )}
        grid
        rowProps={rowProps}
        // @ts-ignore
        title={Title}
        visible={visible}
        autoFocusFirstInput
        drawerProps={{
          destroyOnClose: true,
          height: '100%',
          placement: 'top',
          size: 'large',
          ...restDrawerVisibility,
          onClose: () => closeModal(),
        }}
        submitter={{
          searchConfig: {
            submitText: t('add'),
          },
          submitButtonProps: {
            shape: 'round',
            loading,
          },
          render: (_, defaultDoms) => [defaultDoms[1]],
        }}
        submitTimeout={2000}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      />
    </>
  );
};

export default React.memo(AddCompanyLocationForm);
