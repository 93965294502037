import { Graph } from '@antv/g6';
import { EventNodeStub, GetEventDetailsDiagramResponse } from 'services/api/client/src';
import { dateFormat, getUom, isSameUom } from 'utils';

let ipHideTimer: any;
const ERROR_COLOR = '#F5222D';

export const COLLAPSE_ICON = (x: number, y: number, r: number) => [
  ['M', x - r, y],
  ['a', r, r, 0, 1, 0, r * 2, 0],
  ['a', r, r, 0, 1, 0, -r * 2, 0],
  ['M', x - r + 4, y],
  ['L', x - r + 2 * r - 4, y],
];
export const EXPAND_ICON = (x: number, y: number, r: number) => [
  ['M', x - r, y],
  ['a', r, r, 0, 1, 0, r * 2, 0],
  ['a', r, r, 0, 1, 0, -r * 2, 0],
  ['M', x - r + 4, y],
  ['L', x - r + 2 * r - 4, y],
  ['M', x - r + r, y - r + 4],
  ['L', x, y + r - 4],
];

const structureData = (
  rootNode: EventNodeStub,
  lotId?: string,
  productId?: string,
  isShipment?: boolean,
) => {
  const isMultiProd = !!rootNode?.products?.find(
    (item) => item?.productName !== rootNode?.products?.[0]?.productName,
    [],
  );
  function add(accumulator: any, a: any) {
    return accumulator + a;
  }
  let multiProductQuantity = 0;
  if (isShipment) {
    if ((isMultiProd && isSameUom(rootNode?.products)) || isSameUom(rootNode?.products)) {
      multiProductQuantity = rootNode?.products?.map((item) => item?.quantity).reduce(add, 0);
    }
  } else {
    multiProductQuantity = rootNode?.products
      ?.filter((item) => item?.productId === productId, [])
      ?.map((item) => item?.quantity)
      .reduce(add, 0);
  }
  const isContainer =
    !!(rootNode?.container || rootNode?.containers?.[0])?.identifier ||
    (rootNode?.container?.products?.length || 0) > 0;
  const blockchain = 'Algorand';
  const product = lotId
    ? rootNode?.outputProducts?.filter((p) => p?.instanceId === lotId)?.[0] ||
      rootNode?.outputProducts?.[0] ||
      rootNode?.products?.[0]
    : rootNode?.outputProducts?.[0] || rootNode?.products?.[0];
  const container = rootNode?.container || rootNode?.containers?.[0];
  const primaryId = product?.lotSerial || container?.identifier || '';
  let computedQuantity = 0;
  if (
    (isShipment && isMultiProd && isSameUom(rootNode?.products)) ||
    (isShipment && isSameUom(rootNode?.products)) ||
    (!isShipment && isMultiProd)
  ) {
    computedQuantity = multiProductQuantity || 0;
  } else if (!isShipment && !isMultiProd) {
    computedQuantity =
      rootNode?.products?.filter((p) => p?.instanceId === lotId)?.[0]?.quantity ||
      rootNode?.products?.[0]?.quantity ||
      product?.quantity ||
      0;
  } else {
    computedQuantity = rootNode?.products?.[0]?.quantity || product?.quantity || 0;
  }
  const quantity = computedQuantity || product?.quantity || 0;
  const productName = isMultiProd
    ? 'Multiple'
    : product?.productName || rootNode?.productName || rootNode?.name || '';
  return {
    id: rootNode?.id,
    eventType: rootNode?.eventType || '',
    name: productName,
    primaryId,
    quantity,
    unitOfMeasure:
      getUom({
        product,
      }) || 'LBS',
    blockchain,
    eventTime: dateFormat(String(rootNode?.eventTime)),
    // tbd - get from event
    createdBy: rootNode?.location,
    location: rootNode?.location,
    colourIndex: 'colour_4',
    isContainer,
    eventData: rootNode,
    children: [],
    hideQuantity: isShipment && isMultiProd && !isSameUom(rootNode?.products),
  };
};

export const formatData = (
  data: GetEventDetailsDiagramResponse,
  lotId?: string,
  productId?: string,
  isShipment?: boolean,
) => {
  /* constructing  tree structure from the data received from the API with first node as root */
  const nodes = data?.nodes;
  const edges = data?.edges?.map((edge, i) => ({
    id: `edge-${i}`,
    ...edge,
  }));

  const tree = {
    nodes: nodes?.map((p) => structureData(p, lotId, productId, isShipment)),
    edges,
  };

  return tree;
};

/* Some methods shared by streamlined nodes and complex nodes */
export const getNodeMethods = (mainGraph: Graph) => {
  const nodeBasicMethod = {
    createNodeBox: (group: any, config: any, width: any, height: any, isRoot: any) => {
      /* Outermost large rectangle */
      const container = group.addShape('rect', {
        attrs: {
          x: 0,
          y: 0,
          width,
          height,
        },
        name: 'container-rect-shape',
      });
      if (!isRoot) {
        /* Little dot on the left */
        group.addShape('circle', {
          attrs: {
            x: 3,
            y: height / 2,
            r: 6,
            fill: config.basicColor,
          },
          name: 'left-dot-shape',
        });
      }
      /* rectangle */
      group.addShape('rect', {
        attrs: {
          x: 3,
          y: 0,
          width: width - 19,
          height,
          fill: config.bgColor,
          stroke: config.borderColor,
          radius: 2,
        },
        name: 'rect-shape',
      });
      /* Thick line on the left */
      group.addShape('rect', {
        attrs: {
          x: 3,
          y: 0,
          width: 3,
          height,
          fill: config.basicColor,
          radius: 1.5,
        },
        name: 'left-border-shape',
      });
      return container;
    },
    /* Marker on spanning tree */
    createNodeMarker: (group: any, collapsed: any, x: any, y: any) => {
      // group.addShape('circle', {
      //   attrs: {
      //     x,
      //     y,
      //     r: 13,
      //     fill: 'rgba(47, 84, 235, 0.05)',
      //     opacity: 0,
      //     zIndex: -2,
      //   },
      //   name: 'collapse-icon-bg',
      // });
      group.addShape('marker', {
        attrs: {
          x,
          y,
          r: 7,
          symbol: collapsed ? EXPAND_ICON : COLLAPSE_ICON,
          stroke: 'rgba(0,0,0,0.25)',
          fill: 'rgba(0,0,0,0)',
          lineWidth: 1,
          cursor: 'pointer',
        },
        name: 'collapse-icon',
      });
    },
    afterDraw: (cfg: any, group: any) => {
      /* Show marker background color of operation marker */
      const icon = group.find((element: any) => element.get('name') === 'collapse-icon');
      if (icon) {
        const bg = group.find((element: any) => element.get('name') === 'collapse-icon-bg');
        icon.on('mouseenter', () => {
          bg.attr('opacity', 1);
          mainGraph.get('canvas').draw();
        });
        icon.on('mouseleave', () => {
          bg.attr('opacity', 0);
          mainGraph.get('canvas').draw();
        });
      }
      /* ip display */
      const ipBox = group.find((element: any) => element.get('name') === 'ip-box');
      if (ipBox) {
        /* Several elements copied by ip */
        const ipLine = group.find((element: any) => element.get('name') === 'ip-cp-line');
        const ipBG = group.find((element: any) => element.get('name') === 'ip-cp-bg');
        const ipIcon = group.find((element: any) => element.get('name') === 'ip-cp-icon');
        const ipCPBox = group.find((element: any) => element.get('name') === 'ip-cp-box');

        const onMouseEnter = function onMouseEnter() {
          if (ipHideTimer) {
            clearTimeout(ipHideTimer);
          }
          ipLine.attr('opacity', 1);
          ipBG.attr('opacity', 1);
          ipIcon.attr('opacity', 1);
          mainGraph.get('canvas').draw();
        };
        const onMouseLeave = function onMouseLeave() {
          ipHideTimer = setTimeout(() => {
            ipLine.attr('opacity', 0);
            ipBG.attr('opacity', 0);
            ipIcon.attr('opacity', 0);
            mainGraph.get('canvas').draw();
          }, 100);
        };
        ipBox.on('mouseenter', () => {
          onMouseEnter();
        });
        ipBox.on('mouseleave', () => {
          onMouseLeave();
        });
        ipCPBox.on('mouseenter', () => {
          onMouseEnter();
        });
        ipCPBox.on('mouseleave', () => {
          onMouseLeave();
        });
        ipCPBox.on('click', () => {});
      }
    },
    setState: function setState(name: any, value: any, item: any) {
      const hasOpacityClass = [
        'ip-cp-line',
        'ip-cp-bg',
        'ip-cp-icon',
        'ip-cp-box',
        'ip-box',
        'collapse-icon-bg',
      ];
      const group = item.getContainer();
      const childrens = group.get('children');
      mainGraph.setAutoPaint(false);
      if (name === 'emptiness') {
        if (value) {
          childrens.forEach((shape: any) => {
            if (hasOpacityClass.indexOf(shape.get('name')) > -1) {
              return;
            }
            shape.attr('opacity', 0.4);
          });
        } else {
          childrens.forEach((shape: any) => {
            if (hasOpacityClass.indexOf(shape.get('name')) > -1) {
              return;
            }
            shape.attr('opacity', 1);
          });
        }
      }
      mainGraph.setAutoPaint(true);
    },
  };
  return nodeBasicMethod;
};

export const getNodeConfig = function getNodeConfig(node: any) {
  if (node.nodeError) {
    return {
      basicColor: ERROR_COLOR,
      fontColor: '#FFF',
      borderColor: ERROR_COLOR,
      bgColor: '#E66A6C',
    };
  }
  let config = {
    basicColor: '#8C8C8C',
    fontColor: '#8C8C8C',
    borderColor: '#8C8C8C',
    bgColor: '#FAFAFA',
  };
  switch (node.colourIndex) {
    case 'root':
    case 'colour_0': {
      config = {
        basicColor: '#722ED1',
        fontColor: '#722ED1',
        borderColor: '#722ED1',
        bgColor: '#F6EDFC',
      };
      break;
    }
    case 'colour_1':
      config = {
        basicColor: '#2F54EB',
        fontColor: '#2F54EB',
        borderColor: '#2F54EB',
        bgColor: '#F3F6FD',
      };
      break;
    case 'colour_2':
      config = {
        basicColor: '#52C41A',
        fontColor: '#52C41A',
        borderColor: '#52C41A',
        bgColor: '#F4FCEB',
      };
      break;
    case 'colour_3':
      config = {
        basicColor: '#FA8C16',
        fontColor: '#FA8C16',
        borderColor: '#FA8C16',
        bgColor: '#FCF4E3',
      };
      break;
    case 'colour_4':
      config = {
        basicColor: '#3F51B5',
        fontColor: '#3F51B5',
        borderColor: '#3F51B5',
        bgColor: '#E6EBF5',
      };
      break;
    default:
      break;
  }
  return config;
};

export const strLen = (str: string) => {
  let len = 0;
  if (str) {
    for (let i = 0; i < str.length; i += 1) {
      if (str.charCodeAt(i) > 0 && str.charCodeAt(i) < 128) {
        len += 1;
      } else {
        len += 2;
      }
    }
  }
  return len;
};

export const fittingString = (str: string, maxWidth: number, fontSize: number) => {
  const fontWidth = fontSize * 1.3; // Font size + margin
  // eslint-disable-next-line no-param-reassign
  maxWidth *= 2; // Need to adjust to your own project
  const width = strLen(str) * fontWidth;
  const ellipsis = '…';
  if (width > maxWidth) {
    const actualLen = Math.floor((maxWidth - 10) / fontWidth);
    const result = str.substring(0, actualLen) + ellipsis;
    return result;
  }
  return str;
};
