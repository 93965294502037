import type { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetApplicationIdentifiers } from 'services/api/useLabels';
import GSelect from './GSelect';
import { AppIndentifierSelectProps, FetchDataFn } from './typings';

const AppIdentifierSelect = <
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>({
  disabledItem,
  initParams,
  filterItem,
  ...props
}: AppIndentifierSelectProps<ValueType, OptionType>) => {
  const { data, isLoading } = useGetApplicationIdentifiers(initParams);
  const { t } = useTranslation('pages', { keyPrefix: 'select' });

  const filterOption = useCallback(
    (option?: DefaultOptionType) => {
      if (!filterItem) {
        return true;
      }
      return filterItem?.(option?.itemProps as OptionType['itemProps']);
    },
    [filterItem],
  );

  /* we create here the list with the shape the select is expecting for */
  const options: Array<DefaultOptionType> = useMemo(
    () =>
      data
        ?.map((lot) => ({
          label: `${lot?.label} (AI ${lot.applicationIdentifier})`,
          value: lot?.applicationIdentifier,
          itemProps: lot,
          /* temporary solution to disable items */
          disabled: disabledItem?.(lot) || false,
        }))
        ?.filter(filterOption) || [],
    [data, disabledItem, filterOption],
  );

  const fetchData: FetchDataFn<ValueType> = useCallback(() => {}, []);

  return (
    <GSelect<ValueType, OptionType>
      placeholder={t?.('select_app_identifier')} // "Select Application Identifier"
      {...props}
      loading={isLoading}
      options={options as OptionType[]}
      fetchData={fetchData}
      allowFilterOption
      optionFilterProp="label"
    />
  );
};

export default AppIdentifierSelect;
