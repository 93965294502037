import { ActionType, EditableFormInstance, ProColumns } from '@ant-design/pro-table';
import { RecordKey } from '@ant-design/pro-utils/es/useEditableArray';
import { Button, message, Modal, Space, TablePaginationConfig, Typography } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import { ActionButtons, GTable, ProductSelect } from 'components';
import { TFunction } from 'i18next';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { useGetShipmentProductContainerInstancesById } from 'services/api';
import {
  ContainerProductInstanceResponse,
  GetContainerInventoryResponse,
  GetPaginatedOtherSystemShipmentsOptionalParams,
  ProductInstanceResponse,
  ProductResponse,
} from 'services/api/client/src';
import { errorHandler, getProductsT, getUom, hiddencol, isSameUom } from 'utils';
import styles from '../index.module.less';
import { AllReceiveProductsSSCCProps } from './typings';

export type ReceiveProductsDataType = {
  [x: string]: any;
  id?: string;
  instanceId?: string;
  parentProductId?: string;
  productName?: string;
  primaryId?: string;
  lotSerial?: string;
  quantity?: number;
  instanceQuantity?: number;
  unitOfMeasure?: string;
  currentInventory?: number;
  currentInventoryTotal?: number;
  productUrn?: string;
  isSerial?: boolean;
  isSSCC?: boolean;
  productIdentifierType?: string;
  parentContainerId?: string;
  shipmentType?: string;
  originalInstance?: ProductInstanceResponse;
};

export type AllReceiveTableColumnsType = {
  onEditRow: (actionPayload?: ReceiveProductsDataType) => void;
  lotChangeModal?: (value: string, rowKey: string, tblForm: FormInstance<any>) => void;
  quantityChangeModal?: (value: string, rowKey: string, tblForm: FormInstance<any>) => void;
  t?: TFunction<'pages', 'events.recieve.form_fields'>;
};

// Receive Products Table Columns
export const columns = ({
  onEditRow,
  lotChangeModal,
  quantityChangeModal,
  t,
}: AllReceiveTableColumnsType): Array<ProColumns<ReceiveProductsDataType>> => [
  {
    title: t?.('product'),
    dataIndex: 'parentProductId',
    valueType: 'select',
    hideInForm: true,
    fieldProps: {
      size: 'small',
      disabled: true,
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: 'Required',
        },
      ],
      hasFeedback: false,
    },
    renderFormItem: (_, { record, recordKey }, form) => {
      const isInternal = record?.shipmentType === 'Internal';
      const { setFieldsValue } = form;
      return (
        <ProductSelect
          showExternalIdentifier
          isInsideTable
          allowLotSerial={record?.productIdentifierType === undefined}
          isSerial={record?.productIdentifierType === 'Serial'}
          size="small"
          disabled={isInternal}
          filterItem={(item) => item?.id !== record?.originalInstance?.product?.id}
          isAddProduct
          prependOption={{
            label: (
              <div className={styles.prependoption}>
                {record?.originalInstance?.product?.externalIdentifier ? (
                  <Typography.Text>{`${
                    record?.originalInstance?.product?.externalIdentifier || ''
                  } `}</Typography.Text>
                ) : (
                  ''
                )}
                <Typography.Text type="secondary">
                  {record?.originalInstance?.product?.name}
                </Typography.Text>
              </div>
            ),
            value: record?.originalInstance?.product?.id,
            itemProps: {
              ...record?.originalInstance?.product,
            },
          }}
          onChange={(value, option) => {
            // @ts-ignore
            const item: ProductResponse = option.itemProps;
            setFieldsValue({
              [String(recordKey)]: {
                parentProductId: item?.id,
                productName: item?.name || '',
                isAssignedProduct: true,
                // resets values of row items being edited
                currentInventory: item?.currentInventory,
                currentInventoryTotal: item?.currentInventoryTotal,
                instanceQuantity: item?.currentInventoryTotal,
                unitOfMeasure: item?.simpleUnitOfMeasurement || '',
                productIdentifierType: item?.productIdentifierType,
                prodExtIdentifier: item?.externalIdentifier,
              },
            });
          }}
        />
      );
    },
    render: (text, record) => (
      <div>
        {record.prodExtIdentifier ? (
          <Typography.Text>{`${record?.prodExtIdentifier} `}</Typography.Text>
        ) : (
          ''
        )}
        <Typography.Text type="secondary">{record?.productName}</Typography.Text>
      </div>
    ),
  },
  {
    title: t?.('quantity'),
    dataIndex: 'quantity',
    valueType: 'digit',
    fieldProps: (form, { rowKey }) => {
      const unitOfMeasure = form?.getFieldValue?.(String(rowKey))?.unitOfMeasure || 'LBS';
      const productIdentifierType =
        form?.getFieldValue?.(String(rowKey))?.productIdentifierType || 'Lot';
      return {
        addonAfter:
          productIdentifierType === 'Serial'
            ? t?.('items')
            : `${unitOfMeasure?.toLocaleUpperCase()}`,
        size: 'small',
        stringMode: true,
        type: 'number',
        style: {
          width: '100%',
        },
        onBlur: (e: any) => quantityChangeModal?.(e.target.value, String(rowKey), form),
        disabled: productIdentifierType === 'Serial',
        placeholder: '0',
      };
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: 'Required',
        },
      ],
      hasFeedback: false,
    },
    render: (text, record) => {
      if (record?.hideQuantity && record?.productIdentifierType !== 'Serial') {
        return '-';
      }
      return record?.productIdentifierType === 'Serial'
        ? `${record?.quantity} ${pluralize('item', record?.quantity)}`
        : `${record?.quantity} ${record?.unitOfMeasure?.toLocaleUpperCase() || ''}`;
    },
  },
  {
    title: t?.('primaryid'),
    dataIndex: 'primaryId',
    valueType: 'text',
    fieldProps: (form, { rowKey }) => {
      const productIdentifierType = form?.getFieldValue?.(String(rowKey))?.productIdentifierType;
      return {
        addonBefore: `${productIdentifierType}:` || 'Lot: ',
        size: 'small',
        onBlur: (e: any) => lotChangeModal?.(e.target.value, String(rowKey), form),
        placeholder: productIdentifierType,
      };
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: 'Required',
        },
      ],
      hasFeedback: false,
    },
    render: (_t, record) => `${record?.productIdentifierType}: ${record?.primaryId}`,
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    hideInSetting: true,
    align: 'center',
    width: 160,
    render: (_, record) => (
      <ActionButtons
        record={record}
        onEdit={onEditRow}
        showDelete={false}
        showEdit={!record?.isSSCC}
      />
    ),
  },
  {
    dataIndex: 'eventDate',
    ...hiddencol,
  },
  {
    dataIndex: 'instanceQuantity',
    ...hiddencol,
  },
  {
    dataIndex: 'unitOfMeasure',
    ...hiddencol,
  },
  {
    dataIndex: 'productIdentifierType',
    ...hiddencol,
  },
  {
    dataIndex: 'prodExtIdentifier',
    ...hiddencol,
  },
];

const AllReceiveProducts: React.FC<AllReceiveProductsSSCCProps> = ({
  form,
  eventProducts,
  isLoading,
  containerId,
  parentProducts,
  parentRowId,
  showHeader,
}) => {
  const actionRef = useRef<ActionType>();
  const [queryParams] = useSearchParams();
  const { t } = useTranslation('pages', { keyPrefix: 'events.recieve.form_fields' });
  const [fetched, setFetched] = useState(false);
  const containerEditableFormRef = useRef<EditableFormInstance<any>>();

  const shipmentId = queryParams?.get('shipmentId') || '';
  const [containerInstances, setContainerInstances] = useState<GetContainerInventoryResponse>({});
  const [params, setParams] = useState<GetPaginatedOtherSystemShipmentsOptionalParams>({
    pageNumber: 1,
    pageSize: 20,
    sortBy: '-eventDate',
  });
  const queryClient = useQueryClient();
  const shipmentContainerItemsByContainerId =
    useGetShipmentProductContainerInstancesById(queryClient);

  useEffect(() => {
    let isMounted = true; // To prevent state updates on unmounted components

    const getContainerInventoryResponse = async (
      id: string,
      apiParams: GetPaginatedOtherSystemShipmentsOptionalParams,
    ) => {
      try {
        const productInventoryData = await shipmentContainerItemsByContainerId.mutateAsync({
          shipmentId: shipmentId || '',
          containerId: id || '',
          params: apiParams,
        });

        if (isMounted) {
          setContainerInstances((prevInstances) => {
            // Prevent duplicate entries
            const existingIds = new Set(prevInstances?.results?.map((item) => item.id) || []);
            const newResults = productInventoryData?.results
              ?.filter((item) => !existingIds.has(item.id))
              ?.map((i) => ({
                id: i?.id,
                parentContainerId: containerId,
                // initial value
                productId: i?.aliasProductId || i?.productId,
                parentProductId: i?.aliasProductId || i?.productId,
                productName: i?.aliasProductName || i?.productName,
                instanceId: i?.id || '',
                primaryId: i?.lotSerial || '',
                lotSerial: i?.lotSerial || '',
                quantity: Number(i?.quantity || 0),
                instanceQuantity: Number(i?.quantity || 0),
                unitOfMeasure: getUom({ product: i }) || 'LBS',
                unitQuantity: i?.unitQuantity,
                unitDescriptor: i?.unitDescriptor,
                productIdentifierType: i?.productIdentifierType || 'Lot',
                shipmentType: queryParams?.get('shipmentType') || '',
                prodExtIdentifier: i?.productExternalIdentifier,
                originalInstance: {
                  ...i,
                  product: {
                    id: i?.productId,
                    name: i?.productName,
                    unitQuantity: i?.unitQuantity,
                    unitDescriptor: i?.unitDescriptor,
                    simpleUnitOfMeasurement: getUom({ product: i }) || 'LBS' || '',
                    productIdentifierType: i?.productIdentifierType,
                    externalIdentifier: i?.productExternalIdentifier,
                  },
                },
              }));
            const updatedParentRows = parentProducts?.map(
              (p) =>
                p?.id === parentRowId
                  ? {
                      ...p,
                      containerItems: newResults,
                    }
                  : p,
              [],
            );
            form?.setFieldsValue({
              inboundShipments: updatedParentRows,
            });
            return {
              ...prevInstances,
              results: [...(prevInstances?.results || []), ...(newResults || [])],
              totalItems: productInventoryData?.totalItems || prevInstances?.totalItems,
            };
          });
        }
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    };

    if (containerId) {
      getContainerInventoryResponse(containerId, params);
    }

    return () => {
      isMounted = false; // Cleanup flag
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerId, params]);
  const onTableChange = (pagination: TablePaginationConfig, sorter: any) => {
    if (pagination?.current === params?.pageNumber && pagination?.pageSize === params?.pageSize) {
      setParams({
        ...params,
        sortBy: sorter?.field
          ? `${sorter.order === 'ascend' ? '+' : '-'}${sorter.field}`
          : undefined,
        pageNumber: 1,
      });
    } else {
      setParams({
        ...params,
        pageNumber: pagination?.current,
        pageSize: pagination?.pageSize,
        sortBy: sorter?.field
          ? `${sorter.order === 'ascend' ? '+' : '-'}${sorter.field}`
          : undefined,
      });
    }
  };
  const loadMoreItems = () => {
    if ((containerInstances?.results?.length || 0) < (containerInstances?.totalItems || 20)) {
      setParams((prevParams) => ({
        ...prevParams,
        pageNumber: 1,
        pageSize: containerInstances?.totalItems || 20,
      }));
    }
  };

  // find total quantity in all lots of sscc
  const findTotalSSCCQuantity = useCallback((p: ContainerProductInstanceResponse[]) => {
    let total = 0;
    for (let i = 0; i <= Number(p?.length) - 1 || 0; i += 1) {
      total = total + Number(p?.[i]?.quantity) || 0;
    }
    return total;
  }, []);
  // modified list as per our requirement
  const modifiedProducts: ReceiveProductsDataType[] | undefined = useMemo(
    () =>
      (eventProducts?.productInstances?.length || 0) > 0
        ? eventProducts?.productInstances?.map((i) => ({
            id: i?.id,
            instanceId: i?.id,
            quantity: i?.quantity,
            instanceQuantity: i?.quantity,
            // initial product id -> either and id in case of internal otherwise '' -> to keep track of original product id
            productId: i?.product?.id,
            parentProductId: i?.product?.id,
            primaryId: i?.lotSerial,
            lotSerial: i?.lotSerial,
            productName: i?.product?.name,
            unitOfMeasure: getUom({ product: i?.product }) || 'LBS',
            isSerial: i?.product?.productIdentifierType === 'Serial',
            currentInventory: i?.product?.currentInventory,
            currentInventoryTotal: i?.product?.currentInventoryTotal,
            isSSCC: false,
            productUrn: i?.product?.urn,
            productIdentifierType: i?.product?.productIdentifierType,
            shipmentType: queryParams?.get('shipmentType') || '',
            instanceUrn: i?.urn,
            originalInstance: i,
          }))
        : eventProducts?.containers?.map((c) => {
            const productInstances: any[] = [];
            const isMultipleProd = !!productInstances?.find(
              (j) => j?.productId !== productInstances?.[0]?.productId,
              [],
            );
            const instances: any = productInstances;
            const isSameUOM = isSameUom(instances);
            return {
              id: c?.id,
              parentProductId: 'SSCC',
              primaryId: c?.containerIdentifier,
              quantity: c?.totalQuantity || findTotalSSCCQuantity(productInstances || []),
              instanceQuantity: findTotalSSCCQuantity(productInstances || []),
              isSSCC: true,
              productName: isMultipleProd
                ? t?.('ssccmultiprod')
                : `SSCC: ${productInstances?.[0]?.productName}`,
              productIdentifierType: 'SSCC',
              unitOfMeasure:
                isMultipleProd && !isSameUOM
                  ? ''
                  : getUom({ product: productInstances?.[0] }) || 'LBS',
              hideQuantity: isMultipleProd && !isSameUOM,
              shipmentType: queryParams?.get('shipmentType') || '',
              instanceUrn: c?.urn,
              ...(productInstances?.length && {
                containerItems: [
                  ...(productInstances?.map((i) => ({
                    id: i?.id,
                    parentContainerId: c?.id,
                    // initial value
                    productId: i?.aliasProductId || i?.productId,
                    parentProductId: i?.aliasProductId || i?.productId,
                    productName: i?.aliasProductName || i?.productName,
                    instanceId: i?.id || '',
                    primaryId: i?.lotSerial || '',
                    lotSerial: i?.lotSerial || '',
                    quantity: Number(i?.quantity || 0),
                    instanceQuantity: Number(i?.quantity || 0),
                    unitOfMeasure: getUom({ product: i }) || 'LBS',
                    unitQuantity: i?.unitQuantity,
                    unitDescriptor: i?.unitDescriptor,
                    productIdentifierType: i?.productIdentifierType || 'Lot',
                    shipmentType: queryParams?.get('shipmentType') || '',
                    originalInstance: {
                      ...i,
                      product: {
                        id: i?.productId,
                        name: i?.productName,
                        unitQuantity: i?.unitQuantity,
                        unitDescriptor: i?.unitDescriptor,
                        simpleUnitOfMeasurement: getUom({ product: i }) || 'LBS' || '',
                        productIdentifierType: i?.productIdentifierType,
                      },
                    },
                  })) || []),
                ],
              }),
            };
          }),
    [
      eventProducts?.containers,
      eventProducts?.productInstances,
      findTotalSSCCQuantity,
      queryParams,
      t,
    ],
  );

  useEffect(() => {
    if (containerInstances?.results?.length && !fetched && eventProducts) {
      const updatedParentRows = parentProducts?.map(
        (p) =>
          p?.id === parentRowId
            ? {
                ...p,
                containerItems: containerInstances?.results,
              }
            : p,
        [],
      );
      form?.setFieldsValue({
        inboundShipments: updatedParentRows,
      });
      setFetched(true);
    }
  }, [containerInstances?.results, fetched, eventProducts, parentProducts, form, parentRowId]);

  // Handles on save button click
  const onSaveRow = async (_rowKey: RecordKey, data: ReceiveProductsDataType) => {
    actionRef.current?.cancelEditable(data?.id || 0);
    const existingProduct: any = parentProducts?.find((product) => product.id === data?.id);
    const existingSsccParent = parentProducts?.find(
      (product) => product.id === data?.parentContainerId,
    );

    if (existingProduct) {
      existingProduct.productName = data?.productName || '';
      existingProduct.productId = data?.productId || '';
      existingProduct.primaryId = data?.primaryId || '';
      existingProduct.parentProductId = data?.parentProductId?.trim() || undefined;
      existingProduct.quantity = data?.quantity;
      existingProduct.currentInventory = data?.currentInventory;
      existingProduct.instanceQuantity = data?.currentInventoryTotal;
      existingProduct.unitOfMeasure = data?.unitOfMeasure || '';
      existingProduct.prodExtIdentifier = data?.prodExtIdentifier;

      // update product
      const updatedParentRows = parentProducts?.map((p: any) => {
        if (p.id === existingProduct.id) {
          return existingProduct;
        }
        if (p?.productId === existingProduct?.productId) {
          return {
            ...p,
            parentProductId: data?.parentProductId,
            productName: data?.productName,
            prodExtIdentifier: data?.prodExtIdentifier,
          };
        }
        return p;
      }, []);
      form?.setFieldsValue({
        inboundShipments: updatedParentRows,
      });
    } else if (existingSsccParent) {
      const changedChildren: any = existingSsccParent?.containerItems?.find(
        (c: any) => c?.id === data?.id,
      );
      const updatedChildren = {
        ...changedChildren,
        id: changedChildren?.id,
        parentContainerId: existingSsccParent?.id,
        parentProductId: data?.parentProductId,
        productName: data?.productName,
        primaryId: data?.primaryId || '',
        quantity: Number(data?.quantity || 0),
        instanceQuantity: Number(data?.currentInventoryTotal || 0),
        prodExtIdentifier: data?.prodExtIdentifier,
      };
      const updatedParentRows = parentProducts?.map((p) => {
        if (p.id === existingSsccParent.id) {
          const containerItems = existingSsccParent?.containerItems?.map((child: any) => {
            if (child?.id !== changedChildren?.id) {
              if (child?.productId === changedChildren?.productId) {
                return {
                  ...child,
                  parentProductId: data?.parentProductId,
                  productName: data?.productName,
                  prodExtIdentifier: data?.prodExtIdentifier,
                };
              }
              return child;
            }
            return updatedChildren;
          }, []);

          return {
            ...existingSsccParent,
            quantity: findTotalSSCCQuantity(containerItems || []),
            productName: containerItems?.find(
              (i: any) => i?.parentProductId !== containerItems?.[0]?.parentProductId,
              [],
            )
              ? t?.('ssccmultiprod')
              : `SSCC: ${containerItems?.[0]?.productName}`,
            containerItems,
          };
        }
        const containerItems = p?.containerItems?.map((child: any) => {
          if (child?.productId === changedChildren?.productId) {
            return {
              ...child,
              parentProductId: data?.parentProductId,
              productName: data?.productName,
              prodExtIdentifier: data?.prodExtIdentifier,
            };
          }
          return child;
        }, []);
        return {
          ...p,
          containerItems,
          productName: containerItems?.find(
            (i: any) => i?.parentProductId !== containerItems?.[0]?.parentProductId,
            [],
          )
            ? t?.('ssccmultiprod')
            : `SSCC: ${containerItems?.[0]?.productName}`,
        };
      }, []);
      form?.setFieldsValue({
        inboundShipments: updatedParentRows,
      });
      const updatedContainerParentRows: any = updatedParentRows?.find(
        (i: any) => containerId && i?.id === containerId,
      );
      setContainerInstances({
        ...containerInstances,
        results: updatedContainerParentRows?.containerItems,
      });
    }
  };

  // handles edit click on row
  const onEditRow = (actionPayload?: ReceiveProductsDataType) => {
    actionRef.current?.startEditable(actionPayload?.id || 0);
  };

  const onCancelProduct = async (rowKey: RecordKey, data: ReceiveProductsDataType) => {
    actionRef.current?.cancelEditable(data?.id || 0);
  };

  // lot change modal
  const lotChangeModal = (value: string, rowKey: string, tblForm: FormInstance<any>) => {
    const product = modifiedProducts?.find((item) => item?.instanceId === rowKey);
    let ssccChildren: ReceiveProductsDataType = {};
    for (let i = 0; i <= (modifiedProducts?.length || 1) - 1; i += 1) {
      ssccChildren = modifiedProducts?.[i]?.containerItems?.find(
        (item: any) => item?.id === rowKey,
      );
    }
    const isSerial = product?.productIdentifierType === 'Serial';
    const lotSerial = product?.primaryId || '';
    const ssccChildLotSerial = ssccChildren?.primaryId || '';
    if ((product ? value !== lotSerial : value !== ssccChildLotSerial) && value) {
      Modal.warning({
        title: !isSerial
          ? t?.('lot_change_modal_title', {
              identifier: getProductsT(product?.productIdentifierType),
            })
          : t?.('modaltitle'),
        content: t('modaldesc', {
          idType: isSerial ? t('serialid') : t('lotid'),
          lotSerial: lotSerial || ssccChildLotSerial,
          value,
        }),
        cancelText: t('lot_change_cancel_btn'),
        okText: t('lot_change_continue_btn'),
        cancelButtonProps: {
          type: 'primary',
          shape: 'round',
          ghost: true,
        },
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        centered: true,
        onCancel: () => {
          tblForm?.setFieldsValue({
            [String(rowKey)]: {
              primaryId: lotSerial || ssccChildLotSerial || '',
            },
          });
        },
        okCancel: true,
      });
    }
  };

  // quantity change modal
  const quantityChangeModal = (value: string, rowKey: string, tblForm: FormInstance<any>) => {
    const product = modifiedProducts?.find((item) => item?.id === rowKey);
    let ssccChildren: ReceiveProductsDataType = {};
    for (let i = 0; i <= (modifiedProducts?.length || 1) - 1; i += 1) {
      ssccChildren = modifiedProducts?.[i]?.containerItems?.find(
        (item: any) => item?.id === rowKey,
      );
    }
    const quantity = product?.quantity || 0;
    const ssccChildQuantity = ssccChildren?.quantity || '';
    const isQuantityChangeModalTriggered = tblForm?.getFieldValue(
      String(rowKey),
    )?.isQuantityChangeModalTriggered;

    if (
      (product ? Number(value) !== quantity : value !== ssccChildQuantity) &&
      value &&
      !isQuantityChangeModalTriggered
    ) {
      Modal.info({
        title: t?.('title'),
        content: t?.('desc', {
          quantity: quantity || ssccChildQuantity,
          value,
        }),
        cancelText: t('quantity_change_cancel_btn'),
        okText: t('quantity_change_continue_btn'),
        cancelButtonProps: {
          type: 'primary',
          shape: 'round',
          ghost: true,
        },
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        centered: true,
        onCancel: () => {
          tblForm?.setFieldsValue({
            [String(rowKey)]: {
              quantity: quantity || ssccChildQuantity || 0,
              isQuantityChangeModalTriggered: true,
            },
          });
        },
        onOk: () => {
          tblForm?.setFieldsValue({
            [String(rowKey)]: {
              isQuantityChangeModalTriggered: true,
            },
          });
        },
        okCancel: true,
      });
    }
  };
  return (
    <Space direction="vertical">
      <>
        <Space className={styles.marginleft}>
          <Typography.Text type="secondary">
            {containerInstances?.totalItems || 0} {t?.('itemstotal')}
          </Typography.Text>
        </Space>
        <GTable<ReceiveProductsDataType>
          actionRef={actionRef}
          key="receiveProductsTable"
          rowKey="id"
          columns={columns({ onEditRow, lotChangeModal, quantityChangeModal, t })}
          value={containerInstances?.results}
          loading={isLoading}
          showHeader={showHeader}
          editable={{
            onSave: (rowKey, actionPayload) => onSaveRow(rowKey, actionPayload),
            onCancel: async (rowKey, oldData, data) => onCancelProduct(rowKey, data),
          }}
          options={{
            setting: false,
            reload: false,
          }}
          pagination={false}
          onTableChange={onTableChange}
          editableFormRef={containerEditableFormRef}
          actionsRenderOptions={{
            save: true,
            cancel: true,
          }}
          scroll={{ y: '30vh', x: '100%' }}
        />
      </>
      {/* Load more items button */}
      {(containerInstances?.results?.length || 0) > 0 &&
        (containerInstances?.results?.length || 0) < (containerInstances?.totalItems || 20) && (
          <Space className={styles.marginleft01}>
            <Button type="link" onClick={loadMoreItems}>
              {t?.('loaditems')}
            </Button>
          </Space>
        )}
    </Space>
  );
};

export default AllReceiveProducts;
