import { ProFormColumnsType } from '@ant-design/pro-form';
import { FormFieldType } from '@ant-design/pro-form/lib/components/SchemaForm';
import { ProColumns } from '@ant-design/pro-table';
import { DefaultOptionType } from 'antd/lib/select';
import moment from 'moment';
import { DocumentItem } from 'pages/Documents';
import {
  AggregateDataItem,
  CertificationInfo,
  CommissionDataItem,
  DecommissionDataItem,
  DisaggregateDataItem,
  ReceiveDataItem,
  ReceiveProductItem,
  ShipDataItem,
  TransformDataItem,
} from 'pages/Events';
import { ProductInventoryItem } from 'pages/Products';
import { Shipment } from 'pages/Shipments/typings';
import { AdvanceSetting, CustomAttribute, TemplateItem } from 'pages/Templates';
import {
  Address,
  ContainerProductInstanceResponse,
  DocumentAssociation,
  EventCertificationContract,
  EventDetailResponse,
  EventMasterDataContract,
  EventNodeInstance,
  EventTemplatesResponse,
  InventoryResponse,
  ListAttributesResponse,
  LocationResponse,
  NamedAddressContract,
  OtherSystemShipmentResponse,
  PaginatedContainerInventoryResponse,
  ProductIdentifierType,
  ProductResponse,
  ShipmentDetailsResponse,
  ShipmentResponse,
  SimpleUnitOfMeasurement,
  TemplateAttributeResponse,
  TemplateCertificationRequest,
  TemplateCertificationResponse,
  TradePartnerResponse,
  UpdateTemplateAttributeRequest,
} from 'services/api/client/src';

export interface ContainerProductInstanceQuantity {
  instanceId?: string;
  productName?: string;
  parentProductId?: string;
  lotSerial?: string;
  quantity?: number;
  currentInventory?: number;
  currentInventoryTotal?: number;
  totalInventoryAtLocation?: Number;
  unitOfMeasure?: SimpleUnitOfMeasurement;
  definedUnits?: boolean;
  unitQuantity?: number;
  unitDescriptor?: string;
  lastEvent?: string;
  locationName?: string;
  locationId?: string;
  address?: Address;
  tradePartnerName?: string;
  productIdentifierType?: ProductIdentifierType;
  productId?: string;
  tlcSourceDetails?: NamedAddressContract;
  tlcSource?: string;
  eventId?: string;
}

export interface Errors {
  [key: string]: string[];
}
export interface ErrorProps {
  errors?: Errors;
  status?: number;
  title?: string;
  traceId?: string;
  type?: string;
}
export interface CSVKey {
  title?: string;
  dataIndex?: string;
  valueType?: string;
  required?: boolean;
  initialValue?: string | number | Date;
}
export interface CSVTemplate {
  [key: string]: CSVKey | undefined;
}

export interface DocumentTags {
  locations?: Array<DocumentAssociation>;
  products?: Array<DocumentAssociation>;
  tradePartners?: Array<DocumentAssociation>;
  events?: Array<DocumentAssociation>;
}

export interface ErrorType {
  status?: number;
  title?: string;
  traceId?: string;
  detail?: string;
  errorCode?: string;
}

export interface PhoneCode {
  country?: string;
  code?: string;
  iso?: string;
}

export interface CheckRequestedDocumentsProps {
  template?: EventTemplatesResponse;
  documents?: Array<DocumentItem>;
}

export interface GetUomProps {
  product?: ProductResponse | EventDetailResponse;
}
export interface GetUomEventProps {
  event?: EventNodeInstance;
}

export interface LotItem {
  locationId?: string;
  primaryId?: string;
  [x: string]: any;
}

export interface Timezone {
  value: string;
  abbr: string;
  offset: number;
  isdst: boolean;
  text: string;
  utc: string[];
}

export interface RecordType {
  [key: string]: any;
}

export interface GetDefaultValues {
  template?: EventTemplatesResponse;
  attributes?: ListAttributesResponse;
  locationId?: string;
}

export interface DefaultValue {
  [key: string]: any;
}

export type EventDataItem = CommissionDataItem &
  DecommissionDataItem &
  TransformDataItem &
  AggregateDataItem &
  DisaggregateDataItem &
  ShipDataItem &
  ReceiveDataItem;

export type SheetData = {
  lotID?: string;
  productId?: string;
  quantity?: string;
  poNumber?: string;
  scss?: string;
  date?: string;
  time?: string;
  location?: string;
  bizStep?: string;
  disposition?: string;
  timeZone?: string;
  shippingCompany?: string;
  shippingAddress?: string;
};

export type SheetResponse = {
  [x: string]: Array<CSVTemplate>;
};
export type SheetDataResponse = {
  [x: string]: Array<SheetData>;
};
export interface EventSpecificData {
  companyName?: string;
  locationName?: string;
  address?: string;
  eventType?: string;
  productName?: string;
  lotSerial?: string;
  quantity?: string;
  unitOfMeasure?: string;
  date?: string;
  time?: string;
  eventTimezoneOffset?: string;
  [x: string]: string | undefined;
}
export interface CompanyOverviewData {
  companyName?: string;
  locationName?: string;
  address?: string;
  eventType?: string;
  productName?: string;
  inputProductName?: string;
  lotSerial?: string;
  inputLotSerial?: string;
  quantity?: string;
  unitOfMeasure?: string;
  date?: string;
  time?: string;
  eventTimezoneOffset?: string;
  [x: string]: string | undefined;
}
export interface SupplyChainOverviewData {
  companyName?: string;
  locationName?: string;
  address?: string;
  eventType?: string;
  productName?: string;
  inputProductName?: string;
  lotSerial?: string;
  inputLotSerial?: string;
  quantity?: string;
  unitOfMeasure?: string;
  date?: string;
  time?: string;
  eventTimezoneOffset?: string;
  [x: string]: string | undefined;
}

export interface CSVFormData {
  [key: string]: any;
}
export type GetCSVCustomPropertiesFn = (
  formData?: CSVFormData,
  template?: EventTemplatesResponse,
) => Array<EventMasterDataContract>;
export type GetCustomPropertiesFn = (
  formData?: CSVFormData,
  template?: EventTemplatesResponse,
) => Array<EventMasterDataContract>;
export type GetTemplateFieldsFn = (
  formData?: CSVFormData,
  template?: EventTemplatesResponse,
) => Array<EventMasterDataContract>;

export type GetCertPropertiesFn = (
  formData?: CSVFormData,
  template?: EventTemplatesResponse,
) => Array<EventCertificationContract>;
export type GetTradePartnerOByIdFn = (
  id?: string,
  tradepartners?: Array<TradePartnerResponse>,
) => TradePartnerResponse | undefined;
export type GetTradePartnerIdByIdFn = (
  id?: string,
  tradepartners?: Array<TradePartnerResponse>,
) => string | undefined;
export type GetLocationByIdFn = (
  id?: string,
  records?: Array<LocationResponse>,
) => LocationResponse | undefined;
export type GetLocationNameByIdFn = (
  id?: string,
  records?: Array<LocationResponse>,
) => string | undefined;
export type MapCertPropertiesFn = (
  certifications: Array<CertificationInfo>,
) => Array<EventCertificationContract>;

export type AttributeNode = {
  attributeProduct: string;
  attributeLot: string;
  attributeQuantity: string;
  productName: string;
  lotSerial: string;
  quantity: string;
};

export type MapEventNodes = (
  el: EventNodeInstance,
  idx: number,
  array: Array<EventNodeInstance>,
) => AttributeNode;
export type MapEventNodesFn = (isContainer?: boolean) => MapEventNodes;

export interface GetCSVTemplateFieldsProps {
  templateFields?: Array<TemplateAttributeResponse>;
  certFields?: Array<TemplateCertificationResponse>;
  defaultValues?: DefaultValue;
}

export type GetCSVTemplateFields = (
  props: GetCSVTemplateFieldsProps,
) => Array<ProColumns<CommissionDataItem>>;

export type ConcatDateTimeFn = (
  date?: string | moment.Moment | undefined,
  time?: string | moment.Moment | undefined,
) => string | undefined;

export const eventSpecificData: EventSpecificData = {
  companyName: 'Company Name',
  locationName: 'Company Location',
  address: 'Event Location (Address or Geocoordinates)',
  eventType: 'Event (bizStep)',
  productName: 'Output Product ID',
  lotSerial: 'Output Lot Number',
  quantity: 'Output Quantity',
  unitOfMeasure: 'Unit of Measurement',
  date: 'Date',
  time: 'Time',
  eventTimezoneOffset: 'Time Zone',
};

export const companyOverviewData: CompanyOverviewData = {
  companyName: 'Company Name',
  locationName: 'Company Location',
  address: 'Event Location (Address or Geocoordinates)',
  eventType: 'Event (bizStep)',
  productName: 'Product ID',
  lotSerial: 'Lot Number',
  quantity: 'Quantity',
  unitOfMeasure: 'Unit of Measurement',
  date: 'Date',
  time: 'Time',
  eventTimezoneOffset: 'Time Zone',
  inputProductName: 'Input Product ID',
  inputLotSerial: 'Input Lot Number',
  inputQuantity: 'Input Quantity',
  inputUnitOfMeasure: 'Input Unit of Measurement',
};

export const supplyChainOverviewData: SupplyChainOverviewData = {
  companyName: 'Company Name',
  locationName: 'Company Location',
  address: 'Event Location (Address or Geocoordinates)',
  eventType: 'Event (bizStep)',
  productName: 'Product ID',
  lotSerial: 'Primary Id',
  quantity: 'Quantity',
  unitOfMeasure: 'Unit of Measurement',
  date: 'Date',
  time: 'Time',
  eventTimezoneOffset: 'Time Zone',
  inputProductName: 'Input Product ID',
  inputLotSerial: 'Input Lot Number',
  inputQuantity: 'Input Quantity',
};

export const transMapping: {
  [key: string]: string;
} = {
  'Company Name': 'company_name',
  'Company Location': 'company_location',
  'Event Location (Address or Geocoordinates)': 'event_location',
  'Event (bizStep)': 'event_bizstep',
  'Output Product ID': 'output_product_id',
  'Output Lot Number': 'output_lot_number',
  'Output Quantity': 'output_quantity',
  'Unit of Measurement': 'unit_of_measurement',
  Date: 'date',
  Time: 'time',
  'Time Zone': 'timezone',
  'Input Product ID': 'input_product_id',
  'Product ID': 'product_id',
  'Input Lot Number': 'input_lot_number',
  'Input Quantity': 'input_quantity',
  'Input Unit of Measurement': 'input_unit_of_measurement',
  Quantity: 'quantity',
  'Lot Number': 'lot_number',
  'Primary Id': 'primary_id',
};

export interface JSONData {
  [key: string]: any;
}

export interface Info {
  labels: string[];
  values: string[];
}

export interface AttributesByType {
  product: string[];
  lot: string[];
  quantity: string[];
}

export type GetStringifiedValFn<T = any> = (val: T) => string;
export type MapCustomAtrributeFn = (attr: CustomAttribute) => UpdateTemplateAttributeRequest;
export type MapAdvanceSettingsFn = (attr: AdvanceSetting) => UpdateTemplateAttributeRequest;
export type MapCertRequestFn = (attr: CertificationInfo) => TemplateCertificationRequest;

export type GetCoreUOMOptionsFn = () => Array<DefaultOptionType>;
export type UomType = {
  [key: string]: string;
};

export type mapInvDataItemFn<T = any> = (
  item?: InventoryResponse | PaginatedContainerInventoryResponse,
) => T;
export type FilterInvItemFn = (item?: InventoryResponse) => boolean;
export type mapInvItemFn = (item?: InventoryResponse) => ProductInventoryItem;
export type mapShipItemFn = (item?: ShipmentResponse) => Shipment;
export type mapNewShipItemFn = (item?: ShipmentDetailsResponse) => Shipment[];
export type mapNewExternalShipItemFn = (item?: OtherSystemShipmentResponse) => Shipment[];
/**
 * Defines values for ContainerItemProductIdentifierType. \
 * {@link KnownContainerItemProductIdentifierType} can be used interchangeably with ContainerItemProductIdentifierType,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **Lot** \
 * **Serial**
 */
export type ContainerItemProductIdentifierType = string;
export interface ContainerItem {
  productId?: string;
  productName?: string;
  productGtin?: string;
  productURN?: string;
  primaryId?: string;
  instanceId?: string;
  quantity?: number;
  unitQuantity?: number;
  unitDescriptor?: string;
  unitOfMeasure?: string;
  productIdentifierType?: ContainerItemProductIdentifierType;
}

export type mapShipmentContainerFn = (
  item?: ContainerProductInstanceResponse,
  containerUrn?: string,
) => ContainerItem;
export type mapContainerFn = (
  item?: ContainerProductInstanceResponse,
  containerId?: string,
) => ContainerProductInstanceQuantity;

export type MapTemplateItemFn = (item?: EventTemplatesResponse) => TemplateItem;

export type ProField = ProFormColumnsType<any, 'text' | FormFieldType>;

export type CheckDateValidationsFn = (items?: Array<ReceiveProductItem>) => void;

export interface FeatureFlagProps {
  planCheck?: boolean;
  hideTabs?: boolean;
  newShipment?: boolean;
  validateHash?: boolean;
  hideTraceability?: boolean;
  hideLabels?: boolean;
}
