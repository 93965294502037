import { ActionType, ProColumns } from '@ant-design/pro-table';
import { RecordKey } from '@ant-design/pro-utils/lib/useEditableArray';
import { message, TablePaginationConfig, Typography } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import ActionButtons from 'components/ActionButtons';
import ProductLotSelect from 'components/GSelect/ProductLotSelect';
import ProductSelect from 'components/GSelect/ProductSelect';
import GTable from 'components/GTable';
import useProductStore from 'pages/Products/useProductStore';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  GetPaginatedOtherSystemShipmentsOptionalParams,
  InventoryResponse,
  PaginatedContainerInventoryResponse,
  ProductResponse,
} from 'services/api/client/src';
import {
  useGetProductInstanceById,
  useMultipleProductInstances,
  useProductContainerInstances,
} from 'services/api/useProducts';
import { getProductsT, hiddencol } from 'utils';
import { DisaggregateProductItem } from '../typings';
import { ShipProductsProps } from './typings';

type ColumnsType = {
  t: TFunction<'pages', 'events.disaggregate'>;
  onEditRow: (actionPayload?: DisaggregateProductItem) => void;
  onDelRow: (actionPayload?: DisaggregateProductItem) => void;
  onChangeProduct: (
    value: string,
    option: DefaultOptionType | DefaultOptionType[],
    totalInventoryAtSameLocation: Number,
  ) => void;
  isSerial?: boolean;
  identifier?: string;
  instanceByProductId: any;
  locationValue: string;
};
const columns = ({
  t,
  onEditRow,
  onDelRow,
  onChangeProduct,
  isSerial,
  identifier,
  instanceByProductId,
  locationValue,
}: ColumnsType): Array<ProColumns<DisaggregateProductItem>> => [
  {
    title: t('form_fields.product_title'),
    dataIndex: 'parentProductId',
    formItemProps: {
      rules: [
        {
          required: true,
          message: t('form_fields.product_req'),
        },
      ],
      hasFeedback: false,
    },
    renderFormItem: (_, { recordKey }, form) => {
      const { setFieldsValue } = form;

      return (
        <ProductSelect
          showExternalIdentifier
          isInsideTable
          isSerial={isSerial}
          size="small"
          onChange={async (value, option: any) => {
            // fetch instance when a product is changed
            const productInventoryData = await instanceByProductId.mutateAsync({
              id: option?.itemProps?.id || '',
              params: {
                locationIds: [locationValue],
              },
            });

            // get total Item at a location
            const totalInventoryAtSameLocation = Number(
              productInventoryData?.results?.[0]?.productInstance?.totalInventoryAtSameLocation ||
                productInventoryData?.results?.[0]?.container?.containerItems?.[0]
                  ?.totalInventoryAtSameLocation ||
                0,
            );
            setFieldsValue({
              [String(recordKey)]: {
                primaryId: undefined,
                externalIdentifier: option?.itemProps?.externalIdentifier || '',
              },
            });
            onChangeProduct(value, option, totalInventoryAtSameLocation);
          }}
        />
      );
    },
    render: (text, record) => (
      <div>
        {record.externalIdentifier ? (
          <Typography.Text>{`${record?.externalIdentifier} `}</Typography.Text>
        ) : (
          ''
        )}
        <Typography.Text type="secondary">{record?.name}</Typography.Text>
      </div>
    ),
  },
  {
    title: t('form_fields.product_lot_title', {
      identifier,
    }),
    dataIndex: 'primaryId',
    formItemProps: {
      rules: [
        {
          required: true,
          message: t('form_fields.product_lot_req', {
            identifier,
          }),
        },
      ],
      hasFeedback: false,
    },
    renderFormItem: (_, { recordKey, record }, form) => {
      const { setFieldsValue } = form;

      return (
        <ProductLotSelect
          productId={record?.parentProductId}
          size="small"
          onChange={(value, option) => {
            // @ts-ignore
            const item: InventoryResponse = option.itemProps;
            const instance = item?.productInstance;
            setFieldsValue({
              [String(recordKey)]: {
                lotSerial: instance?.lotSerial || '',
                quantity: instance?.quantity || 0,
              },
            });
          }}
        />
      );
    },
    render: (text, record) => `${record?.lotSerial || ''}`,
  },
  {
    title: t('form_fields.quantity_title'),
    dataIndex: 'quantity',
    fieldProps: {
      addonAfter: 'LBS',
      type: 'number',
      size: 'small',
      placeholder: t('form_fields.quantity_placeholder'),
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: t('form_fields.quantity_req'),
        },
      ],
      hasFeedback: false,
    },
    render: (text, record) => (!isSerial ? `${text} ${record?.unitOfMeasure}` : '1 item'),
  },
  {
    dataIndex: 'externalIdentifier',
    ...hiddencol,
  },
  {
    hideInTable: true,
    dataIndex: 'actions',
    valueType: 'option',
    render: (_text, record) => (
      <ActionButtons record={record} onEdit={onEditRow} onDelete={onDelRow} />
    ),
  },
];
const DisaggregateProducts: FC<ShipProductsProps> = ({ form, isSerial, identifier }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'events.disaggregate' });
  const { getFieldValue, setFieldsValue } = form;
  const sactionRef = useRef<ActionType>();
  const { productId = '' } = useParams();
  const [queryParams] = useSearchParams();
  const containerIdArray = queryParams?.get('containers')?.split(',') || [];
  const { paramsStore } = useProductStore();
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data: dataProducts,
    isLoading,
    isSuccess,
  } = useMultipleProductInstances([], containerIdArray, paramsStore);

  const queryClient = useQueryClient();
  const instanceByProductId = useGetProductInstanceById(queryClient);
  const locationValue: string = getFieldValue('location');

  const [tmpProduct, setTmpProduct] = useState<DisaggregateProductItem>();
  const [totalItems, setTotalItems] = useState<number>(1);

  const products: Array<DisaggregateProductItem> = getFieldValue('eventProducts');

  const setProducts = useCallback(
    (value: Array<DisaggregateProductItem>) => {
      setFieldsValue({
        eventProducts: value,
      });
    },
    [setFieldsValue],
  );
  const [params, setParams] = useState<GetPaginatedOtherSystemShipmentsOptionalParams>({
    pageNumber: 1,
    pageSize: 20,
    sortBy: '-eventDate',
  });

  const { data: containerInstances } = useProductContainerInstances(containerIdArray[0], params);
  const onTableChange = (pagination: TablePaginationConfig, sorter: any) => {
    if (pagination?.current === params?.pageNumber && pagination?.pageSize === params?.pageSize) {
      setParams({
        ...params,
        sortBy: sorter?.field
          ? `${sorter.order === 'ascend' ? '+' : '-'}${sorter.field}`
          : undefined,
        pageNumber: 1,
      });
    } else {
      setParams({
        ...params,
        pageNumber: pagination?.current,
        pageSize: pagination?.pageSize,
        sortBy: sorter?.field
          ? `${sorter.order === 'ascend' ? '+' : '-'}${sorter.field}`
          : undefined,
      });
    }
  };
  useEffect(() => {
    if (isSuccess) {
      setTotalItems(containerInstances?.totalItems || 0);
      const modifiedProducts =
        (containerInstances as PaginatedContainerInventoryResponse)?.results?.map((p) => ({
          id: p?.id || '0',
          parentProductId: productId || '0',
          name: p?.productName || '',
          primaryId: p?.id || '0',
          lotSerial: p?.lotSerial || '',
          quantity: p?.quantity || 0,
          unitOfMeasure: p?.simpleUnitOfMeasurement || '',
          externalIdentifier: p?.productExternalIdentifier,
        })) || [];

      setProducts(modifiedProducts);
    }
  }, [containerInstances, isSuccess, setProducts, productId]);
  const onChangeProduct: ColumnsType['onChangeProduct'] = (
    value,
    option,
    totalInventoryAtSameLocation,
  ) => {
    if (value && option) {
      // @ts-ignore
      const selProd: ProductResponse = option?.itemProps;

      setTmpProduct({
        id: value || '0',
        name: selProd?.name || '',
        lotSerial: '',
        primaryId: '',
        currentInventory: Number(totalInventoryAtSameLocation) || 0,
        quantity: Number(selProd?.shippedInventory || 0),
        unitOfMeasure: selProd?.simpleUnitOfMeasurement || '',
        externalIdentifier: selProd?.externalIdentifier,
      });
    }
  };
  const onEditRow = (actionPayload?: DisaggregateProductItem) => {
    sactionRef.current?.startEditable(actionPayload?.id || 0);
    setTmpProduct(actionPayload);
  };
  const onDelRow = async (actionPayload?: DisaggregateProductItem) => {
    if (actionPayload && products.length > 1) {
      setProducts(products.filter((p) => p.id !== actionPayload.id));
    } else {
      message.error(getProductsT('delete_last_product'));
    }
  };
  const onSaveProduct = async (_rowKey: RecordKey, data: DisaggregateProductItem) => {
    sactionRef.current?.cancelEditable(data?.id || 0);

    const existingProduct = products.find((product) => product.id === data?.id);
    if (existingProduct) {
      existingProduct.name = tmpProduct?.name || data?.name || '';
      existingProduct.quantity = data?.quantity;
      existingProduct.currentInventory = tmpProduct?.currentInventory || data?.currentInventory;
      existingProduct.primaryId = data?.primaryId || '';
      existingProduct.lotSerial = data?.lotSerial || '';
      existingProduct.externalIdentifier = tmpProduct?.externalIdentifier;

      // update product
      setProducts(products.map((p) => (p.id === existingProduct.id ? existingProduct : p)));
    } else {
      const productData = {
        id: data?.id || '0',
        parentProductId: tmpProduct?.parentProductId || '0',
        name: tmpProduct?.name || '',
        primaryId: data?.primaryId || '0',
        lotSerial: data?.lotSerial || '',
        currentInventory: Number(tmpProduct?.currentInventory || 0),
        quantity: Number(data?.quantity || 0),
        unitOfMeasure: tmpProduct?.unitOfMeasure || '',
        externalIdentifier: tmpProduct?.externalIdentifier,
      };
      // add product
      setProducts([...products, productData]);
    }
  };
  return (
    <GTable<DisaggregateProductItem>
      key="sactionRef"
      actionRef={sactionRef}
      columns={columns({
        t,
        onEditRow,
        onDelRow,
        onChangeProduct,
        isSerial,
        identifier,
        instanceByProductId,
        locationValue,
      })}
      editable={{
        onSave: (rowKey, data) => onSaveProduct(rowKey, data),
        onCancel: async (_rowKey, data) => {
          sactionRef.current?.cancelEditable(data?.id || 0);
        },
      }}
      options={{
        reload: false,
        setting: false,
      }}
      actionsRenderOptions={{
        save: true,
        cancel: true,
      }}
      recordCreatorProps={false}
      onAddRecordClick={() => {
        sactionRef.current?.addEditRecord?.({
          id: Date.now().toString(),
        });
      }}
      loading={isLoading}
      value={products}
      scroll={{ y: '50vh' }}
      onTableChange={onTableChange}
      pagination={{
        defaultPageSize: params.pageSize,
        total: totalItems,
        current: params?.pageNumber,
      }}
    />
  );
};
export default React.memo(DisaggregateProducts);
