import { InfoCircleOutlined } from '@ant-design/icons';
import { ProFormColumnsType } from '@ant-design/pro-form';
import { ActionType } from '@ant-design/pro-table';
import { RowProps, Typography } from 'antd';
import { useWatch } from 'antd/lib/form/Form';
import { MultipleDatesFormItem } from 'components';
import { ProductSelect, TimezoneSelect } from 'components/GSelect';
import BizStepSelect from 'components/GSelect/BizStepSelect';
import DispositionSelect from 'components/GSelect/DispositionSelect';
import TemplateSelect from 'components/GSelect/TemplateSelect';
import MultiReceiveTable from 'pages/AllShipments/MultiReceiveTable';
import { DataItem as DocumentItem } from 'pages/Documents/Forms/typings';
import { useEventsStore } from 'pages/Events/hooks';
import { useShipmentStore } from 'pages/Shipments';
import { useRef, useState } from 'react';
import { ProductResponse } from 'services/api/client/src';
import { checkSameDate, getTemplateFields } from 'utils';
import {
  CertDocuments,
  CertificationInfo,
  DataTemplate,
  Documents,
  EventDetails,
} from '../Sections';
import NewReceiveDataTemplate from '../Sections/NewReceiveDataTemplate';
import ReceiveDataTemplate from '../Sections/ReceiveDataTemplate';
import DataEntryMethod from './DataEntryMethod';
import AllReceiveProducts from './TransformTables/AllReceiveProducts';
import NewReceiveProducts from './TransformTables/NewReceiveProducts';
import { AllReceiveFormFieldsProps, ReceiveProductItem } from './typings';

const colProps = { xs: 24, sm: 12, md: 12, lg: 6 };
const colPropsDataEntry = { xs: 24, sm: 12, md: 18, lg: 18 };
const colPropsFull = { xs: 24, sm: 24, md: 24, lg: 24 };
const colPropsNull = { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 };
const colPropsFullCsv = { xs: 24, sm: 24, md: 24, lg: 24 };

const rowProps: RowProps = { gutter: [32, 24] };

export type MultiReceiveProductItem = {
  company?: string;
  date?: string;
  time?: string;
  poNumber?: string;
  location?: string;
  shipmentId?: string;
  inboundShipments?: Array<ReceiveProductItem>;
  documents?: Array<DocumentItem>;
  customProperties?: { [key: string]: any };
  certificationList?: Array<CertificationInfo>;
  companyName?: string;
  locationName?: string;
  originLocationId?: string;
  shipEventDate?: string;
  bizStep?: string;
  disposition?: string;
  timeZone?: string;
  [key: string]: any;
};

export type DataItem = {
  id?: string;
  poNumber?: string;
  location?: string;
  customData?: string;
  assignedProduct?: string;
  primaryId?: string;
  scss?: string;
  quantity?: number;
  unitOfMeasure?: string;
  date?: string;
  time?: string;
  aggregateDate?: string;
  aggregateTime?: string;
  timeZone?: string;
  bizStep?: string;
  disposition?: string;
  inboundShipments?: Array<ReceiveProductItem>;
  multiReceiveObjects?: Array<MultiReceiveProductItem>;
  originalMultiReceiveObjects?: Array<MultiReceiveProductItem>;
  advancedField?: string;
  advancedField2?: string;
  advancedField3?: string;
  documents?: Array<DocumentItem>;
  csvData?: Array<DataItem>;
  customProperties?: Array<string>;
  autologDisaggregateEvent?: boolean;
  disableAutologDisaggregateEvent?: boolean;
  certificationList?: Array<CertificationInfo>;
  isMultipleDates?: boolean;
  isMultipleLocations?: boolean;
  isMultipleProducts?: boolean;
  sscc?: string;
  parentProductId?: string;
  productName?: string;
  isAssignedProduct?: boolean;
  productProps?: any;
  newReceiveFileUpload?: boolean;
  [key: string]: any;
};

export const AllReceiveFormFields = ({
  hasAdvancedSettings,
  dataEntryMethod,
  lastEventDate,
  isSSCC,
  // uom,
  defaultTimezone,
  shipmentIdArray,
  eventProducts,
  isLoading,
  t,
  disabledDate,
  onChangeDate,
  disabledTime,
  onChangeTemplate,
  timeValidation,
  editableTableFormRef,
  receiveForm,
  hasDifferntLocations,
  onMultiLocChange,
  validationMessage,
}: AllReceiveFormFieldsProps): ProFormColumnsType<DataItem>[] => {
  const newReceiveFileUpload = useWatch?.('newReceiveFileUpload', receiveForm) || false;

  const isManualEntry = dataEntryMethod === 'manual';
  const { template } = useEventsStore();
  const { selectedShipments } = useShipmentStore();
  // ref for receive products table
  const actionRef = useRef<ActionType>();
  // row being edited in new receive products table
  const [rowBeingEdited, setRowBeingEdited] = useState('');

  return [
    {
      renderFormItem: () => <EventDetails />,
    },
    {
      valueType: 'dependency',
      fieldProps: {
        name: ['location'],
      },
      columns: ({ location }: DataItem) => [
        {
          dataIndex: 'isMultipleProducts',
          valueType: 'switch',
          initialValue: false,
          renderFormItem: (row, config, form) => (
            <MultipleDatesFormItem
              form={form}
              title="Multiple Products"
              dataIndex="isMultipleProducts"
              tooltip="Having multiple products will turn this into an SSCC."
              actionRef={actionRef}
              rowBeingEdited={rowBeingEdited}
            />
          ),
          colProps: colPropsFullCsv,
          hideInForm: shipmentIdArray?.[0] !== 'new_receive',
        },
        {
          dataIndex: 'isMultipleDates',
          valueType: 'switch',
          renderFormItem: (row, config, form) => (
            <MultipleDatesFormItem
              form={form}
              title={t('form_fields.multiple_dates_title')}
              dataIndex="isMultipleDates"
            />
          ),
          colProps,
          hideInForm: !isManualEntry || (selectedShipments?.length || 0) <= 1,
        },
        {
          dataIndex: 'isMultipleLocations',
          valueType: 'switch',
          renderFormItem: (row, config, form) => (
            <MultipleDatesFormItem
              form={form}
              title="Multiple Locations"
              dataIndex="isMultipleLocations"
              {...(hasDifferntLocations ? { onValueChange: onMultiLocChange } : {})}
            />
          ),
          colProps: colPropsDataEntry,
          hideInForm: !isManualEntry || (selectedShipments?.length || 0) <= 1,
        },
        {
          valueType: 'group',
          colProps: colPropsFull,
          rowProps,
          columns: [
            {
              title: t('form_fields.custom_data_title'),
              dataIndex: 'customData',
              fieldProps: {
                placeholder: t('form_fields.custom_data_placeholder'),
              },
              formItemProps: {
                rules: [
                  {
                    required: false,
                    message: t('form_fields.custom_data_req'),
                  },
                ],
              },
              renderFormItem: (row, config, form) => (
                <TemplateSelect
                  onChange={(value, option) =>
                    onChangeTemplate?.(
                      form,
                      option,
                      value,
                      location,
                      (shipmentIdArray?.length || 1) > 1,
                    )
                  }
                />
              ),
              colProps,
            },
            {
              title: t('form_fields.data_entry_title'),
              dataIndex: 'dataEntryMethod',
              hideInForm: !(
                shipmentIdArray?.[0] === 'new_receive' || (shipmentIdArray?.length || 1) > 1
              ),
              renderFormItem: (row, config, form) => (
                <DataEntryMethod
                  onChange={(value) => {
                    if (value === 'manual') {
                      form?.setFieldsValue({ csvData: [] });
                      if (shipmentIdArray?.[0] === 'new_receive') {
                        form?.setFieldsValue({
                          customProperties: [],
                          certificationList: [],
                          date: undefined,
                          time: undefined,
                          location: undefined,
                          companyName: undefined,
                          company: undefined,
                          locationName: undefined,
                          poNumber: undefined,
                          inboundShipments: [],
                          newReceiveFileUpload: false,
                          sscc: undefined,
                          parentProductId: undefined,
                        });
                      }
                    }
                  }}
                />
              ),
              colProps: colPropsDataEntry,
            },
          ],
        },
      ],
    },
    {
      dataIndex: 'csvData',
      initialValue: [],
      renderFormItem: (row, record, form) => (
        <NewReceiveDataTemplate form={form} editableTableFormRef={editableTableFormRef} />
      ),
      colProps: colPropsFullCsv,
      hideInForm: newReceiveFileUpload
        ? false
        : !(shipmentIdArray?.[0] === 'new_receive' && !isManualEntry),
    },
    {
      valueType: 'dependency',
      fieldProps: {
        name: [
          'assignedProduct',
          'date',
          'time',
          'scss',
          'autologDisaggregateEvent',
          'disableAutologDisaggregateEvent',
          'unitOfMeasure',
          'isMultipleDates',
          'aggregateDate',
          'sscc',
          'product',
          'isMultipleProducts',
        ],
      },
      columns: ({ date, isMultipleDates, isMultipleProducts }: DataItem) => [
        {
          title: 'Receiver Purchase Order',
          dataIndex: 'poNumber',
          colProps,
          fieldProps: {
            placeholder: 'Receiver Purchase Order',
          },
          hideInForm: newReceiveFileUpload ? false : !isManualEntry || isMultipleDates,
          tooltip: {
            icon: <InfoCircleOutlined />,
            placement: 'top',
            title:
              'Receiver Purchase Order Number may be different from Supplier Purchase Order Number.',
            key: null,
            type: '',
            props: null,
          },
        },
        {
          title: 'Product',
          dataIndex: 'parentProductId',
          valueType: 'select',
          hideInForm: newReceiveFileUpload
            ? isMultipleProducts
            : shipmentIdArray?.[0] !== 'new_receive' || isMultipleProducts || !isManualEntry,
          formItemProps: {
            rules: [
              {
                required: true,
                message: 'product required',
              },
            ],
          },
          fieldProps: {
            placeholder: 'Product',
          },
          colProps,
          render: (text, record) => (
            <div>
              {record.prodExtIdentifier ? (
                <Typography.Text>{`${record?.productProps?.externalIdentifier} `}</Typography.Text>
              ) : (
                ''
              )}
              <Typography.Text type="secondary">{record?.productName}</Typography.Text>
            </div>
          ),
          renderFormItem: (_, _cfg, form) => (
            <ProductSelect
              showExternalIdentifier
              isAddProduct
              allowLotSerial
              onChange={(value, option) => {
                // @ts-ignore
                const item: ProductResponse = option.itemProps;
                form?.setFieldsValue({
                  parentProductId: item?.id,
                  productName: item?.name || '',
                  isAssignedProduct: true,
                  productProps: item,
                  inboundShipments: undefined,
                });
                // cancel editing/adding new item for selected row in new receive products table
                actionRef?.current?.cancelEditable(rowBeingEdited);
              }}
              onClear={() => {
                form?.setFieldsValue({
                  parentProductId: undefined,
                  productName: undefined,
                  isAssignedProduct: false,
                  productProps: undefined,
                  inboundShipments: undefined,
                });
                actionRef?.current?.cancelEditable(rowBeingEdited);
              }}
              allowClear
            />
          ),
        },
        {
          title: 'SSCC',
          dataIndex: 'sscc',
          hideInForm: newReceiveFileUpload
            ? !isMultipleProducts
            : shipmentIdArray?.[0] !== 'new_receive' || !isMultipleProducts || !isManualEntry,
          formItemProps: {
            rules: [
              {
                required: true,
                message: t('form_fields.scss_req'),
              },
              {
                whitespace: true,
                message: t('form_fields.scss_req'),
              },
            ],
          },
          fieldProps: {
            placeholder: t('form_fields.scss_placeholder'),
          },
          colProps,
        },
        {
          title: 'Received Date',
          valueType: 'date',
          dataIndex: 'date',
          initialValue: null,
          fieldProps: (form) => ({
            placeholder: t('form_fields.date_placeholder'),
            className: 'full-width',
            disabledDate,
            onChange: (value: string) => onChangeDate?.(form, value),
          }),
          formItemProps: {
            rules: [
              {
                required: true,
                message: t('form_fields.date_req'),
              },
            ],
          },
          colProps,
          hideInForm: newReceiveFileUpload ? false : !isManualEntry || isMultipleDates,
        },
        {
          title: 'Received Time',
          valueType: 'time',
          dataIndex: 'time',
          className: 'timecol',
          initialValue: '12:00:00',
          fieldProps: {
            placeholder: t('form_fields.time_placeholder'),

            className: 'full-width',
            // check is same day as date
            disabledTime: checkSameDate(date, lastEventDate) ? disabledTime : undefined,
          },

          formItemProps: {
            rules: [
              {
                required: true,
                message: t('form_fields.time_req'),
              },
              ...(timeValidation?.(date) || []),
            ],
          },
          colProps,
          hideInForm: newReceiveFileUpload ? false : !isManualEntry || isMultipleDates,
        },
        {
          title: 'Timezone',
          dataIndex: 'timeZone',
          valueType: 'select',
          initialValue: defaultTimezone,

          fieldProps: {
            placeholder: t('form_fields.time_zone_placeholder'),
          },
          formItemProps: {
            rules: [
              {
                required: true,
                message: t('form_fields.time_zone_req'),
              },
            ],
          },
          renderFormItem: () => <TimezoneSelect />,

          colProps: !isManualEntry || !hasAdvancedSettings ? colPropsNull : colProps,
        },
        {
          title: t('form_fields.bizstep_title'),
          dataIndex: 'bizStep',
          initialValue: 'urn:epcglobal:cbv:bizstep:receiving',
          valueType: 'select',
          renderFormItem: () => <BizStepSelect />,
          formItemProps: {
            rules: [
              {
                required: true,
                message: t('form_fields.bizstep_req'),
              },
            ],
          },
          tooltip: {
            icon: <InfoCircleOutlined />,
            title: t('form_fields.bizstep_tooltip'),
            key: null,
            type: '',
            props: null,
          },
          colProps: (
            newReceiveFileUpload ? !hasAdvancedSettings : !isManualEntry || !hasAdvancedSettings
          )
            ? colPropsNull
            : colProps,
        },
        {
          title: t('form_fields.disposition_title'),
          dataIndex: 'disposition',
          initialValue: 'urn:epcglobal:cbv:disp:in_progress',
          valueType: 'select',
          renderFormItem: () => <DispositionSelect />,
          formItemProps: {
            rules: [
              {
                required: true,
                message: t('form_fields.disposition_req'),
              },
            ],
          },
          tooltip: {
            icon: <InfoCircleOutlined />,
            title: t('form_fields.disposition_tooltip'),
            key: null,
            type: '',
            props: null,
          },
          colProps: (
            newReceiveFileUpload ? !hasAdvancedSettings : !isManualEntry || !hasAdvancedSettings
          )
            ? colPropsNull
            : colProps,
        },
        {
          valueType: 'divider',
          hideInForm: shipmentIdArray?.[0] === 'new_receive' ? !isManualEntry : false,
        },
        {
          dataIndex: 'inboundShipments',
          renderFormItem: (_row, _schema, form) =>
            shipmentIdArray?.[0] === 'new_receive' ? (
              <NewReceiveProducts
                form={form}
                actionRef={actionRef}
                setRowBeingEdited={setRowBeingEdited}
              />
            ) : (
              <AllReceiveProducts
                form={form}
                eventProducts={eventProducts?.[0]}
                isLoading={isLoading}
              />
            ),
          hideInForm: newReceiveFileUpload
            ? false
            : !isManualEntry || (shipmentIdArray?.length || 1) > 1,
        },
        {
          dataIndex: 'multiReceiveObjectsTable',
          renderFormItem: (_row, _schema, form) => (
            <MultiReceiveTable
              form={form}
              eventProducts={eventProducts}
              hasDifferntLocations={hasDifferntLocations}
              validationMessage={validationMessage}
            />
          ),
          hideInForm: !isManualEntry || (shipmentIdArray?.length || 1) === 1,
        },
        /* autolog disaggregate event */
        {
          valueType: 'divider',
          hideInForm: !isSSCC || !isManualEntry || (shipmentIdArray?.length || 1) > 1,
        },
      ],
    },
    {
      valueType: 'divider',
      hideInForm: !isManualEntry || (shipmentIdArray?.length || 1) > 1,
    },
    {
      dataIndex: 'csvData',
      initialValue: [],
      renderFormItem: (row, record, form) => (
        <DataTemplate form={form} editableTableFormRef={editableTableFormRef} />
      ),
      colProps: colPropsFullCsv,
      hideInForm: !isManualEntry || (shipmentIdArray?.length || 1) > 1,
    },
    {
      dataIndex: 'csvData',
      initialValue: [],
      renderFormItem: (row, record, form) => (
        <ReceiveDataTemplate
          form={form}
          editableTableFormRef={editableTableFormRef}
          eventProducts={eventProducts}
          validationMessage={validationMessage}
        />
      ),
      colProps: colPropsFullCsv,
      hideInForm: isManualEntry || (shipmentIdArray?.length || 1) === 1,
    },
    {
      valueType: 'formSet',
      dataIndex: 'customProperties',
      initialValue: [],
      columns: getTemplateFields(template),
      rowProps,
      hideInForm: newReceiveFileUpload
        ? false
        : !isManualEntry || (shipmentIdArray?.length || 1) > 1 || !template,
    },
    {
      dataIndex: 'certificationList',
      rowProps,
      renderFormItem: (row, record, form) => <CertDocuments form={form} />,
      hideInForm: newReceiveFileUpload
        ? !template || !template?.templateAttributes || !template?.allowCertificationDocuments
        : !isManualEntry ||
          (shipmentIdArray?.length || 1) > 1 ||
          !template ||
          !template?.templateAttributes ||
          !template?.allowCertificationDocuments,
    },
    {
      valueType: 'divider',
      hideInForm:
        shipmentIdArray?.[0] === 'new_receive'
          ? !isManualEntry
          : (shipmentIdArray?.length || 1) > 1,
    },
    {
      dataIndex: 'documents',
      initialValue: [],
      renderFormItem: (_row, _config, form) => <Documents form={form} />,
      colProps: colPropsFull,
      hideInForm: newReceiveFileUpload
        ? false
        : !isManualEntry || (shipmentIdArray?.length || 1) > 1,
    },
    {
      dataIndex: 'originalMultiReceiveObjects',
      fieldProps: {
        className: 'no-display',
      },
      formItemProps: {
        className: 'no-display',
      },
      colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
    },
    {
      dataIndex: 'multiReceiveObjects',
      fieldProps: {
        className: 'no-display',
      },
      formItemProps: {
        className: 'no-display',
      },
      colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
    },
    {
      dataIndex: 'newReceiveFileUpload',
      fieldProps: {
        className: 'no-display',
      },
      formItemProps: {
        className: 'no-display',
      },
      colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
    },
    {
      dataIndex: 'productName',
      fieldProps: {
        className: 'no-display',
      },
      formItemProps: {
        className: 'no-display',
      },
      colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
    },
    {
      dataIndex: 'company',
      fieldProps: {
        className: 'no-display',
      },
      formItemProps: {
        className: 'no-display',
      },
      colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
    },
    {
      dataIndex: 'location',
      fieldProps: {
        className: 'no-display',
      },
      formItemProps: {
        className: 'no-display',
      },
      colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
    },
    {
      dataIndex: 'isAssignedProduct',
      fieldProps: {
        className: 'no-display',
      },
      formItemProps: {
        className: 'no-display',
      },
      colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
    },
    {
      dataIndex: 'productProps',
      fieldProps: {
        className: 'no-display',
      },
      formItemProps: {
        className: 'no-display',
      },
      colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
    },
  ];
};

export default AllReceiveFormFields;
