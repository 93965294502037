import { Card, Divider, Modal, Space, Typography } from 'antd';
import { LoadingProgress } from 'components';
import GMap from 'components/GMap';
import { GeoJSONPolygonFeatureCollection, MarkerItemProps } from 'components/GMap/typings';
import useModalVisibility from 'hooks/useModalVisibility/useModalVisibility';
import LifecycleHeader from 'pages/Products/Inventory/EventDetails/LifecycleHeader';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { EventResponse, MapNode } from 'services/api/client/src';
import { useEventMapById } from 'services/api/eventDetails';
import {
  useShipEventByIdShipments,
  useShipEventByIdShipmentsMutate,
} from 'services/api/newShipments';
import {
  dateFormat,
  flattenAddress,
  getActiveMarker,
  getEventsT,
  getProductsT,
  globalStyles,
  orderNodesByEdges,
} from 'utils';
import DetailsModal from './DetailsModal/DetailsModal';
import styles from './index.module.less';
import { EventMapProps, MapNodeFn, RenderEventNode } from './typings';

const EventMap: FC<EventMapProps> = ({ lifeCyle }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'products.event_details' });
  const queryClient = useQueryClient();
  const location = useLocation();
  const isInboundShipment = location.pathname?.includes('inbound');
  const [activeEvent, setActiveEvent] = useState<EventResponse>();
  const infoModal = useModalVisibility(false);
  const { shipmentId = '1' } = useParams();
  const [queryParams] = useSearchParams();
  const hideDocument = queryParams?.get('hideDocument') === 'true' || false;

  const { data, isLoading } = useEventMapById(shipmentId, {
    showAllProducts: true,
    isInboundShipment,
  });
  const locationData = data?.nodes?.flatMap((e1) =>
    e1?.events?.map((e2) => ({
      location: e2?.location,
    })),
  );

  const { data: eventData, isLoading: isELoading } = useShipEventByIdShipments(
    activeEvent?.id || '',
  );
  const getEventCall = useShipEventByIdShipmentsMutate();
  const mapNodes = useCallback<MapNodeFn>((item) => {
    const lat = item?.address?.geoCoordinates?.latitude || item?.latitude || 0;
    const lng = item?.address?.geoCoordinates?.longitude || item?.longitude || 0;
    return {
      id: item?.id || Date.now().toString(),
      position: {
        lng,
        lat,
      },
      title: item?.tradePartnerName || '',
      label: String(item?.events?.length || '0'),
      shortDesc: item?.locationName || '',
      longDesc: flattenAddress(item?.address) || `${item?.latitude},${item?.longitude}`,
      extra: item,
    };
  }, []);
  const markers: Array<MarkerItemProps<MapNode>> = useMemo(() => {
    const nodes = orderNodesByEdges(data);
    return nodes?.map(mapNodes) || [];
  }, [data, mapNodes]);
  const polygons: GeoJSONPolygonFeatureCollection = locationData?.reduce(
    (acc: GeoJSONPolygonFeatureCollection, loc) => {
      const polygonData = loc?.location?.geoJson;
      if (polygonData) {
        const feature = JSON.parse(polygonData);
        if (feature?.features) {
          acc.features.push(...feature.features);
        }
      }
      return acc;
    },
    { type: 'FeatureCollection', features: [] } as GeoJSONPolygonFeatureCollection, // Explicitly type the accumulator
  ) ?? { type: 'FeatureCollection', features: [] };

  const initActiveMarker = useMemo(
    () => getActiveMarker(markers, data?.nodes?.[0]?.events?.[0]?.id),
    [data?.nodes, markers],
  );
  const onEventClick = useCallback(
    async (evt: EventResponse) => {
      const eventInfo = await getEventCall?.mutateAsync({
        shipmentIds: evt?.id || '',
      });
      setActiveEvent(eventInfo || evt);
      infoModal.show();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [infoModal, queryClient.getQueryData],
  );
  const showExternalModal = useCallback(() => {
    Modal.info({
      title: t?.('ext_modal_title'),
      content: t?.('ext_modal_desc'),
      cancelText: t?.('ext_modal_ok'),
      cancelButtonProps: {
        type: 'primary',
        shape: 'round',
      },
      okButtonProps: {
        hidden: true,
      },
      centered: true,
      okCancel: true,
    });
  }, [t]);

  const renderEvent = useCallback<RenderEventNode>(
    (evt) => {
      const container = evt?.containers?.[0];
      const isContainerl = !!container?.identifier || (evt?.containers?.length || 0) > 0;
      const isMultiProd = !!evt?.products?.find(
        (item) => item?.productName !== evt?.products?.[0]?.productName,
        [],
      );

      const p = evt?.products?.[0] || evt?.outputProducts?.[0] || evt?.inputProducts?.[0];
      const identifier = getProductsT(String(p?.productIdentifierType ?? 'Lot').toLowerCase());
      const lotSerial = p?.lotSerial;

      const productName = isMultiProd ? 'Multiple Products' : p?.productName || '';
      const indentifierText = !isContainerl
        ? `${identifier}: ${(evt?.products?.length || 0) > 1 ? 'Multiple' : lotSerial}`
        : `${getProductsT('sscc')}: ${
            (evt?.containers?.length || 0) > 1 ? 'Multiple' : container?.identifier
          }`;
      const eventstyle = evt?.deleted ? { backgroundColor: '#FFCCC7' } : {};
      return (
        <Space
          direction="vertical"
          key={evt?.id}
          onClick={() => onEventClick(evt)}
          className={styles.event}
          style={eventstyle}
        >
          <div className={styles.eventtitle}>
            <Typography.Text ellipsis className={styles.infosubtitle}>
              {getEventsT(evt?.eventType)}
            </Typography.Text>
            <Typography.Text ellipsis className={styles.infosubtitle}>
              {dateFormat(new Date(evt?.eventTime || '').toISOString())}
            </Typography.Text>
          </div>
          <Typography.Text ellipsis className={styles.infodesc}>
            {productName || ''}
          </Typography.Text>
          <Typography.Text ellipsis type="secondary" className={styles.infodesc}>
            {indentifierText}
          </Typography.Text>
        </Space>
      );
    },
    [onEventClick],
  );

  const renderPendingReceive = useCallback(
    (activeMaker: MarkerItemProps<MapNode>) => {
      const mapNode = activeMaker?.extra;
      if (mapNode?.events?.length) {
        return mapNode?.events?.map(renderEvent) || '';
      }
      if (mapNode?.acceptedByReceiver) {
        return (
          <Space
            direction="vertical"
            key={mapNode?.id}
            onClick={showExternalModal}
            className={styles.event}
          >
            <div className={styles.eventtitle}>
              <Typography.Text ellipsis className={styles.infosubtitle}>
                {getEventsT('receive')}
              </Typography.Text>
              {/* <Typography.Text ellipsis className={styles.infosubtitle}>
                {dateFormat(new Date(mapNode?. || '').toISOString())}
              </Typography.Text> */}
            </div>
          </Space>
        );
      }
      return (
        <Typography.Text ellipsis type="secondary" className={styles.preceive}>
          {t('pending_receive')}...
        </Typography.Text>
      );
    },
    [t, renderEvent, showExternalModal],
  );

  const renderInfoWindow = useCallback(
    (activeMaker: MarkerItemProps<MapNode>) => (
      <>
        <Space>
          <Typography.Text strong ellipsis className={styles.infotitle}>
            {activeMaker?.title || ''}
          </Typography.Text>
        </Space>
        <Space direction="vertical" className={styles.infocontainer}>
          <Typography.Text
            ellipsis
            title={activeMaker?.shortDesc || ''}
            className={styles.infosubtitle}
          >
            {activeMaker?.shortDesc || ''}
          </Typography.Text>
          <Typography.Text title={activeMaker?.longDesc || ''} ellipsis className={styles.infodesc}>
            {activeMaker?.longDesc || ''}
          </Typography.Text>
        </Space>
        <Divider className={styles.headerdivider} />
        <Space direction="vertical" className={styles.eventscontainer}>
          {renderPendingReceive(activeMaker)}
        </Space>
      </>
    ),
    [renderPendingReceive],
  );

  const [tick, setTick] = useState<number>(0);

  useEffect(() => {
    if (eventData) {
      /* set active event with full data */
      setActiveEvent(eventData);
    }
  }, [eventData]);

  return tick <= 100 ? (
    <Card className={styles.height80}>
      <LoadingProgress
        label={t('supply_chain_progress')}
        percent={Number(tick) || 0}
        isLoading={isLoading}
        setTick={setTick}
      />
    </Card>
  ) : (
    <>
      <DetailsModal
        key={activeEvent?.id}
        modalProps={infoModal}
        activeEvent={activeEvent}
        loading={isELoading}
        hideDocument={hideDocument}
      />
      <LifecycleHeader propsString={lifeCyle} />
      {markers && markers.length > 0 ? (
        <GMap
          markers={markers}
          edges={data?.edges}
          renderInfoWindow={renderInfoWindow}
          containerStyle={globalStyles.mapContainer}
          showPolylines
          initActiveMarker={initActiveMarker}
          allowFitBounds
          geoPolygonJSON={polygons}
        />
      ) : (
        <GMap containerStyle={globalStyles.mapContainer} />
      )}
    </>
  );
};
export default React.memo(EventMap);
