import { InfoCircleOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Space, Switch, Tooltip } from 'antd';
import React, { FC } from 'react';
import styles from './index.module.less';
import LifecycleHeader from './LifecycleHeader';
import { EventFilterLifecycleProps } from './typings';

const EventFilterLifecycle: FC<EventFilterLifecycleProps> = ({
  t,
  showAllProducts,
  showErrorDeclarations,
  setShowAllProducts,
  setShowErrorDeclarations,
  showLifeCycle,
}) => {
  const menu = (
    <Menu className={styles.noarrow}>
      <Menu.Item>
        <Space>
          <Switch checked={showAllProducts} onChange={setShowAllProducts} />
          <Tooltip placement="bottom" title={t('show_all_filter')}>
            <InfoCircleOutlined />
          </Tooltip>
          {t('view_all_products')}
        </Space>
      </Menu.Item>
      <Menu.Item>
        <Space>
          <Switch checked={showErrorDeclarations} onChange={setShowErrorDeclarations} />
          <Tooltip placement="bottom" title={t('show_error_declaration')}>
            <InfoCircleOutlined />
          </Tooltip>
          {t('view_all_declaration')}
        </Space>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.lifecyclecontainer}>
      <div>{showLifeCycle && <LifecycleHeader />}</div>
      <Dropdown overlay={menu} trigger={['click']}>
        <Button type="link">
          <Space>
            <SettingOutlined />
            {t('filter')}
          </Space>
        </Button>
      </Dropdown>
    </div>
  );
};

export default React.memo(EventFilterLifecycle);
