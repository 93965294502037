import { BetaSchemaForm } from '@ant-design/pro-form';
import { message } from 'antd';
import GCardDrawer from 'components/GCardDrawer';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useGetPalletById, useUpdatePalletLabel } from 'services/api/useLabels';
import { errorHandler } from 'utils';
import BarcodePalletLayoutPrint from '../LabelForm/BarcodePalletLayoutPrint';
import { AddCardColumns, AddFormColumns } from './AddEditPallet.fields';
import { AddModalProps, addSubmitTypeFn, DataItem, PrintOptions } from './typings';
import useAddEditActions from './useAddEditActions';

const AddPallet: FC<AddModalProps> = ({ modal, onHeaderFooterFinish }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'labels.edit_pallet' });
  const { labelId = '1' } = useParams();
  const {
    contextHolder,
    onCancel,
    onAdd,
    modalFormRef,
    modalCardRef,
    setBothFormValues,
    onDrawerClose,
    printConfirm,
  } = useAddEditActions({
    t,
    modal,
    labelId,
  });
  const queryClient = useQueryClient();
  const editPalletAPI = useUpdatePalletLabel(queryClient);
  const [printData, setPrintData] = useState<DataItem | null>(null);
  const printRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [printSetting, setPrintSetting] = useState<PrintOptions>('4x6');
  const { data } = useGetPalletById(labelId);

  useEffect(() => {
    const mappedData = {
      labelName: data?.labelName,
      product: data?.product?.id,
      gtin: data?.product?.gtin,
      formFieldList: data?.sections
        ?.sort((a, b) => (Number(a?.position) || 0) - (Number(b?.position) || 0))
        ?.map(
          (i) => ({
            title: i?.title,
            content: i?.sectionContent,
            data: i?.data,
            position: i?.position,
          }),
          [],
        ),
      existingSSCC: data?.isReadyToPrint ? 'Yes' : 'No',
      printOptions: data?.printSetting || '4x6',
      ...(data?.isReadyToPrint ? { sscc: data?.sscc } : { fieldSSCC: data?.sscc }),
    };
    setBothFormValues(mappedData);
  }, [
    data?.isReadyToPrint,
    data?.labelName,
    data?.printSetting,
    data?.product?.gtin,
    data?.product?.id,
    data?.sections,
    data?.sscc,
    setBothFormValues,
  ]);

  // Create print styles based on selected format
  const getPageStyle = () => `
    @page {
      size: ${printSetting === '4x6' ? '4in 6in' : '4in 12in'};
      margin: 0;
    }
  `;

  const handlePrint = useReactToPrint({
    contentRef: printRef,
    onAfterPrint: () => {
      // Cleanup after printing
      document.body.classList.remove('printing');
      return true;
    },
    onBeforePrint: async () => {
      // Add any pre-print preparations
      document.body.classList.add('printing');
    },
    pageStyle: getPageStyle(),
  });

  const onValueChange = useCallback(
    (_: any, values: DataItem) => modalCardRef?.current?.setFieldsValue(values),
    [modalCardRef],
  );

  const handlePrintOption = (e: PrintOptions) => {
    setPrintSetting(e);
  };

  const generatePalletData = (formData: DataItem) => ({
    labelName: formData?.labelName,
    product: formData.product,
    sections: formData.formFieldList?.map((c, index) => ({
      title: c.title,
      sectionContent: c.content,
      data: c.data,
      position: index,
    })),
    sscc: formData.sscc,
    gtin: formData.gtin,
  });

  const onSaveAsDraft = useCallback(async () => {
    const values: DataItem = modalFormRef?.current?.getFieldsValue();
    const palletData = generatePalletData(values);
    try {
      setIsLoading(true);
      const isValidated = await modalFormRef.current?.validateFields();
      if (!isValidated) return;
      await editPalletAPI?.mutateAsync({
        id: labelId,
        options: {
          body: {
            ...palletData,
            isReadyToPrint: values.existingSSCC === 'Yes',
            printSetting: values.printOptions,
          },
        },
      });
      message.success(t?.('succsess.draft_save', { labelName: palletData.labelName }));
      onCancel();
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    } finally {
      setIsLoading(false);
    }
  }, [editPalletAPI, labelId, modalFormRef, onCancel, t]);

  const handlePrintSave = useCallback(async () => {
    try {
      setIsSave(true);

      await modalFormRef.current?.validateFields();

      await onAdd();
    } catch (error) {
      setIsSave(false);
    }
  }, [modalFormRef, onAdd]);

  const onFormFinish = useCallback<addSubmitTypeFn>(
    async (formData) => {
      onHeaderFooterFinish?.(formData);
      const palletData = generatePalletData(formData);
      setPrintData(palletData);
      setTimeout(() => handlePrint(), 170);
      try {
        await editPalletAPI?.mutateAsync({
          id: labelId,
          options: {
            body: {
              ...palletData,
              isReadyToPrint: formData.existingSSCC === 'Yes',
              printSetting: formData.printOptions,
            },
          },
        });
        message.success(t?.('succsess.draft_save', { labelName: palletData.labelName }));
        onCancel();
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      } finally {
        setIsSave(false);
      }
    },
    [editPalletAPI, handlePrint, labelId, onCancel, onHeaderFooterFinish, t],
  );

  return (
    <>
      {contextHolder}
      <GCardDrawer
        t={t}
        DrawerProps={{
          ...modal,
          visible: modal ? modal?.visible : true,
          onClose: () => onDrawerClose(),
        }}
        cancelButtonProps={{
          onClick: onSaveAsDraft,
          loading: isLoading,
        }}
        saveButtonProps={{
          onClick: handlePrintSave,
          loading: isSave,
        }}
        rightColSpan="40%"
        leftChildern={
          <BetaSchemaForm<DataItem>
            formRef={modalCardRef}
            layoutType="Form"
            grid
            submitter={{
              render: () => null,
            }}
            columns={AddCardColumns({ handlePrintOption, modalFormRef })}
            //  initialValues={initialValues}
          />
          // <Card title={t('pallet.preview')}>
          //   <BarcodePalletLayout
          //     labelName={modalCardRef.current}
          //     product={formData.product}
          //     sections={formData.sections}
          //     sscc={formData.sscc}
          //     gtin={formData.gtin}
          //     t={t}
          //   />
          // </Card>
        }
        rightChildern={
          <BetaSchemaForm<DataItem>
            formRef={modalFormRef}
            layoutType="Form"
            grid
            onFinish={onFormFinish}
            onValuesChange={onValueChange}
            onFinishFailed={() => printConfirm()}
            submitter={{
              render: () => null,
            }}
            //  initialValues={initialValues}
            columns={AddFormColumns({
              t,
              isEdit: true,
              modalCardRef,
            })}
          />
        }
      />

      <BarcodePalletLayoutPrint
        ref={printRef}
        handlePrintOption={handlePrintOption}
        sections={modalFormRef.current?.getFieldValue('formFieldList') ?? []}
        sscc={printData?.fieldSSCC ?? ''}
        gtin={printData?.gtin}
        t={t}
        printOptions={printSetting}
      />
    </>
  );
};

export default React.memo(AddPallet);
