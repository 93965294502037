import type { ActionType, ProColumns } from '@ant-design/pro-table';
import { Modal, Typography } from 'antd';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Actions, ComingSoon, ShareProduct, SupportModal, TitleBar } from 'components';
import GTable from 'components/GTable';
import { useGTableContext } from 'components/GTable/contexts';
import useModalVisibility, { UseModalVisibilityReturnType } from 'hooks/useModalVisibility';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useAccountSubscription } from 'services/api';
import { featureFlag, supportEmailLink } from 'utils';
import EmptyStateProducts from './EmptyStateProducts';
import styles from './index.module.less';
import { ProductItem, ProductsTitleProps } from './typings';
import useProductActions from './useProductActions';
import useProductFilters from './useProductFilters';

const generalActionItems = (
  hasSelected: boolean,
  t?: TFunction<'pages', 'products'>,
): Array<ItemType> => [
  {
    key: 'add',
    label: t?.('add_product'),
  },
];
const productActionItems = (t?: TFunction<'pages', 'products'>): Array<ItemType> => [
  {
    key: 'view_product',
    label: t?.('view_product'),
  },
  {
    key: 'view_settings',
    label: t?.('view_settings'),
  },
  {
    type: 'group',
    key: 'manage',
    label: t?.('manage'),
    children: [
      // {
      //   key: 'share',
      //   label: t?.('share'),
      // },
      {
        key: 'archive',
        label: t?.('archive'),
      },
    ],
  },
];

type ColumnsType = {
  onRowActionClick: (actionItemKey: string, actionPayload?: ProductItem) => void;
  productSearchfilter: ProColumns;
  itemIdSearchFilter: ProColumns;
  t?: TFunction<'pages', 'products'>;
  supportModal?: UseModalVisibilityReturnType;
};

const columns = ({
  onRowActionClick,
  productSearchfilter,
  itemIdSearchFilter,
  t,
}: ColumnsType): Array<ProColumns<ProductItem>> => [
  {
    dataIndex: 'id',
    hideInTable: true,
  },
  {
    title: t?.('col_item_id'),
    dataIndex: 'externalIdentifier',
    ellipsis: true,
    ...itemIdSearchFilter,
  },
  {
    title: t?.('col_product_name'),
    dataIndex: 'name',
    hideInSetting: true,
    render: (text, record) => (
      <Link className="text-link" to={String(record.id)} title={`View ${text}`}>
        {text}
      </Link>
    ),
    ellipsis: true,
    ...productSearchfilter,
  },
  {
    title: t?.('col_blockchain'),
    dataIndex: 'blockchain',
    valueType: 'checkbox',
    hideInTable: true,
  },
  {
    title: t?.('col_current_inventory'),
    dataIndex: 'currentInventory',
    sorter: true,
    ellipsis: true,
  },
  {
    title: t?.('col_shipped_inventory'),
    dataIndex: 'shippedInventory',
    sorter: true,
    ellipsis: true,
  },
  {
    title: t?.('col_recent_activity'),
    dataIndex: 'recentActivity',
    sorter: true,
    ellipsis: true,
  },
  {
    title: t?.('col_shared_title'),
    dataIndex: 'tags',
    ellipsis: true,
    hideInTable: true,
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    hideInSetting: true,

    render: (text, record) => (
      <Actions
        className="actions"
        buttonProps={{
          ghost: true,
          size: 'small',
        }}
        items={productActionItems(t)}
        onClick={onRowActionClick}
        actionPayload={record}
      />
    ),
  },
];

const ProductsTitle: FC<ProductsTitleProps> = ({
  total,
  onShareProduct,
  onUnselectRow,
  onArchiveProductMultiple,
  supportModal,
  subscription,
  t,
}) => {
  const { selectedRowKeys } = useGTableContext();
  const navigate = useNavigate();

  const onActionItemClick = (actionItemKey: string) => {
    switch (actionItemKey) {
      case 'share':
        onShareProduct?.();

        break;
      case 'archive':
        onArchiveProductMultiple?.(selectedRowKeys);
        onUnselectRow?.();
        break;
      case 'add':
        if (
          featureFlag?.planCheck
            ? total < (subscription?.totalProducts || 0) ||
              !Object.prototype.hasOwnProperty.call(subscription, 'totalProducts')
            : true
        ) {
          navigate('add');
        } else {
          supportModal?.show();
        }

        break;

      default:
        break;
    }
  };
  const title = useMemo(() => t?.('title', { total }), [t, total]);

  const extra = useMemo(
    () =>
      selectedRowKeys.length > 0 && (
        <Typography.Title level={5} className="selectiontext">
          {`${selectedRowKeys.length} 
          ${selectedRowKeys.length === 1 ? t?.('item') : t?.('items')} ${t?.('selected')}`}
        </Typography.Title>
      ),
    [selectedRowKeys?.length, t],
  );

  return (
    <TitleBar
      title={title}
      extra={extra}
      actionItems={generalActionItems(selectedRowKeys.length > 0, t)}
      onActionItemClick={onActionItemClick}
    />
  );
};

/**
 * Component to manage products.
 */
const Products = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'products' });
  const actionRef = useRef<ActionType>();
  const navigate = useNavigate();
  const shareModal = useModalVisibility();
  const shareModalUnfinished = useModalVisibility();
  const { data: account } = useAccountSubscription();
  const subscription = useMemo(() => account?.subscriptionDetails || {}, [account]);
  const supportModal = useModalVisibility(false);
  const [product, setProduct] = useState<ProductItem>();
  const {
    params,
    records,
    netItemCount,
    totalItems,
    isLoading,
    filters: [productSearchfilter, itemIdSearchFilter],
    onTableChange,
    refetch,
  } = useProductFilters();
  const { onArchiveProduct, onArchiveProductMultiple } = useProductActions();

  const onShareProduct = useCallback(
    (data?: ProductItem) => {
      setProduct(data);
      shareModalUnfinished.show();
    },
    [shareModalUnfinished, setProduct],
  );

  const onRowActionClick = (actionItemKey: string, actionPayload?: ProductItem) => {
    switch (actionItemKey) {
      case 'view_product':
        navigate(`${actionPayload?.id}`);
        break;
      case 'view_settings':
        navigate(`../${actionPayload?.id}/settings`);
        break;
      case 'share':
        onShareProduct(actionPayload);
        break;
      case 'archive':
        onArchiveProduct(actionPayload);
        break;

      default:
        break;
    }
  };

  const headerTitle = useMemo(
    () => (
      <ProductsTitle
        total={netItemCount}
        onShareProduct={onShareProduct}
        onUnselectRow={() => actionRef.current?.clearSelected?.()}
        onArchiveProductMultiple={onArchiveProductMultiple}
        t={t}
        supportModal={supportModal}
        subscription={subscription}
      />
    ),
    [netItemCount, onShareProduct, onArchiveProductMultiple, t, supportModal, subscription],
  );

  return (
    <>
      <Modal
        {...shareModalUnfinished}
        title={t('share_product_modal_title')}
        cancelText={t('share_product_cancel_btn')}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ type: 'primary', shape: 'round' }}
      >
        <ComingSoon className={styles.sharemodalbody} />
      </Modal>
      <ShareProduct modal={shareModal} product={product} key={product?.id} />
      <GTable<ProductItem>
        actionRef={actionRef}
        columns={columns({ onRowActionClick, productSearchfilter, itemIdSearchFilter, t })}
        columnsState={{
          defaultValue: {
            blockchain: {
              show: false,
            },
          },
          persistenceKey: 'pages-products',
        }}
        loading={isLoading}
        value={records}
        headerTitle={headerTitle}
        recordCreatorProps={false}
        pagination={{
          defaultPageSize: params.pageSize,
          total: totalItems,
          showTotal: (total, range) =>
            total <= 1
              ? t('product_pagi', { range0: range[0], range1: range[1], total })
              : t('products_pagi', { range0: range[0], range1: range[1], total }),
        }}
        onTableChange={onTableChange}
        scroll={netItemCount > 0 ? { y: '75vh', x: 915 } : undefined}
        emptyContainer={!netItemCount ? <EmptyStateProducts t={t} /> : undefined}
        showHeader={netItemCount > 0}
        options={{
          reload: () => refetch(),
        }}
      />
      <SupportModal
        modal={supportModal}
        description={
          <div>
            {t('upgrade_modal_description')}&nbsp;
            <a href={`mailto:${supportEmailLink}`} type="mail">
              {t('support_anchor_email_title')}
            </a>
            &nbsp;
            {t('upgrade_modal_desc2')}
          </div>
        }
      />
    </>
  );
};

export default React.memo(Products);
