import { Col, Radio, Row, Space, Typography } from 'antd';
import { forwardRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import BarcodeGenerator, { generateGS1String } from '../components/BarcodeGenerator';
import {
  BarcodePalletLayoutProps,
  BarcodeType,
  PrintOptions,
  SectionProps,
} from '../components/typings';
import styles from './index.module.less';

const { Text, Title } = Typography;

export const BarcodePalletLayoutPrint = forwardRef<HTMLDivElement, BarcodePalletLayoutProps>(
  ({ sections, sscc, gtin, t, printOptions, handlePrintOption }, ref) => {
    // Helper function to chunk sections into pairs
    const chunkSections = (items: SectionProps[] | undefined | null, size: number) => {
      if (!items?.length) {
        return [];
      }
      const chunks = [];
      for (let i = 0; i < items.length; i += size) {
        chunks.push(items.slice(i, i + size));
      }
      return chunks;
    };
    const [radioValue, setRadioValue] = useState<PrintOptions>('4x6');

    const { gs1String = '' } =
      generateGS1String({
        barcodeType: 'GS1',
        batchLotID: '',
        bestBeforeDate: undefined,
        brandLogo: '',
        coolInformation: '',
        countryOfOrigin: '',
        harvestMethod: '',
        itemNumber: '',
        labelName: '',
        layoutType: '4x6 Case Label',
        packDate: undefined,
        processedInUSA: 'false',
        product: '',
        quantity: 0,
        sellByDate: undefined,
        sellingUnitUPCA: '',
        supplierInformation: '',
        supplierStockNumber: '',
        uniquePackageID: '',
        unitOfMeasure: 'LBS',
        vendorID: '',
        gtin14: gtin ?? '',
        sscc,
      }) || {};
    // Split sections into rows of 2
    const sectionRows = chunkSections(sections, 2);

    const renderSectionContent = (section: SectionProps) => {
      if (!section?.content) return null;

      if (section.content && section.content !== 'text') {
        return (
          <div className={styles['printable-pallet-only-barcode-wrapper']}>
            <BarcodeGenerator
              value={section.data}
              type={section.content as BarcodeType}
              width={100}
              height={90}
              voicePickCode={null}
              options={{ width: 1, fontSize: 8 }}
              handlePrint={() => {}}
            />
          </div>
        );
      }

      return (
        section &&
        section?.data && (
          <div className={styles.sectionData}>
            {section.data.split('\n').map((line) => (
              <Text key={uuidv4()} className={styles['printable-pallet-only-data-line']}>
                {line}
              </Text>
            ))}
          </div>
        )
      );
    };
    return (
      <>
        <div
          ref={ref}
          className={`${styles['printable-pallet-only-label-container']} print-container`}
        >
          {printOptions === '4x6' && (
            <>
              {/* Dynamic Sections */}
              {sectionRows.map((row) => (
                <Row gutter={[0, 0]} className={styles['printable-pallet-content-section']}>
                  {row.map((section) => (
                    <Col span={24 / row.length}>
                      <div
                        className={styles['printable-pallet-only-section-container']}
                        key={uuidv4()}
                      >
                        <Text strong className={styles['printable-pallet-only-section-title']}>
                          {section?.title}
                        </Text>
                        <div
                          className={styles['printable-pallet-only-section-content']}
                          key={uuidv4()}
                        >
                          {renderSectionContent(section)}
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              ))}

              {/* Barcode Section - Full Width */}
              <Row gutter={[0, 0]} justify="start" align="bottom" />
            </>
          )}
          {printOptions === '4x12' && (
            <>
              {/* Dynamic Sections */}
              {sectionRows.map((row) => (
                <Row gutter={[0, 0]} className={styles['content-section']}>
                  {row.map((section) => (
                    <Col span={12 / row.length} style={{ height: '100%' }}>
                      <div
                        className={styles['printable-pallet-only-section-container']}
                        key={uuidv4()}
                      >
                        <Text strong className={styles['printable-pallet-only-section-title']}>
                          {section?.title}
                        </Text>
                        <div
                          className={styles['printable-pallet-only-section-content']}
                          key={uuidv4()}
                        >
                          {renderSectionContent(section)}
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              ))}

              {/* Barcode Section - Full Width */}
              <Row gutter={[0, 24]} justify="center" align="bottom">
                <Col span={24}>
                  {gtin && (
                    <BarcodeGenerator
                      type="barcode"
                      value={gs1String}
                      options={{
                        format: 'CODE128', // GS1-compatible barcode type
                        ean128: true, // Enable GS1 compatibility
                        text: gs1String, // Add human-readable text below
                        width: 1, // Width of bars
                        height: 120, // Height of the barcode
                        textMargin: 5, // Space between text and barcode
                        fontSize: 10, // Font size of the text
                        margin: 10, // Margin around the barcode
                        displayValue: true, //
                      }}
                      handlePrint={() => {}}
                      voicePickCode=""
                    />
                  )}
                </Col>
              </Row>
            </>
          )}
        </div>

        <Space direction="vertical" size="small">
          <Title level={4}>{t('form_fields.printing_settings')}</Title>
          <Radio.Group
            onChange={(e) => {
              if (handlePrintOption) {
                handlePrintOption(e.target.value as PrintOptions);
                setRadioValue(e.target.value as PrintOptions);
              }
            }}
            value={radioValue}
          >
            <Space direction="vertical">
              <Radio value={'4x6' as PrintOptions}>
                Four into Six Single Sided{' '}
                <Text type="secondary">{t('form_fields.printing_default_option')}</Text>
              </Radio>
              <Radio value={'4x12' as PrintOptions}>
                Four into Twelve Double Sided / Corner Label
              </Radio>
            </Space>
          </Radio.Group>
        </Space>
      </>
    );
  },
);

export default BarcodePalletLayoutPrint;
