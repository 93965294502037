import { FullscreenOutlined, VerticalAlignMiddleOutlined } from '@ant-design/icons';
import { Card, Col, Row, Tooltip } from 'antd';
import { LoadingProgress } from 'components';
import useModalVisibility from 'hooks/useModalVisibility';
import 'pages/Products/Inventory/EventDetails/diagram.css';
import LifecycleHeader from 'pages/Products/Inventory/EventDetails/LifecycleHeader';
import MainDiagram from 'pages/Products/Inventory/EventDetails/MainDiagram';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useEventDiagramById } from 'services';
import { EventResponse } from 'services/api/client/src';
import {
  useShipEventByIdShipments,
  useShipEventByIdShipmentsMutate,
} from 'services/api/newShipments';
import DetailsModal from './DetailsModal/DetailsModal';
import styles from './index.module.less';
import { EventDiagramProps } from './typings';

const SupplyChainDiagram: FC<EventDiagramProps> = ({ lifeCyle }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'products.event_details' });
  const queryClient = useQueryClient();
  const location = useLocation();
  const isInboundShipment = location.pathname?.includes('inbound');
  const [fullscreenView, setFullscreenView] = useState(false);
  const [mainGraph, setMainGraph] = useState<any>(null);
  const [activeEvent, setActiveEvent] = useState<EventResponse>();
  const infoModal = useModalVisibility(false);
  const { shipmentId = '1' } = useParams();
  const [queryParams] = useSearchParams();
  const hideDocument = queryParams?.get('hideDocument') === 'true' || false;
  const getEventCall = useShipEventByIdShipmentsMutate();

  const { data, isLoading } = useEventDiagramById(shipmentId, {
    instanceId: undefined,
    containerId: undefined,
    showAllProducts: true,
    isInboundShipment,
  });
  const { data: eventData, isLoading: isELoading } = useShipEventByIdShipments(
    activeEvent?.id || '',
  );

  const handle = useFullScreenHandle();
  const handleFullscreenChange = (status: boolean) => {
    setFullscreenView(status);
  };
  const onNodeClick = useCallback(
    async (evt: EventResponse) => {
      const eventInfo = await getEventCall?.mutateAsync({
        shipmentIds: evt?.id || '',
      });

      setActiveEvent(eventInfo || evt);
      infoModal.show();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [infoModal, queryClient.getQueryData],
  );

  const alignGraph = () => mainGraph && mainGraph.fitView();

  const [tick, setTick] = useState<number>(0);
  useEffect(() => {
    if (eventData) {
      /* set active event with full data */
      setActiveEvent(eventData);
    }
  }, [eventData]);
  return (
    <>
      <DetailsModal
        modalProps={infoModal}
        activeEvent={activeEvent}
        loading={isELoading}
        hideDocument={hideDocument}
      />

      <LifecycleHeader propsString={lifeCyle} />
      <Card className={styles.diagramcard}>
        <Row justify="end">
          <Col span={22} />
          <Col span={1}>
            <Tooltip title={t('center_diagram_tooltip')} placement="bottom">
              <VerticalAlignMiddleOutlined className={styles.diagramicon} onClick={alignGraph} />
            </Tooltip>
          </Col>
          <Col span={1}>
            <Tooltip title={t('expand_diagram_tooltip')} placement="bottom">
              <FullscreenOutlined onClick={handle.enter} className={styles.diagramicon} />
            </Tooltip>
          </Col>
        </Row>
        <FullScreen handle={handle} onChange={handleFullscreenChange}>
          {tick <= 100 ? (
            <div className={styles.height80}>
              <LoadingProgress
                label={t('fetching')}
                percent={Number(tick) || 0}
                isLoading={isLoading}
                setTick={setTick}
              />
            </div>
          ) : (
            <MainDiagram
              fullscreenView={fullscreenView}
              mainGraph={mainGraph}
              setMainGraph={setMainGraph}
              event={data}
              onNodeClick={onNodeClick}
              isShipment
            />
          )}
        </FullScreen>
      </Card>
    </>
  );
};

export default React.memo(SupplyChainDiagram);
