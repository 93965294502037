import { FullscreenOutlined, VerticalAlignMiddleOutlined } from '@ant-design/icons';
import { Card, Col, Row, Tooltip } from 'antd';
import { LoadingProgress } from 'components';
import useModalVisibility from 'hooks/useModalVisibility';
import { useShipmentStore } from 'pages/Shipments';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useEventById, useEventDiagramById } from 'services/api';
import { EventResponse } from 'services/api/client/src';
import DetailsModal from './DetailsModal/DetailsModal';
import EventFilterLifecycle from './EventFilterLifecycle';
import MainDiagram from './MainDiagram';
import './diagram.css';
import styles from './index.module.less';
import { EventDiagramProps } from './typings';

const SupplyChainDiagram: FC<EventDiagramProps> = ({
  event,
  productInstance,
  product,
  showLifeCycle = true,
  showMinmap = true,
  actionsComponent,
  eventId,
  isContainer,
  primaryId,
  modalWidth,
  isInboundShipment,
  closeAllModal,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'products.event_details' });
  const queryClient = useQueryClient();
  const [fullscreenView, setFullscreenView] = useState(false);
  const [mainGraph, setMainGraph] = useState<any>(null);
  const [activeEvent, setActiveEvent] = useState<EventResponse>();
  const infoModal = useModalVisibility(false);
  const { inventoryItem } = useShipmentStore();

  const [showAllProducts, setShowAllProducts] = useState<boolean>(true);
  const [showErrorDeclarations, setShowErrorDeclarations] = useState<boolean>(false);
  const { data, isLoading } = useEventDiagramById(eventId, {
    isInboundShipment,
    instanceId: !isContainer ? primaryId : undefined,
    ...(isContainer
      ? {
          containerId: primaryId,
          instanceId:
            inventoryItem?.containerItems?.[0]?.instanceId ||
            productInstance?.results?.[0]?.productInstance?.id ||
            undefined,
        }
      : {}),
    showAllProducts,
    showErrorDeclarations,
  });
  const { data: eventData, isLoading: isELoading } = useEventById(activeEvent?.id);

  const handle = useFullScreenHandle();
  const handleFullscreenChange = (status: boolean) => {
    setFullscreenView(status);
  };
  const onNodeClick = useCallback(
    (evt: EventResponse) => {
      const cachedEvent = queryClient.getQueryData<EventResponse | undefined>(['event', evt?.id]);

      setActiveEvent(cachedEvent || evt);
      infoModal.show();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [infoModal, queryClient.getQueryData],
  );

  const alignGraph = () => mainGraph && mainGraph.fitView();

  const [tick, setTick] = useState<number>(0);
  useEffect(() => {
    if (eventData) {
      /* set active event with full data */
      setActiveEvent(eventData);
    }
  }, [eventData]);
  return (
    <>
      <DetailsModal
        modalProps={infoModal}
        event={event}
        activeEvent={activeEvent}
        product={product}
        productInstance={productInstance}
        loading={isELoading}
        modalWidth={modalWidth}
        closeAllModal={closeAllModal}
      />

      <EventFilterLifecycle
        t={t}
        showAllProducts={showAllProducts}
        showErrorDeclarations={showErrorDeclarations}
        setShowAllProducts={setShowAllProducts}
        setShowErrorDeclarations={setShowErrorDeclarations}
        showLifeCycle={showLifeCycle}
      />
      <Card className={styles.diagramcard}>
        {!actionsComponent ? (
          <Row justify="end">
            <Col span={22} />
            <Col span={1}>
              <Tooltip title={t('center_diagram_tooltip')} placement="bottom">
                <VerticalAlignMiddleOutlined className={styles.diagramicon} onClick={alignGraph} />
              </Tooltip>
            </Col>
            <Col span={1}>
              <Tooltip title={t('expand_diagram_tooltip')} placement="bottom">
                <FullscreenOutlined onClick={handle.enter} className={styles.diagramicon} />
              </Tooltip>
            </Col>
          </Row>
        ) : (
          actionsComponent
        )}

        <FullScreen handle={handle} onChange={handleFullscreenChange}>
          {tick <= 100 ? (
            <div className={styles.height80}>
              <LoadingProgress
                label={t('fetching')}
                percent={Number(tick) || 0}
                isLoading={isLoading}
                setTick={setTick}
              />
            </div>
          ) : (
            <MainDiagram
              fullscreenView={fullscreenView}
              mainGraph={mainGraph}
              setMainGraph={setMainGraph}
              event={data}
              onNodeClick={onNodeClick}
              productInstance={productInstance}
              product={product}
              primaryId={primaryId}
              showMinmap={showMinmap}
              productId={product?.id}
              isShipment={false}
            />
          )}
        </FullScreen>
      </Card>
    </>
  );
};

export default React.memo(SupplyChainDiagram);
