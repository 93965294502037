import { Col, Image, message, Radio, Row, Space, Typography } from 'antd';
import wholechainLogo from 'assets/images/wholechainLogoBarcode.png';
import { format } from 'date-fns';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getHideInFormSetting } from '../components/AddLabel.fields';
import BarcodeGenerator, {
  generateGS1String,
  generateVoicePickCode,
} from '../components/BarcodeGenerator';
import {
  BarcodeLabelLayoutProps,
  BarcodePreviewFields,
  countryCodes,
  FIELD_CONFIG,
  PrintOptions,
} from '../components/typings';
import { transformBarcodeType } from '../helper';
import styles from './index.module.less';

const { Title, Text } = Typography;

const ptiFields = [
  'packaging',
  'grownIn',
  'harvestDate',
  'grade',
  'responsibleParty',
  'plu',
  'upc',
];

const chunkSections = (items: BarcodePreviewFields[] | undefined | null, size: number) => {
  if (!items?.length) {
    return [];
  }
  const chunks = [];
  for (let i = 0; i < items.length; i += size) {
    chunks.push(items.slice(i, i + size));
  }
  return chunks;
};
const BarcodeLabelLayout = forwardRef<HTMLDivElement, BarcodeLabelLayoutProps>(
  (
    { data, t, certificationLogo, handlePrintOption, modalFormRef, applicationIdentifierLookup },
    ref,
  ) => {
    const [radioValue, setRadioValue] = useState<PrintOptions>('4x6');

    useEffect(() => {
      setRadioValue((data?.printOptions as PrintOptions) || '4x6');
    }, [data?.printOptions]);

    const { success = false, gs1String = '' } =
      generateGS1String(data, t, applicationIdentifierLookup) || {};
    const invalidBarcodeLength = `${t?.('barcode.invalid_barcode_length')}`;
    const hasVoicePickCode =
      (data?.fieldInfo && getHideInFormSetting(data?.fieldInfo, 'voicePickCode')?.visible) ||
      data?.layoutType === 'PTI';
    const hasWholechainLogo =
      data?.fieldInfo && getHideInFormSetting(data?.fieldInfo, 'wcLogo')?.visible;

    const barcodeType = transformBarcodeType(data);

    const hasLogos = Boolean(data?.brandLogoURL || data?.certLogoURL || data?.logoURL);
    const hasCertOrCompanyLogos = Boolean(data?.certLogoURL || data?.logoURL);
    const isGS1andDataMatrix = data?.barcodeType === 'GS1 and Data Matrix';
    const isPTI = data?.layoutType === 'PTI';

    // generate voice pick code if available
    const voicePickCodeData =
      data?.gtin14 && data?.batchLotID && hasVoicePickCode
        ? generateVoicePickCode(
            data.gtin14,
            data.batchLotID,
            data?.packDate ? format(new Date(data?.packDate), 'yyMMdd') : null,
          )
        : null;

    // Determine column span based on presence of logos
    const colSpan = hasLogos ? 6 : 4; // 4 columns (span 6) or 5 columns (span 4)

    // Determine UPC data and requirements
    const getUPCField = () => {
      let field = data?.newFieldInfo?.find(
        (c) => c.dataIndex?.toLocaleLowerCase().trim() === 'upc',
      )?.value;
      if (data && 'upc' in data) {
        field = (data.upc as string | undefined) ?? '';
      }
      if (isPTI && field) {
        return field;
      }
      return null;
    };
    const UPCField = getUPCField();
    const isValidUPC = (UPCField?.length ?? 0) === 12;

    // Helper function to render field value
    const renderFieldValue = (
      value: string | undefined,
      isRequired: boolean,
      key: string | undefined,
    ) => {
      if (!value && !isRequired) {
        return null;
      }
      let fieldValue = null;
      const validKey = key?.split('-')[0] ?? '';
      const isValidDate = ['bestBeforeDate', 'packDate', 'sellByDate'].some((c) => c === validKey);
      if (value && isValidDate) {
        fieldValue = format(new Date(value), 'yyyy-MM-dd');
      } else {
        fieldValue = value;
      }
      if (['countryOfOrigin'].some((c) => c === validKey)) {
        fieldValue = countryCodes.find((c) => c.value === value)?.label ?? '';
      }
      return <div className={styles['field-value']}>{fieldValue?.toLocaleUpperCase() || '-'}</div>;
    };

    // Filter and sort fields to display based on visibility and if it's required
    const fieldsToDisplayData = useMemo(() => {
      // Process standard fields from fieldInfo
      const standardFields = (data?.fieldInfo || [])
        .filter((field) => {
          // Show only specific fields for PTI layout
          if (data?.layoutType === 'PTI') {
            return [
              'quantity',
              'unitOfMeasure',
              'packaging',
              'packDate',
              'grownIn',
              'grade',
              'responsibleParty',
              'plu',
              'gtin14',
              'batchLotID',
            ].includes(field.dataIndex);
          }
          return field.visible && !field.isOnlyLabel && !ptiFields.includes(field.dataIndex);
        })
        .map((field, index) => {
          const value = field.dataIndex ? data?.[field.dataIndex as keyof typeof data] : undefined;
          const isRequired = field.required;
          const shouldDisplay =
            isRequired || (value !== undefined && value !== null && value !== '');

          if (!shouldDisplay) return null;

          const fieldConfig = FIELD_CONFIG.find((f) => f.key === field.dataIndex);
          const order = fieldConfig?.order ?? FIELD_CONFIG.length + 1;

          return {
            key: `${field.dataIndex}-${index}`,
            title: field.title,
            required: isRequired,
            value: value ?? '-',
            isCustom: false,
            order,
          };
        })
        .filter(
          (
            field,
          ): field is {
            key: string;
            title: string;
            required: boolean;
            value: any;
            isCustom: boolean;
            order: number;
          } => field !== null,
        );
      // Process custom fields from newFieldInfo
      const customFields = (data?.newFieldInfo || [])
        .filter((field) => field.visible)
        .map((field, index) => {
          const isRequired = field.required;
          const { value } = field;
          const shouldDisplay =
            isRequired || (value !== undefined && value !== null && value !== '');

          if (!shouldDisplay) return null;
          return {
            key: `custom-${field.dataIndex}-${index}`,
            title: field.title,
            required: isRequired,
            value: value ?? '-',
            isCustom: true,
            order: FIELD_CONFIG.length + 1,
          };
        })
        .filter(
          (
            field,
          ): field is {
            key: string;
            title: string;
            required: boolean;
            value: any;
            isCustom: boolean;
            order: number;
          } => field !== null,
        );
      // Combine and sort
      return [...standardFields, ...customFields].sort((a, b) => a.order - b.order);
    }, [data]);

    // Render field
    const renderField = (field: any) => (
      <Col span={colSpan} className={styles.fieldContainer} key={field.key}>
        <Text className={styles.fieldLabel}>{field.title}</Text>
        {renderFieldValue(field.value?.toString(), field.required, field.key)}
      </Col>
    );

    const renderPrintingOptionLayout = () =>
      !isPTI && (
        <div className={styles['case-label-wrapper-printing-settings']}>
          <div className={styles['case-label-container']}>
            <div className={styles['case-label-content-printing-settings']}>
              <div className={styles['case-label-content-header']}>
                <Space direction="vertical" size="small">
                  <Title level={4}>{t('form_fields.printing_settings')}</Title>
                  <Radio.Group
                    onChange={(e) => {
                      if (handlePrintOption) {
                        handlePrintOption(e.target.value as PrintOptions);
                        setRadioValue(e.target.value as PrintOptions);
                        modalFormRef?.current?.setFieldsValue({
                          printOptions: e.target.value,
                        });
                      }
                    }}
                    value={radioValue}
                  >
                    <Space direction="vertical">
                      <Radio value={'4x6' as PrintOptions}>
                        {t('form_fields.four_into_six_single_sided')}{' '}
                        <Text type="secondary">{t('form_fields.printing_default_option')}</Text>
                      </Radio>
                      <Radio value={'4x12' as PrintOptions}>
                        {t('form_fields.four_into_twelve_double_sided')}
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Space>
              </div>
            </div>
          </div>
        </div>
      );

    const isValidPTICode = isPTI && UPCField && isValidUPC;

    const rowsOfFields = chunkSections(fieldsToDisplayData, 5);

    const renderLayout = () => {
      // If not layout selected return an empty layout
      if (!data?.layoutType) {
        return (
          <>
            <div className={styles['case-label-wrapper']}>
              <div className={styles['case-label-container']}>
                <div className={styles['case-label-content']}>
                  <div className={styles['case-label-content-header']} />
                  <div className={styles['case-label-content-body']}>
                    <div className={styles['case-label-content-body-empty']}>
                      <Text type="secondary">{t('empty_case_label_message')}</Text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {renderPrintingOptionLayout()}
          </>
        );
      }

      if (data.layoutType === 'PTI') {
        return (
          <div ref={ref} className={styles['case-pti-label-wrapper']}>
            <div className={styles['case-pti-label-container']}>
              <div className={styles['case-label-content']}>
                {/* Header Section */}
                <div className={styles['case-pti-label-content-header']}>
                  <Row gutter={2}>
                    {data?.brandLogoURL && (
                      <Col span={4}>
                        <div className={styles['case-label-brand-logo']}>
                          <Image
                            width={116}
                            height={116}
                            src={data?.brandLogoURL}
                            preview={false}
                          />
                        </div>
                      </Col>
                    )}
                    <Col span={data?.brandLogoURL ? 16 : 20}>
                      <Title level={2} className={styles['case-label-product-name']}>
                        {data?.productName ?? ''}
                      </Title>
                      {data?.productDescription && (
                        <Title level={5} className={styles['case-label-product-description']}>
                          {data?.fieldInfo?.find((c) => c.dataIndex === 'productDescription')
                            ?.visible && data?.productDescription}
                        </Title>
                      )}
                      {data?.processedInUSA && (
                        <Text
                          type="secondary"
                          className={styles['case-label-product-description-processed-usa']}
                        >
                          {t('form_fields.processed_in_usa')} {' - '}
                          {data.usStateOfProcessing}
                        </Text>
                      )}
                    </Col>
                    {hasCertOrCompanyLogos && (
                      <Col span={4}>
                        <Space
                          direction="vertical"
                          size={16}
                          className={styles['case-label-logos-container']}
                        >
                          {certificationLogo && (
                            <div className={styles['case-label-certification-logo']}>
                              <Image
                                width={116}
                                height={116}
                                src={certificationLogo}
                                preview={false}
                              />
                            </div>
                          )}
                          {data?.logoURL && (
                            <div className={styles['case-label-logo-url']}>
                              <Image width={80} height={80} src={data?.logoURL} preview={false} />
                            </div>
                          )}
                        </Space>
                      </Col>
                    )}
                  </Row>
                </div>

                {/* Fields Grid */}
                {/* <div className={styles['case-label-content-body']}> */}
                {/*   <Row gutter={[0, 7]} className={styles['fields-grid']}> */}
                {/*     {fieldsToDisplayData.map(renderField)} */}
                {/*   </Row> */}
                {/* </div> */}
                <div className={styles['case-label-content-body']}>
                  {rowsOfFields.map((rowFields) => (
                    <Row gutter={[0, 5]} className={styles['fields-grid']} key={uuidv4()}>
                      {rowFields.map(renderField)}
                    </Row>
                  ))}
                </div>

                {/* Barcode and voice pick code section */}
                <Row gutter={[7, 0]} align="bottom">
                  <Col span={barcodeType === 'gs1-datamatrix' ? 24 : 18}>
                    <div
                      className={
                        gs1String
                          ? styles['case-label-card-barcode-container']
                          : styles['case-label-card-barcode-container-empty']
                      }
                    >
                      {!gs1String && (
                        <Text type="secondary">{t('empty_case_label_barcode_selection_text')}</Text>
                      )}
                      {gs1String && (
                        <BarcodeGenerator
                          value={gs1String}
                          options={{
                            format: 'CODE128', // GS1-compatible barcode type
                            ean128: true, // Enable GS1 compatibility
                            text: gs1String, // Add human-readable text below
                            width: 1.6, // Width of bars
                            height: 128, // Height of the barcode
                            textMargin: 5, // Space between text and barcode
                            fontSize: 12, // Font size of the text
                            margin: 10, // Margin around the barcode
                            displayValue: true, //
                          }}
                          handlePrint={() => {}}
                          voicePickCode={data?.voicePickCode}
                          type={barcodeType}
                          height={128} // height for datamatrix (if selected)
                          typeOption="case"
                        />
                      )}
                    </div>
                  </Col>
                  {(barcodeType === 'barcode' || barcodeType === 'datamatrix') && (
                    <Col span={6}>
                      <div className={styles['case-pti-label-voice-pick-code-container']}>
                        {isValidPTICode && (
                          <BarcodeGenerator
                            value={UPCField}
                            options={{
                              format: 'upc',
                              width: 1.1,
                              height: 50,
                              displayValue: true,
                              font: 'monospace',
                              textAlign: 'center',
                              textPosition: 'bottom',
                              textMargin: 2,
                              fontSize: 12,
                              background: '#ffffff',
                              lineColor: '#000000',
                              margin: 10,
                            }}
                            type="barcode"
                            handlePrint={() => {}}
                            typeOption="case"
                          />
                        )}

                        {!isGS1andDataMatrix && hasVoicePickCode && (
                          <div className={styles['case-pti-label-card-voice-pick-code']}>
                            <Text className={styles['case-pti-label-card-voice-pick-code-text']}>
                              {voicePickCodeData?.toString().substring(0, 2)}
                              <span
                                className={
                                  styles['case-pti-label-card-voice-pick-code-text-special-digit']
                                }
                              >
                                {voicePickCodeData?.toString().substring(2, 4)}
                              </span>
                            </Text>
                          </div>
                        )}
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            </div>
          </div>
        );
      }
      return (
        <>
          <div ref={ref} className={styles['case-label-wrapper']}>
            <div className={styles['case-label-container']}>
              <div className={styles['case-label-content']}>
                {/* Header Section */}
                <div className={styles['case-label-content-header']}>
                  <Row gutter={2}>
                    {data?.brandLogoURL && (
                      <Col span={4}>
                        <div className={styles['case-label-brand-logo']}>
                          <Image
                            width={116}
                            height={116}
                            src={data?.brandLogoURL}
                            preview={false}
                          />
                        </div>
                      </Col>
                    )}
                    <Col span={data?.brandLogoURL ? 16 : 20}>
                      <Title level={2} className={styles['case-label-product-name']}>
                        {data?.productName ?? ''}
                      </Title>
                      {data?.productDescription && (
                        <Title level={5} className={styles['case-label-product-description']}>
                          {data?.fieldInfo?.find((c) => c.dataIndex === 'productDescription')
                            ?.visible && data?.productDescription}
                        </Title>
                      )}
                      {data?.processedInUSA && (
                        <Text
                          type="secondary"
                          className={styles['case-label-product-description-processed-usa']}
                        >
                          {t('form_fields.processed_in_usa')} {' - '}
                          {data.usStateOfProcessing}
                        </Text>
                      )}
                    </Col>
                    {hasCertOrCompanyLogos && (
                      <Col span={4}>
                        <Space
                          direction="vertical"
                          size={16}
                          className={styles['case-label-logos-container']}
                        >
                          {certificationLogo && (
                            <div className={styles['case-label-certification-logo']}>
                              <Image
                                width={116}
                                height={116}
                                src={certificationLogo}
                                preview={false}
                              />
                            </div>
                          )}
                          {data?.logoURL && (
                            <div className={styles['case-label-logo-url']}>
                              <Image width={80} height={80} src={data?.logoURL} preview={false} />
                            </div>
                          )}
                        </Space>
                      </Col>
                    )}
                  </Row>
                </div>

                {/* Fields Grid */}
                <div className={styles['case-label-content-body']}>
                  <Row gutter={[0, 7]} className={styles['fields-grid']}>
                    {fieldsToDisplayData.map(renderField)}
                  </Row>
                </div>

                {/* Barcode and voice pick code section */}
                <Row gutter={[7, 0]} align="bottom">
                  <Col span={barcodeType === 'gs1-datamatrix' ? 24 : 18}>
                    <div
                      className={
                        gs1String
                          ? styles['case-label-card-barcode-container']
                          : styles['case-label-card-barcode-container-empty']
                      }
                    >
                      {!gs1String && (
                        <Text type="secondary">{t('empty_case_label_barcode_selection_text')}</Text>
                      )}
                      {gs1String && (
                        <BarcodeGenerator
                          value={gs1String}
                          options={{
                            format: 'CODE128', // GS1-compatible barcode type
                            ean128: true, // Enable GS1 compatibility
                            text: gs1String, // Add human-readable text below
                            width: barcodeType === 'gs1-datamatrix' ? 1.5 : 2, // Width of bars
                            height: 128, // Height of the barcode
                            textMargin: 5, // Space between text and barcode
                            fontSize: 12, // Font size of the text
                            margin: 10, // Margin around the barcode
                            displayValue: true, //
                          }}
                          handlePrint={() => {}}
                          voicePickCode={data?.voicePickCode}
                          type={barcodeType}
                          height={128} // height for datamatrix (if selected)
                          typeOption="case"
                        />
                      )}
                    </div>
                  </Col>
                  {(barcodeType === 'barcode' || barcodeType === 'datamatrix') && (
                    <Col span={5}>
                      <div className={styles['case-label-voice-pick-code-container']}>
                        {!isGS1andDataMatrix && !isPTI && hasWholechainLogo && (
                          <div className={styles['case-label-wholechain-logo']}>
                            <img src={wholechainLogo} alt="wholechain" />
                          </div>
                        )}

                        {isPTI && UPCField && isValidUPC && (
                          <BarcodeGenerator
                            value={UPCField}
                            options={{
                              format: 'upc',
                              width: 2,
                              height: 100,
                              displayValue: true,
                              font: 'monospace',
                              textAlign: 'center',
                              textPosition: 'bottom',
                              textMargin: 2,
                              fontSize: 12,
                              background: '#ffffff',
                              lineColor: '#000000',
                              margin: 10,
                            }}
                            type="barcode"
                            handlePrint={() => {}}
                          />
                        )}

                        {!isGS1andDataMatrix && hasVoicePickCode && (
                          <div className={styles['case-label-card-voice-pick-code']}>
                            <Text className={styles['case-label-card-voice-pick-code-text']}>
                              {voicePickCodeData?.toString().substring(0, 2)}
                              <span
                                className={
                                  styles['case-label-card-voice-pick-code-text-special-digit']
                                }
                              >
                                {voicePickCodeData?.toString().substring(2, 4)}
                              </span>
                            </Text>
                          </div>
                        )}
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            </div>
          </div>
          {renderPrintingOptionLayout()}
        </>
      );
    };

    // Show notification if the GS1 string is invalid
    useEffect(() => {
      if (!success && gs1String) {
        message.warning({
          content: invalidBarcodeLength,
          duration: 5,
        });
      }
      if (isPTI) {
        handlePrintOption?.('4x2' as PrintOptions);
      }
    }, [success, gs1String, invalidBarcodeLength, isPTI, handlePrintOption]);
    return renderLayout();
  },
);
export default BarcodeLabelLayout;
