import {
  BarcodeOutlined,
  ContactsOutlined,
  ContainerOutlined,
  DashboardOutlined,
  FileDoneOutlined,
  ScanOutlined,
  SwapOutlined,
  TagsOutlined,
} from '@ant-design/icons';
import { MenuDataItem } from '@ant-design/pro-layout';
import { TFunction } from 'i18next';
import { featureFlag } from 'utils';

export type ActionType = {
  t: TFunction<'pages', 'drawer_menu'>;
};

const menuData = ({ t }: ActionType): MenuDataItem[] =>
  [
    {
      path: '/',
      name: t('dashboard') || 'Dashboard',
      icon: <DashboardOutlined />,
      exact: true,
    },
    {
      path: '/products',
      name: t('products') || 'Products',
      icon: <TagsOutlined />,
    },
    {
      path: '/shipments',
      name: t('shipments') || 'Shipments',
      icon: <SwapOutlined />,
      hideChildrenInMenu: false,
      ...(featureFlag?.newShipment && {
        children: [
          {
            name: t('inbound') || 'Inbound',
            path: '/shipments/inbound',
          },
          {
            name: t('outbound') || 'Outbound',
            path: '/shipments/outbound',
          },
        ],
      }),
    },

    {
      path: '/templates',
      name: t('templates') || 'Templates',
      icon: <FileDoneOutlined />,
    },
    {
      path: '/documents',
      name: t('documents') || 'Documents',
      icon: <ContainerOutlined />,
    },
    {
      path: '/network',
      name: t('network') || 'Network',
      icon: <ContactsOutlined />,
    },
    {
      path: '/reports',
      name: t('reports') || 'Reports',
      icon: <ScanOutlined />,
    },
  ].concat(
    !featureFlag?.hideLabels
      ? [
          {
            path: '/labels',
            name: t('labels') || 'Labels',
            icon: <BarcodeOutlined />,
          },
        ]
      : [],
  );

export default menuData;
