import { Select } from 'antd';
import { FC, useMemo } from 'react';
import { countryCodes, GS1CountrySelectProps, states, StateSelectProps } from './typings';

const StateSelect: FC<StateSelectProps> = (props) => {
  const options = useMemo(() => states, []);

  return <Select showSearch optionFilterProp="label" options={options} {...props} />;
};

export default StateSelect;

// Country code to numeric mapping (ISO 3166-1 numeric codes)
export const GS1CountryCodeList: FC<GS1CountrySelectProps> = (props) => {
  const options = useMemo(() => countryCodes, []);

  return <Select showSearch optionFilterProp="label" options={options} {...props} />;
};
