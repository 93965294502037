import { ProFormInstance } from '@ant-design/pro-form';
import { Button, ColProps, Input, message, RowProps, Space, Typography } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import UploadCropImage from 'components/UploadImageCropper';
import { ChangeEvent, MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { InventoryResponse, ProductResponse } from 'services/api/client/src';
import { ProductLotSelect } from '../../../components';
import ProductSelect from '../../../components/GSelect/ProductSelect';
import BarcodeLabelLayout from '../LabelForm/BarcodeLabelLayout';
import StateSelect, { GS1CountryCodeList } from './StateSelect';
import { FieldItem, LabelColumnFnExtended, LabelColumnsFn } from './typings';

const rowProps: RowProps = { gutter: [32, 8] };
const colProps12: ColProps = { xs: 24, sm: 24, md: 12, lg: 12 };
// const colProps6: ColProps = { xs: 24, sm: 24, md: 6, lg: 6 };
const colProps24: ColProps = { xs: 24, sm: 24, md: 24, lg: 24 };
const hiddenColProps: ColProps = { xs: 0, sm: 0, md: 0, lg: 0 };

export const getHideInFormSetting = (fieldInfo: FieldItem[], dataIndex: string) =>
  fieldInfo?.find?.((item: FieldItem) => item?.dataIndex === dataIndex, []);

// Helper function to validate lot ID characters
const validateLotID = (value: string, t?: (key: string) => string) => {
  const validLotID = /^[A-Z0-9_-]+$/i.test(value);

  if (!validLotID) {
    return {
      isValid: false,
      message: `${t?.('invalid_lot_should_only_contain')}`,
    };
  }

  return { isValid: true };
};

const handleExistingLotIdChange = (
  value: string,
  form: any,
  modalCardRef: MutableRefObject<ProFormInstance<any> | undefined> | undefined,
) => {
  if (value === 'No') {
    modalCardRef?.current?.setFieldsValue({ batchLotID: '' });
    form?.setFieldsValue({ batchLotID: undefined });

    // Reset validation errors for batchLotID
    form?.setFields([
      {
        name: 'batchLotID',
        errors: [],
      },
    ]);
  }
};

export const AddCardColumns: LabelColumnFnExtended = ({
  handlePrintOption,
  modalFormRef,
  applicationIdentifierLookup,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'labels.add_label' });
  return [
    {
      valueType: 'group',
      colProps: colProps24,
      rowProps,
      columns: [
        {
          valueType: 'select',
          dataIndex: 'profileSelect',
          hideInForm: true,
          title: t?.('profile_select_col'),
          valueEnum: {
            sample: {
              text: t('sample'),
              status: 'Default',
            },
          },
        },
        {
          valueType: 'group',
          colProps: colProps24,
          columns: [
            {
              valueType: 'dependency',
              fieldProps: {
                name: [
                  'product',
                  'productDescription',
                  'processedInUSA',
                  'usStateOfProcessing',
                  'gtin14',
                  'quantity',
                  'itemNumber',
                  'vendorID',
                  'coolInformation',
                  'supplierInformation',
                  'supplierStockNumber',
                  'sellingUnitUPCA',
                  'uniquePackageID',
                  'harvestMethod',
                  'newFieldInfo',
                  'newBarcodeFieldInfo',
                  'fieldInfo',
                  'logo',
                  'certificationLogo',
                  'logoURL',
                  'certLogoURL',
                  'brandLogo',
                  'brandLogoURL',
                  'productName',
                  'barcodeType',
                  'unitOfMeasure',
                  'countryOfOrigin',
                  'layoutType',
                  'labelName',
                  'batchLotID',
                  'packDate',
                  'sellByDate',
                  'bestBeforeDate',
                  'printOptions',
                  'wholechainLogo',
                  'voicePickCode',
                  'packaging',
                  'grownIn',
                  'grade',
                  'responsibleParty',
                  'plu',
                  'upc',
                  'harvestDate',
                ],
              },
              columns: ({
                gtin14,
                product,
                productDescription,
                processedInUSA,
                usStateOfProcessing,
                quantity,
                itemNumber,
                vendorID,
                coolInformation,
                supplierInformation,
                supplierStockNumber,
                sellingUnitUPCA,
                uniquePackageID,
                harvestMethod,
                newFieldInfo,
                newBarcodeFieldInfo,
                fieldInfo,
                logo,
                certificationLogo,
                logoURL,
                certLogoURL,
                brandLogo,
                brandLogoURL,
                productName,
                barcodeType,
                unitOfMeasure,
                countryOfOrigin,
                layoutType,
                labelName,
                batchLotID,
                packDate,
                sellByDate,
                bestBeforeDate,
                printOptions,
                wholechainLogo,
                voicePickCode,
                packaging,
                grownIn,
                grade,
                responsibleParty,
                plu,
                upc,
                harvestDate,
              }) => [
                {
                  title: () => null,
                  dataIndex: 'previewCard',
                  renderFormItem: () => {
                    const formData = {
                      product,
                      productDescription,
                      usStateOfProcessing,
                      processedInUSA,
                      gtin14,
                      quantity,
                      itemNumber,
                      vendorID,
                      coolInformation,
                      supplierInformation,
                      supplierStockNumber,
                      sellingUnitUPCA,
                      uniquePackageID,
                      harvestMethod,
                      newFieldInfo,
                      newBarcodeFieldInfo,
                      fieldInfo,
                      logoURL,
                      certLogoURL,
                      logo,
                      certificationLogo,
                      brandLogo,
                      brandLogoURL,
                      productName,
                      barcodeType,
                      unitOfMeasure,
                      countryOfOrigin,
                      layoutType,
                      labelName,
                      batchLotID,
                      packDate,
                      sellByDate,
                      bestBeforeDate,
                      printOptions,
                      wholechainLogo,
                      voicePickCode,
                      packaging,
                      grownIn,
                      grade,
                      responsibleParty,
                      plu,
                      upc,
                      harvestDate,
                    };

                    return (
                      <BarcodeLabelLayout
                        applicationIdentifierLookup={applicationIdentifierLookup}
                        data={{ ...formData }}
                        handlePrintOption={handlePrintOption}
                        t={t}
                        brandLogo={brandLogoURL}
                        certificationLogo={certLogoURL}
                        companyLogo={null}
                        modalFormRef={modalFormRef}
                      />
                    );
                    // <PreviewCard formFieldList={formFieldList} gtin={gtin} />
                  },
                },
              ],
            },
            {
              dataIndex: 'productName',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: hiddenColProps,
            },
            {
              dataIndex: 'usStateOfProcessing',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: hiddenColProps,
            },
            {
              dataIndex: 'product',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: hiddenColProps,
            },
            {
              dataIndex: 'gtin14',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: hiddenColProps,
            },
            {
              dataIndex: 'harvestMethod',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: hiddenColProps,
            },
            {
              dataIndex: 'itemNumber',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: hiddenColProps,
            },
            {
              dataIndex: 'coolInformation',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: hiddenColProps,
            },
            {
              dataIndex: 'vendorID',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: hiddenColProps,
            },
            {
              dataIndex: 'supplierInformation',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: hiddenColProps,
            },
            {
              dataIndex: 'supplierStockNumber',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: hiddenColProps,
            },
            {
              dataIndex: 'sellingUnitUPCA',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: hiddenColProps,
            },
            {
              dataIndex: 'uniquePackageID',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: hiddenColProps,
            },
            {
              dataIndex: 'newFieldInfo',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: hiddenColProps,
            },
            {
              dataIndex: 'fieldInfo',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: hiddenColProps,
            },
            {
              dataIndex: 'certificationLogo',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: hiddenColProps,
            },
            {
              dataIndex: 'logo',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: hiddenColProps,
            },
            {
              dataIndex: 'certLogoURL',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
            },
            {
              dataIndex: 'logoURL',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
            },
            {
              dataIndex: 'brandLogo',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: hiddenColProps,
            },
            {
              dataIndex: 'brandLogoURL',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
            },
            {
              dataIndex: 'barcodeType',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
            },
            {
              dataIndex: 'unitOfMeasure',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
            },
            {
              dataIndex: 'countryOfOrigin',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
            },
            {
              dataIndex: 'layoutType',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
            },
            {
              dataIndex: 'labelName',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
            },
            {
              dataIndex: 'batchLotID',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
            },
            {
              dataIndex: 'packDate',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
            },
            {
              dataIndex: 'sellByDate',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
            },
            {
              dataIndex: 'bestBeforeDate',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
            },
            {
              dataIndex: 'printOptions',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
            },
            {
              dataIndex: 'voicePickCode',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
            },
            {
              dataIndex: 'packaging',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
            },
            {
              dataIndex: 'grownIn',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
            },
            {
              dataIndex: 'grade',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
            },
            {
              dataIndex: 'responsibleParty',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
            },
            {
              dataIndex: 'plu',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
            },
            {
              dataIndex: 'upc',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
            },
            {
              dataIndex: 'harvestDate',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
            },
          ],
        },
      ],
    },
  ];
};

export const AddFormColumns: LabelColumnsFn = ({
  onBarcodeEdit,
  onLabelEdit,
  modalCardRef,
  isEdit,
  modalFormRef,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'labels.add_label' });
  return [
    {
      valueType: 'group',
      colProps: colProps24,
      rowProps,
      columns: [
        {
          valueType: 'group',
          colProps: colProps24,
          title: t?.('header_label'),
          rowProps,
          columns: [
            {
              valueType: 'dependency',
              fieldProps: {
                name: ['logoURL', 'certLogoURL', 'description', 'layoutType'],
              },
              columns: ({ layoutType }) => [
                {
                  title: '',
                  valueType: 'text',
                  colProps: isEdit ? hiddenColProps : colProps24,
                  renderFormItem: () => (
                    <Typography.Text>{t?.('label_instructions')}</Typography.Text>
                  ),
                },
                {
                  title: t('label_name'),
                  dataIndex: 'labelName',
                  valueType: 'text',
                  colProps: colProps24,
                  fieldProps: {
                    placeholder: t('label_name'),
                  },
                  formItemProps: {
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: t?.('form_fields.required_message', {
                          fieldName: t?.('label_name'),
                        }),
                      },
                      {
                        validator: (_, value) => {
                          const emojiRegex =
                            /([\u203C-\u3299]|\uD83C[\uD000-\uDFFF]|\uD83D[\uD000-\uDFFF]|\uD83E[\uD000-\uDFFF])/;
                          if (value && emojiRegex.test(value)) {
                            return Promise.reject(
                              t?.('form_fields.required_message', {
                                fieldName: t?.('label_name'),
                              }),
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ],
                  },
                },
                {
                  title: t('layout'),
                  dataIndex: 'layoutType',
                  valueType: 'select',
                  colProps: isEdit ? hiddenColProps : colProps24,
                  valueEnum: {
                    'Four into Six Case Label': t('form_fields.four_into_six_case_label'),
                    'Four into Six Publix': t('form_fields.four_into_six_case_publix'),
                    PTI: t('form_fields.four_by_two_pti_case_label'),
                  },

                  formItemProps: {
                    rules: [
                      {
                        required: true,
                        message: t?.('form_fields.required_message', {
                          fieldName: t?.('layout'),
                        }),
                      },
                    ],
                  },
                  fieldProps: (form) => ({
                    onChange: (value: any) => {
                      // Set default barcode type for PTI layout
                      if (value === 'PTI') {
                        form.setFieldsValue({
                          layoutType: value,
                          barcodeType: 'GS1 Barcode',
                        });
                      }
                      if (value === 'Four into Six Publix') {
                        form.setFieldsValue({
                          layoutType: value,
                          barcodeType: 'Data Matrix',
                        });
                      }
                    },
                    placeholder: t('layout'),
                  }),
                },
                {
                  title: t('product'),
                  dataIndex: 'product',
                  colProps: isEdit ? hiddenColProps : colProps24,
                  formItemProps: {
                    rules: [
                      {
                        required: true,
                        message: t?.('form_fields.required_message', {
                          fieldName: t?.('product'),
                        }),
                      },
                    ],
                  },
                  renderFormItem: (_row, record, form) => (
                    <ProductSelect
                      placeholder={t('product')}
                      onChange={(value, option) => {
                        const product = (option as DefaultOptionType)?.itemProps as ProductResponse;
                        const fieldVals = {
                          productId: product?.id,
                          product: product?.id,
                          productName: product?.name,
                          productDescription: product?.description,
                          quantity: product?.unitQuantity,
                          unitOfMeasure: product?.simpleUnitOfMeasurement,
                          batchLotID: undefined,
                          gtin14: product?.gtin || '',
                        };
                        form?.setFieldsValue(fieldVals);
                        modalCardRef?.current?.setFieldsValue(fieldVals);
                      }}
                    />
                  ),
                },
                {
                  valueType: 'group',
                  title: () => (
                    <Space direction="horizontal">
                      <Typography.Text>{t?.('information_header')}</Typography.Text>
                      {layoutType === 'Four into Six Case Label' && (
                        <Button
                          size="small"
                          type="primary"
                          shape="round"
                          onClick={() => onLabelEdit?.()}
                        >
                          {t?.('form_fields.edit')}
                        </Button>
                      )}
                    </Space>
                  ),
                  columns: [
                    {
                      valueType: 'dependency',
                      fieldProps: {
                        name: [
                          'fieldInfo',
                          'certLogoURL',
                          'description',
                          'processedInUSA',
                          'brandLogoURL',
                          'productName',
                          'productDescription',
                          'unitOfMeasure',
                          'logoURL',
                          'layoutType',
                        ],
                      },
                      columns: ({
                        fieldInfo,
                        logoURL,
                        certLogoURL,
                        processedInUSA,
                        brandLogoURL,
                        productName,
                        productDescription,
                        unitOfMeasure,
                      }) => [
                        {
                          title: t?.('form_fields.brand_logo_col'), // Brand Logo
                          dataIndex: 'brandLogo',
                          formItemProps: {
                            rules: [
                              {
                                required:
                                  getHideInFormSetting(fieldInfo, 'brandLogo')?.required === true ||
                                  false,
                                message: t?.('form_fields.required_message', {
                                  fieldName: t?.('form_fields.brand_logo_col'),
                                }),
                              },
                            ],
                            hasFeedback: false,
                          },
                          renderFormItem: (_schema, _config, form) => (
                            <UploadCropImage
                              title={t?.('form_fields.brand_logo_col')}
                              form={form}
                              formKey="brandLogo"
                              width={200}
                              height={200}
                              initialURL={brandLogoURL}
                              initialObj={form?.getFieldValue('brandLogo')}
                              getImageURL={(url) => {
                                form.setFieldsValue({
                                  brandLogoURL: url,
                                });
                                const brandLogo = form?.getFieldValue('brandLogo');
                                modalCardRef?.current?.setFieldsValue({
                                  brandLogoURL: url,
                                  brandLogo,
                                });
                              }}
                            />
                          ),
                          colProps: colProps12,
                          hideInForm:
                            layoutType === 'PTI' ||
                            getHideInFormSetting(fieldInfo, 'brandLogo')?.visible === false ||
                            false,
                        },
                        {
                          title: t?.('background_col'), // Certification Logo
                          dataIndex: 'certificationLogo',
                          formItemProps: {
                            rules: [
                              {
                                required:
                                  getHideInFormSetting(fieldInfo, 'certificationLogo')?.required ===
                                    true || false,
                                message: t?.('form_fields.required_message', {
                                  fieldName: t?.('background_col'),
                                }),
                              },
                            ],
                            hasFeedback: false,
                          },
                          renderFormItem: (_schema, _config, form) => (
                            <UploadCropImage
                              title={t?.('background_col')}
                              form={form}
                              height={200}
                              width={200}
                              formKey="certificationLogo"
                              initialURL={certLogoURL}
                              initialObj={form?.getFieldValue('certificationLogo')}
                              getImageURL={(url) => {
                                form.setFieldsValue({
                                  certLogoURL: url,
                                });
                                const logo = form?.getFieldValue('certificationLogo');
                                modalCardRef?.current?.setFieldsValue({
                                  certLogoURL: url,
                                  certificationLogo: logo,
                                });
                              }}
                            />
                          ),
                          hideInForm:
                            layoutType === 'PTI' ||
                            getHideInFormSetting(fieldInfo, 'certificationLogo')?.visible ===
                              false ||
                            false,
                          colProps: colProps12,
                        },
                        {
                          title: t?.('claim_logo_col'), // Logo
                          dataIndex: 'logo',
                          formItemProps: {
                            rules: [
                              {
                                required:
                                  getHideInFormSetting(fieldInfo, 'logo')?.required === true ||
                                  false,
                                message: t?.('form_fields.required_message', {
                                  fieldName: t?.('claim_logo_col'),
                                }),
                              },
                            ],
                            hasFeedback: false,
                          },
                          renderFormItem: (_schema, _config, form) => (
                            <UploadCropImage
                              title={t?.('claim_logo_col')}
                              form={form}
                              formKey="logo"
                              width={200}
                              height={200}
                              initialURL={logoURL}
                              initialObj={form?.getFieldValue('logo')}
                              getImageURL={(url) => {
                                form.setFieldsValue({
                                  logoURL: url,
                                });
                                const logo = form?.getFieldValue('logo');
                                modalCardRef?.current?.setFieldsValue({
                                  logoURL: url,
                                  logo,
                                });
                              }}
                            />
                          ),
                          colProps: colProps12,
                          hideInForm:
                            layoutType === 'PTI' ||
                            getHideInFormSetting(fieldInfo, 'logo')?.visible === false ||
                            false,
                        },
                        {
                          title: t('form_fields.product_name'),
                          dataIndex: 'productName',
                          valueType: 'text',
                          colProps: colProps24,
                          fieldProps: {
                            disabled: true,
                            placeholder: t('form_fields.product_name'),
                            value: productName,
                          },
                          formItemProps: {
                            rules: [
                              {
                                required:
                                  getHideInFormSetting(fieldInfo, 'productName')?.required ===
                                    true || false,
                                message: t?.('form_fields.required_message', {
                                  fieldName: t?.('form_fields.product_name'),
                                }),
                              },
                            ],
                          },
                          hideInForm:
                            getHideInFormSetting(fieldInfo, 'productName')?.visible === false ||
                            false,
                        },
                        {
                          title: t('form_fields.product_description'),
                          dataIndex: 'productDescription',
                          valueType: 'text',
                          colProps: colProps24,
                          fieldProps: {
                            disabled: true,
                            placeholder: t('form_fields.product_description'),
                            value: productDescription,
                          },
                          formItemProps: {
                            rules: [
                              {
                                required:
                                  getHideInFormSetting(fieldInfo, 'productDescription')
                                    ?.required === true || false,
                                message: t?.('form_fields.required_message', {
                                  fieldName: t?.('form_fields.product_description'),
                                }),
                              },
                            ],
                          },
                          hideInForm:
                            layoutType === 'PTI' ||
                            getHideInFormSetting(fieldInfo, 'productDescription')?.visible ===
                              false ||
                            layoutType === 'Four into Six Publix' ||
                            false,
                        },
                        {
                          title: () => null,
                          hideInForm:
                            layoutType === 'PTI' ||
                            getHideInFormSetting(fieldInfo, 'processedInUSA')?.visible === false ||
                            false,
                          dataIndex: 'processedInUSA',
                          fieldProps: (form) => ({
                            onChange: (checked: string[]) => {
                              const isChecked = checked.includes('processed_in_usa');
                              form.setFieldsValue({
                                processedInUSA: isChecked ? 'processed_in_usa' : null,
                              });
                            },
                          }),
                          valueType: 'checkbox',
                          valueEnum: {
                            processed_in_usa: t('processed_in_usa'),
                          },
                          formItemProps: {
                            rules: [
                              {
                                required:
                                  getHideInFormSetting(fieldInfo, 'processedInUSA')?.required ===
                                    true || false,
                                message: t?.('form_fields.required_message', {
                                  fieldName: t?.('processed_in_usa'),
                                }),
                              },
                            ],
                          },
                        },
                        {
                          title: t('us_state_of_processing'),
                          dataIndex: 'usStateOfProcessing',
                          valueType: 'select',
                          renderFormItem: () => (
                            <StateSelect
                              placeholder={t('us_state_of_processing')}
                              allowClear={false}
                              size="middle"
                            />
                          ),
                          formItemProps: {
                            rules: [
                              {
                                required: Boolean(processedInUSA === 'processed_in_usa') || false,
                                message: t?.('form_fields.required_message', {
                                  fieldName: t?.('us_state_of_processing'),
                                }),
                              },
                            ],
                          },
                          hideInForm: layoutType === 'PTI' || processedInUSA !== 'processed_in_usa',
                        },
                        {
                          title: t?.('quantity'),
                          dataIndex: 'quantity',
                          valueType: 'text',
                          colProps: colProps12,
                          rowProps: { gutter: 12 },
                          fieldProps: (form) => ({
                            placeholder: t('quantity'),
                            onChange: (e: any) => {
                              const newValue = e.target.value?.replace(/[^\d]/g, '');
                              form?.setFieldsValue({
                                quantity: newValue,
                              });
                            },
                          }),
                          formItemProps: {
                            rules: [
                              {
                                required:
                                  getHideInFormSetting(fieldInfo, 'quantity')?.required === true ||
                                  false,
                                message: t?.('form_fields.required_message', {
                                  fieldName: t?.('quantity'),
                                }),
                              },
                              {
                                validator: (_, value) => {
                                  if (value.length > 14) {
                                    return Promise.reject(t?.('form_fields.quantity_too_long'));
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ],
                          },
                        },
                        {
                          title: t('unit_of_measure'),
                          dataIndex: 'unitOfMeasure',
                          hideInForm:
                            getHideInFormSetting(fieldInfo, 'unitOfMeasure')?.visible === false ||
                            false,
                          valueType: 'select',
                          colProps: colProps12,
                          rowProps: { gutter: 2 },
                          valueEnum: {
                            LBS: t('lbs'),
                          },
                          fieldProps: {
                            placeholder: t('unit_of_measure'),
                            disabled: true,
                            value: unitOfMeasure,
                          },
                          formItemProps: {
                            rules: [
                              {
                                required:
                                  getHideInFormSetting(fieldInfo, 'unitOfMeasure')?.required ||
                                  false,
                                message: t?.('form_fields.required_message', {
                                  fieldName: t?.('unit_of_measure'),
                                }),
                              },
                            ],
                          },
                        },
                        {
                          title: t?.('form_fields.item_number'),
                          dataIndex: 'itemNumber',
                          fieldProps: {
                            placeholder: t('form_fields.item_number'),
                          },
                          hideInForm:
                            layoutType === 'PTI' ||
                            getHideInFormSetting(fieldInfo, 'itemNumber')?.visible === false ||
                            false,
                          valueType: 'text',
                          formItemProps: {
                            rules: [
                              {
                                required:
                                  getHideInFormSetting(fieldInfo, 'itemNumber')?.required || false,
                                message: t?.('form_fields.required_message', {
                                  fieldName: t?.('form_fields.item_number'),
                                }),
                              },
                              {
                                validator: (_, value) => {
                                  if (value.length > 25) {
                                    return Promise.reject(t?.('form_fields.item_number_too_long'));
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ],
                          },
                        },
                        {
                          title: t?.('cool_information'),
                          dataIndex: 'coolInformation',
                          hideInForm:
                            layoutType === 'PTI' ||
                            getHideInFormSetting(fieldInfo, 'coolInformation')?.visible === false ||
                            false,
                          valueType: 'text',
                          fieldProps: {
                            placeholder: t('cool_information'),
                          },
                          formItemProps: {
                            rules: [
                              {
                                required:
                                  getHideInFormSetting(fieldInfo, 'coolInformation')?.required ||
                                  false,
                                message: t?.('form_fields.required_message', {
                                  fieldName: t?.('cool_information'),
                                }),
                              },
                              {
                                validator: (_, value) => {
                                  if (value.length > 50) {
                                    return Promise.reject(t?.('form_fields.cool_too_long'));
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ],
                          },
                        },
                        {
                          title: t('harvest_method'),
                          dataIndex: 'harvestMethod',
                          hideInForm:
                            layoutType === 'PTI' ||
                            getHideInFormSetting(fieldInfo, 'harvestMethod')?.visible === false ||
                            false,
                          valueType: 'text',
                          fieldProps: {
                            placeholder: t('harvest_method'),
                          },
                          formItemProps: {
                            rules: [
                              {
                                required:
                                  getHideInFormSetting(fieldInfo, 'harvestMethod')?.required ||
                                  false,
                                message: t?.('form_fields.required_message', {
                                  fieldName: t?.('harvest_method'),
                                }),
                              },
                              {
                                validator: (_, value) => {
                                  if (value.length > 28) {
                                    return Promise.reject(
                                      t?.('form_fields.harvest_method_too_long'),
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ],
                          },
                        },
                        {
                          title: t?.('vendor_id'),
                          dataIndex: 'vendorID',
                          hideInForm:
                            layoutType === 'PTI' ||
                            getHideInFormSetting(fieldInfo, 'vendorID')?.visible === false ||
                            false,
                          valueType: 'text',
                          fieldProps: {
                            placeholder: t('vendor_id'),
                          },
                          formItemProps: {
                            rules: [
                              {
                                required:
                                  getHideInFormSetting(fieldInfo, 'vendorID')?.required || false,
                                message: t?.('form_fields.required_message', {
                                  fieldName: t?.('vendor_id'),
                                }),
                              },
                              {
                                validator: (_, value) => {
                                  if (value.length > 25) {
                                    return Promise.reject(t?.('form_fields.vendor_id_too_long'));
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ],
                          },
                        },
                        {
                          title: t?.('form_fields.supplier_information'),
                          dataIndex: 'supplierInformation',
                          hideInForm:
                            layoutType === 'PTI' ||
                            getHideInFormSetting(fieldInfo, 'supplierInformation')?.visible ===
                              false ||
                            false,
                          valueType: 'text',
                          fieldProps: {
                            placeholder: t('form_fields.supplier_information'),
                          },
                          formItemProps: {
                            rules: [
                              {
                                required:
                                  getHideInFormSetting(fieldInfo, 'supplierInformation')
                                    ?.required || false,
                                message: t?.('form_fields.required_message', {
                                  fieldName: t?.('form_fields.supplier_information'),
                                }),
                              },
                              {
                                validator: (_, value) => {
                                  if (value.length > 28) {
                                    return Promise.reject(
                                      t?.('form_fields.supplier_information_too_long'),
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ],
                          },
                        },
                        {
                          title: t?.('form_fields.supplier_stock_number'),
                          dataIndex: 'supplierStockNumber',
                          hideInForm:
                            layoutType === 'PTI' ||
                            getHideInFormSetting(fieldInfo, 'supplierStockNumber')?.visible ===
                              false ||
                            false,
                          valueType: 'text',
                          fieldProps: {
                            placeholder: t('form_fields.supplier_stock_number'),
                          },
                          formItemProps: {
                            rules: [
                              {
                                required:
                                  getHideInFormSetting(fieldInfo, 'supplierStockNumber')
                                    ?.required || false,
                                message: t?.('form_fields.required_message', {
                                  fieldName: t?.('form_fields.supplier_stock_number'),
                                }),
                              },
                              {
                                validator: (_, value) => {
                                  if (value.length > 28) {
                                    return Promise.reject(
                                      t?.('form_fields.supplier_stock_too_long'),
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ],
                          },
                        },
                        {
                          title: t?.('form_fields.selling_unit_upca'),
                          dataIndex: 'sellingUnitUPCA',
                          hideInForm:
                            layoutType === 'PTI' ||
                            getHideInFormSetting(fieldInfo, 'sellingUnitUPCA')?.visible === false ||
                            false,
                          valueType: 'text',
                          fieldProps: {
                            placeholder: t('form_fields.selling_unit_upca'),
                          },
                          formItemProps: {
                            rules: [
                              {
                                required:
                                  getHideInFormSetting(fieldInfo, 'sellingUnitUPCA')?.required ||
                                  false,
                                message: t?.('form_fields.required_message', {
                                  fieldName: t?.('form_fields.selling_unit_upca'),
                                }),
                              },
                            ],
                          },
                        },
                        {
                          title: t?.('unique_package_id'),
                          dataIndex: 'uniquePackageID',
                          hideInForm:
                            layoutType === 'PTI' ||
                            getHideInFormSetting(fieldInfo, 'uniquePackageID')?.visible === false ||
                            false,
                          valueType: 'text',
                          fieldProps: {
                            minLength: 6,
                            maxLength: 6,
                            showCount: true,
                            placeholder: t('unique_package_id'),
                          },
                          formItemProps: {
                            rules: [
                              {
                                required:
                                  getHideInFormSetting(fieldInfo, 'uniquePackageID')?.required ||
                                  false,
                                message: t?.('form_fields.required_message', {
                                  fieldName: t?.('unique_package_id'),
                                }),
                              },
                            ],
                          },
                        },
                        {
                          title: t('packaging'),
                          dataIndex: 'packaging',
                          hideInForm:
                            layoutType !== 'PTI' ||
                            getHideInFormSetting(fieldInfo, 'packaging')?.visible === false ||
                            false,
                          valueType: 'text',
                          fieldProps: {
                            placeholder: t('packaging'),
                          },
                          formItemProps: {
                            rules: [
                              {
                                required:
                                  getHideInFormSetting(fieldInfo, 'packaging')?.required || false,
                                message: t?.('form_fields.required_message', {
                                  fieldName: t?.('packaging'),
                                }),
                              },
                              {
                                validator: (_, value) => {
                                  if (value.length > 28) {
                                    return Promise.reject(t?.('form_fields.packaging_too_long'));
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ],
                          },
                        },
                        {
                          title: t('grown_in'),
                          dataIndex: 'grownIn',
                          hideInForm:
                            layoutType !== 'PTI' ||
                            getHideInFormSetting(fieldInfo, 'grown_in')?.visible === false ||
                            false,
                          valueType: 'text',
                          fieldProps: {
                            placeholder: t('grown_in'),
                          },
                          formItemProps: {
                            rules: [
                              {
                                required:
                                  getHideInFormSetting(fieldInfo, 'grownIn')?.required || false,
                                message: t?.('form_fields.required_message', {
                                  fieldName: t?.('grown_in'),
                                }),
                              },
                              {
                                validator: (_, value) => {
                                  if (value.length > 28) {
                                    return Promise.reject(t?.('form_fields.grown_in_too_long'));
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ],
                          },
                        },
                        {
                          title: t('grade'),
                          dataIndex: 'grade',
                          hideInForm:
                            layoutType !== 'PTI' ||
                            getHideInFormSetting(fieldInfo, 'grade')?.visible === false ||
                            false,
                          valueType: 'text',
                          fieldProps: {
                            placeholder: t('grade'),
                          },
                          formItemProps: {
                            rules: [
                              {
                                required:
                                  getHideInFormSetting(fieldInfo, 'grade')?.required || false,
                                message: t?.('form_fields.required_message', {
                                  fieldName: t?.('grade'),
                                }),
                              },
                              {
                                validator: (_, value) => {
                                  if (value.length > 28) {
                                    return Promise.reject(t?.('form_fields.grade_too_long'));
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ],
                          },
                        },
                        {
                          title: t('responsible_party'),
                          dataIndex: 'responsibleParty',
                          hideInForm:
                            layoutType !== 'PTI' ||
                            getHideInFormSetting(fieldInfo, 'responsibleParty')?.visible ===
                              false ||
                            false,
                          valueType: 'text',
                          fieldProps: {
                            placeholder: t('responsible_party'),
                          },
                          formItemProps: {
                            rules: [
                              {
                                required:
                                  getHideInFormSetting(fieldInfo, 'responsibleParty')?.required ||
                                  false,
                                message: t?.('form_fields.required_message', {
                                  fieldName: t?.('responsible_party'),
                                }),
                              },
                              {
                                validator: (_, value) => {
                                  if (value.length > 28) {
                                    return Promise.reject(
                                      t?.('form_fields.responsible_party_too_long'),
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ],
                          },
                        },
                        {
                          title: t('plu'),
                          dataIndex: 'plu',
                          hideInForm:
                            layoutType !== 'PTI' ||
                            getHideInFormSetting(fieldInfo, 'plu')?.visible === false ||
                            false,
                          valueType: 'text',
                          fieldProps: {
                            placeholder: t('plu'),
                          },
                          formItemProps: {
                            rules: [
                              {
                                required: getHideInFormSetting(fieldInfo, 'plu')?.required || false,
                                message: t?.('form_fields.required_message', {
                                  fieldName: t?.('plu'),
                                }),
                              },
                              {
                                validator: (_, value) => {
                                  if (value.length > 28) {
                                    return Promise.reject(t?.('form_fields.plu_too_long'));
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ],
                          },
                        },
                        {
                          title: t('upc'),
                          dataIndex: 'upc',
                          hideInForm:
                            layoutType !== 'PTI' ||
                            getHideInFormSetting(fieldInfo, 'upc')?.visible === false ||
                            false,
                          valueType: 'text',
                          fieldProps: {
                            placeholder: t('upc'),
                          },
                          formItemProps: {
                            rules: [
                              {
                                required: getHideInFormSetting(fieldInfo, 'upc')?.required || false,
                                message: t?.('form_fields.required_message', {
                                  fieldName: t?.('upc'),
                                }),
                              },
                              {
                                validator: (_, value) => {
                                  if (value.length > 28) {
                                    return Promise.reject(t?.('form_fields.upc_too_long'));
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ],
                          },
                        },
                        {
                          valueType: 'group',
                          colProps: colProps24,
                          columns: [
                            {
                              valueType: 'dependency',
                              fieldProps: {
                                name: ['newFieldInfo'],
                              },
                              columns: ({ newFieldInfo }) =>
                                newFieldInfo
                                  ?.filter?.((item: FieldItem) => item?.visible === true, [])
                                  .map(
                                    (item: FieldItem) => ({
                                      title: item?.title,
                                      dataIndex: item?.dataIndex,
                                      colProps: colProps24,
                                      initialValue: item?.value,
                                      valueType: 'text',
                                      fieldProps: (form: any) => ({
                                        placeholder: item?.title,
                                        onChange: (e: ChangeEvent<HTMLInputElement>) => {
                                          // Get the current values from the form
                                          const currentValues =
                                            form?.getFieldValue('newFieldInfo') || [];

                                          // Create a new array with the updated value for the current field
                                          // while preserving all other field values
                                          const updatedValues = currentValues.map(
                                            (field: FieldItem) => {
                                              // Only update the specific field being changed
                                              if (
                                                field.dataIndex === item.dataIndex &&
                                                field.title === item.title
                                              ) {
                                                return {
                                                  ...field,
                                                  value: e.target.value || '',
                                                };
                                              }
                                              // Return all other fields unchanged
                                              return field;
                                            },
                                          );

                                          form?.setFieldsValue({
                                            newFieldInfo: updatedValues,
                                          });
                                        },
                                      }),
                                      formItemProps: {
                                        rules: [
                                          {
                                            required: item?.required,
                                            message: t?.('form_fields.required_message', {
                                              fieldName: item?.title,
                                            }),
                                          },
                                        ],
                                        hasFeedback: false,
                                      },
                                    }),
                                    [],
                                  ) || [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  valueType: 'group',
                  title: () => (
                    <Space direction="horizontal">
                      <Typography.Text>{t?.('barcode_label')}</Typography.Text>
                      {layoutType === 'Four into Six Case Label' && (
                        <Button
                          size="small"
                          type="primary"
                          shape="round"
                          onClick={() => onBarcodeEdit?.()}
                        >
                          {t?.('form_fields.edit')}
                        </Button>
                      )}
                    </Space>
                  ),
                  columns: [
                    {
                      valueType: 'dependency',
                      fieldProps: {
                        name: ['barcodeFieldInfo'],
                      },
                      columns: ({ barcodeFieldInfo }) => [
                        {
                          title: t('barcode_type'),
                          dataIndex: 'barcodeType',
                          valueType: 'select',
                          fieldProps: {
                            disabled: layoutType === 'Four into Six Publix' || layoutType === 'PTI',
                            placeholder: t('barcode_type'),
                          },
                          hideInForm:
                            getHideInFormSetting(barcodeFieldInfo, 'barcodeType')?.visible ===
                              false || false,
                          valueEnum: {
                            'GS1 Barcode': t('gs1_barcode'),
                            'Data Matrix': t('data_matrix'),
                            'GS1 and Data Matrix': t('gs1_barcode_and_data_matrix'),
                          },
                          formItemProps: {
                            rules: [
                              {
                                required:
                                  getHideInFormSetting(barcodeFieldInfo, 'barcodeType')
                                    ?.required === true || false,
                                message: t?.('form_fields.required_message', {
                                  fieldName: t?.('barcode_type'),
                                }),
                              },
                            ],
                          },
                        },
                        {
                          title: t('gtin_14'),
                          dataIndex: 'gtin14',
                          valueType: 'text',
                          fieldProps: {
                            disabled: true,
                            placeholder: t('gtin_14'),
                          },
                          hideInForm:
                            getHideInFormSetting(barcodeFieldInfo, 'gtin14')?.visible === false ||
                            false,
                          formItemProps: {
                            rules: [
                              {
                                required:
                                  getHideInFormSetting(barcodeFieldInfo, 'gtin14')?.required ===
                                    true || false,
                                message: t?.('form_fields.required_message', {
                                  fieldName: t?.('gtin_14'),
                                }),
                              },
                            ],
                          },
                        },
                        {
                          title: t?.('form_fields.lot_id_question'),
                          valueType: 'radio',
                          initialValue: 'Yes',
                          dataIndex: 'existingLotId',
                          valueEnum: {
                            Yes: {
                              text: t('yes'),
                              status: 'default',
                            },
                            No: {
                              text: t('no'),
                              status: 'default',
                            },
                          },
                          fieldProps: (form) => ({
                            onChange: (value: string) =>
                              handleExistingLotIdChange(value, form, modalCardRef),
                          }),
                        },
                        {
                          colProps: colProps24,
                          valueType: 'dependency',
                          fieldProps: {
                            name: ['existingLotId', 'product'],
                          },
                          columns: () => [
                            {
                              title: t?.('batch_lot_id'),
                              dataIndex: 'batchLotID',
                              colProps: colProps24,
                              renderFormItem: (_, __, form) => {
                                // Get form from third argument
                                const existingLotId = form?.getFieldValue('existingLotId');

                                if (existingLotId === 'Yes') {
                                  return (
                                    <ProductLotSelect
                                      placeholder={t('batch_lot_id')}
                                      productId={form?.getFieldValue('product') || ''}
                                      onChange={(value, option) => {
                                        const inventory = (option as DefaultOptionType)
                                          ?.itemProps as InventoryResponse;
                                        const lotId =
                                          inventory?.productInstance?.lotSerial || value;

                                        if (lotId) {
                                          const validation = validateLotID(lotId, t);
                                          if (!validation.isValid) {
                                            message.warning({
                                              content: t('invalid_lot_characters'),
                                              duration: 5,
                                            });
                                            return;
                                          }
                                        }

                                        form?.setFieldsValue({ batchLotID: lotId });
                                        modalCardRef?.current?.setFieldsValue({
                                          batchLotID: lotId,
                                        });
                                      }}
                                    />
                                  );
                                }
                                return (
                                  <Input
                                    placeholder={t('batch_lot_id')}
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      form?.setFieldsValue({ batchLotID: value });
                                      modalCardRef?.current?.setFieldsValue({
                                        batchLotID: value,
                                      });
                                    }}
                                  />
                                );
                              },
                              formItemProps: {
                                rules: [
                                  ({ getFieldValue }) => ({
                                    required:
                                      getHideInFormSetting(barcodeFieldInfo, 'batchLotID')
                                        ?.required || false,
                                    validator: async (_, value) => {
                                      const existingLotId = getFieldValue('existingLotId');
                                      // Only validate if required based on existingLotId
                                      if (existingLotId === 'Yes' && !value) {
                                        return Promise.reject(
                                          new Error(
                                            t?.('form_fields.required_message', {
                                              fieldName: t?.('batch_lot_id'),
                                            }),
                                          ),
                                        );
                                      }
                                      if (value) {
                                        const validation = validateLotID(value);
                                        if (!validation.isValid) {
                                          return Promise.reject(new Error(validation.message));
                                        }
                                      }
                                      return Promise.resolve();
                                    },
                                  }),
                                ],
                              },
                            },
                            {
                              dataIndex: 'printOptions',
                              fieldProps: {
                                className: 'no-display',
                              },
                              formItemProps: {
                                className: 'no-display',
                              },
                            },
                          ],
                        },
                        {
                          title: t?.('pack_date'),
                          dataIndex: 'packDate',
                          hideInForm:
                            getHideInFormSetting(barcodeFieldInfo, 'packDate')?.visible === false ||
                            false,
                          valueType: 'date',
                          width: '100%',
                          fieldProps: {
                            placeholder: t('pack_date'),
                          },
                          formItemProps: {
                            rules: [
                              {
                                required:
                                  getHideInFormSetting(barcodeFieldInfo, 'packDate')?.required ||
                                  false,
                                message: t?.('form_fields.required_message', {
                                  fieldName: t?.('pack_date'),
                                }),
                              },
                            ],
                          },
                        },
                        {
                          title: t?.('country_of_origin'),
                          dataIndex: 'countryOfOrigin',
                          hideInForm:
                            getHideInFormSetting(barcodeFieldInfo, 'countryOfOrigin')?.visible ===
                              false || layoutType === 'PTI',
                          valueType: 'text',
                          fieldProps: {
                            placeholder: t('country_of_origin'),
                          },
                          formItemProps: {
                            rules: [
                              {
                                required:
                                  getHideInFormSetting(barcodeFieldInfo, 'countryOfOrigin')
                                    ?.required || false,
                                message: t?.('form_fields.required_message', {
                                  fieldName: t?.('country_of_origin'),
                                }),
                              },
                            ],
                          },
                          renderFormItem: () => (
                            <GS1CountryCodeList
                              placeholder={t('country_of_origin')}
                              allowClear
                              size="middle"
                            />
                          ),
                        },
                        {
                          title: t?.('form_fields.sell_by_date'),
                          dataIndex: 'sellByDate',
                          width: '100%',
                          hideInForm:
                            getHideInFormSetting(barcodeFieldInfo, 'sellByDate')?.visible ===
                              false || false,
                          valueType: 'date',
                          fieldProps: {
                            placeholder: t('form_fields.sell_by_date'),
                          },
                          formItemProps: {
                            rules: [
                              {
                                required:
                                  getHideInFormSetting(barcodeFieldInfo, 'sellByDate')?.required ||
                                  false,
                                message: t?.('form_fields.required_message', {
                                  fieldName: t?.('form_fields.sell_by_date'),
                                }),
                              },
                            ],
                          },
                        },
                        {
                          title: t?.('form_fields.harvest_date'),
                          dataIndex: 'harvestDate',
                          hideInForm:
                            layoutType !== 'PTI' ||
                            getHideInFormSetting(barcodeFieldInfo, 'harvestDate')?.visible ===
                              false ||
                            false,
                          valueType: 'date',
                          width: '100%',
                          fieldProps: {
                            placeholder: t('form_fields.harvest_date'),
                          },
                          formItemProps: {
                            rules: [
                              {
                                required:
                                  getHideInFormSetting(barcodeFieldInfo, 'harvestDate')?.required ||
                                  false,
                                message: t?.('form_fields.required_message', {
                                  fieldName: t?.('form_fields.harvest_date'),
                                }),
                              },
                            ],
                          },
                        },
                        {
                          title: t?.('form_fields.best_before_date'),
                          dataIndex: 'bestBeforeDate',
                          hideInForm:
                            layoutType !== 'PTI' &&
                            getHideInFormSetting(barcodeFieldInfo, 'bestBeforeDate')?.visible ===
                              false,
                          valueType: 'date',
                          width: '100%',
                          fieldProps: {
                            placeholder: t('form_fields.best_before_date'),
                          },
                          formItemProps: {
                            rules: [
                              {
                                required:
                                  getHideInFormSetting(barcodeFieldInfo, 'bestBeforeDate')
                                    ?.required || false,
                                message: t?.('form_fields.required_message', {
                                  fieldName: t?.('form_fields.best_before_date'),
                                }),
                              },
                            ],
                          },
                        },
                        {
                          valueType: 'group',
                          colProps: colProps24,
                          columns: [
                            {
                              valueType: 'dependency',
                              fieldProps: {
                                name: ['newBarcodeFieldInfo'],
                              },
                              columns: ({ newBarcodeFieldInfo }) =>
                                newBarcodeFieldInfo
                                  ?.filter?.((item: FieldItem) => item?.visible === true, [])
                                  .map(
                                    (item: FieldItem) => ({
                                      title: item?.title,
                                      dataIndex: item?.dataIndex,
                                      colProps: colProps24,
                                      valueType: item?.title?.includes('DATE') ? 'date' : 'text',
                                      width: '100%',
                                      initialValue: item?.value,
                                      fieldProps: (form: any) => ({
                                        placeholder: item?.title,
                                        onChange: (e: ChangeEvent<HTMLInputElement>) => {
                                          // Get the current values from the form
                                          const currentValues =
                                            form?.getFieldValue('newBarcodeFieldInfo') || [];

                                          // Create a new array with the updated value for the current field
                                          // while preserving all other field values
                                          const updatedValues = currentValues.map(
                                            (field: FieldItem) => {
                                              // Only update the specific field being changed
                                              if (
                                                field.dataIndex === item.dataIndex &&
                                                field.title === item.title
                                              ) {
                                                return {
                                                  ...field,
                                                  value: e.target.value || '',
                                                };
                                              }
                                              // Return all other fields unchanged
                                              return field;
                                            },
                                          );

                                          // Update all relevant forms with the new values
                                          if (form?.current) {
                                            form.current.setFieldsValue({
                                              newBarcodeFieldInfo: updatedValues,
                                            });
                                          }

                                          form?.setFieldsValue({
                                            newBarcodeFieldInfo: updatedValues,
                                          });

                                          if (modalFormRef?.current) {
                                            modalFormRef.current.setFieldsValue({
                                              newBarcodeFieldInfo: updatedValues,
                                            });
                                          }

                                          if (modalCardRef?.current) {
                                            modalCardRef.current.setFieldsValue({
                                              newBarcodeFieldInfo: updatedValues,
                                            });
                                          }
                                        },
                                      }),
                                      formItemProps: {
                                        rules: [
                                          {
                                            required: item?.required,
                                            message: t?.('form_fields.required_message', {
                                              fieldName: item?.title,
                                            }),
                                          },
                                        ],
                                        hasFeedback: false,
                                      },
                                    }),
                                    [],
                                  ) || [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              dataIndex: 'fieldInfo',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: hiddenColProps,
            },
            {
              dataIndex: 'newFieldInfo',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: hiddenColProps,
            },
            {
              dataIndex: 'barcodeFieldInfo',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: hiddenColProps,
            },
            {
              dataIndex: 'newBarcodeFieldInfo',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: hiddenColProps,
            },
            {
              dataIndex: 'productId',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
            },
            {
              dataIndex: 'certLogoURL',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
            },
            {
              dataIndex: 'logoURL',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
            },
            {
              dataIndex: 'brandLogoURL',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
            },
            {
              dataIndex: 'printOptions',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
            },
            {
              dataIndex: 'ogNewBarcodeFieldInfo',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: hiddenColProps,
            },
            {
              dataIndex: 'ogNewFieldInfo',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: hiddenColProps,
            },
          ],
        },
      ],
    },
  ];
};
