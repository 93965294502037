import { CloseOutlined } from '@ant-design/icons';
import ProCard from '@ant-design/pro-card';
import { Button, Drawer, Space, Typography } from 'antd';
import { useWindowSize } from 'hooks';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './GCardDrawer.module.less';
import { EventType, GCardDrawerProps } from './typings';

const GCardDrawer: FC<GCardDrawerProps> = ({
  cancelButtonProps,
  saveButtonProps,
  DrawerProps,
  containerCardProps,
  leftCardProps,
  rightCardProps,
  leftChildern,
  rightChildern,
  leftColSpan,
  rightColSpan,
  t,
}) => {
  const [width] = useWindowSize();
  const navigate = useNavigate();
  const smallScreen = useMemo(() => width < 769, [width]);
  const bodyStyle = useMemo(
    () => ({
      overflow: smallScreen ? 'scroll' : 'hidden',
      padding: 0,
    }),
    [smallScreen],
  );

  const formColSpan = useMemo(
    () => (smallScreen ? '100%' : rightColSpan || '35%'),
    [rightColSpan, smallScreen],
  );

  return (
    <Drawer
      title={
        <Space>
          <CloseOutlined onClick={(e) => DrawerProps?.onClose?.(e as EventType)} />
          <Typography.Text>{t('drawer_title')}</Typography.Text>
        </Space>
      }
      closeIcon={null}
      className={styles.drawer}
      placement="top"
      height="100%"
      bodyStyle={bodyStyle}
      visible
      extra={
        <Space>
          <Button
            ghost
            shape="round"
            type="primary"
            onClick={() => navigate(-1)}
            {...cancelButtonProps}
          >
            {t('cancel_button')}
          </Button>
          <Button type="primary" shape="round" {...saveButtonProps}>
            {t('save')}
          </Button>
        </Space>
      }
      {...DrawerProps}
    >
      <ProCard split={smallScreen ? 'horizontal' : 'vertical'} {...containerCardProps}>
        <ProCard colSpan={leftColSpan} className={styles.procard} {...leftCardProps}>
          {leftChildern}
        </ProCard>
        <ProCard colSpan={formColSpan} className={styles.procard} {...rightCardProps}>
          {rightChildern}
        </ProCard>
      </ProCard>
    </Drawer>
  );
};

export default GCardDrawer;
