import { ActionType } from '@ant-design/pro-table';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Actions } from 'components';
import GTable from 'components/GTable';
import { GTableDateType } from 'components/GTable/contexts/typings';
import { CurrentInventorySSCCProps } from 'pages/AllShipments/typings';
import { useEventActions } from 'pages/Events/components';
import SsccPaginatedTable from 'pages/Events/components/SsccPaginatedTable';
import { useShipmentStore } from 'pages/Shipments';
import React, { FC, Key, useCallback, useRef } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Link, createSearchParams, useNavigate } from 'react-router-dom';
import {
  dateLocalFormat,
  dateLocaleFormat,
  downloadJsonToCSV,
  getEventsT,
  getProductsT,
  getShipmentItemFromProductInventory,
  getUom,
  quantitiesCheckbox,
} from 'utils';
import { HistoryInventoryProps, InvColumnFunction, ProductInventoryItem } from './typings';
import useProductInstanceFilters from './useProductInstanceFilters';

type ActionItemsProps = {
  t: TFunction<'pages', 'products.history_inventory'>;
  selectedRowKeys: Array<Key>;
  selectedRows?: GTableDateType[];
  record?: ProductInventoryItem;
};

/**
 * Generates action items for a product record.
 * @param record The product record.
 * @param t Translation function.
 * @returns An array of action items.
 */
const productActionItems = ({ t }: ActionItemsProps): Array<ItemType> => [
  {
    key: 'view_event',
    label: t('actions.view_event'),
  },
  // {
  //   key: 'receive',
  //   label: t('actions.receive'),
  //   disabled: !(
  //     record?.shipped &&
  //     (record?.shipmentType === 'NonWholechain' || record?.shipmentType === 'Internal')
  //   ),
  // },
  {
    key: 'manage',
    label: t('manage_grp'),
    type: 'group',
    children: [
      {
        key: 'remove_event',
        label: t('actions.remove_event'),
      },
      {
        key: 'download_xls',
        label: t('actions.download_csv_selected'),
      },
    ],
  },
];

/**
 * Generates columns configuration for the inventory table.
 * @param t Translation function.
 * @param onRowActionClick Function to handle row action clicks.
 * @param setInventoryItem Function to set the inventory item.
 * @param primaryIdSearchfilter Search filter for primary ID column.
 * @param poSearchfilter Search filter for purchase order column.
 * @param selectedRowKeys Keys of the selected rows.
 * @param selectedRows Selected rows.
 * @param defaultUom Default unit of measure.
 * @param coreLocations Filters for core locations.
 * @param coreEventTypes Filters for core event types.
 * @param isSerial Indicates if the inventory is for a serial product.
 * @returns An array of column configurations for the inventory table.
 */
const columns: InvColumnFunction = ({
  t,
  onRowActionClick,
  setInventoryItem,
  primaryIdSearchfilter,
  poSearchfilter,
  selectedRowKeys,
  selectedRows,
  defaultUom,
  coreLocations,
  coreEventTypes,
  isSerial,
}) => [
  {
    title: t('tbl_col_primary_id'),
    dataIndex: 'primaryId',
    hideInSetting: true,
    width: 200,

    fixed: 'left',
    render: (text, record) => {
      const name = !record?.isContainer
        ? `${getProductsT(record.productIdentifierType)}: ${record?.lotSerial || ''}`
        : `${getProductsT('sscc')}: ${record?.containerIdentifier || ''}`;
      return record?.id ? (
        <Link
          className="text-link"
          to={{
            pathname: String(record?.id || record?.containerId || ''),
            search: `?eventId=${record?.eventId || '1'}${
              record?.isContainer ? '&isContainer=true' : ''
            }&history=true`,
          }}
          onClick={() => {
            setInventoryItem(record);
          }}
        >
          {name}
        </Link>
      ) : (
        name
      );
    },
    ellipsis: true,
    ...primaryIdSearchfilter,
  },
  {
    title: t('tbl_col_quantity'),
    dataIndex: 'quantity',
    width: 180,
    hideInTable: isSerial || false,
    ellipsis: true,
    render: (text, record) =>
      `${record?.quantity || ''} ${
        getUom({
          product: record,
        }) || defaultUom
      }`,
  },
  {
    title: t('tbl_col_event_name'),
    dataIndex: 'eventName',
    width: 150,
    filters: coreEventTypes,
    render: (text, record) => `${getEventsT(record?.eventName) || ''}`,
  },
  {
    title: t('tbl_col_location'),
    dataIndex: 'locationAddress',
    filters: coreLocations,
    filterSearch: true,
    width: '30vw',
    ellipsis: true,
  },
  {
    title: t('tbl_col_purchase_order'),
    dataIndex: 'purchaseOrder',
    width: 150,
    ellipsis: true,
    ...poSearchfilter,
  },
  {
    title: t('tbl_col_event_date'),
    dataIndex: 'eventDate',
    width: 150,

    sorter: true,
    ellipsis: true,
    render: (text, record) => `${record?.eventDate ? dateLocaleFormat(record?.eventDate) : ''}`,
  },
  {
    title: t('tbl_col_logged_date'),
    dataIndex: 'loggedDate',
    width: 150,
    sorter: true,
    ellipsis: true,
    render: (text, record) => `${record?.loggedDate ? dateLocalFormat(record?.loggedDate) : ''}`,
  },
  {
    title: t('tbl_col_blockchain'),
    dataIndex: 'blockchain',
    width: 120,
    ellipsis: true,
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    hideInSetting: true,
    width: 120,
    fixed: 'right',
    render: (text, record) =>
      record?.id ? (
        <Actions
          className="actions"
          buttonProps={{
            ghost: true,
            size: 'small',
          }}
          items={productActionItems({ selectedRowKeys, selectedRows, record, t })}
          onClick={onRowActionClick}
          actionPayload={record}
        />
      ) : undefined,
  },
];

/**
 * Component to display history inventory table.
 * @param selectedRowKeys Keys of the selected rows.
 * @param selectedRows Selected rows.
 * @param onRowSelectionChange Function to handle row selection change.
 */
const HistoryInventory: FC<HistoryInventoryProps> = ({
  selectedRowKeys,
  selectedRows,
  onRowSelectionChange,
}) => {
  const actionRef = useRef<ActionType>();
  const navigate = useNavigate();
  const { t } = useTranslation('pages', { keyPrefix: 'products.history_inventory' });
  const { t: historyTranslation } = useTranslation('pages', {
    keyPrefix: 'products.current_inventory',
  });

  const { productId, product, defaultUom, isSerial, onRemoveEvent } = useEventActions();
  const { setProducts: setShipmentProduct, setInventoryItem } = useShipmentStore();

  const {
    params,
    totalItems,
    isLoading,
    records,
    onTableChange,
    coreLocations,
    coreEventTypes,
    refetch,
    filters: [primaryIdSearchfilter, poSearchfilter],
  } = useProductInstanceFilters(productId, {
    isHistory: true,
    sortBy: '-loggedDate',
  });

  const onRowActionClick = (actionItemKey: string, actionPayload?: ProductInventoryItem) => {
    const recordId = actionPayload?.id || actionPayload?.containerId || '';

    const productsSearchParams = createSearchParams({
      products: String(recordId),
      locationId: actionPayload?.destinationId || actionPayload?.locationId || '',
      shippedFrom: actionPayload?.tradePartnerName || '',
      eventDate: actionPayload?.eventDate ? dateLocaleFormat(actionPayload?.eventDate) : '',
      ...(actionPayload?.isContainer && { containers: actionPayload?.containerId || '' }),
    });

    switch (actionItemKey) {
      case 'view_event':
        navigate({
          pathname: String(recordId || '1'),
          search: `?eventId=${actionPayload?.eventId || '1'}`,
        });
        break;
      case 'archive':
        break;
      case 'receive':
        navigate({
          pathname: '../events/receive',
          search: actionPayload ? `?${productsSearchParams}` : undefined,
        });
        setShipmentProduct?.([
          getShipmentItemFromProductInventory(actionPayload, product, {
            shipmentType: 'Internal',
            status: 'Pending',
          }),
        ]);
        break;
      case 'download_xls':
        downloadJsonToCSV([actionPayload]);
        break;
      case 'remove_event':
        onRemoveEvent(actionPayload);
        break;

      default:
        break;
    }
    onRowSelectionChange?.([], []);
  };

  const { identifier } = useEventActions();
  const expandedRowRender = useCallback(
    (rowData: ProductInventoryItem) => (
      <SsccPaginatedTable
        isInventory
        showHeader={false}
        identifier={identifier}
        t={historyTranslation}
        containerId={rowData?.containerId}
        columnProps={CurrentInventorySSCCProps}
      />
    ),
    [historyTranslation, identifier],
  );
  const rowExpandable = useCallback(
    (rowData: ProductInventoryItem) => rowData?.isContainer || false,
    [],
  );
  return (
    <GTable<ProductInventoryItem>
      actionRef={actionRef}
      className="gtbl-abs-toolbar"
      columns={columns({
        t,
        onRowActionClick,
        setInventoryItem,
        primaryIdSearchfilter,
        selectedRowKeys,
        selectedRows,
        defaultUom,
        coreLocations,
        coreEventTypes,
        isSerial,
        poSearchfilter,
      })}
      columnsState={{
        defaultValue: {
          purchaseOrder: {
            show: true,
          },
          eventDate: {
            show: false,
          },
          loggedDate: {
            show: false,
          },
          blockchain: {
            show: false,
          },
        },
        persistenceKey: 'history-inventory-columns',
      }}
      loading={isLoading}
      enableRowSelection
      value={records}
      recordCreatorProps={false}
      pagination={{
        defaultPageSize: params.pageSize,
        total: totalItems,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100', '500', '1000'],
      }}
      rowSelection={{
        selectedRowKeys,
        onChange: onRowSelectionChange,
        columnWidth: 30,
        preserveSelectedRowKeys: true,
        getCheckboxProps: quantitiesCheckbox,
      }}
      expandable={{ expandedRowRender, rowExpandable, columnWidth: 30 }}
      onTableChange={onTableChange}
      scroll={{ y: '75vh', x: 1200 }}
      options={{
        reload: () => refetch(),
      }}
    />
  );
};
export default React.memo(HistoryInventory);
