import { ProColumns } from '@ant-design/pro-table';
import { EditableProTableProps } from '@ant-design/pro-table/lib/components/EditableTable';
import { useTableSearchFilter } from 'hooks';
import { CoreEventTypes } from 'pages/Products/typings';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useProducts } from 'services/api';
import { EventDetailResponse, ListEventDetailsOptionalParams } from 'services/api/client/src';
import { useEventListById } from 'services/api/eventDetails';
import { useLocationFilters } from 'services/api/useLocations';
import {
  CompanyOverviewData,
  EventSpecificData,
  SupplyChainOverviewData,
  companyOverviewData,
  dateFormat,
  dateLocaleFormat,
  eventSpecificData,
  flattenAddress,
  getBizStepNameByIdentifier,
  getEventsT,
  getProductsT,
  getTimezoneTextByOffset,
  getUom,
  supplyChainOverviewData,
  timeLocaleFormat,
  transMapping,
} from 'utils';
import * as XLSX from 'xlsx';
import { EventListItem } from './typings';

const eventTypes = ({ t }: CoreEventTypes): ProColumns['filters'] => [
  { value: 'COMMISSION', text: t('actions.commission') },
  { value: 'OBSERVE', text: t('actions.observe') },
  { value: 'SHIP', text: t('actions.ship') },
  { value: 'TRANSFORM', text: t('actions.transform') },
  { value: 'AGGREGATION', text: t('actions.aggregate') },
  { value: 'DECOMMISSION', text: t('actions.decommission') },
  { value: 'DISAGGREGATION', text: t('actions.disaggregate') },
  { value: 'RECEIVE', text: t('actions.receive') },
];

type ReactText = string | number;
type Sorter = {
  order: 'ascend' | 'descend';
  field?: string;
};
const useEventListActions = (initParams?: ListEventDetailsOptionalParams) => {
  const { t } = useTranslation('pages', { keyPrefix: 'products.current_inventory' });

  const [params, setParams] = useState<ListEventDetailsOptionalParams>({
    pageNumber: 1,
    pageSize: 20,
    ...initParams,
  });

  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      showErrorDeclarations: initParams?.showErrorDeclarations,
      showAllProducts: initParams?.showAllProducts,
    }));
  }, [initParams?.showErrorDeclarations, initParams?.showAllProducts]);

  const { data, isLoading } = useEventListById(params);
  const { data: productList } = useProducts();

  const totalItems = useMemo(() => data?.totalItems || 0, [data?.totalItems]);
  /* locations */
  const coreLocations = useLocationFilters();
  /* event types */
  const coreEventTypes = useMemo(() => eventTypes({ t }), [t]);

  const netItemCount = useMemo(() => data?.totalItems || 0, [data]);

  const records: Array<EventListItem> = useMemo(
    () =>
      data?.results?.map((item) => ({
        productName: item?.productName,
        productId: item?.productId,
        primaryId: `${
          item?.isContainer ? getProductsT('sscc') : getProductsT(item?.identifierType)
        }: ${item?.lotSerial}`,
        quantity: `${item?.quantity} ${getUom({ product: item })}`,
        event: getEventsT(item?.eventType),
        company: item?.companyName,
        location: item?.locationName,
        eventDate: dateLocaleFormat(item?.eventDate || new Date()),
        itemProps: item,
      })) || [],
    [data],
  );

  const productNameFilter = useMemo(
    () =>
      productList?.results?.map(
        (item) => ({
          text: item?.name || '',
          value: item?.id || '',
        }),
        [],
      ),
    [productList?.results],
  );

  const productIdFilter = useMemo(
    () =>
      productList?.results?.map(
        (item) => ({
          text: item?.id || '',
          value: item?.id || '',
        }),
        [],
      ),
    [productList?.results],
  );

  const onSearch = (text: string) => {
    setParams({
      ...params,
      pageNumber: 1,
      lotSerial: text,
    });
  };
  const { filters: primaryIdSearchfilter } = useTableSearchFilter({
    title: t('tbl_primary_id_filter_placeholder'),
    onSearch,
  });
  const getSortField = (sorter: Sorter) => {
    switch (sorter.field) {
      case 'quantity':
        return 'quantity';
      case 'eventDate':
        return 'eventDate';

      default:
        return sorter.field;
    }
  };
  const onTableChange: EditableProTableProps<EventListItem, ReactText[]>['onTableChange'] = (
    pagination,
    filters,
    sorter: any,
  ) => {
    const apiFilters: ListEventDetailsOptionalParams = {
      locationIds: filters?.location?.length ? (filters.location as string[]) : undefined,
      companyIds: filters?.company?.length ? (filters.company as string[]) : undefined,
      productIds:
        filters?.productName?.length || filters?.productId?.length
          ? [
              ...((filters.productName || []) as string[]),
              ...((filters?.productId || []) as string[]),
            ]
          : undefined,
      eventTypes: filters?.event?.length ? (filters.event as string[]) : undefined,
    };
    if (pagination?.current === params?.pageNumber && pagination?.pageSize === params?.pageSize) {
      setParams({
        ...params,
        ...apiFilters,
        sortBy: sorter?.field
          ? `${sorter.order === 'ascend' ? '+' : '-'}${getSortField(sorter)}`
          : undefined,
        pageNumber: 1,
      });
    } else {
      setParams({
        ...params,
        ...apiFilters,
        pageNumber: pagination?.current,
        pageSize: pagination?.pageSize,
      });
    }
  };
  const getEventDetailRecord = useCallback(
    (record: EventDetailResponse, type = 'eventSpecific') => {
      let keys: Array<string>;
      let overviewData: EventSpecificData | CompanyOverviewData | SupplyChainOverviewData;
      switch (type) {
        case 'eventSpecific':
          overviewData = eventSpecificData;
          keys = Object.keys(eventSpecificData);
          break;
        case 'company':
          overviewData = companyOverviewData;
          keys = Object.keys(companyOverviewData);
          break;
        case 'supplyChain':
          overviewData = supplyChainOverviewData;
          keys = Object.keys(supplyChainOverviewData);
          break;

        default:
          overviewData = eventSpecificData;
          keys = Object.keys(supplyChainOverviewData);
          break;
      }

      const mappedData = keys?.reduce(
        (acc, key) => {
          const title = overviewData[key as keyof EventSpecificData];
          let value: string;
          switch (key) {
            case 'address':
              value = record?.address?.displayAddress || flattenAddress(record?.address);
              break;
            case 'inputProductName':
              value = record?.productName || '';
              break;
            case 'inputLotSerial':
              value = record?.lotSerial || '';
              break;
            case 'inputQuantity':
              value = String(record?.quantity || '');
              break;
            case 'inputUnitOfMeasure':
              value = getUom({ product: record });
              break;

            case 'date':
              value = dateFormat(record?.eventDate || new Date());
              break;

            case 'time':
              value = timeLocaleFormat(new Date(dateLocaleFormat(record?.eventDate || new Date())));
              break;
            case 'eventType':
              value = `${getEventsT(record?.eventType)} (${getBizStepNameByIdentifier(
                record?.businessStep || '',
              )})`;
              break;

            case 'eventTimezoneOffset':
              value = getTimezoneTextByOffset?.(record?.eventTimezoneOffset || '') || '';
              break;

            default:
              value = record?.[key as keyof EventDetailResponse]
                ? String(record?.[key as keyof EventDetailResponse])
                : '';
              break;
          }
          acc[t(`${transMapping[String(title)]}`)] = value;
          return acc;
        },
        {} as {
          [key: string]: any;
        },
      );
      const customData =
        record?.masterData?.reduce(
          (acc, item) => ({
            ...acc,
            [item?.name || '']: item?.value ? String(item?.value) : '',
          }),
          {} as {
            [key: string]: string;
          },
        ) || {};
      const overallData = {
        ...mappedData,
        ...customData,
      };
      return overallData;
    },
    [t],
  );
  const onDownloadExcel = useCallback(() => {
    const workbook = XLSX.utils.book_new();

    const recrds = data?.results || [];
    /* event specific data */
    const eventSpecificRecords = recrds?.map(
      (record) => getEventDetailRecord(record, 'eventSpecific') || {},
    );
    const sheet1 = XLSX.utils.json_to_sheet(eventSpecificRecords);
    XLSX.utils.book_append_sheet(workbook, sheet1, t('event_specific_data'));

    /* company overview data */
    const companyRecords = recrds?.map((record) => getEventDetailRecord(record, 'company') || {});
    const sheet2 = XLSX.utils.json_to_sheet(companyRecords);
    XLSX.utils.book_append_sheet(workbook, sheet2, t('company_overview'));

    /* supply chain overview */
    const supplyChainRecords = recrds?.map(
      (record) => getEventDetailRecord(record, 'supplyChain') || {},
    );
    const sheet3 = XLSX.utils.json_to_sheet(supplyChainRecords);
    XLSX.utils.book_append_sheet(workbook, sheet3, t('supply_chain_overview'));

    /* write file */
    XLSX.writeFile(workbook, `${t('list_data_title')}.xlsx`);
  }, [data, getEventDetailRecord, t]);

  return {
    params,
    setParams,
    totalItems,
    netItemCount,
    isLoading,
    records,
    coreLocations,
    coreEventTypes,
    onTableChange,
    filters: [primaryIdSearchfilter],
    onDownloadExcel,
    productIdFilter,
    productNameFilter,
  };
};
export default useEventListActions;
