import { Col, Row, Typography } from 'antd';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import BarcodeGenerator, { generateGS1String } from '../components/BarcodeGenerator';
import { BarcodePalletLayoutProps, BarcodeType, SectionProps } from '../components/typings';
import styles from './index.module.less';

const { Text } = Typography;

export const BarcodePalletLayout = forwardRef<HTMLDivElement, BarcodePalletLayoutProps>(
  ({ sscc, sections }, ref) => {
    const { t } = useTranslation('pages', { keyPrefix: 'labels.add_pallet' });

    const { gs1String = '' } =
      generateGS1String({
        barcodeType: 'GS1',
        batchLotID: '',
        bestBeforeDate: undefined,
        brandLogo: '',
        coolInformation: '',
        countryOfOrigin: '',
        harvestMethod: '',
        itemNumber: '',
        labelName: '',
        layoutType: '4x6 Case Label',
        packDate: undefined,
        processedInUSA: 'false',
        product: '',
        quantity: 0,
        sellByDate: undefined,
        sellingUnitUPCA: '',
        supplierInformation: '',
        supplierStockNumber: '',
        uniquePackageID: '',
        unitOfMeasure: 'LBS',
        vendorID: '',
        gtin14: undefined,
        sscc,
      }) || {};
    // Helper function to chunk sections into pairs
    const chunkSections = (items: SectionProps[] | undefined | null, size: number) => {
      if (!items?.length || !items?.some((c) => (c ? Object.keys(c).length : c))) {
        return [];
      }
      const chunks = [];
      const validItems = items.length
        ? items.filter((c) => (c ? Object.keys(c).length : null))
        : [];
      for (let i = 0; i < validItems.length; i += size) {
        chunks.push(validItems.slice(i, i + size));
      }
      return chunks;
    };

    const renderSectionContent = (section: SectionProps) => {
      if (!section?.content) return null;

      if (section.content && section.content !== 'text') {
        const isDataMatrixCss =
          (section.content as BarcodeType) === 'datamatrix'
            ? 'section-data-datamatrix'
            : 'section-data-barcode';
        return (
          <div className={styles[`${isDataMatrixCss}`]}>
            <BarcodeGenerator
              value={section.data}
              type={section.content as BarcodeType}
              width={120}
              height={110}
              voicePickCode={null}
              options={{ width: 1.2, fontSize: 12 }}
              handlePrint={() => {}}
              typeOption="pallet"
            />
          </div>
        );
      }

      return (
        section &&
        section?.data && (
          <div className={styles['section-data']}>
            {section.data.split('\n').map((line) => (
              <Text key={uuidv4()} className={styles['data-line']}>
                {line}
              </Text>
            ))}
          </div>
        )
      );
    };

    const renderLayout = () => {
      const isSectionEmpty = sections?.some((c) => (c ? Object.keys(c).length : c)) ?? true;
      if (!gs1String && !isSectionEmpty) {
        return (
          <div className={styles['pallet-label-card-container-empty']}>
            <Text type="secondary">{t?.('empty_pallet_message')}</Text>
          </div>
        );
      }

      return (
        <div
          ref={ref}
          key={uuidv4()}
          className={`${styles['pallet-label-container']} print-container`}
        >
          {/* Dynamic Sections */}
          {chunkSections(sections, 2).map((row) => (
            <div className={styles['content-section']}>
              {row.map((section) => (
                <Col span={24 / row.length}>
                  <div className={styles['section-container']} key={uuidv4()}>
                    <Text strong className={styles['section-title']}>
                      {section?.title}
                    </Text>
                    <div className={styles['section-content']} key={uuidv4()}>
                      {renderSectionContent(section)}
                    </div>
                  </div>
                </Col>
              ))}
            </div>
          ))}

          {/* Barcode Section - Full Width */}
          <Row gutter={[0, 8]} justify="center" align="middle">
            {sscc && (
              <div className={styles['section-pallet-barcode']}>
                <BarcodeGenerator
                  type="barcode"
                  value={gs1String}
                  options={{
                    format: 'CODE128',
                    ean128: true,
                    text: gs1String,
                    width: 1,
                    height: 105,
                    textMargin: 5,
                    fontSize: 12,
                    margin: 10,
                    displayValue: true,
                  }}
                  handlePrint={() => {}}
                  voicePickCode=""
                  typeOption="pallet"
                />
              </div>
            )}
          </Row>
        </div>
      );
    };
    return renderLayout();
  },
);

export default BarcodePalletLayout;
