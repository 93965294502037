import { InfoCircleOutlined } from '@ant-design/icons';
import { ActionType, EditableFormInstance, ProColumns } from '@ant-design/pro-table';
import { RecordKey } from '@ant-design/pro-utils/lib/useEditableArray';
import { Button, message, Space, TablePaginationConfig, Typography } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import classNames from 'classnames';
import { ActionButtons, GTable } from 'components';
import TraceabilityLotCodeSourceSelect from 'components/GSelect/TraceabilityLotCodeSourceSelect';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useGetProductContainerInstancesById } from 'services/api';
import {
  ContainerProductInstanceResponse,
  GetContainerInventoryResponse,
  GetPaginatedOtherSystemShipmentsOptionalParams,
} from 'services/api/client/src';
import { errorHandler, featureFlag, getProductsT, getUom, mapContainer } from 'utils';
import styles from './index.module.less';
import { ContainerTableColumnProps, SsccContainerTableProps } from './typings';
/**
 * Columns
 * Defines the columns configuration for displaying container product instance quantity data.
 * @param columnProps Additional properties for configuring each column.
 * @param identifier Identifier for distinguishing between different types of columns.
 * @returns An array of ProColumns<ContainerProductInstanceQuantity> objects representing the columns configuration.
 */
const Columns = ({
  columnProps,
  identifier,
  setNewCompanyLocationList,
  newCompanyLocationList,
  onEditRow,
  t,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  editableFormRef,
}: ContainerTableColumnProps): ProColumns<ContainerProductInstanceResponse>[] => [
  {
    dataIndex: 'id',
    hideInTable: true,
    ...columnProps?.id,
  },
  {
    title: t?.('tbl_col_primary_id_title'),
    dataIndex: 'instanceId',
    hideInTable: true,
    ...columnProps?.instanceId,
  },
  {
    title: t?.('traceability_lotcode'),
    dataIndex: 'lotSerial',
    render: (text, record) => (
      <span>
        {getProductsT(identifier)}: {record?.lotSerial || ''} &nbsp;
        <Typography.Text type="secondary">{record?.productName || ''}</Typography.Text>
      </span>
    ),
    ...columnProps?.lotSerial,
  },
  {
    title: t?.('tbl_col_quantity_title'),
    dataIndex: 'quantity',
    /* might need in future based on the requirement */
    // hideInTable: identifier === 'Serial',
    sorter: true,
    render: (text, record) => `${record?.quantity || 0} ${getUom({ product: record }) || 'LBS'}`,
    ...columnProps?.quantity,
  },
  {
    title: t?.('tbl_col_current_inventory_title'),
    dataIndex: 'currentInventory',
    sorter: true,
    // hideInTable: true,
    render: (text, record) =>
      `${record?.totalInventoryAtLocation || 0} ${getUom({ product: record }) || 'LBS'}`,
    ...columnProps?.currentInventory,
    fieldProps: (form, { entity }) => ({
      value: `${entity?.totalInventoryAtLocation || 0}`,
      ...columnProps?.currentInventory?.fieldProps,
    }),
  },
  {
    title: t?.('last_vent'),
    dataIndex: 'lastEvent',
    ellipsis: true,
    ...columnProps?.lastEvent,
  },
  {
    title: t?.('location'),
    dataIndex: 'locationName',
    ellipsis: true,
    ...columnProps?.locationName,
  },
  {
    title: t?.('traceability_lotcode'),
    dataIndex: 'tlcSource',
    tooltip: {
      title: (
        <span className={styles.tooltiplink}>
          {t?.('traceability_info_tooltip')}{' '}
          <Typography.Link
            href="https://www.fda.gov/food/food-safety-modernization-act-fsma/traceability-lot-code"
            target="_blank"
          >
            {t?.('traceability_info_tooltip_link')}
          </Typography.Link>
          .
        </span>
      ),
      icon: <InfoCircleOutlined />,
    },
    valueType: 'select',
    formItemProps: {
      rules: [
        {
          required: false,
          message: t?.('tlcs_required'),
        },
      ],
    },
    fieldProps: {
      placeholder: t?.('tlcs_placeholder'),
    },
    renderFormItem: (_, { recordKey, record }, form) => (
      <TraceabilityLotCodeSourceSelect
        size="small"
        isAddProduct
        allowLotSerial
        onChange={(value, option: any) => {
          const { setFieldsValue } = form;
          if (option && option?.itemProps) {
            const item: any = option?.itemProps;
            setFieldsValue({
              [String(recordKey)]: {
                tlcSourceDetails: { ...item?.address, name: item?.name },
                tlcSource: item?.name,
              },
            });
          }
        }}
        onClear={() => {
          const { setFieldsValue } = form;
          setFieldsValue({
            [String(recordKey)]: { tlcSource: undefined, tlcSourceDetails: undefined },
          });
        }}
        allowClear
        eventId={record?.eventId || ''}
        updateListHander={(updatedList: DefaultOptionType[]) => {
          setNewCompanyLocationList(updatedList);
        }}
        newCompanyLocationList={newCompanyLocationList}
      />
    ),
    render: (text, record: any) => record?.tlcSource || '',
    ...columnProps?.tlcSource,
    hideInTable: featureFlag.hideTraceability,
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    render: (_text, record: any) => (
      <ActionButtons
        record={record}
        showEdit={!featureFlag.hideTraceability}
        onEdit={onEditRow}
        showDelete={false}
      />
    ),
    ...columnProps?.actions,
  },
];

/**
 * ContainerTable
 * Represents a table component for displaying container product instance quantity data.
 * @param containerInstances?.results The array of container product instance quantity data to be displayed.
 * @param showHeader Boolean indicating whether to display the table header.
 * @param className Additional CSS classes for styling the table.
 * @param columnProps Additional properties for configuring each column.
 * @param identifier Identifier for distinguishing between different types of columns.
 */
const ContainerTable: FC<SsccContainerTableProps> = ({
  containerId,
  showHeader,
  className,
  columnProps,
  identifier,
  setNewCompanyLocationList,
  newCompanyLocationList,
  parentProducts,
  parentRowId,
  parentForm,
  t,
}) => {
  const [containerInstances, setContainerInstances] = useState<GetContainerInventoryResponse>({});
  const [params, setParams] = useState<GetPaginatedOtherSystemShipmentsOptionalParams>({
    pageNumber: 1,
    pageSize: 20,
    sortBy: '-eventDate',
  });
  const queryClient = useQueryClient();
  const containerItemsByContainerId = useGetProductContainerInstancesById(queryClient);

  useEffect(() => {
    let isMounted = true; // To prevent state updates on unmounted components

    const getContainerInventoryResponse = async (
      id: string,
      apiParams: GetPaginatedOtherSystemShipmentsOptionalParams,
    ) => {
      try {
        const productInventoryData = await containerItemsByContainerId.mutateAsync({
          containerId: id || '',
          params: apiParams,
        });

        if (isMounted) {
          setContainerInstances((prevInstances) => {
            // Prevent duplicate entries
            const existingIds = new Set(prevInstances?.results?.map((item) => item.id) || []);
            const newResults = productInventoryData?.results?.filter(
              (item: any) => !existingIds.has(item.id),
            );
            const updatedParentRows = parentProducts?.map(
              (p) =>
                p?.id === parentRowId
                  ? {
                      ...p,
                      containerItems: newResults?.map((item: any) => mapContainer(item)),
                    }
                  : p,
              [],
            );
            parentForm?.setFieldsValue({
              eventProducts: updatedParentRows,
            });

            return {
              ...prevInstances,
              results: [...(prevInstances?.results || []), ...(newResults || [])],
              totalItems: productInventoryData?.totalItems || prevInstances?.totalItems,
            };
          });
        }
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    };

    if (containerId) {
      getContainerInventoryResponse(containerId, params);
    }

    return () => {
      isMounted = false; // Cleanup flag
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerId, params]);

  const onTableChange = (pagination: TablePaginationConfig, sorter: any) => {
    if (pagination?.current === params?.pageNumber && pagination?.pageSize === params?.pageSize) {
      setParams({
        ...params,
        sortBy: sorter?.field
          ? `${sorter.order === 'ascend' ? '+' : '-'}${sorter.field}`
          : undefined,
        pageNumber: 1,
      });
    } else {
      setParams({
        ...params,
        pageNumber: pagination?.current,
        pageSize: pagination?.pageSize,
        sortBy: sorter?.field
          ? `${sorter.order === 'ascend' ? '+' : '-'}${sorter.field}`
          : undefined,
      });
    }
  };
  const containerSactionRef = useRef<ActionType>();
  const containerEditableFormRef = useRef<EditableFormInstance<any>>();
  const onEditRow = (actionPayload?: any) => {
    containerSactionRef.current?.startEditable(actionPayload?.id || 0);
  };
  const onSaveProduct = async (rowKey: RecordKey, data: ContainerProductInstanceResponse) => {
    containerSactionRef.current?.cancelEditable(data?.id || 0);
    const existingProduct = containerInstances?.results?.find((p) => p.id === data?.id);
    /* if (existingProduct) {
      existingProduct.tlcSource = data?.tlcSource || '';
      existingProduct.tlcSourceDetails = data?.tlcSourceDetails;
    } */
    // update product
    const updatedContainerItems = containerInstances?.results?.map((p) =>
      p.id === existingProduct?.id ? existingProduct : p,
    );

    const updatedParentRows = parentProducts?.map(
      (p) =>
        p?.id === parentRowId
          ? {
              ...p,
              containerItems: updatedContainerItems?.map((item) => mapContainer(item)),
            }
          : p,
      [],
    );
    parentForm?.setFieldsValue({
      eventProducts: updatedParentRows,
    });
  };
  const loadMoreItems = () => {
    if ((containerInstances?.results?.length || 0) < (containerInstances?.totalItems || 20)) {
      setParams((prevParams) => ({
        ...prevParams,
        pageNumber: 1,
        pageSize: containerInstances?.totalItems || 20,
      }));
    }
  };
  return (
    <div>
      <Space className={styles.marginleft}>
        <Typography.Text type="secondary">
          {containerInstances?.totalItems || 0} {t('itemstotal')}
        </Typography.Text>
      </Space>
      <GTable<ContainerProductInstanceResponse>
        key="containerSactionRef"
        actionRef={containerSactionRef}
        editableFormRef={containerEditableFormRef}
        rowKey="id"
        className={classNames('expanded-row-tbl', className)}
        columns={Columns({
          columnProps,
          identifier,
          setNewCompanyLocationList,
          newCompanyLocationList,
          onEditRow,
          t,
        })}
        options={{
          setting: false,
          reload: false,
        }}
        value={containerInstances?.results}
        showHeader={showHeader}
        editable={{
          onSave: (rowKey, data) => onSaveProduct(rowKey, data),
          onCancel: async (_rowKey, data) => {
            containerSactionRef.current?.cancelEditable(data?.id || 0);
          },
        }}
        actionsRenderOptions={{
          save: true,
          cancel: true,
        }}
        pagination={false}
        onTableChange={onTableChange}
        scroll={{ y: '30vh', x: '100%' }}
      />
      {/* Load more items button */}
      {(containerInstances?.results?.length || 0) < (containerInstances?.totalItems || 20) && (
        <Space className={styles.marginleft}>
          <Button type="link" onClick={loadMoreItems}>
            {t('loaditems')}
          </Button>
        </Space>
      )}
    </div>
  );
};
export default React.memo(ContainerTable);
