import { InfoCircleOutlined } from '@ant-design/icons';
import { ActionType, ProColumns } from '@ant-design/pro-table';
import { RecordKey } from '@ant-design/pro-utils/lib/useEditableArray';
import { Typography } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { ProductSelect } from 'components';
import ActionButtons from 'components/ActionButtons';
import GTable from 'components/GTable';
import { FC, useCallback, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useGetProductInstanceById } from 'services/api';
import { ProductResponse } from 'services/api/client/src';
import {
  getFieldsPropsAddon,
  getIdentifier,
  getInventoryPropsAddon,
  hiddencol,
  onChangeFieldsPropProduct,
} from 'utils';
import { AggregateProductItem } from '../AggregationForm.fields';
import { OutputProductsProps } from './typings';

type ColumnsType = {
  t?: TFunction<'pages', 'events.transform.form_fields'>;
  onEditRow: (actionPayload?: AggregateProductItem) => void;
  onDelRow: (actionPayload?: AggregateProductItem) => void;
  onChangeProduct: (
    value: string,
    option: DefaultOptionType | DefaultOptionType[],
    totalInventoryAtSameLocation: Number,
  ) => void;
  defaultUom?: string;
  isSerial?: boolean;
  identifier?: string;
  instanceByProductId?: any;
  locationValue: string;
};
const columns = ({
  t,
  onEditRow,
  onDelRow,
  onChangeProduct,
  defaultUom,
  isSerial,
  identifier,
  instanceByProductId,
  locationValue,
}: ColumnsType): Array<ProColumns<AggregateProductItem>> => [
  {
    title: t?.('tbl_col_product_title'),
    dataIndex: 'parentProductId',
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('tbl_col_product_title_req'),
        },
      ],
      hasFeedback: false,
    },
    renderFormItem: (_, config, form) => (
      <ProductSelect
        showExternalIdentifier
        isInsideTable
        allowLotSerial
        size="small"
        onChange={async (value, option: any) => {
          // fetch instance when a product is changed
          const productInventoryData = await instanceByProductId.mutateAsync({
            id: option?.itemProps?.id || '',
            params: {
              locationIds: [locationValue],
            },
          });
          // get total Item at a location
          const totalInventoryAtSameLocation = Number(
            productInventoryData?.results?.[0]?.productInstance?.totalInventoryAtSameLocation ||
              productInventoryData?.results?.[0]?.container?.containerItems?.[0]
                ?.totalInventoryAtSameLocation ||
              0,
          );

          onChangeFieldsPropProduct(form, config, option, {}, totalInventoryAtSameLocation);
          onChangeProduct(value, option, totalInventoryAtSameLocation);
        }}
      />
    ),
    render: (text, record) => (
      <div>
        {record.externalIdentifier ? (
          <Typography.Text>{`${record?.externalIdentifier} `}</Typography.Text>
        ) : (
          ''
        )}
        <Typography.Text type="secondary">{record?.name}</Typography.Text>
      </div>
    ),
  },
  {
    title: t?.('tbl_col_primary_id_title'),
    dataIndex: 'lotSerial',
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('tbl_col_primary_id_req'),
        },
        {
          whitespace: true,
          message: t?.('tbl_col_primary_id_req'),
        },
      ],
      hasFeedback: false,
    },
    fieldProps: {
      size: 'small',
    },
    render: (text, record) => getIdentifier(record, identifier),
  },
  {
    title: t?.('tbl_col_quantity_title'),
    dataIndex: 'quantity',
    valueType: 'digit',
    fieldProps: (form, config) =>
      getFieldsPropsAddon(form, config, defaultUom, {}, false, isSerial),
    formItemProps: (form, { rowKey }) => ({
      rules: [
        {
          required: !(form?.getFieldValue?.(String(rowKey))?.unitOfMeasure === 'item'),
          message: t?.('tbl_col_quantity_req'),
        },
      ],
      hasFeedback: false,
    }),
    render: (text, record) =>
      record?.unitOfMeasure !== 'item'
        ? `${record?.quantity || ''} ${record?.unitOfMeasure || ''}`
        : '1 item',
  },
  {
    title: t?.('tbl_col_current_inventory_title'),
    dataIndex: 'currentInventory',
    tooltip: {
      title: t?.('tbl_col_current_inventory_tooltip'),
      icon: <InfoCircleOutlined />,
    },
    fieldProps: (form, config) =>
      getInventoryPropsAddon(
        form,
        config,
        defaultUom,
        {
          disabled: true,
          placeholder: t?.('tbl_col_current_inventory_placeholder'),
        },
        isSerial,
      ),

    render: (text, record) =>
      `${record.currentInventory || 0} ${
        record?.unitOfMeasure !== 'item' ? record?.unitOfMeasure : 'items'
      }`,
  },
  {
    dataIndex: 'externalIdentifier',
    ...hiddencol,
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    render: (text, record) => (
      <ActionButtons record={record} onEdit={onEditRow} onDelete={onDelRow} />
    ),
  },
];
const OutputProducts: FC<OutputProductsProps> = ({ form, defaultUom, isSerial, identifier }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'events.transform.form_fields' });

  const queryClient = useQueryClient();
  const instanceByProductId = useGetProductInstanceById(queryClient);
  const actionRef = useRef<ActionType>();
  const { setFieldsValue, getFieldValue } = form;

  const [tmpProduct, setTmpProduct] = useState<AggregateProductItem>();

  const products: Array<AggregateProductItem> = getFieldValue('opProducts');
  const locationValue: string = getFieldValue('location');
  const setProducts = useCallback(
    (value: Array<AggregateProductItem>) =>
      setFieldsValue({
        opProducts: value,
      }),
    [setFieldsValue],
  );

  const onChangeProduct: ColumnsType['onChangeProduct'] = async (
    value,
    option,
    totalInventoryAtSameLocation,
  ) => {
    if (value && option) {
      // @ts-ignore
      const selProd: ProductResponse = option?.itemProps;

      const productData = {
        id: value || '0',
        parentProductId: selProd?.id || '0',
        name: selProd?.name || '',
        lotSerial: '',
        primaryId: '',
        currentInventory: Number(totalInventoryAtSameLocation) || 0,
        quantity: Number(selProd?.shippedInventory || 0),
        unitOfMeasure: selProd?.simpleUnitOfMeasurement || '',
        externalIdentifier: selProd?.externalIdentifier,
      };
      setTmpProduct(productData);
    }
  };
  const onEditRow = (actionPayload?: AggregateProductItem) => {
    actionRef.current?.startEditable(actionPayload?.id || 0);
  };
  const onDelRow = async (actionPayload?: AggregateProductItem) => {
    if (actionPayload) {
      setProducts(products.filter((p) => p.id !== actionPayload.id));
    }
  };
  const onSaveProduct = async (_rowKey: RecordKey, data: AggregateProductItem) => {
    actionRef.current?.cancelEditable(data?.id || 0);

    const existingProduct = products.find((product) => product.id === data?.id);
    if (existingProduct) {
      existingProduct.name = tmpProduct?.name || data?.name || '';
      existingProduct.quantity = data?.unitOfMeasure !== 'item' ? data?.quantity : 1;
      existingProduct.currentInventory =
        data?.currentInventory || tmpProduct?.currentInventory || 0;
      existingProduct.primaryId = data?.primaryId || '';
      existingProduct.lotSerial = data?.lotSerial || '';
      existingProduct.unitOfMeasure = data?.unitOfMeasure || '';
      existingProduct.externalIdentifier = data?.externalIdentifier;

      // update product
      setProducts(products.map((p) => (p.id === existingProduct.id ? existingProduct : p)));
    } else {
      const productData = {
        id: data?.id || '0',
        parentProductId: tmpProduct?.parentProductId || '0',
        name: tmpProduct?.name || '',
        primaryId: data?.primaryId || '0',
        lotSerial: data?.lotSerial || '',
        currentInventory: Number(data?.currentInventory || 0),
        quantity: data?.unitOfMeasure !== 'item' ? Number(data?.quantity || 0) : 1,
        unitOfMeasure: data?.unitOfMeasure || '',
        externalIdentifier: data?.externalIdentifier,
      };
      // add product
      setProducts([productData, ...products]);
    }
  };

  return (
    <GTable<AggregateProductItem>
      key="opProducts"
      actionRef={actionRef}
      columns={columns({
        t,
        onEditRow,
        onDelRow,
        onChangeProduct,
        defaultUom,
        isSerial,
        identifier,
        instanceByProductId,
        locationValue,
      })}
      editable={{
        onSave: (rowKey, data) => onSaveProduct(rowKey, data),
        onCancel: async (_rowKey, data) => {
          actionRef.current?.cancelEditable(data?.id || 0);
        },
      }}
      options={{
        reload: false,
        setting: false,
      }}
      actionsRenderOptions={{
        save: true,
        cancel: true,
      }}
      recordCreatorProps={false}
      onAddRecordClick={() => {
        actionRef.current?.addEditRecord?.(
          {
            id: Date.now().toString(),
          },
          {
            position: 'top',
          },
        );
      }}
      value={products}
      enableRecordCreator
      scroll={{ y: '50vh' }}
      pagination={{
        pageSize: 10,
        showSizeChanger: true,
      }}
    />
  );
};
export default OutputProducts;
