/* eslint-disable no-useless-catch */
import { BetaSchemaForm } from '@ant-design/pro-form';
import { EditableFormInstance } from '@ant-design/pro-table';
import { message, Modal } from 'antd';
import useReceiveEventActions from 'pages/AllShipments/hooks/useReceiveEventActions';
import { useShipmentStore } from 'pages/Shipments';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getCachedProductById, useCreateReceiveEvent } from 'services/api';
import {
  DirectReceiveRequest,
  ReceiveExistingProductInstance,
  ReceiveRequest,
  ReceiveResponse,
  ShipmentDetailsResponse,
} from 'services/api/client/src';
import {
  useDirectReceiveMutate,
  useGetExternalShipmentDetailsById,
  useInboundDetailsShipments,
} from 'services/api/newShipments';
import {
  apiDateFormat,
  errorHandler,
  getBizStepIdByURN,
  getCustomProperties,
  getDispositionIdByURN,
  getTimeFormat,
  getTimezoneOffset,
  mapCertProperties,
} from 'utils';
import { useEventsStore, useTitle } from '../hooks';
import AllReceiveFormFields, { DataItem } from './Forms/AllReceiveForm.fields';
import { EventPageProps } from './typings';

const Receive: FC<EventPageProps<DataItem>> = ({ form }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'events.recieve' });
  useTitle('receive');
  const {
    disabledDate,
    disabledTime,
    onChangeDate,
    onChangeTemplate,
    timeValidation,
    lastEventDate,
    defaultTimezone,
  } = useReceiveEventActions();
  const { productId: shipmentId } = useParams();
  const [queryParams] = useSearchParams();
  const shipmentIdArray = useMemo(
    () => (shipmentId?.[0] !== 'new_receive' ? shipmentId?.split(',') : ['new_receive']),
    [shipmentId],
  );
  const queryClient = useQueryClient();
  const createReceiveEvent = useCreateReceiveEvent(queryClient);
  const isOtherSystem = useMemo(() => queryParams?.get('isOtherSystem') === 'true', [queryParams]);
  const isSameLocation = useMemo(
    () => queryParams?.get('isSameLocation') === 'true',
    [queryParams],
  );
  const isPreview = useMemo(() => queryParams?.get('isPreview') === 'true', [queryParams]);
  const editableTableFormRef = useRef<EditableFormInstance<DataItem>>();
  const createDirectReceive = useDirectReceiveMutate(queryClient);
  const { hasAdvancedSettings, dataEntryMethod, template, setSubmitting } = useEventsStore();
  const defaultProduct = queryParams?.get('defaultProduct');
  const navigate = useNavigate();
  const { data: products, isLoading } = useInboundDetailsShipments(shipmentIdArray || [], {
    sortBy: '-eventDate',
    enabled: (shipmentIdArray?.length || 1) >= 1 && !isOtherSystem,
  });
  const { data: otherSystemRes } = useGetExternalShipmentDetailsById(
    shipmentIdArray || [],
    {},
    isOtherSystem,
  );
  const [validationMessage, setValidationMessage] = useState<boolean>(false);
  const { selectedShipments } = useShipmentStore();
  const hasDifferntLocations = useMemo(
    () => (shipmentIdArray?.length || 1) > 1 && !isSameLocation,
    [isSameLocation, shipmentIdArray?.length],
  );

  const eventProducts: ShipmentDetailsResponse[] | undefined = useMemo(
    () =>
      isOtherSystem
        ? otherSystemRes?.map(
            (data) => ({
              shipmentId: data?.shipmentId || '',
              hasNewProducts: data?.hasNewProducts,
              productInstances: data?.instances?.length
                ? data?.instances?.map(
                    (item) => ({
                      id: item?.id,
                      quantity: item?.quantity,
                      lotSerial: item?.lotSerial,
                      isSSCC: false,
                      urn: item?.originUrn,
                      product: {
                        id: item?.productId,
                        name: item?.productName,
                        productIdentifierType: item?.productIdentifierType,
                        currentInventory: 0,
                        currentInventoryTotal: 0,
                        urn: item?.productUrn,
                        unitQuantity: item?.unitQuantity,
                        simpleUnitOfMeasurement: item?.unitOfMeasure,
                        unitOfMeasure: item?.unitOfMeasure,
                        unitDescriptor: item?.unitDescriptor,
                      },
                    }),
                    [],
                  )
                : [],
              containers: data?.containers?.length
                ? data?.containers?.map(
                    (item) => ({
                      externalShipmentId: item?.externalShipmentId,
                      id: item?.containerId,
                      containerIdentifier: item?.containerIdentifier,
                      urn: item?.urn,
                      eventId: item?.shipmentEventId,
                      productInstances: item?.instances?.map(
                        (i) => ({
                          id: i?.id,
                          productId: i?.productId,
                          productName: i?.productName,
                          locationName: i?.destinationName,
                          lotSerial: i?.lotSerial,
                          quantity: i?.quantity,
                          unitQuantity: i?.unitQuantity,
                          unitDescriptor: i?.unitDescriptor,
                          eventType: 'Ship',
                          address: i?.originAddress,
                          simpleUnitOfMeasurement: i?.unitOfMeasure,
                          productIdentifierType: i?.productIdentifierType,
                          productGtin: i?.productGtin,
                          productUrn: i?.productUrn,
                          totalInventoryAtLocation: i?.quantity,
                        }),
                        [],
                      ),
                    }),
                    [],
                  )
                : [],
            }),
            [],
          )
        : products?.map((product) => ({
            ...product,
            productInstances: product?.productInstances?.map((instance) => ({
              ...instance,
              product: instance?.aliasProduct || instance?.product,
              ogProduct: instance?.product,
            })),
          })),
    [isOtherSystem, otherSystemRes, products],
  );

  const initProduct = useCallback(async () => {
    if (shipmentId === 'new_receive' && defaultProduct) {
      const product = await getCachedProductById(defaultProduct);
      form?.setFieldsValue({
        parentProductId: product?.id,
        productName: product?.name || '',
        isAssignedProduct: true,
        productProps: product,
        inboundShipments: undefined,
      });
    }
  }, [defaultProduct, form, shipmentId]);

  useEffect(() => {
    initProduct();
  }, [initProduct]);

  const onReceiveSuccess = useCallback(
    (res: ReceiveResponse) => {
      if (res) {
        setSubmitting(false);
        message.destroy();
        if (isPreview) {
          navigate(-2);
        } else {
          navigate('/shipments/inbound');
        }
        if (shipmentId === 'new_receive') {
          message.success(`Successfully received ${res?.body?.[0]}! `);
        } else {
          message.success(
            `Successfully received the ${
              (shipmentIdArray?.length || 1) > 1
                ? `${shipmentIdArray?.length} shipments`
                : `${selectedShipments?.[0]?.originCompanyName} shipment`
            }! `,
          );
        }
      }
    },
    [isPreview, navigate, selectedShipments, setSubmitting, shipmentId, shipmentIdArray?.length],
  );

  const onMultiLocChange = useCallback(
    (val: boolean) => {
      if (!val) {
        Modal.info({
          title:
            'Multiple Locations will force all shipments to be received at the same company and location',
          content: 'Turning this off will remove the company and locations from each shipment.',
          okText: 'done',
          cancelText: 'Cancel',
          cancelButtonProps: {
            type: 'primary',
            shape: 'round',
            ghost: true,
          },
          okButtonProps: {
            type: 'primary',
            shape: 'round',
          },
          centered: true,
          okCancel: true,
          onCancel: () => {
            form?.setFieldsValue({
              isMultipleLocations: true,
            });
          },
        });
      }
    },
    [form],
  );

  const customValidation = useCallback(
    (formData: DataItem, isCSVUpload?: boolean) => {
      if (
        (shipmentIdArray?.length || 1) > 1 &&
        (formData?.isMultipleLocations || formData?.isMultipleDates || isCSVUpload)
      ) {
        const showError = formData?.multiReceiveObjects?.find((item) => {
          if (formData?.isMultipleLocations || isCSVUpload) {
            if (!(item?.location && item?.company)) {
              return true;
            }
          }
          if (formData?.isMultipleDates || isCSVUpload) {
            if (!(item?.date && item?.time)) {
              return true;
            }
          }
          return false;
        }, []);
        return !!showError;
      }
      return false;
    },
    [shipmentIdArray?.length],
  );

  const onMultiReceive = async (formDataReq: DataItem, isCSVUpload?: boolean) => {
    try {
      const invalid = customValidation(formDataReq, isCSVUpload);
      if (invalid) {
        setValidationMessage(true);
        setSubmitting(false);
        return;
      }

      const reqDataArray: ReceiveRequest[] = [];
      formDataReq?.multiReceiveObjects?.forEach((formData) => {
        const records: ReceiveExistingProductInstance[] = [];
        formData?.inboundShipments?.forEach((row) => {
          if (row?.isSSCC) {
            row?.containerItems?.forEach((p: any) => {
              const isProductChanged = p?.parentProductId !== p?.productId;
              const isLotSerialChanged = p?.lotSerial !== p?.primaryId;
              const transformChanges = {
                aliasProductId: isProductChanged ? p?.parentProductId : undefined,
                originalInstanceUrn:
                  isProductChanged || isLotSerialChanged ? row?.instanceUrn : undefined,
              };
              records?.push({
                id: p?.instanceId || undefined,
                quantity: Number(p.quantity || 0),
                lotSerial: p?.primaryId,
                ...transformChanges,
              });
            }, []);
          } else {
            const isProductChanged = row?.parentProductId !== row?.productId;
            const isLotSerialChanged = row?.lotSerial !== row?.primaryId;
            const transformChanges = {
              ...(isProductChanged ? { aliasProductId: row?.parentProductId } : undefined),
              ...(isProductChanged || isLotSerialChanged
                ? { originalInstanceUrn: row?.instanceUrn }
                : undefined),
            };
            records?.push({
              id: row?.instanceId || undefined,
              quantity: Number(row.quantity || 0),
              lotSerial: row?.primaryId,
              ...transformChanges,
            });
          }
        }, []);
        const reqData: ReceiveRequest = {
          ...(isOtherSystem && { externalShipmentId: formData?.shipmentId }),
          originLocationId: formData?.originLocationId || undefined,
          destinationLocationId:
            formDataReq?.isMultipleLocations || isCSVUpload
              ? formData.location
              : formDataReq?.location,
          purchaseOrder:
            formDataReq?.isMultipleDates || isCSVUpload ? formData.poNumber : formDataReq?.poNumber,
          eventTimeZone: getTimezoneOffset(
            isCSVUpload ? formData?.timeZone || formDataReq.timeZone : formDataReq.timeZone,
          ),
          bizStepId: getBizStepIdByURN(
            isCSVUpload ? formData?.bizStep || formDataReq.bizStep : formDataReq.bizStep,
          ),
          dispositionId: getDispositionIdByURN(
            isCSVUpload
              ? formData?.disposition || formDataReq.disposition
              : formDataReq.disposition,
          ),
          eventTime:
            formDataReq?.isMultipleDates || isCSVUpload
              ? apiDateFormat(formData?.date, getTimeFormat(formData?.time))
              : apiDateFormat(formDataReq?.date, getTimeFormat(formDataReq?.time)),
          products: records,
          containerIdentifiers:
            formData?.inboundShipments
              ?.filter((item) => item?.isSSCC, [])
              ?.map((item) => item?.id || '', []) || [],
          documentsIds: formData?.documents?.map((doc) => doc?.id || '') || [],
          templateId: template?.id,
          masterData: getCustomProperties(formData?.customProperties, template) || [],
          certifications: mapCertProperties(formData?.certificationList || []),
        };

        reqDataArray?.push(reqData);
      }, []);

      const res = await createReceiveEvent.mutateAsync({
        receives: reqDataArray,
      });

      onReceiveSuccess(res);
    } catch (error) {
      throw error;
    }
  };

  /* form submit */
  const onManualReceive = async (formData: DataItem) => {
    const records: ReceiveExistingProductInstance[] = [];
    try {
      formData?.inboundShipments?.forEach((row) => {
        if (row?.isSSCC) {
          row?.containerItems?.forEach((p: any) => {
            const isProductChanged = p?.parentProductId !== p?.productId;
            const isLotSerialChanged = p?.lotSerial !== p?.primaryId;
            const transformChanges = {
              aliasProductId: isProductChanged ? p?.parentProductId : undefined,
              originalInstanceUrn:
                isProductChanged || isLotSerialChanged ? row?.instanceUrn : undefined,
            };
            records?.push({
              id: p?.instanceId || undefined,
              quantity: Number(p.quantity || 0),
              lotSerial: p?.primaryId,
              ...transformChanges,
            });
          }, []);
        } else {
          const isProductChanged = row?.parentProductId !== row?.productId;
          const isLotSerialChanged = row?.lotSerial !== row?.primaryId;
          const transformChanges = {
            ...(isProductChanged ? { aliasProductId: row?.parentProductId } : undefined),
            ...(isProductChanged || isLotSerialChanged
              ? { originalInstanceUrn: row?.instanceUrn }
              : undefined),
          };
          records?.push({
            id: row?.instanceId || undefined,
            quantity: Number(row.quantity || 0),
            lotSerial: row?.primaryId,
            ...transformChanges,
          });
        }
      }, []);

      const reqData: ReceiveRequest = {
        ...(isOtherSystem && { externalShipmentId: selectedShipments?.[0]?.shipmentId }),
        originLocationId: selectedShipments?.[0]?.originLocationId || undefined,
        destinationLocationId: formData.location,
        purchaseOrder: formData.poNumber,
        eventTimeZone: getTimezoneOffset(formData.timeZone),
        bizStepId: getBizStepIdByURN(formData.bizStep),
        dispositionId: getDispositionIdByURN(formData.disposition),
        eventTime: apiDateFormat(formData?.date, getTimeFormat(formData?.time)),
        products: records,
        containerIdentifiers:
          formData?.inboundShipments
            ?.filter((item) => item?.isSSCC, [])
            ?.map((item) => item?.id || '', []) || [],
        documentsIds: formData?.documents?.map((doc) => doc?.id || ''),
        templateId: template?.id,
        masterData: getCustomProperties(formData?.customProperties, template),
        certifications: mapCertProperties(formData?.certificationList || []),
      };

      const res = await createReceiveEvent.mutateAsync({
        receives: [reqData],
      });

      onReceiveSuccess(res);
    } catch (error) {
      throw error;
    }
  };

  const onDirectReceive = useCallback(
    async (formData: DataItem) => {
      if (!formData?.inboundShipments?.length) {
        message.error('Please provide receive item details.');
        setSubmitting(false);
        return;
      }
      const req: DirectReceiveRequest = {
        purchaseOrder: formData?.poNumber,
        bizStepId: getBizStepIdByURN(formData.bizStep),
        dispositionId: getDispositionIdByURN(formData.disposition),
        eventTime: apiDateFormat(formData?.date, getTimeFormat(formData?.time)),
        eventTimeZone: getTimezoneOffset(formData.timeZone),
        documentsIds: formData?.documents?.map((doc) => doc?.id || '') || [],
        masterData: getCustomProperties(formData?.customProperties, template),
        certifications: mapCertProperties(formData?.certificationList || []),
        templateId: template?.id,
        locationId: formData?.location,
        ...(formData?.isMultipleProducts
          ? {
              containerIdentifier: formData?.sscc,
              containerProductInstances:
                formData?.inboundShipments?.map(
                  (item) => ({
                    productId: item?.parentProductId,
                    quantity: Number(item?.quantity),
                    lotSerial: item?.primaryId,
                  }),
                  [],
                ) || [],
            }
          : {
              productId: formData?.parentProductId,
              productInstances:
                formData?.inboundShipments?.map(
                  (item) => ({
                    quantity: Number(item?.quantity),
                    lotSerial: item?.primaryId,
                  }),
                  [],
                ) || [],
            }),
      };

      const res = await createDirectReceive?.mutateAsync({
        options: {
          body: {
            directReceives: [req],
          },
        },
      });
      if (res) {
        onReceiveSuccess({
          body: [
            formData?.isMultipleProducts
              ? formData?.scss || ''
              : `in ${formData?.productName}` || '',
          ],
        });
      }
    },
    [createDirectReceive, onReceiveSuccess, setSubmitting, template],
  );

  const onFinish = async (formData: DataItem) => {
    try {
      setSubmitting(true);
      if (shipmentId === 'new_receive') {
        // for both manual & upload of direct receive
        await onDirectReceive(formData);
      } else if ((shipmentIdArray?.length || 1) > 1) {
        // for both manual & upload multi receive
        await onMultiReceive(formData, dataEntryMethod === 'csvUpload');
      } else {
        // for manual single shipment receive
        await onManualReceive(formData);
      }
    } catch (error) {
      setSubmitting(false);
      message.destroy();
      const errMsg = errorHandler(error);
      if (errMsg !== 'SIMILAR_PRODUCT_ERROR') {
        setTimeout(() => {
          message.error(errMsg);
        }, 500);
      }
    }
  };

  return (
    <BetaSchemaForm<DataItem>
      columns={AllReceiveFormFields({
        t,
        hasAdvancedSettings,
        dataEntryMethod,
        eventProducts,
        isLoading,
        editableTableFormRef,
        defaultTimezone,
        shipmentIdArray,
        lastEventDate,
        disabledDate,
        disabledTime,
        onChangeDate,
        onChangeTemplate,
        timeValidation,
        receiveForm: form,
        hasDifferntLocations,
        onMultiLocChange,
        validationMessage,
      })}
      form={form}
      grid
      rowProps={{ gutter: 32 }}
      layoutType="Form"
      onFinish={onFinish}
      submitter={{ render: () => null }}
    />
  );
};

export default React.memo(Receive);
