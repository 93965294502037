import { ProFormInstance } from '@ant-design/pro-form';
import { message, Modal } from 'antd';
import { useCallback, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { CreateCaseLabelRequest } from 'services/api/client/src';
import {
  useCreateCaseLabel,
  useDeleteCaseLabelFieldSettings,
  useSaveFieldSettings,
  useUpdateCaseLabel,
  useUploadLogo,
} from 'services/api/useLabels';
import { errorHandler } from 'utils';
import { AddEditProps, DataItem } from './typings';

const useAddEditActions = ({ t, modal, labelId }: AddEditProps) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const modalFormRef = useRef<ProFormInstance>();
  const modalCardRef = useRef<ProFormInstance>();
  const [{ warning }, contextHolder] = Modal.useModal();
  const queryClient = useQueryClient();
  const createNewCase = useCreateCaseLabel(queryClient);
  const uploadLogoAPI = useUploadLogo(queryClient);
  const saveFields = useSaveFieldSettings(queryClient);
  const updateLabelAPI = useUpdateCaseLabel(queryClient);
  const deleteAttribute = useDeleteCaseLabelFieldSettings(queryClient);

  const onDrawerClose = useCallback(() => {
    Modal.warning({
      title: t('exit_confirm.title'),
      content: t('exit_confirm.desc'),
      centered: true,
      okCancel: true,
      okText: t('exit_confirm.remove'),
      cancelText: t('exit_confirm.cancel'),
      okButtonProps: {
        type: 'primary',
        shape: 'round',
      },
      cancelButtonProps: {
        type: 'primary',
        ghost: true,
        shape: 'round',
      },
      onOk: () => (modal ? modal?.hide() : navigate(-1)),
    });
  }, [t, modal, navigate]);

  const onCancel = useCallback(() => (modal ? modal?.hide() : navigate(-1)), [modal, navigate]);

  const onAdd = useCallback(() => {
    modalFormRef?.current?.submit();
    modalCardRef?.current?.submit();
  }, []);
  const printConfirm = useCallback(
    async () =>
      new Promise((resolve) => {
        Modal.warning({
          title: t('print_confirm.title'),
          content: t('print_confirm.desc'),
          centered: true,
          okCancel: true,
          okText: t('print_confirm.remove'),
          cancelText: t('print_confirm.cancel'),
          okButtonProps: {
            type: 'primary',
            shape: 'round',
          },
          cancelButtonProps: {
            type: 'primary',
            ghost: true,
            shape: 'round',
          },
          onCancel: () => resolve(false),
          onOk: () => resolve(true),
        });
      }),
    [t],
  );
  const addEditAPICall = useCallback(
    async (values: DataItem) => {
      try {
        const reqBody: CreateCaseLabelRequest = {
          labelName: values?.labelName,
          layout: values?.layoutType,
          productId: values?.productId,
          processedInUSA: values?.processedInUSA === 'processed_in_usa',
          usStateOfProcessing: values?.usStateOfProcessing,
          coolInformation: values?.coolInformation,
          harvestMethod: values?.harvestMethod,
          vendorID: values?.vendorID,
          supplierInformation: values?.supplierInformation,
          supplierStockNumber: values?.supplierStockNumber,
          sellingUnitUpca: values?.sellingUnitUPCA,
          uniquePackageID: values?.uniquePackageID,
          barcodeType: values?.barcodeType,
          gtin14: values?.gtin14 || undefined,
          lotSerial: values?.batchLotID,
          countryOfOrigin: values?.countryOfOrigin,
          packDate: values?.packDate ? new Date(values?.packDate) : undefined,
          sellByDate: values?.sellByDate ? new Date(values?.sellByDate) : undefined,
          bestBeforeDate: values?.bestBeforeDate ? new Date(values?.bestBeforeDate) : undefined,
          itemNumber: values?.itemNumber,
          quantity: Number(values?.quantity),
          labelCustomValues: values?.newFieldInfo?.length
            ? values?.newFieldInfo?.map(
                (item) => ({
                  name: item?.title,
                  value: item?.value,
                }),
                [],
              )
            : undefined,
          barcodeCustomValues: values?.newBarcodeFieldInfo?.length
            ? values?.newBarcodeFieldInfo?.map(
                (item) => ({
                  name: item?.title,
                  value: item?.value,
                }),
                [],
              )
            : undefined,
          printSetting: values?.printOptions || '4x6',
        };
        const labelInfo: any = labelId
          ? await updateLabelAPI?.mutateAsync({
              id: labelId,
              options: {
                body: reqBody,
              },
            })
          : await createNewCase?.mutateAsync({
              options: {
                body: reqBody,
              },
            });

        const allFields = [
          ...(values?.fieldInfo || []),
          ...(values?.newFieldInfo || []),
          ...(values?.barcodeFieldInfo || []),
          ...(values?.newBarcodeFieldInfo || []),
        ];

        if (allFields?.length) {
          const allAPIPayload = allFields?.map(
            (item) => ({
              fieldIdentifier: item?.appId || item?.dataIndex,
              isVisible: item?.visible,
              isRequired: item?.required,
              fieldSettingId: item?.id || undefined,
            }),
            [],
          );
          saveFields?.mutateAsync({
            labelId: labelInfo?.body || labelId || '',
            options: {
              body: {
                fieldSettings: allAPIPayload,
              },
            },
          });
          if (labelId) {
            const removedFields = values?.ogNewFieldInfo?.filter(
              (originalItem) =>
                !values?.newFieldInfo?.some((updatedItem) => updatedItem.id === originalItem.id),
            );
            const removedBarcodeFields = values?.ogNewBarcodeFieldInfo?.filter(
              (originalItem) =>
                !values?.newBarcodeFieldInfo?.some(
                  (updatedItem) => updatedItem.id === originalItem.id,
                ),
            );

            const allRemovedFields = [
              ...(removedFields || []),
              ...(removedBarcodeFields || []),
            ]?.filter((item) => !!item?.id, []);
            if (allRemovedFields?.length) {
              const apiCalls = allRemovedFields?.map(async (item) => {
                await deleteAttribute?.mutateAsync({
                  labelId,
                  settingId: item?.id || '',
                });
              }, []);
              Promise.all(apiCalls);
            }
          }
        }

        await uploadLogoAPI?.mutateAsync({
          id: labelInfo?.body || labelId || '',
          options: {
            brandLogo: values?.brandLogo,
            certificationLogo: values.certificationLogo,
            logo: values?.logo,
          },
        });

        message.success(t?.('success.save_label', { labelName: values.labelName }));
        setTimeout(() => {
          queryClient.invalidateQueries(['getPalletLabel', labelInfo?.body || labelId || '']);
          queryClient.invalidateQueries(['getLabelById', labelInfo?.body || labelId || '']);
          queryClient.invalidateQueries('getLabels');
          onCancel();
        }, 100);
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    [
      createNewCase,
      deleteAttribute,
      labelId,
      onCancel,
      queryClient,
      saveFields,
      t,
      updateLabelAPI,
      uploadLogoAPI,
    ],
  );

  const onSaveAsDraft = useCallback(async () => {
    try {
      if (isLoading) {
        return;
      }
      setIsLoading(true);
      // get all form values
      const cardValues = modalCardRef.current?.getFieldsValue();
      const formValues = modalFormRef.current?.getFieldsValue();
      const dataValues = {
        ...formValues,
        ...cardValues,
      };
      // perform manual validation of fields we need
      if (dataValues?.labelName && dataValues?.layoutType && dataValues?.product) {
        addEditAPICall(dataValues);
      } else {
        // call API and perform save
        message.error(t?.('error.fill_required'));
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [addEditAPICall, isLoading, t]);

  const removeConfirm = useCallback(
    async () =>
      new Promise((resolve) => {
        warning({
          title: t('remove_confirm.title'),
          content: t('remove_confirm.desc'),
          centered: true,
          okCancel: true,
          okText: t('remove_confirm.remove'),
          cancelText: t('remove_confirm.cancel'),
          okButtonProps: {
            type: 'primary',
            shape: 'round',
          },
          cancelButtonProps: {
            type: 'primary',
            ghost: true,
            shape: 'round',
          },
          onCancel: () => resolve(false),
          onOk: () => resolve(true),
        });
      }),
    [t, warning],
  );

  const setBothFormValues = useCallback((obj: any) => {
    modalFormRef?.current?.setFieldsValue(obj);
    modalCardRef?.current?.setFieldsValue(obj);
  }, []);

  return {
    navigate,
    contextHolder,
    onCancel,
    onAdd,
    modalFormRef,
    modalCardRef,
    setBothFormValues,
    removeConfirm,
    onSaveAsDraft,
    setIsLoading,
    isLoading,
    onDrawerClose,
    printConfirm,
  };
};

export default useAddEditActions;
