import { ActionType, ProColumns } from '@ant-design/pro-table';
import { Alert, Button, Col, Row, Space, Typography } from 'antd';
import { useWatch } from 'antd/lib/form/Form';
import { GTable } from 'components';
import { useModalVisibility } from 'hooks';
import useListLocation from 'hooks/useListLocation';
import moment from 'moment';
import { useEventsStore } from 'pages/Events';
import { MultiReceiveProductItem } from 'pages/Events/components/Forms/AllReceiveForm.fields';
import MultiReceiveForm from 'pages/Events/components/Sections/MultiReceiveForm';
import { useShipmentStore } from 'pages/Shipments';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ContainerProductInstanceResponse, InboundShipmentResponse } from 'services/api/client/src';
import { dateLocaleFormat, getUom } from 'utils';
import EditableEventTable from './EditableEventTable';
import useWatchValues from './hooks/useWatchValues';
import styles from './index.module.less';
import {
  MultiReceiveTableFieldsProps,
  MultiReceiveTableItem,
  MultiReceiveTableProps,
} from './typings';

// multipple receive column
const multipleReceiveColumns = ({
  onReceiveEdit,
  isMultipleLocations,
  isMultipleDates,
}: MultiReceiveTableFieldsProps): Array<ProColumns<MultiReceiveTableItem>> => [
  {
    dataIndex: 'shipmentId',
    hideInTable: true,
  },
  {
    dataIndex: 'originCompanyName',
    hideInSetting: true,
    render: (text, record) => (
      <div>
        <Typography.Text strong>{`${record?.originCompanyName} `}</Typography.Text>
        <Col>
          <Row gutter={[32, 8]}>
            <Col>
              <Typography.Text type="secondary" strong>
                SHIPMENT DETAILS
              </Typography.Text>
            </Col>
            <Col>
              <Typography.Text
                className={styles.tabledetails}
                type="secondary"
              >{`Number of Products: ${record?.numberOfProducts || '-'}`}</Typography.Text>
            </Col>
            <Col>
              <Typography.Text className={styles.tabledetails} type="secondary">{`Ship Date: ${
                moment(record?.eventDate).format('YYYY/MM/DD') || '-'
              }`}</Typography.Text>
            </Col>
            <Col>
              <Typography.Text className={styles.tabledetails} type="secondary">{`Purchase Order: ${
                record?.purchaseOrderNumber || '-'
              }`}</Typography.Text>
            </Col>
          </Row>
          <Row gutter={[32, 8]}>
            {(isMultipleLocations || isMultipleDates) && (
              <Col>
                <Typography.Text type="secondary" strong>
                  RECEIVE DETAILS
                </Typography.Text>
              </Col>
            )}
            {isMultipleLocations && (
              <>
                <Col>
                  <Typography.Text
                    className={styles.tabledetails}
                    type="secondary"
                  >{`Receive Company: ${record?.company || '-'}`}</Typography.Text>
                </Col>
                <Col>
                  <Typography.Text
                    className={styles.tabledetails}
                    type="secondary"
                  >{`Receive Location: ${record?.location || '-'}`}</Typography.Text>
                </Col>
              </>
            )}
            {isMultipleDates && (
              <>
                <Col>
                  <Typography.Text
                    className={styles.tabledetails}
                    type="secondary"
                  >{`Receive Date: ${
                    record?.date ? moment(record?.date).format('YYYY/MM/DD') : '-'
                  }`}</Typography.Text>
                </Col>
                <Col>
                  <Typography.Text
                    className={styles.tabledetails}
                    type="secondary"
                  >{`Receiver Purchase Order: ${
                    record.rpurchaseOrderNumber || '-'
                  }`}</Typography.Text>
                </Col>
              </>
            )}
          </Row>
        </Col>
      </div>
    ),
    ellipsis: true,
    width: '90%',
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    width: 150,
    hideInSetting: true,
    fixed: 'right',
    render: (text, record) =>
      record?.shipmentId ? (
        <Button
          className="actions"
          type="link"
          size="small"
          onClick={() => onReceiveEdit?.(record)}
        >
          Edit Receive Details
        </Button>
      ) : undefined,
  },
];

const MultiReceiveTable: FC<MultiReceiveTableProps> = ({
  form,
  eventProducts,
  hasDifferntLocations,
  validationMessage,
}) => {
  const sactionRef = useRef<ActionType>();
  const { selectedShipments } = useShipmentStore();
  const editModal = useModalVisibility();
  const [queryParams] = useSearchParams();
  const { setFieldsValue } = form;
  const [fetched, setFetched] = useState(false);
  const [selectedShipment, setSelectedShipment] = useState<InboundShipmentResponse>({});
  const { dataEntryMethod } = useEventsStore();
  const isCSVUpload = dataEntryMethod === 'csvUpload';
  const isMultipleLocations: boolean =
    useWatch('isMultipleLocations', form) || isCSVUpload || false;
  const isMultipleDates: boolean = useWatch('isMultipleDates', form) || isCSVUpload || false;
  const { multiReceiveObjects } = useWatchValues(form);
  const isManualEntry = dataEntryMethod === 'manual';
  const shipmentType = queryParams?.get('shipmentType');
  const { data: locations } = useListLocation({
    tradePartnerStatus: ['Self', 'NonWholechain'],
  });
  const records = useMemo(
    () =>
      selectedShipments?.map((item) => {
        const obj = multiReceiveObjects?.find((i) => i?.shipmentId === item?.shipmentId, []);
        return {
          ...item,
          date: obj?.date,
          time: obj?.time,
          location: obj?.locationName,
          company: obj?.companyName,
          rpurchaseOrderNumber: obj?.poNumber,
        };
      }, []),
    [multiReceiveObjects, selectedShipments],
  );

  // function to update receive products table data
  const SetReceiveProducts = useCallback(
    (value: MultiReceiveProductItem[]) => {
      setFieldsValue({
        multiReceiveObjects: value,
      });
    },
    [setFieldsValue],
  );

  const setMultiReceiveObj = useCallback(
    (value: boolean) => {
      setFieldsValue({
        isMultipleLocations: value,
      });
    },
    [setFieldsValue],
  );

  // function to update receive products table data
  const SetOriginalReceiveProducts = useCallback(
    (value: MultiReceiveProductItem[]) => {
      setFieldsValue({
        originalMultiReceiveObjects: value,
      });
    },
    [setFieldsValue],
  );

  // find total quantity in all lots of sscc
  const findTotalSSCCQuantity = useCallback((p: ContainerProductInstanceResponse[]) => {
    let total = 0;
    for (let i = 0; i <= Number(p?.length) - 1 || 0; i += 1) {
      total = total + Number(p?.[i]?.quantity) || 0;
    }
    return total;
  }, []);

  const showhasNewProductMessage: boolean = useMemo(
    () =>
      (shipmentType === 'Wholechain' && eventProducts?.some((item) => item?.hasNewProducts, [])) ||
      false,
    [eventProducts, shipmentType],
  );

  const modifiedProducts: MultiReceiveProductItem[] | undefined = useMemo(
    () =>
      eventProducts?.map((eventProduct) => {
        const productInstances: any =
          (eventProduct?.productInstances?.length || 0) > 0
            ? eventProduct?.productInstances?.map((i) => ({
                id: i?.id,
                instanceId: i?.id,
                quantity: i?.quantity,
                instanceQuantity: i?.quantity,
                productId: i?.product?.id || '',
                parentProductId: i?.product?.id,
                primaryId: i?.lotSerial,
                lotSerial: i?.lotSerial,
                productName: i?.product?.name,
                unitOfMeasure: getUom({ product: i?.product }) || 'LBS',
                isSerial: i?.product?.productIdentifierType === 'Serial',
                currentInventory: i?.product?.currentInventory,
                currentInventoryTotal: i?.product?.currentInventoryTotal,
                isSSCC: false,
                productUrn: i?.product?.urn,
                productIdentifierType: i?.product?.productIdentifierType,
                shipmentType: queryParams?.get('shipmentType') || '',
                instanceUrn: i?.urn,
                originalInstance: i,
                prodExtIdentifier: i?.product?.externalIdentifier,
              }))
            : [];
        const containers: any =
          (eventProduct?.containers?.length || 0) > 0
            ? eventProduct?.containers?.map((c) => ({
                id: c?.id,
                parentProductId: 'SSCC',
                primaryId: c?.containerIdentifier,
                quantity: findTotalSSCCQuantity(c?.productInstances || []),
                instanceQuantity: findTotalSSCCQuantity(c?.productInstances || []),
                isSSCC: true,
                productName: c?.productInstances?.find(
                  (i) => i?.productId !== c?.productInstances?.[0]?.productId,
                  [],
                )
                  ? 'SSCC: Multiple Products'
                  : `SSCC: ${c?.productInstances?.[0]?.productName}`,
                productIdentifierType: 'SSCC',
                unitOfMeasure: getUom({ product: c }) || 'LBS',
                shipmentType: queryParams?.get('shipmentType') || '',
                instanceUrn: c?.urn,
                ...(c.productInstances?.length && {
                  containerItems: [
                    ...(c.productInstances?.map((i) => ({
                      id: i?.id,
                      parentContainerId: c?.id,
                      // initial value
                      productId: i?.productId || '',
                      parentProductId: i?.productId,
                      productName: i?.productName,
                      instanceId: i?.id || '',
                      primaryId: i?.lotSerial || '',
                      lotSerial: i?.lotSerial || '',
                      quantity: Number(i?.quantity || 0),
                      instanceQuantity: Number(i?.quantity || 0),
                      unitOfMeasure: getUom({ product: i }) || 'LBS',
                      unitQuantity: i?.unitQuantity,
                      unitDescriptor: i?.unitDescriptor,
                      productIdentifierType: i?.productIdentifierType || 'Lot',
                      shipmentType: queryParams?.get('shipmentType') || '',
                      originalInstance: {
                        ...i,
                        product: {
                          id: i?.productId,
                          name: i?.productName,
                          unitQuantity: i?.unitQuantity,
                          unitDescriptor: i?.unitDescriptor,
                          simpleUnitOfMeasurement: getUom({ product: i }) || 'LBS' || '',
                          productIdentifierType: i?.productIdentifierType,
                        },
                      },
                    })) || []),
                  ],
                }),
              }))
            : [];
        return {
          shipmentId: eventProduct?.shipmentId,
          inboundShipments: [...productInstances, ...containers],
        };
      }, []),
    [eventProducts, findTotalSSCCQuantity, queryParams],
  );

  useEffect(() => {
    if (modifiedProducts && !fetched && eventProducts && !isCSVUpload) {
      const updatedWithLoc = modifiedProducts?.map((eventProduct) => {
        const selectedShip = selectedShipments?.find(
          (row) => row?.shipmentId === eventProduct?.shipmentId,
        );
        const locObj = locations?.find(
          (item) => item?.urn === selectedShip?.destinationLocationUrn,
          [],
        );

        return {
          ...eventProduct,
          originLocationId: selectedShip?.originLocationId || '',
          shipEventDate:
            dateLocaleFormat(
              typeof selectedShip?.eventDate === 'string'
                ? new Date(selectedShip?.eventDate)
                : selectedShip?.eventDate,
            ) || '',
          ...(hasDifferntLocations && {
            companyName: locObj?.tradePartnerName || '',
            locationName: locObj?.name || '',
            location: locObj?.id || '',
            company: locObj?.tradePartnerId || '',
          }),
        };
      }, []);
      SetReceiveProducts(updatedWithLoc);
      SetOriginalReceiveProducts(updatedWithLoc);
      if (hasDifferntLocations) {
        setMultiReceiveObj(true);
      }
      setFetched(true);
    }
  }, [
    modifiedProducts,
    fetched,
    SetReceiveProducts,
    eventProducts,
    SetOriginalReceiveProducts,
    multiReceiveObjects,
    isCSVUpload,
    hasDifferntLocations,
    setMultiReceiveObj,
    selectedShipments,
    locations,
  ]);

  const expandedRowRender = (row: InboundShipmentResponse) => (
    <EditableEventTable form={form} shipmentId={row?.shipmentId} />
  );

  const onReceiveEdit = useCallback(
    (record: InboundShipmentResponse) => {
      setSelectedShipment(record);
      editModal?.show();
    },
    [editModal],
  );

  return (
    <>
      {editModal?.visible && (
        <MultiReceiveForm modal={editModal} form={form} editableShipment={selectedShipment} />
      )}

      <Space direction="vertical">
        {isManualEntry && (
          <Space direction="vertical">
            <Typography.Text strong>Shipments</Typography.Text>
            {showhasNewProductMessage && (
              <Alert
                message="This shipment contains products that you do not currently have in your account. Receiving as is will add this product into your account or you can assign an alias."
                type="info"
                showIcon
                closable
              />
            )}
            <Typography.Text>
              Each shipment will have it&apos;s own set of custom data attributes and documents.
              Click into each shipment to add that data and other receive details and expand each
              shipment to edit lots.
            </Typography.Text>
          </Space>
        )}
        {validationMessage && (
          <Alert
            message="Please provide receive details for all shipments below. View inside each shipment to add required information."
            type="error"
            showIcon
          />
        )}

        <GTable<MultiReceiveTableItem>
          actionRef={sactionRef}
          showHeader={false}
          rowKey="shipmentId"
          key="multiReceiveTable"
          columns={multipleReceiveColumns({ onReceiveEdit, isMultipleLocations, isMultipleDates })}
          columnsState={{
            persistenceKey: 'pages-products1',
          }}
          value={records}
          expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
          options={{
            setting: false,
            reload: false,
          }}
        />
      </Space>
    </>
  );
};

export default MultiReceiveTable;
