import { InfoCircleOutlined } from '@ant-design/icons';
import { ActionType, ProColumns } from '@ant-design/pro-table';
import { RecordKey } from '@ant-design/pro-utils/lib/useEditableArray';
import { Form, message, Typography } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { ProductSelect } from 'components';
import ActionButtons from 'components/ActionButtons';
import ProductLotSelect from 'components/GSelect/ProductLotSelect';
import GTable from 'components/GTable';
import useProductStore from 'pages/Products/useProductStore';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { ProductResponse } from 'services/api/client/src';
import { useGetProductInstanceById, useMultipleProductInstances } from 'services/api/useProducts';
import {
  disableExistingLotLocations,
  getIdentifier,
  getInventoryPropsAddon,
  getIsSerial,
  getLotUomAddon,
  getProductsT,
  getQuantityAddon,
  hiddencol,
  mapInvDataItem,
  onChangeFieldsPropInstance,
  onChangeFieldsPropProduct,
} from 'utils';
import { AggregateProductItem } from '../AggregationForm.fields';
import { useEventActions } from '../hooks';
import { InputProductsProps } from './typings';

type ColumnsType = {
  t?: TFunction<'pages', 'events.transform.form_fields'>;
  onEditRow: (actionPayload?: AggregateProductItem) => void;
  onDelRow: (actionPayload?: AggregateProductItem) => void;
  onChangeProduct: (
    value: string,
    option: DefaultOptionType | DefaultOptionType[],
    totalInventoryAtSameLocation: Number,
  ) => void;
  productId?: string;
  locationId?: string;
  defaultUom?: string;
  products?: AggregateProductItem[];
  isSerial?: boolean;
  showEdit?: boolean;
  showDelete?: boolean;
  identifier?: string;
  instanceByProductId: any;
  locationValue: string;
};

const columns = ({
  t,
  onEditRow,
  onDelRow,
  onChangeProduct,
  locationId,
  defaultUom,
  products,
  isSerial,
  identifier,
  showDelete,
  showEdit,
  instanceByProductId,
  locationValue,
}: ColumnsType): Array<ProColumns<AggregateProductItem>> => [
  {
    title: t?.('tbl_col_product_title'),
    dataIndex: 'parentProductId',
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('tbl_col_product_title_req'),
        },
      ],
      hasFeedback: false,
    },
    renderFormItem: (_, config, form) => (
      <ProductSelect
        showExternalIdentifier
        isInsideTable
        allowLotSerial
        key="ipselect"
        size="small"
        onChange={async (value, option: any) => {
          // fetch instance when a product is changed
          const productInventoryData = await instanceByProductId.mutateAsync({
            id: option?.itemProps?.id || '',
            params: {
              locationIds: [locationValue],
            },
          });

          // get total Item at a location
          const totalInventoryAtSameLocation = Number(
            productInventoryData?.results?.[0]?.productInstance?.totalInventoryAtSameLocation ||
              productInventoryData?.results?.[0]?.container?.containerItems?.[0]
                ?.totalInventoryAtSameLocation ||
              0,
          );

          onChangeFieldsPropProduct(form, config, option, {}, totalInventoryAtSameLocation);
          onChangeProduct(value, option, totalInventoryAtSameLocation);
        }}
      />
    ),
    render: (text, record) => (
      <div>
        {record.externalIdentifier ? (
          <Typography.Text>{`${record?.externalIdentifier} `}</Typography.Text>
        ) : (
          ''
        )}
        <Typography.Text type="secondary">{record?.name}</Typography.Text>
      </div>
    ),
  },
  {
    title: t?.('tbl_col_primary_id_title'),
    dataIndex: 'primaryId',
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('tbl_col_primary_id_req'),
        },
      ],
      hasFeedback: false,
    },
    renderFormItem: (_, { recordKey, record }, form) => (
      <ProductLotSelect
        productId={record?.parentProductId}
        disabledItem={(item) => disableExistingLotLocations(item, products, locationId)}
        size="small"
        onChange={(value, option) => onChangeFieldsPropInstance(form, { recordKey }, option)}
      />
    ),
    render: (text, record) => getIdentifier(record, identifier),
  },
  {
    title: t?.('tbl_col_quantity_title'),
    dataIndex: 'quantity',
    valueType: 'digit',
    fieldProps: (form, config) => getLotUomAddon(form, config, defaultUom, {}, undefined, isSerial),

    formItemProps: (form, { rowKey }) => ({
      rules: [
        {
          required: !getIsSerial(products, rowKey, form),
          message: t?.('tbl_col_quantity_req'),
        },
      ],
      hasFeedback: false,
    }),
    render: (text, record) =>
      record?.unitOfMeasure !== 'item'
        ? `${record?.quantity || ''} ${getQuantityAddon(record, defaultUom)}`
        : '1 item',
  },
  {
    title: t?.('tbl_col_current_inventory_title'),
    dataIndex: 'currentInventory',
    tooltip: {
      title: t?.('tbl_col_current_inventory_tooltip'),
      icon: <InfoCircleOutlined />,
    },
    fieldProps: (form, config) =>
      getInventoryPropsAddon(
        form,
        config,
        defaultUom,
        {
          placeholder: t?.('tbl_col_current_inventory_title'),
          disabled: true,
        },
        isSerial,
      ),
    render: (text, record) =>
      `${record.currentInventory || 0} ${
        record?.unitOfMeasure !== 'item' ? record?.unitOfMeasure : 'items'
      }`,
  },
  {
    dataIndex: 'instanceInventory',
    ...hiddencol,
  },
  {
    dataIndex: 'externalIdentifier',
    ...hiddencol,
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    render: (text, record) => (
      <ActionButtons
        record={record}
        onEdit={onEditRow}
        onDelete={onDelRow}
        showEdit={showEdit}
        showDelete={showDelete}
      />
    ),
  },
];
const InputProducts: FC<InputProductsProps> = ({
  form,
  defaultUom,
  isSerial,
  identifier,
  selectedInstances,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'events.transform.form_fields' });
  const [fetched, setFetched] = useState<boolean>(false);
  const isWorkflow = useMemo(() => (selectedInstances?.length || 0) > 0, [selectedInstances]);
  const showEdit = !isWorkflow;
  const showDelete = !isWorkflow;

  const { setFieldsValue, getFieldValue } = form;
  const elocationId = Form?.useWatch?.('location', form);
  const ipactionRef = useRef<ActionType>();
  const [tmpProduct, setTmpProduct] = useState<AggregateProductItem>();
  const { productId, productIdArray, locationId } = useEventActions({
    locationId: elocationId,
  });

  const queryClient = useQueryClient();
  const instanceByProductId = useGetProductInstanceById(queryClient);
  const locationValue: string = getFieldValue('location');
  const { paramsStore } = useProductStore();
  const {
    data: dataProducts = [],
    isLoading,
    isSuccess,
  } = useMultipleProductInstances(productIdArray, [], paramsStore);
  const products: Array<AggregateProductItem> = Form?.useWatch?.('ipProducts', form) || [];
  const setProducts = useCallback(
    (value: Array<AggregateProductItem>) => {
      setFieldsValue({
        ipProducts: value,
      });
    },
    [setFieldsValue],
  );

  const getProducts = useCallback(() => {
    if (isSuccess && !fetched) {
      const modifiedProducts = dataProducts?.map(mapInvDataItem) || [];

      setProducts(modifiedProducts);
      setFetched(true);
    }

    if (isWorkflow) {
      const initProducts =
        selectedInstances?.map((p) => ({
          id: p?.id || '0',
          parentProductId: p?.productId,
          name: p?.name || '',
          primaryId: p?.primaryId || p?.id || '0',
          lotSerial: p?.lotSerial || '',
          quantity: p?.quantity || 0,
          currentInventory: p?.currentInventory || 0,
          unitOfMeasure: p?.unitOfMeasure,
          instanceInventory: p?.quantity || 0,
          productIdentifierType: p?.productIdentifierType,
          externalIdentifier: p?.externalIdentifier,
        })) || [];
      setProducts(initProducts);
    }
  }, [dataProducts, isSuccess, fetched, setProducts, isWorkflow, selectedInstances]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  const onChangeProduct: ColumnsType['onChangeProduct'] = (
    value,
    option,
    totalInventoryAtSameLocation,
  ) => {
    if (value && option) {
      // @ts-ignore
      const selProd: ProductResponse = option?.itemProps;

      setTmpProduct({
        id: value || '0',
        name: selProd?.name || '',
        parentProductId: selProd?.id || '0',
        lotSerial: '',
        primaryId: '',
        currentInventory: Number(totalInventoryAtSameLocation) || 0,
        quantity: Number(selProd?.shippedInventory || 0),
        unitOfMeasure: selProd?.simpleUnitOfMeasurement || '',
        externalIdentifier: selProd?.externalIdentifier,
      });
    }
  };
  const onEditRow = (actionPayload?: AggregateProductItem) => {
    ipactionRef.current?.startEditable(actionPayload?.id || 0);
    setTmpProduct(actionPayload);
  };
  const onDelRow = async (actionPayload?: AggregateProductItem) => {
    if (actionPayload && products.length > 1) {
      setProducts(products.filter((p) => p.id !== actionPayload.id));
    } else {
      message.error(getProductsT('delete_last_product'));
    }
  };
  const onSaveProduct = async (_rowKey: RecordKey, data: AggregateProductItem) => {
    ipactionRef.current?.cancelEditable(data?.id || 0);

    const existingProduct = products.find((product) => product.id === data?.id);
    if (existingProduct) {
      existingProduct.name = tmpProduct?.name || data?.name || '';
      existingProduct.quantity = data?.unitOfMeasure !== 'item' ? data?.quantity : 1;
      existingProduct.instanceInventory = data?.instanceInventory || 0;
      existingProduct.currentInventory =
        data?.currentInventory || tmpProduct?.currentInventory || 0;
      existingProduct.primaryId = data?.primaryId || '';
      existingProduct.lotSerial = data?.lotSerial || '';
      existingProduct.unitOfMeasure = data?.unitOfMeasure || '';
      existingProduct.productIdentifierType = data?.productIdentifierType || '';
      existingProduct.externalIdentifier = data?.externalIdentifier;

      // update product
      setProducts(products.map((p) => (p.id === existingProduct.id ? existingProduct : p)));
    } else {
      const productData = {
        id: data?.id || '0',
        name: tmpProduct?.name || '',
        parentProductId: tmpProduct?.parentProductId || '0',
        primaryId: data?.primaryId || '0',
        lotSerial: data?.lotSerial || '',
        currentInventory: Number(data?.currentInventory || tmpProduct?.currentInventory || 0),
        quantity: data?.unitOfMeasure !== 'item' ? Number(data?.quantity || 0) : 1,
        unitOfMeasure: data?.unitOfMeasure || '',
        instanceInventory: Number(data?.instanceInventory || 0),
        productIdentifierType: data?.productIdentifierType,
        externalIdentifier: data?.externalIdentifier,
      };
      // add product
      setProducts([productData, ...products]);
    }
  };

  return (
    <GTable<AggregateProductItem>
      key="ipProducts"
      actionRef={ipactionRef}
      columns={columns({
        t,
        onEditRow,
        onDelRow,
        onChangeProduct,
        productId,
        locationId,
        defaultUom,
        products,
        isSerial,
        identifier,
        showDelete,
        showEdit,
        instanceByProductId,
        locationValue,
      })}
      editable={{
        onSave: (rowKey, data) => onSaveProduct(rowKey, data),
        onCancel: async (_rowKey, data) => {
          ipactionRef.current?.cancelEditable(data?.id || 0);
        },
      }}
      options={{
        reload: false,
        setting: false,
      }}
      actionsRenderOptions={{
        save: true,
        cancel: true,
      }}
      onAddRecordClick={() => {
        ipactionRef.current?.addEditRecord?.(
          {
            id: Date.now().toString(),
          },
          {
            position: 'top',
          },
        );
      }}
      loading={isLoading}
      value={products}
      enableRecordCreator={!isWorkflow}
      scroll={{ y: '50vh' }}
      pagination={{
        pageSize: 10,
        showSizeChanger: true,
      }}
    />
  );
};
export default React.memo(InputProducts);
