import { FormInstance } from 'antd';
import { GTableDateType } from 'components/GTable/contexts/typings';
import { ShipDataItem } from 'pages/Events';
import { ProductInventoryItem } from 'pages/Products';
import { DataItem } from 'pages/Products/Actions/AddProduct/Columns';
import { EventItem } from 'pages/Products/Inventory/EventDetails/typings';
import { ShipmentStore } from 'pages/Shipments/hooks/typings';
import { Shipment } from 'pages/Shipments/typings';
import {
  ContainerProductInstanceResponse,
  EventResponse,
  ExternalProductInstanceResponse,
  ExternalShipmentContainerResponse,
  GetContainerInventoryResponse,
  InventoryResponse,
  OtherSystemShipmentResponse,
  ProductResponse,
  ShipmentResponse,
} from 'services/api/client/src';
import {
  CheckDateValidationsFn,
  FilterInvItemFn,
  GetCoreUOMOptionsFn,
  UomType,
  getProductsT,
  mapContainerFn,
  mapInvDataItemFn,
  mapNewShipItemFn,
  mapShipItemFn,
  mapShipmentContainerFn,
} from 'utils';
import {
  dateFormat,
  dateLocalFormat,
  dateLocaleFormat,
  isRejected,
  timeLocaleFormat,
} from './helpers';
import { getEventsT, getT } from './language';
import {
  certificationMappings,
  getBizStepNameByIdentifier,
  getContainerProductName,
  getDispositionNameByIdentifier,
  getQuantitySum,
  getUom,
  getUomEvent,
  isSameProducts,
  isSameUom,
  mapIProducts,
  mapOProducts,
  mapProducts,
} from './products';
import { isNullString } from './template';

export const setReceivedProduct = (
  form?: FormInstance<DataItem>,
  product?: ProductResponse,
  value?: boolean,
) => {
  form?.setFieldsValue({
    receivedProduct: value,
    name: product?.name || '',
    wholechainProductId: product?.id || '',
    epcUrn: product?.urn || '',
    gtin: product?.gtin || '',
    traceabilityType: product?.productIdentifierType || '',
    identifier: product?.urn || '',
    blockchain: product?.blockchain || '',
    unitOfMeasure: product?.simpleUnitOfMeasurement || '',
    definedUnits: !!product?.unitDescriptor || false,
    dataSharingPolicy: product?.sharingPolicy || '',
    unitDescriptor: product?.unitDescriptor || '',
    unitQuantity: product?.unitQuantity || 0,
    customDataAttributes: (product?.productMasterData?.length || 0) > 0,
    customAttributes:
      product?.productMasterData?.map((el) => ({
        id: el?.id,
        attribute: el?.name || '',
        value: el?.value || '',
        elementId: String(el?.id || ''),
      })) || [],
  });
};
export const resetReceivedProduct = (form?: FormInstance<DataItem>, props?: DataItem) => {
  form?.setFieldsValue({
    receivedProduct: false,
    name: undefined,
    epcUrn: undefined,
    gtin: undefined,
    traceabilityType: 'Lot',
    identifierType: 'My Own GTIN',
    blockchain: 'Algorand',
    dataSharingPolicy: 'Open',
    ...props,
  });
};

export const onchangeReceivedProduct = (
  key: string,
  form?: FormInstance<DataItem>,
  actual?: string | number | boolean,
  value?: string | number | boolean,
  isReceive?: boolean,
) => {
  if (actual !== value && isReceive) {
    form?.setFieldsValue({
      receivedProduct: false,
      [key]: value,
    });
  } else {
    form?.setFieldsValue({
      [key]: value,
    });
  }
};

export const isInternal = (p: ShipmentResponse | Shipment) => {
  const evt = (p as ShipmentResponse)?.event;
  const shpItem = p as Shipment;
  const isEvtInternal = evt?.shipmentType === 'Internal' || evt?.shipmentType === 'NonWholechain';
  const isShpInternal =
    shpItem?.shipmentType === 'Internal' || shpItem?.shipmentType === 'NonWholechain';

  return isEvtInternal || isShpInternal;
};

export const getAssignedProductId = (
  s?: ShipmentResponse | Shipment,
  products?: Array<ProductResponse>,
) => {
  if (s) {
    const product = (s as ShipmentResponse)?.productInstance?.product;
    const containerProduct = (s as ShipmentResponse)?.container?.productInstances?.[0];
    const shp = s as Shipment;
    const pId = shp?.productId;
    if (isInternal(s)) {
      return product?.id || containerProduct?.productId || pId;
    }

    /* check if product urn already exist in dataProducts?.results , if already exist then send id of product */
    const p = products?.find(
      (item) => item?.urn === (product?.urn || containerProduct?.productUrn || shp?.productURN),
    );
    if (p) {
      return p?.id;
    }

    return undefined;
  }
  return undefined;
};

export const receiveBtnDisabled = (fields?: GTableDateType[]) => {
  if (fields?.length) {
    const hasRejected = isRejected(fields);

    return hasRejected;
  }
  return true;
};

export const getShipmentByName = (primaryId?: string, products?: Array<ShipmentResponse>) => {
  const product = products?.find((p) => p?.productInstance?.lotSerial === primaryId);
  return product;
};

export const getShipmentContainerByName = (
  primaryId?: string,
  products?: Array<ShipmentResponse>,
) => {
  const container = products?.find((p) =>
    p?.container?.productInstances?.find((pi) => pi?.lotSerial === primaryId),
  );
  return container;
};

export const getExternalShipmentByName = (
  primaryId?: string,
  products?: Array<ExternalProductInstanceResponse>,
) => {
  const product = products?.find((p) => p?.lotSerial === primaryId);
  return product;
};
/* If array is empty then return empty string
If array length is 1 then return first element
If array length is 2 then return first element and second element with 'and' in between
If array length is more than 2 then return first element  last element with 'and' in between
eg : ['a','b','c'] => 'a,b and c'

*/
export const convertArrToPuncString = (arr?: Array<string>) => {
  const andStr = getProductsT('and');
  if (!arr?.length) {
    return '';
  }
  if (arr?.length === 1) {
    return `“${arr[0]}”`;
  }
  if (arr?.length === 2) {
    return `“${arr[0]}” ${andStr} “${arr[1]}”`;
  }
  if (arr?.length > 2) {
    const last = arr[arr.length - 1];
    const first = arr.slice(0, arr.length - 1).map((el) => `“${el}”`);
    return `${first.join(', ')} ${andStr} “${last}”`;
  }
  return '';
};

export const filterUnique = (arr: string[]) => {
  const uniqueArr = arr.filter((item, index) => arr.indexOf(item) === index);
  return uniqueArr;
};

export const getDetailsAttributes = (activeEvent?: EventResponse) => {
  const t = (key: string) => getT(`products.event_details.${String(key).toLowerCase()}`);
  const instances = activeEvent?.productInstances || [];
  const containers = activeEvent?.containers || [];
  const container = containers?.[0];
  const isSerial = instances?.[0]?.productIdentifierType === 'Serial' || false;

  const identifier = isSerial ? t?.('serial_title') : t?.('lot_title');
  const isContainer = !!((containers?.length || 0) > 0);
  const inputInstances = instances?.filter((el) => el?.transformationType === 'Input') || [];
  const outputInstances = instances?.filter((el) => el?.transformationType === 'Output') || [];
  const inputProducts = inputInstances?.map(mapIProducts) || [];
  const outputProducts = outputInstances?.map(mapOProducts) || [];
  let containerProducts = [];
  if (isContainer) {
    // previous (activeEvent?.container || activeEvent?.containers?.[0]) || [];
    const containerPInstances = containers?.[0]?.productInstances || [];

    containerProducts = containerPInstances?.map(mapProducts(isContainer)) || [];
  } else {
    containerProducts = instances?.map(mapProducts(false)) || [];
  }

  const certificationList =
    ((activeEvent?.certifications?.length || 0) > 0 &&
      activeEvent?.certifications
        ?.map(
          (item) => ({
            type: item?.type || '-',
            standard: item?.standard || '-',
            agency: item?.agency || '-',
            value: item?.value || '-',
            identification: item?.identification || '-',
          }),
          [],
        )
        ?.map((el, idx) => ({
          labels: Object.keys(el).map(
            (key) => `${getProductsT(certificationMappings[key])} (${idx + 1})`,
          ),
          values: Object.values(el)?.map((val) => val || '-'),
        }))) ||
    [];

  const lineItems = [] as Array<string>;
  const lineItemVals = [] as Array<string>;

  inputProducts?.forEach((el) => {
    lineItems.push(el.attributeProduct);
    lineItems.push(el.attributeLot);
    lineItems.push(el.attributeQuantity);

    lineItemVals.push(el?.productName);
    lineItemVals.push(el?.lotSerial);
    lineItemVals.push(el?.quantity);
  });
  outputProducts?.forEach((el) => {
    lineItems.push(el.attributeProduct);
    lineItems.push(el.attributeLot);
    lineItems.push(el.attributeQuantity);

    lineItemVals.push(el?.productName);
    lineItemVals.push(el?.lotSerial);
    lineItemVals.push(el?.quantity);
  });
  if (activeEvent?.eventType !== 'Transform') {
    containerProducts?.forEach((el) => {
      lineItems.push(el.attributeProduct);
      lineItems.push(el.attributeLot);
      lineItems.push(el.attributeQuantity);

      lineItemVals.push(el?.productName);
      lineItemVals.push(el?.lotSerial);
      lineItemVals.push(el?.quantity);
    });
  }

  const attributes = [
    t('logged_on_title'),
    t('user_title'),
    t('user_wc_id'),
    t('date_time_title'),
    t('timezone_offset_title'),
    t('bizstep_title'),
    t('disposition_title'),
    t('urn_title'),
    t('product_title'),
    isContainer ? t('sscc_title') : identifier,
    t('quantity_title'),
    ...(lineItems || []),
    ...(certificationList
      ?.map((el) => el.labels)
      ?.map((val) => val)
      ?.flat() || []),
    t('event_title'),
    t('event_location_title'),
    t('event_geolocation_title'),
    t('purchase_order_title'),
    t('data_template_title'),
    t('deleted_on_date'),
    t('error_declaration_time'),
    t('reason_for_event_declaration'),
    ...(activeEvent?.masterData?.map((item) => item.name) || []),
    t('polygon_title'),
  ];

  let quantity: number | string = '';

  if (!isContainer) {
    quantity = instances?.[0]?.quantity || outputProducts?.[0]?.quantity || '-';
  } else {
    const ctProducts = container?.productInstances || [];
    const isSameUnits = ctProducts?.every(
      (el) =>
        el?.simpleUnitOfMeasurement === ctProducts?.[0]?.simpleUnitOfMeasurement &&
        el?.unitDescriptor === ctProducts?.[0]?.unitDescriptor &&
        el?.unitQuantity === ctProducts?.[0]?.unitQuantity,
    );
    const quantitySum = ctProducts?.reduce((acc, el) => acc + (el?.quantity || 0), 0);
    if (isSameUnits) {
      quantity = `${quantitySum} ${getUomEvent({
        event: ctProducts?.[0],
      })}`;
    }

    quantity = '';
  }
  const uom = !isContainer
    ? `${
        instances?.[0]?.unitQuantity || outputInstances?.[0]?.unitQuantity
          ? `x ${instances?.[0]?.unitQuantity || outputInstances?.[0]?.unitQuantity}`
          : ''
      } ${
        instances?.[0]?.simpleUnitOfMeasurement ||
        outputInstances?.[0]?.simpleUnitOfMeasurement ||
        ''
      } ${instances?.[0]?.unitDescriptor || outputInstances?.[0]?.unitDescriptor || ''}`
    : '';

  const productName = getContainerProductName(activeEvent);

  const values = [
    dateLocalFormat(activeEvent?.loggedDate || new Date()),
    activeEvent?.tradePartner?.name,
    !isNullString(activeEvent?.location?.accountId) ? activeEvent?.location?.accountId : '-',
    dateLocaleFormat(activeEvent?.eventDate || new Date()),
    `${t('gmt_title')} ${activeEvent?.timezone}`,
    getBizStepNameByIdentifier(activeEvent?.bizStep?.urn || ''),
    getDispositionNameByIdentifier(activeEvent?.disposition?.urn || ''),
    activeEvent?.urn || '',
    !isContainer ? '' : productName,
    !isContainer
      ? ''
      : container?.containerIdentifier || containers?.[0]?.containerIdentifier || '',
    !isContainer ? '' : `${quantity} ${uom}`,
    ...(lineItemVals || []),
    ...(certificationList
      ?.map((el) => el.values)
      ?.flat()
      ?.map((val) => String(val)) || []),
    getEventsT(activeEvent?.eventType),
    activeEvent?.location?.name,
    `${activeEvent?.location?.address?.geoCoordinates?.latitude}, ${activeEvent?.location?.address?.geoCoordinates?.longitude}`,
    activeEvent?.purchaseOrder,
    activeEvent?.templateName || t('none'),
    activeEvent?.errorDeclaration?.declarationTime
      ? dateFormat(activeEvent?.errorDeclaration?.declarationTime)
      : null,
    activeEvent?.errorDeclaration?.declarationTime
      ? timeLocaleFormat(activeEvent?.errorDeclaration?.declarationTime)
      : null,
    activeEvent?.errorDeclaration?.reason,
    ...(activeEvent?.masterData?.map((item) => item?.value || '-') || []),
    activeEvent?.location?.geoJson,
  ];
  const data: Array<EventItem> = attributes
    .map((item, index) => ({
      id: index.toString(),
      attribute: item,
      values: values[index],
    }))
    ?.filter((item) => item.values?.trim());

  return data;
};

export const getShipDetailsAttributes = (activeEvent?: EventResponse) => {
  const t = (key: string) => getT(`products.event_details.${String(key).toLowerCase()}`);
  const instances = activeEvent?.productInstances || [];
  const containers = activeEvent?.containers || [];
  const container = containers?.[0];
  const isContainer = !!((containers?.length || 0) > 0);
  const inputInstances = instances?.filter((el) => el?.transformationType === 'Input') || [];
  const outputInstances = instances?.filter((el) => el?.transformationType === 'Output') || [];
  const inputProducts = inputInstances?.map(mapIProducts) || [];
  const outputProducts = outputInstances?.map(mapOProducts) || [];

  const certificationList =
    ((activeEvent?.certifications?.length || 0) > 0 &&
      activeEvent?.certifications
        ?.map(
          (item) => ({
            type: item?.type || '-',
            standard: item?.standard || '-',
            agency: item?.agency || '-',
            value: item?.value || '-',
            identification: item?.identification || '-',
          }),
          [],
        )
        ?.map((el, idx) => ({
          labels: Object.keys(el).map(
            (key) => `${getProductsT(certificationMappings[key])} (${idx + 1})`,
          ),
          values: Object.values(el)?.map((val) => val || '-'),
        }))) ||
    [];

  const lineItems = [] as Array<string>;
  const lineItemVals = [] as Array<string>;

  inputProducts?.forEach((el) => {
    lineItems.push(el.attributeProduct);
    lineItems.push(el.attributeLot);
    lineItems.push(el.attributeQuantity);

    lineItemVals.push(el?.productName);
    lineItemVals.push(el?.lotSerial);
    lineItemVals.push(el?.quantity);
  });
  outputProducts?.forEach((el) => {
    lineItems.push(el.attributeProduct);
    lineItems.push(el.attributeLot);
    lineItems.push(el.attributeQuantity);

    lineItemVals.push(el?.productName);
    lineItemVals.push(el?.lotSerial);
    lineItemVals.push(el?.quantity);
  });
  if (activeEvent?.eventType !== 'Transform' && isContainer) {
    containers?.forEach((item) => {
      lineItems.push(t('sscc_title'));
      lineItemVals.push(item?.containerIdentifier || '');
      item?.productInstances?.map(mapProducts(true))?.forEach((el) => {
        lineItems.push(el.attributeProduct);
        lineItems.push(el.attributeLot);
        lineItems.push(el.attributeQuantity);
        lineItemVals.push(el?.productName);
        lineItemVals.push(el?.lotSerial);
        lineItemVals.push(el?.quantity);
      });
    });
  }

  if (activeEvent?.eventType !== 'Transform' && !isContainer) {
    instances?.map(mapProducts(false))?.forEach((el) => {
      lineItems.push(el.attributeProduct);
      lineItems.push(el.attributeLot);
      lineItems.push(el.attributeQuantity);

      lineItemVals.push(el?.productName);
      lineItemVals.push(el?.lotSerial);
      lineItemVals.push(el?.quantity);
    });
  }

  const attributes = [
    t('logged_on_title'),
    t('user_title'),
    t('user_wc_id'),
    t('date_time_title'),
    ...(activeEvent?.timezone ? [t('timezone_offset_title')] : []),
    t('bizstep_title'),
    t('disposition_title'),
    t('urn_title'),
    t('product_title'),
    t('quantity_title'),
    ...(lineItems || []),
    ...(certificationList
      ?.map((el) => el.labels)
      ?.map((val) => val)
      ?.flat() || []),
    t('event_title'),
    t('event_location_title'),
    t('event_geolocation_title'),
    t('purchase_order_title'),
    t('data_template_title'),
    t('deleted_on_date'),
    t('error_declaration_time'),
    t('reason_for_event_declaration'),
    ...(activeEvent?.masterData?.map((item) => item.name) || []),
    t('polygon_title'),
  ];

  let quantity: number | string = '';

  if (!isContainer) {
    quantity = instances?.[0]?.quantity || outputProducts?.[0]?.quantity || '-';
  } else {
    const ctProducts = container?.productInstances || [];
    const isSameUnits = ctProducts?.every(
      (el) =>
        el?.simpleUnitOfMeasurement === ctProducts?.[0]?.simpleUnitOfMeasurement &&
        el?.unitDescriptor === ctProducts?.[0]?.unitDescriptor &&
        el?.unitQuantity === ctProducts?.[0]?.unitQuantity,
    );
    const quantitySum = ctProducts?.reduce((acc, el) => acc + (el?.quantity || 0), 0);
    if (isSameUnits) {
      quantity = `${quantitySum} ${getUomEvent({
        event: ctProducts?.[0],
      })}`;
    }

    quantity = '';
  }
  const uom = !isContainer
    ? `${
        instances?.[0]?.unitQuantity || outputInstances?.[0]?.unitQuantity
          ? `x ${instances?.[0]?.unitQuantity || outputInstances?.[0]?.unitQuantity}`
          : ''
      } ${
        instances?.[0]?.simpleUnitOfMeasurement ||
        outputInstances?.[0]?.simpleUnitOfMeasurement ||
        ''
      } ${instances?.[0]?.unitDescriptor || outputInstances?.[0]?.unitDescriptor || ''}`
    : '';

  const productName = getContainerProductName(activeEvent);

  const values = [
    dateLocalFormat(activeEvent?.loggedDate || new Date()),
    activeEvent?.tradePartner?.name,
    !isNullString(activeEvent?.location?.accountId) ? activeEvent?.location?.accountId : '-',
    dateLocaleFormat(activeEvent?.eventDate || new Date()),
    ...(activeEvent?.timezone ? [`${t('gmt_title')} ${activeEvent?.timezone}`] : []),
    getBizStepNameByIdentifier(activeEvent?.bizStep?.urn || ''),
    getDispositionNameByIdentifier(activeEvent?.disposition?.urn || ''),
    activeEvent?.urn || '',
    !isContainer ? '' : productName,
    !isContainer ? '' : `${quantity} ${uom}`,
    ...(lineItemVals || []),
    ...(certificationList
      ?.map((el) => el.values)
      ?.flat()
      ?.map((val) => String(val)) || []),
    getEventsT(activeEvent?.eventType),
    activeEvent?.location?.name,
    `${activeEvent?.location?.address?.geoCoordinates?.latitude}, ${activeEvent?.location?.address?.geoCoordinates?.longitude}`,
    activeEvent?.purchaseOrder,
    activeEvent?.templateName || t('none'),
    activeEvent?.errorDeclaration?.declarationTime
      ? dateFormat(activeEvent?.errorDeclaration?.declarationTime)
      : null,
    activeEvent?.errorDeclaration?.declarationTime
      ? timeLocaleFormat(activeEvent?.errorDeclaration?.declarationTime)
      : null,
    activeEvent?.errorDeclaration?.reason,
    ...(activeEvent?.masterData?.map((item) => item?.value || '-') || []),
    activeEvent?.location?.geoJson,
  ];
  const data: Array<EventItem> = attributes
    .map((item, index) => ({
      id: index.toString(),
      attribute: item,
      values: values[index],
    }))
    ?.filter((item) => (typeof item.values === 'string' ? item.values.trim() : item.values));

  return data;
};

export const getInventoryItem = () => {
  const storage = sessionStorage.getItem('shipment-storage');
  const store = JSON.parse(storage || '{}') as {
    state: ShipmentStore;
  };

  const inventoryItem = store?.state?.inventoryItem;
  return inventoryItem;
};

export const shipActionInvItem = () => {
  const actionInvItem = sessionStorage.getItem(
    'ship-actionpayload-storage',
  ) as ProductInventoryItem;
  // @ts-ignore
  return JSON.parse(actionInvItem) as ProductInventoryItem[];
};

export const mapContainer: mapContainerFn = (p, containerId) => {
  const preInvItem = getInventoryItem();
  const actionInvItem = shipActionInvItem();
  const rowSpecificData = actionInvItem?.find((r) => r?.containerId === containerId);

  // updated inv item from inventory to get row specific data
  const invItem = preInvItem || rowSpecificData;

  const cp = invItem?.containerItems?.find((el) => el?.id === p?.id);
  const productId = cp?.parentProductId || p?.id;
  // @ts-ignore
  const currentInventory = invItem?.currentInventory || cp?.currentInventory || 0;
  const locationName = invItem?.location || p?.address?.displayAddress || '';
  const locationId = invItem?.locationId || '';
  const tradePartnerName = invItem?.tradePartnerName || '';

  return {
    // check product id
    productId,
    parentProductId: productId,
    productName: p?.productName,
    lotSerial: p?.lotSerial,
    instanceId: p?.id,
    quantity: Number(p?.quantity || 0),
    unitQuantity: p?.unitQuantity,
    unitDescriptor: p?.unitDescriptor,
    unitOfMeasure: p?.simpleUnitOfMeasurement,
    productIdentifierType: p?.productIdentifierType,
    lastEvent: p?.eventType,
    currentInventory,
    // @ts-ignore
    currentInventoryTotal: p?.currentInventoryTotal || 0,
    definedUnits: !!p?.unitDescriptor || false,
    // locationName is missing
    locationName,
    locationId,
    address: p?.address || invItem?.address,
    tradePartnerName,
    totalInventoryAtLocation: invItem?.containerItems?.[0]?.totalInventoryAtSameLocation || 0,
    eventId: p?.eventId,
  };
};
export const mapInvDataItem: mapInvDataItemFn = (p: any) => {
  const i = p as InventoryResponse;
  const data = p as any;
  const instance = i?.productInstance;
  const container = i?.container;
  const product = i?.productInstance?.product;
  const event = i?.event;
  const containerResponse = p as GetContainerInventoryResponse;
  const isContainer =
    !!container?.containerId ||
    (containerResponse?.results?.length || 0) > 0 ||
    !!data?.containerId;

  const mainProductId = window?.location?.pathname?.split('/')?.[2];
  const preInvItem = getInventoryItem();
  const actionInvItem = shipActionInvItem();
  const rowSpecificData = actionInvItem?.find((r) => r?.containerId === data?.containerId);

  // updated inv item from inventory to get row specific data
  const invItem = preInvItem || rowSpecificData;

  const id =
    instance?.id || container?.containerId || invItem?.containerId || data?.containerId || '0';

  const lotSerial =
    instance?.lotSerial ||
    container?.containerIdentifier ||
    invItem?.containerIdentifier ||
    data?.containerIdentifier ||
    '';

  const currentInventory =
    invItem?.currentInventory ||
    invItem?.containerItems?.[0]?.totalInventoryAtSameLocation ||
    instance?.totalInventoryAtSameLocation ||
    data?.results?.[0]?.totalInventoryAtLocation ||
    p?.totalCurrentInventory ||
    0;
  return {
    id,
    parentProductId: instance?.product?.id || mainProductId,
    name: instance?.product?.name || '',
    primaryId:
      invItem?.containerId ||
      instance?.id ||
      invItem?.containerIdentifier ||
      data?.containerId ||
      '0',
    lotSerial,
    quantity: isContainer ? p?.quantity : instance?.quantity || 0,
    unitOfMeasure: isContainer
      ? getUom({ product: p })
      : getUom({ product: instance?.product /* || containerItems?.[0]  */ }),
    currentInventory,
    instanceInventory: instance?.quantity || 0,
    eventDate: event?.eventDate ? dateLocaleFormat(event?.eventDate) : '',
    eventId: event?.id,
    productIdentifierType:
      product?.productIdentifierType || data?.results?.[0]?.productIdentifierType || 'Lot',
    isContainer,
    containerItems: [],
    externalIdentifier: product?.externalIdentifier || '',
  };
};

export const mapDecommissionInvDataItem: mapInvDataItemFn = (p) => {
  const modifiedProducts = mapInvDataItem(p);
  return {
    ...modifiedProducts,
  };
};

export const mapInvItem: any /* mapInvItemFn */ = (el: any) => {
  const container = el?.container;
  const containerItems: any[] = [];
  const instance = el?.productInstance;
  const product = instance?.product;
  const event = el?.event;
  const isContainer = !!container?.containerId || false;
  const id = !isContainer ? el?.productInstance?.id : el?.container?.containerId;
  const geoCoordinates = event?.address?.geoCoordinates;
  const locationAddress = `${event?.locationName || ''} - ${
    event?.address?.displayAddress || `${geoCoordinates?.latitude},${geoCoordinates?.longitude}`
  }`;
  const unitOfMeasure =
    product?.simpleUnitOfMeasurement || container?.simpleUnitOfMeasurement || 'LBS';
  const unitDescriptor = product?.unitDescriptor || null;
  const unitQuantity = product?.unitQuantity || null;
  const containerQuantity = container?.totalQuantity;
  const quantity = !isContainer ? Number(instance?.quantity || 0) : containerQuantity;
  let productIdentifier = '';
  if (isContainer) {
    if (!container?.hasMultipleProducts && !container?.simpleUnitOfMeasurement) {
      productIdentifier = 'Serial';
    } else {
      productIdentifier = container?.productIdentifierType || 'Lot';
    }
  } else {
    productIdentifier = product?.productIdentifierType || 'Lot';
  }
  return {
    id,
    name: instance?.lotSerial || '',
    instanceId: instance?.id || '',
    primaryId: instance?.lotSerial || '',
    lotSerial: instance?.lotSerial || '',
    quantity,
    unitOfMeasure: quantity ? unitOfMeasure : '',
    unitDescriptor,
    unitQuantity,
    eventName: event?.eventType || '',
    location: event?.locationName || '',
    locationAddress,
    locationId: event?.locationId || '',
    destinationId: event?.destinationLocationId || event?.locationId,
    destinationAddress: event?.destinationAddress?.displayAddress || '',
    destinationName: event?.destinationName || '',
    eventId: event?.id || '',
    blockchain: 'Algorand',
    isContainer,
    containerId: container?.containerId || '',
    containerIdentifier: container?.containerIdentifier || '',
    containerItems: [],
    tradePartnerName: product?.accountName || '',
    address: event?.address,
    eventDate: event?.eventDate,
    loggedDate: event?.loggedDate,
    purchaseOrder: event?.purchaseOrder || '',
    shipmentType: event?.shipmentType || '',
    shipped: el?.productInstance?.shipped || containerItems?.[0]?.shipped || false,
    productIdentifierType: productIdentifier,
    // tbd: need to check if this is correct
    status: el?.event?.shipmentStatus || '',
    currentInventoryTotal: el?.container?.totalCurrentInventory,
  };
};
export const mapShipmentContainer: mapShipmentContainerFn = (p, containerUrn) => ({
  productId: p?.productId,
  productName: p?.productName,
  productGtin: p?.productGtin,
  productURN: p?.productUrn,
  primaryId: p?.lotSerial,
  instanceId: p?.id,
  quantity: Number(p?.quantity || 0),
  unitQuantity: p?.unitQuantity,
  unitDescriptor: p?.unitDescriptor,
  unitOfMeasure: p?.simpleUnitOfMeasurement,
  productIdentifierType: p?.productIdentifierType,
  instanceUrn: containerUrn || '',
});
export const mapExternalShipItem = (item: ExternalProductInstanceResponse): Shipment => ({
  ...item,
  id: item?.id,
  direction: item?.direction || '',
  ticketId: item?.ticketId || '',
  shipmentEventId: item?.id || '',
  name: item?.lotSerial || '',
  productName: item?.productName || '',
  productId: item?.productId,
  productGtin: item?.productGtin || '',
  productURN: item?.productUrn || '',
  instanceId: item?.id || '',
  primaryId: item?.lotSerial,
  lotSerial: item?.lotSerial || '',
  quantity: Number(item?.quantity || 0),
  unitOfMeasure: item?.unitOfMeasure,
  unitDescriptor: item?.unitDescriptor,
  unitQuantity: item?.unitQuantity,
  eventName: 'Ship',
  location: item?.shippedFromLocationName || '',
  originAddress: item?.originAddress,
  originId: item?.originId || '',
  originUrn: item?.originUrn || '',
  originName: item?.shippedFromLocationName || '',
  senderId: '',
  senderURN: item?.senderUrn,
  senderName: item?.shippedFromCompanyName || '',
  receiverName: item?.destinationName,
  receiverURN: item?.receiverUrn,
  receiverGtin: '',
  destinationId: item?.destinationId,
  destinationUrn: item?.destinationUrn || '',
  destinationAddress: item?.destinationAddress,
  isExternalShipment: item?.isExternalShipment,
  eventId: item?.id || '',
  blockchain: 'Algorand',
  isContainer: false,
  containerId: item?.containerId || '',
  containerIdentifier: item?.containerId || '',
  containerItems: [],
  tradePartnerName: item?.destinationName || '',
  address: item?.originAddress,
  loggedDate: dateLocalFormat(new Date(item?.loggedDate || new Date())),
  eventDate: dateLocaleFormat(new Date(item?.eventDate || new Date())),
  purchaseOrderNumber: item?.purchaseOrder || '',
  shipmentType: 'Wholechain',
  shipped: true,
  productIdentifierType: item?.productIdentifierType || 'Lot',
  externalShipmentId: item?.externalShipmentId,
});
export const mapExternalContainerShipItem = (el: ExternalShipmentContainerResponse) => {
  const container = el || null;
  const isExternalShipment = true;
  const containerItems =
    container?.instances?.map((p: ContainerProductInstanceResponse) =>
      mapShipmentContainer(p, container?.urn),
    ) || [];
  const isSameContainerProducts = isSameProducts(containerItems);
  const isUomSame = isSameUom(containerItems);
  const containerUom = isUomSame ? containerItems?.[0]?.unitOfMeasure : '';
  const containerUnitDescriptor = isUomSame ? containerItems?.[0]?.unitDescriptor : '';
  const containerUnitQuantity = isUomSame ? containerItems?.[0]?.unitQuantity : undefined;
  const containerQuantity = isUomSame ? getQuantitySum(containerItems) : undefined;
  const unitOfMeasure = containerUom;
  const unitDescriptor = containerUnitDescriptor;
  const unitQuantity = containerUnitQuantity;
  const quantity = containerQuantity;
  return {
    isExternalShipment,
    isSameContainerProducts,
    id: container?.instances?.[0]?.id,
    direction: 'Inbound',
    ticketId: container?.instances?.[0]?.ticketId || '',
    shipmentEventId: container?.instances?.[0]?.id || '',
    name: container?.instances?.[0]?.lotSerial || '',
    productName: container?.instances?.[0]?.productName || '',
    productId: container?.instances?.[0]?.productId,
    productGtin: container?.instances?.[0]?.productGtin || '',
    productURN: container?.instances?.[0]?.productUrn || '',
    instanceId: container?.instances?.[0]?.id || '',
    primaryId: container?.instances?.[0]?.lotSerial,
    lotSerial: container?.instances?.[0]?.lotSerial || '',
    eventName: 'Ship',
    location: container?.instances?.[0]?.shippedFromLocationName || '',
    originAddress: container?.instances?.[0]?.originAddress,
    originId: container?.instances?.[0]?.originId || '',
    originUrn: container?.instances?.[0]?.originUrn || '',
    originName: container?.instances?.[0]?.shippedFromLocationName || '',
    senderId: '',
    senderURN: container?.instances?.[0]?.senderUrn,
    senderName: container?.instances?.[0]?.shippedFromCompanyName || '',
    receiverName: container?.instances?.[0]?.destinationName,
    receiverURN: container?.instances?.[0]?.receiverUrn,
    receiverGtin: '',
    destinationId: container?.instances?.[0]?.destinationId,
    destinationUrn: container?.instances?.[0]?.destinationUrn || '',
    destinationAddress: container?.instances?.[0]?.destinationAddress,
    eventId: container?.instances?.[0]?.id || '',
    blockchain: 'Algorand',
    containerId: container?.instances?.[0]?.containerId || '',
    containerIdentifier: container?.instances?.[0]?.containerId || '',
    containerItems: [],
    tradePartnerName: container?.instances?.[0]?.destinationName || '',
    address: container?.instances?.[0]?.originAddress,
    loggedDate: dateLocalFormat(new Date(container?.instances?.[0]?.loggedDate || new Date())),
    eventDate: dateLocaleFormat(new Date(container?.instances?.[0]?.eventDate || new Date())),
    purchaseOrderNumber: container?.instances?.[0]?.purchaseOrder || '',
    shipmentType: 'Wholechain',
    shipped: true,
    productIdentifierType: container?.instances?.[0]?.productIdentifierType || 'Lot',
    externalShipmentId: container?.instances?.[0]?.externalShipmentId,
    quantity,
    unitOfMeasure,
    unitDescriptor,
    unitQuantity,
    isContainer: true,
  };
};
export const mapShipInvItem: mapShipItemFn = (el) => {
  const container = el?.container;
  const instance = el?.productInstance;
  const product = instance?.product;
  const event = el?.event;
  const isContainer = !!container?.id || false;
  const id = el?.container?.id || el?.productInstance?.id;

  const isExternalShipment = event?.shipmentType === 'External' || false;
  const externalShipmentId = isExternalShipment ? event?.id : undefined;
  const primaryId = container?.containerIdentifier || instance?.lotSerial || '';
  const containerItems =
    container?.productInstances?.map((p) => mapShipmentContainer(p, container?.urn)) || [];

  /* container */
  const isUomSame = isSameUom(containerItems);
  const containerUom = isUomSame ? containerItems?.[0]?.unitOfMeasure : '';
  const containerUnitDescriptor = isUomSame ? containerItems?.[0]?.unitDescriptor : '';
  const containerUnitQuantity = isUomSame ? containerItems?.[0]?.unitQuantity : undefined;
  const containerQuantity = isUomSame ? getQuantitySum(containerItems) : undefined;

  const unitOfMeasure = product?.simpleUnitOfMeasurement || containerUom;
  const unitDescriptor = product?.unitDescriptor || containerUnitDescriptor;
  const unitQuantity = product?.unitQuantity || containerUnitQuantity;
  const quantity = !isContainer ? Number(instance?.quantity || 0) : containerQuantity;

  return {
    externalShipmentId,
    id,
    direction: el?.direction || '',
    ticketId: event?.ticketId || '',
    shipmentEventId: event?.id || '',
    name: instance?.lotSerial || '',
    productName: product?.name || container?.productInstances?.[0]?.productName || '',
    productId: product?.id || container?.productInstances?.[0]?.productId || '',
    productGtin: product?.gtin || '',
    productURN: product?.urn || instance?.urn || container?.productInstances?.[0]?.productUrn || '',
    instanceId: instance?.id || '',
    primaryId,
    lotSerial: instance?.lotSerial || '',
    quantity,
    unitOfMeasure,
    unitDescriptor,
    unitQuantity,
    eventName: event?.eventType || '',
    location: event?.locationName || '',
    originAddress: event?.address,
    originId: event?.locationId || '',
    originUrn: event?.originUrn || '',
    originName: event?.originName || event?.locationName || '',
    senderId: product?.accountId || '',
    senderURN: event?.senderUrn,
    senderName: event?.senderName || product?.accountName || '',
    receiverName: event?.receiverName,
    receiverURN: event?.receiverUrn,
    receiverGtin: event?.receiverGtin,
    destinationId: event?.destinationId || event?.locationId,
    destinationUrn: event?.destinationUrn || event?.destinationTradePartnerId || '',
    destinationAddress: event?.address,
    isExternalShipment,
    eventId: event?.id || '',
    blockchain: 'Algorand',
    isContainer,
    containerId: container?.id || '',
    containerIdentifier: container?.containerIdentifier || '',
    containerItems,
    tradePartnerName: product?.accountName || '',
    address: event?.address,
    loggedDate: dateLocalFormat(new Date(event?.loggedDate || new Date())),
    eventDate: dateLocaleFormat(new Date(event?.eventDate || new Date())),
    purchaseOrderNumber: event?.purchaseOrder || '',
    shipmentType: event?.shipmentType || '',
    shipped: el?.productInstance?.shipped || false,
    productIdentifierType:
      product?.productIdentifierType ||
      container?.productInstances?.[0]?.productIdentifierType ||
      'Lot',
    // tbd: need to check if this is correct
    status: el?.event?.shipmentStatus || '',
    instanceUrn: instance?.urn || container?.urn || '',
  };
};
export const mapNewShip = (el: any, isContainer: boolean = false) => {
  const container = isContainer ? el : null;
  const instance = !isContainer ? el : null;
  const product = instance?.product;
  const event = el?.event;
  const id = container?.id || instance?.id;
  const isExternalShipment = event?.shipmentType === 'External' || false;
  const externalShipmentId = isExternalShipment ? event?.id : undefined;
  const primaryId = container?.containerIdentifier || instance?.lotSerial || '';
  const containerItems: any[] = [];
  const isSameContainerProducts = !el.hasMultipleProducts || isSameProducts(containerItems);
  const isUomSame = !!el?.simpleUnitOfMeasurement;
  const containerUom = isUomSame
    ? el?.simpleUnitOfMeasurement || containerItems?.[0]?.unitOfMeasure
    : '';
  const containerUnitDescriptor = isUomSame ? containerItems?.[0]?.unitDescriptor : '';
  const containerUnitQuantity = isUomSame ? containerItems?.[0]?.unitQuantity : undefined;
  const containerQuantity = el?.totalQuantity;
  const unitOfMeasure = product?.simpleUnitOfMeasurement || containerUom;
  const unitDescriptor = product?.unitDescriptor || containerUnitDescriptor;
  const unitQuantity = product?.unitQuantity || containerUnitQuantity;
  const quantity = !isContainer ? Number(instance?.quantity || 0) : containerQuantity;
  const isMultipleProd = el?.hasMultipleProducts;
  return {
    externalShipmentId,
    id,
    direction: el?.direction || '',
    ticketId: event?.ticketId || '',
    shipmentEventId: event?.id || '',
    name: instance?.lotSerial || '',
    displayName:
      instance?.aliasProduct?.name ||
      container?.productInstances?.[0]?.aliasProductName ||
      product?.name ||
      container?.productInstances?.[0]?.productName ||
      '',
    productName: product?.name || container?.productInstances?.[0]?.productName || '',
    externalIdentifier:
      product?.productExternalIdentifier ||
      product?.externalIdentifier ||
      container?.productInstances?.[0]?.aliasProductExternalIdentifier ||
      container?.productInstances?.[0]?.productExternalIdentifier,
    productId: product?.id || container?.productInstances?.[0]?.productId || '',
    productGtin: product?.gtin || '',
    productURN: product?.urn || instance?.urn || container?.productInstances?.[0]?.productUrn || '',
    instanceId: instance?.id || '',
    primaryId,
    lotSerial: isContainer ? container?.containerIdentifier : instance?.lotSerial || '',
    quantity,
    unitOfMeasure,
    unitDescriptor,
    unitQuantity,
    eventName: event?.eventType || '',
    location: event?.locationName || '',
    originAddress: event?.address,
    originId: event?.locationId || '',
    originUrn: event?.originUrn || '',
    originName: event?.originName || event?.locationName || '',
    senderId: product?.accountId || '',
    senderURN: event?.senderUrn,
    senderName: event?.senderName || product?.accountName || '',
    receiverName: event?.receiverName,
    receiverURN: event?.receiverUrn,
    receiverGtin: event?.receiverGtin,
    destinationId: event?.destinationId || event?.locationId,
    destinationUrn: event?.destinationUrn || event?.destinationTradePartnerId || '',
    destinationAddress: event?.address,
    isExternalShipment,
    eventId: event?.id || '',
    blockchain: 'Algorand',
    isContainer,
    isSameContainerProducts,
    containerId: container?.id || '',
    containerIdentifier: container?.containerIdentifier || '',
    containerItems,
    tradePartnerName: product?.accountName || '',
    address: event?.address,
    loggedDate: dateLocalFormat(new Date(event?.loggedDate || new Date())),
    eventDate: dateLocaleFormat(new Date(event?.eventDate || new Date())),
    purchaseOrderNumber: event?.purchaseOrder || '',
    shipmentType: event?.shipmentType || '',
    shipped: isContainer ? false : product?.shipped || false,
    productIdentifierType:
      product?.productIdentifierType ||
      container?.productInstances?.[0]?.productIdentifierType ||
      'Lot',
    status: el?.event?.shipmentStatus || '',
    instanceUrn: instance?.urn || container?.urn || '',
    hideMultipleProductsDifferentUOMQuantity: isMultipleProd && !isUomSame,
  };
};
export const mapNewShipItem: mapNewShipItemFn = (el) => {
  const shipmentData: Shipment[] = [];
  el?.containers?.forEach((containerItems) => {
    shipmentData.push(mapNewShip(containerItems, true));
  });
  el?.productInstances?.forEach((containerItems) => {
    shipmentData.push(mapNewShip(containerItems, false));
  });
  return shipmentData;
};

export const mapInvShipDataItem: any = (p: any, isSscc: boolean) => {
  const i = p as any;
  const data = p as any;
  const instance = i;
  const container = i?.container;
  const product = i?.product;
  const event = i?.event;
  /* const containerResponse = p as GetContainerInventoryResponse; */
  const isContainer = isSscc;
  /* !!container?.containerId ||
    (containerResponse?.results?.length || 0) > 0 ||
    !!data?.containerId */ const mainProductId = window?.location?.pathname?.split('/')?.[2];
  const preInvItem = getInventoryItem();
  const actionInvItem = shipActionInvItem();
  const rowSpecificData = actionInvItem?.find((r) => r?.containerId === data?.containerId);

  // updated inv item from inventory to get row specific data
  const invItem = preInvItem || rowSpecificData;

  const id =
    instance?.id || container?.containerId || invItem?.containerId || data?.containerId || '0';

  const lotSerial =
    instance?.lotSerial ||
    container?.containerIdentifier ||
    invItem?.containerIdentifier ||
    data?.containerIdentifier ||
    '';

  const currentInventory =
    invItem?.currentInventory ||
    invItem?.containerItems?.[0]?.totalInventoryAtSameLocation ||
    instance?.totalInventoryAtSameLocation ||
    data?.results?.[0]?.totalInventoryAtLocation ||
    p?.totalCurrentInventory ||
    instance?.product?.currentInventoryTotal ||
    0;
  return {
    id,
    parentProductId: instance?.product?.id || mainProductId,
    name: instance?.product?.name || '',
    primaryId:
      invItem?.containerId ||
      instance?.id ||
      invItem?.containerIdentifier ||
      data?.containerId ||
      '0',
    lotSerial,
    quantity: isContainer ? p?.totalQuantity || p?.quantity : instance?.quantity || 0,
    unitOfMeasure: isContainer
      ? getUom({ product: p })
      : getUom({ product: instance?.product /* || containerItems?.[0]  */ }),
    currentInventory,
    instanceInventory: instance?.quantity || 0,
    eventDate: event?.eventDate ? dateLocaleFormat(event?.eventDate) : '',
    eventId: event?.id,
    productIdentifierType:
      product?.productIdentifierType || data?.results?.[0]?.productIdentifierType || 'Lot',
    isContainer,
    containerItems: [],
  };
};
export const mapNewShipEventItem: any = (el: any) => {
  const shipmentData: Shipment[] = [];
  el?.containers?.forEach((containerItems: any) => {
    shipmentData.push(mapInvShipDataItem(containerItems, true));
  });
  el?.productInstances?.forEach((containerItems: any) => {
    shipmentData.push(mapInvShipDataItem(containerItems, false));
  });
  return shipmentData[0];
};

export const mapNewExternalShipItem = (el: OtherSystemShipmentResponse) => {
  const shipmentData: Shipment[] = [];
  el?.containers?.forEach((containerItems: ExternalShipmentContainerResponse) => {
    shipmentData.push(mapExternalContainerShipItem(containerItems));
  });
  el?.instances?.forEach((containerItems: ExternalProductInstanceResponse) => {
    shipmentData.push(mapExternalShipItem(containerItems));
  });
  return shipmentData;
};
export const filterInvItem: FilterInvItemFn = (l) => {
  const container = l?.container;
  const instance = l?.productInstance;
  const isContainer = !!container?.containerId || false;
  const isShipped = instance?.shipped || false;
  // filter out shipped items and isContainer
  return !isShipped && !isContainer;
};
export const coreUnitofMeasureEnum = {
  LBS: 'Lbs',
  KGM: 'Kgm',
  CASES: 'Cases',
  UNITS: 'Units',
};

export const getCoreUOMOptions: GetCoreUOMOptionsFn = () => {
  const t = getProductsT;
  const coreUnitofMeasureOptions = [
    {
      label: t(coreUnitofMeasureEnum.LBS),
      value: coreUnitofMeasureEnum.LBS,
    },
    {
      label: t(coreUnitofMeasureEnum.KGM),
      value: coreUnitofMeasureEnum.KGM,
    },
    {
      label: t(coreUnitofMeasureEnum.CASES),
      value: coreUnitofMeasureEnum.CASES,
    },
    {
      label: t(coreUnitofMeasureEnum.UNITS),
      value: coreUnitofMeasureEnum.UNITS,
    },
  ];
  return coreUnitofMeasureOptions;
};

export const getCoreUOMEnum = () => {
  const options = getCoreUOMOptions();
  const uomEnum = options.reduce((acc, el) => {
    const valueKey = String(el.value || coreUnitofMeasureEnum.LBS);
    return {
      ...acc,
      [valueKey]: String(el.label),
    };
  }, {} as UomType);
  return uomEnum;
};

export const checkDateValidations: CheckDateValidationsFn = (items) => {
  // has filled date and time for every item
  const hasDateandTimeFilled = items?.every((item) => item?.date && item?.time);
  const hasLength = (items?.length || 0) > 0;
  if (!hasDateandTimeFilled && hasLength) {
    throw new Error(getT('events.events_date_time_required'));
  }
};
export const checkTlcsContainerItemsValidations = (formData: ShipDataItem) => {
  const missingTlcItems: string[] = [];

  formData?.eventProducts?.forEach((parentProduct) => {
    parentProduct?.containerItems?.forEach((item) => {
      if (!item?.tlcSource || !item?.tlcSourceDetails) {
        missingTlcItems.push(item?.instanceId || 'Unknown ID');
      }
    });
  });

  if (missingTlcItems.length > 0) {
    throw new Error(getT('events.events_tlcs_required'));
  }
};
export const checkTlcsValidations = (formData: ShipDataItem) => {
  // has filled date and time for every item
  const hasAllTlcs = formData?.eventProducts
    ?.filter((product) => !product?.isContainer || false)
    ?.every((item) => item?.tlcSource && item?.tlcSourceDetails);
  const hasLength =
    (formData?.eventProducts?.filter((product) => !product?.isContainer || false)?.length || 0) > 0;
  if (!hasAllTlcs && hasLength) {
    throw new Error(getT('events.events_tlcs_required'));
  }
};
