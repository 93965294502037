import { FormInstance } from '@ant-design/pro-form';
import { ProColumns } from '@ant-design/pro-table';
import { GTableDateType } from 'components/GTable/contexts/typings';
import { UseModalVisibilityReturnType } from 'hooks';
import { GetLocationsOptionalParams } from 'hooks/useListLocation/typings';
import { ShipNewProductItem } from 'pages/Events';
import { Shipment } from 'pages/Shipments/typings';
import React, { Key, ReactNode } from 'react';
import { TFunction } from 'react-i18next';
import {
  GetPaginatedInboundShipmentsOptionalParams,
  GetPaginatedOtherSystemShipmentsOptionalParams,
  GetPaginatedOutboundShipmentsOptionalParams,
  InboundShipmentResponse,
  ListTradePartnersOptionalParams,
  OutboundShipmentResponse,
  ShipmentDetailsResponse,
  ShipmentStatusContract,
  TradePartnerResponse,
} from 'services/api/client/src';

export const ShipmentSSCCProps = {
  productName: {
    width: 240,
    hideInTable: false,
  },
  quantity: {
    width: 215,
    hideInTable: false,
  },
  gtin: {
    width: 200,
    hideInTable: false,
  },
  lotSerial: {
    width: 200,
    hideInTable: false,
  },
  purchaseOrder: {
    hideInTable: true,
  },
  eventDate: {
    hideInTable: true,
  },
  loggedDate: {
    hideInTable: true,
  },
  blockchain: {
    hideInTable: true,
  },
  currentInventory: {
    hideInTable: true,
  },
  lastEvent: {
    hideInTable: true,
  },
  locationName: {
    hideInTable: true,
  },
  actions: {
    hideInTable: true,
  },
  primaryId: {
    hideInTable: true,
  },
  eventName: {
    hideInTable: true,
  },
  locationAddress: {
    hideInTable: true,
  },
};
export const CurrentInventorySSCCProps = {
  lotSerial: {
    width: 260,
  },
  quantity: {
    width: 180,
  },
  eventName: {
    width: 150,
  },
  locationAddress: {
    width: '30vw',
  },
  purchaseOrder: {
    width: 200,
  },
  eventDate: {
    width: 150,
    hideInTable: true,
  },
  loggedDate: {
    width: 150,
    hideInTable: true,
  },
  blockchain: {
    width: 150,
    hideInTable: true,
  },
  currentInventory: {
    hideInTable: true,
  },
  lastEvent: {
    hideInTable: true,
  },
  locationName: {
    hideInTable: true,
  },
  actions: {
    hideInTable: true,
  },
  productName: {
    hideInTable: true,
  },
  gtin: {
    hideInTable: true,
  },
  primaryId: {
    hideInTable: true,
  },
};
export interface ShipmentDetailsTableProps {
  shipmentId?: string;
}

export interface EventDetailsProps {
  modalProps?: UseModalVisibilityReturnType;
  shipmentId?: string;
  lotId?: string;
  eventId?: string;
  isContainer?: boolean;
}

export interface InboundInternalShipmentProps {
  inboundShipmentType?: string;
  showFilter?: boolean;
  selectedRowKeys: Array<Key>;
  onRowSelectionChange?: (
    selectedKeys: Array<Key>,
    currentselectedRows: Array<GTableDateType>,
  ) => void;
  isNetworkShip?: boolean;
  inboundShipParam?: GetPaginatedInboundShipmentsOptionalParams;
  inboundOtherShipParam?: GetPaginatedOtherSystemShipmentsOptionalParams;
  considerAsOutbound?: boolean;
}
export interface OutboundInternalShipmentProps {
  outboundShipmentType?: string;
  showFilter?: boolean;
  selectedRowKeys: Array<Key>;
  onRowSelectionChange?: (
    selectedKeys: Array<Key>,
    currentselectedRows: Array<GTableDateType>,
  ) => void;
  isNetworkShip?: boolean;
  outboundShipParam?: GetPaginatedOutboundShipmentsOptionalParams;
  considerAsInbound?: boolean;
}
export interface ShipmentColumnType {
  t: TFunction<'pages', 'products.current_inventory'>;
  onRowActionClick?: (actionItemKey: string, actionPayload?: ShipNewProductItem) => void;
  primaryIdSearchfilter: ProColumns;
  gtinSearchfilter: ProColumns;
  selectedRowKeys?: Array<Key>;
  selectedRows?: GTableDateType[];
  defaultUom?: string;
  isSerial?: boolean;
  identifier?: string;
  onViewEvent?: (actionPayload?: ShipNewProductItem) => void;
}
export type ShipmentColumnFunction = (
  props: ShipmentColumnType,
) => Array<ProColumns<ShipNewProductItem>>;

export interface MultiReceiveTableProps {
  form: FormInstance<any>;
  eventProducts?: ShipmentDetailsResponse[];
  hasDifferntLocations?: boolean;
  validationMessage?: boolean;
}

export interface MultiReceiveTableFieldsProps {
  onReceiveEdit?: (record: InboundShipmentResponse) => void;
  isMultipleLocations?: boolean;
  isMultipleDates?: boolean;
}

export interface EditableEventTableProps {
  shipmentId?: string;
  form: FormInstance<any>;
  isEdit?: boolean;
  editableForm?: FormInstance<any>;
  outerTableEdit?: boolean;
}

export type ColumnType = {
  onEditRow: (actionPayload?: Shipment) => void;
  isEdit?: boolean;
  quantityChangeModal?: (value: string, rowKey: string, tblForm: FormInstance<any>) => void;
  lotChangeModal?: (value: string, rowKey: string, tblForm: FormInstance<any>) => void;
};

export interface EditableMultiReceiveTableProps {
  form: FormInstance<any>;
  shipmentId?: string;
  editableShipment?: InboundShipmentResponse;
  currentForm?: FormInstance<any>;
}

export interface ShipmentsGlobalFilter {
  purchaseOrderNumber?: string;
  originTradePartnerUrn?: string[];
  originLocationUrn?: string[];
}
export interface ShipmentToolbarProps {
  t: TFunction<'pages', 'shipments'>;
  originLocationUrn?: string[];
  purchaseOrderNumber?: string;
  originTradePartnerUrn?: string[];
  onLocationChange: (originLocationUrn?: string[]) => void;
  onTradePartnerChange: (originTradePartnerUrn?: string[]) => void;
  onPOchange: (purchaseOrderNumber?: string) => void;
  tradePartnerParam?: ListTradePartnersOptionalParams;
  tradePartnerFilterItem?: (tradepartner?: TradePartnerResponse) => boolean | undefined;
  locationParam?: GetLocationsOptionalParams;
}
export interface EmptyStateProps {
  t: TFunction<'pages', 'shipments'>;
  params: any;
  shipmentType: string;
  isInbound: boolean;
}

export interface OutboundShipmentTableRowDataProps {
  record?: OutboundShipmentResponse;
  hideStatus?: boolean;
}

export interface InboundShipmentTableRowDataProps {
  record?: InboundShipmentResponse;
  showStatus?: boolean;
}

export interface ShipmentTitleProps {
  t: TFunction<'pages', 'shipments.inbound'>;
  selectedRowKeys: Array<Key>;
  selectedRows?: GTableDateType[];
  setProducts?: (products?: Shipment[] | undefined) => void;
  onDownloadCSVMultiple: (rows?: GTableDateType[] | undefined) => void;
  onRowSelectionChange?: (selectedKeys: React.Key[], currentselectedRows: GTableDateType[]) => void;
  hideTabs?: boolean;
  setSelectedShipments?: (selectedShipments: InboundShipmentResponse[]) => void;
  showFilter?: boolean;
  setShowFilter?: (showFilter: boolean) => void;
  shipmentType?: string;
  isOtherSystem?: boolean;
}
export interface ShipmentStatusProps {
  status?: string;
  t?: TFunction<'pages', 'settings.users_permission'>;
}

export interface MultiReceiveTableItem {
  shipmentId?: string;
  originCompanyName?: string;
  originLocationName?: string;
  destinationLocationId?: string;
  destinationTradePartnerId?: string;
  loggedDate?: Date;
  eventDate?: Date;
  purchaseOrderNumber?: string;
  numberOfProducts?: number;
  hasContainer?: boolean;
  shipmentStatus?: ShipmentStatusContract;
  date?: string;
  location?: string;
  company?: string;
  rpurchaseOrderNumber?: string;
}
export interface TabTitleProps {
  title: ReactNode;
  count?: number;
  showZero?: boolean;
  activeTab?: boolean;
}
