import { BetaSchemaForm } from '@ant-design/pro-form';
import { message } from 'antd';
import GCardDrawer from 'components/GCardDrawer';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { useCreatePalletLabel } from 'services/api/useLabels';
import useProducts from 'services/api/useProducts';
import { errorHandler } from 'utils';
import { queryClient } from '../../../index';
import BarcodePalletLayoutPrint from '../LabelForm/BarcodePalletLayoutPrint';
import { AddCardColumns, AddFormColumns } from './AddEditPallet.fields';
import { AddModalProps, addSubmitTypeFn, DataItem, PrintOptions } from './typings';
import useAddEditActions from './useAddEditActions';

const AddPallet: FC<AddModalProps> = ({ modal, onHeaderFooterFinish }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'labels.add_pallet' });
  const {
    contextHolder,
    onDrawerClose,
    onAdd,
    modalFormRef,
    modalCardRef,
    onCancel,
    printConfirm,
  } = useAddEditActions({
    t,
    modal,
  });

  const [printData, setPrintData] = useState<DataItem | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const createPalletAPI = useCreatePalletLabel(queryClient);
  const printRef = useRef(null);
  const [printSetting, setPrintSetting] = useState<PrintOptions>('4x6');
  // Fetch products to get the first product
  const { data: productsData } = useProducts();

  // Set default product when products are loaded
  useEffect(() => {
    if (productsData?.results && productsData.results.length > 0 && modalFormRef?.current) {
      const firstProduct = productsData.results[0];
      const fieldVals = {
        lotID: undefined,
        product: firstProduct.id,
        unitOfMeasure: firstProduct?.simpleUnitOfMeasurement,
        gtin: firstProduct?.gtin,
      };

      modalFormRef.current.setFieldsValue(fieldVals);
      if (modalCardRef?.current) {
        modalCardRef.current.setFieldsValue(fieldVals);
      }
    }
  }, [productsData, modalFormRef, modalCardRef]);

  // Create print styles based on selected format
  const getPageStyle = () => `
    @page {
      size: ${printSetting === '4x6' ? '4in 6in' : '4in 12in'};
      margin: 0;
    }
  `;

  const handlePrint = useReactToPrint({
    contentRef: printRef,
    onAfterPrint: () => {
      // Cleanup after printing
      document.body.classList.remove('printing');
      return true;
    },
    onBeforePrint: async () => {
      // Add any pre-print preparations
      document.body.classList.add('printing');
    },
    pageStyle: getPageStyle(),
  });

  const onValueChange = useCallback(
    (_: any, values: DataItem) => modalCardRef?.current?.setFieldsValue(values),
    [modalCardRef],
  );

  const handlePrintOption = (e: PrintOptions) => {
    setPrintSetting(e);
  };

  const generatePalletData = (formData: DataItem) => ({
    labelName: formData?.labelName,
    product: formData.product,
    sections: formData.formFieldList?.map((c, index) => ({
      title: c.title,
      sectionContent: c.content,
      data: c.data,
      position: index,
    })),
    sscc: formData.existingSSCC === 'Yes' ? formData?.sscc : formData.fieldSSCC,
    gtin: formData.gtin,
  });

  const onSaveAsDraft = useCallback(async () => {
    const values: DataItem = modalFormRef?.current?.getFieldsValue();
    const palletData = generatePalletData(values);
    try {
      setIsLoading(true);
      // Validate the form fields
      const isValidated = await modalFormRef.current?.validateFields();
      if (!isValidated) return;
      await createPalletAPI?.mutateAsync({
        body: {
          ...palletData,
          productId: palletData.product ?? modalFormRef.current?.getFieldValue('product'),
          isReadyToPrint: values.existingSSCC === 'Yes',
          printSetting: values.printOptions,
        },
      });
      message.success(t?.('succsess.draft_save', { labelName: palletData.labelName }));
      onCancel();
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    } finally {
      setIsLoading(false);
    }
  }, [createPalletAPI, modalFormRef, onCancel, t]);

  const handlePrintSave = useCallback(async () => {
    try {
      setIsSave(true);

      await modalFormRef.current?.validateFields();

      await onAdd();
    } catch (error) {
      setIsSave(false);
    }
  }, [modalFormRef, onAdd]);

  const onFormFinish = useCallback<addSubmitTypeFn>(
    async (formData) => {
      onHeaderFooterFinish?.(formData);
      const palletData = generatePalletData(formData);
      setPrintData(palletData);
      setTimeout(() => handlePrint(), 175);
      try {
        await createPalletAPI?.mutateAsync({
          body: {
            ...palletData,
            productId: palletData.product ?? modalFormRef.current?.getFieldValue('product'),
            isReadyToPrint: formData.existingSSCC === 'Yes',
            printSetting: formData.printOptions,
          },
        });
        message.success(t?.('succsess.draft_save', { labelName: palletData.labelName }));
        onCancel();
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      } finally {
        setIsSave(false);
      }
    },
    [createPalletAPI, handlePrint, onCancel, onHeaderFooterFinish, modalFormRef, t],
  );

  return (
    <>
      {contextHolder}
      <GCardDrawer
        t={t}
        DrawerProps={{
          ...modal,
          visible: modal ? modal?.visible : true,
          onClose: () => onDrawerClose(),
        }}
        cancelButtonProps={{
          onClick: onSaveAsDraft,
          loading: isLoading,
        }}
        saveButtonProps={{
          onClick: handlePrintSave,
          loading: isSave,
        }}
        rightColSpan="40%"
        leftChildern={
          <BetaSchemaForm<DataItem>
            formRef={modalCardRef}
            layoutType="Form"
            grid
            submitter={{
              render: () => null,
            }}
            columns={AddCardColumns({ handlePrintOption, modalFormRef })}
            //  initialValues={initialValues}
          />
          // <Card title={t('pallet.preview')}>
          //   <BarcodePalletLayout
          //     labelName={modalCardRef.current}
          //     product={formData.product}
          //     sections={formData.sections}
          //     sscc={formData.sscc}
          //     gtin={formData.gtin}
          //     t={t}
          //   />
          // </Card>
        }
        rightChildern={
          <BetaSchemaForm<DataItem>
            formRef={modalFormRef}
            layoutType="Form"
            grid
            onFinish={onFormFinish}
            onValuesChange={onValueChange}
            onFinishFailed={() => printConfirm()}
            submitter={{
              render: () => null,
            }}
            //  initialValues={initialValues}
            columns={AddFormColumns({
              t,
              isEdit: false,
              modalCardRef,
            })}
          />
        }
      />

      <BarcodePalletLayoutPrint
        ref={printRef}
        handlePrintOption={handlePrintOption}
        sections={modalFormRef.current?.getFieldValue('formFieldList') ?? []}
        sscc={printData?.fieldSSCC ?? ''}
        gtin={printData?.gtin}
        t={t}
        printOptions={printSetting}
      />
    </>
  );
};

export default React.memo(AddPallet);
