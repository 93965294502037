import { BarcodeType, DataItem, PrintOptions } from './components/typings';

export const getPrintingOption = (option: PrintOptions) => {
  switch (option) {
    case '4x6':
      return '6in 4in;';
    case '4x12':
      return '12in 4in;';
    case '4x2':
      return '4in 2in;';
    default:
      return '6in 4in;';
  }
};

export const transformBarcodeType = (data: DataItem | undefined) => {
  if (data?.barcodeType === 'GS1 Barcode') {
    return 'barcode' as BarcodeType;
  }
  if (data?.barcodeType === 'Data Matrix') {
    return 'datamatrix' as BarcodeType;
  }
  if (data?.barcodeType === 'GS1 and Data Matrix') {
    return 'gs1-datamatrix' as BarcodeType;
  }
  return 'barcode';
};

export default { getPrintingOption, transformBarcodeType };
