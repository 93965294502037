import bwipjs from '@bwip-js/browser';
import { message, Space, Typography } from 'antd';
import { format } from 'date-fns';
import JsBarcode from 'jsbarcode';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import QRCode from 'react-qr-code';
import { GetApplicationIdentifiersResponse } from 'services/api/client/src';
import { errorHandler } from 'utils';
import { v4 as uuidv4 } from 'uuid';
import styles from './index.module.less';
import { BarcodeGeneratorProps, DataItem, FieldItem } from './typings';

const BarcodeGenerator: FC<BarcodeGeneratorProps> = ({
  value,
  options = {},
  type = 'barcode',
  width = 200,
  height = 100,
  typeOption,
}) => {
  const barcodeRef = useRef<SVGSVGElement>(null);
  const dataMatrixRef = useRef<SVGSVGElement>(null);
  const [dataMatrixAis, setDataMatrixAis] = useState<string[] | null>();

  const generateBarcode = useCallback(() => {
    if (!value || !barcodeRef.current) return;

    const barcodeValue = value.replace(/[()]/g, '');
    try {
      JsBarcode(barcodeRef.current, barcodeValue, {
        width: 2,
        height: height * 0.8,
        displayValue: true,
        ...options,
        text: value,
      });
    } catch (error) {
      message.error('Failed to generate barcode');
    }
  }, [value, height, options]);

  const generateDataMatrix = useCallback(() => {
    if (!value || !dataMatrixRef.current) return;
    // Split the GS1 string by the group separator (\x1D)
    const components = value.split('\x1D');

    // Filter out any empty strings (in case of trailing separators)
    const filteredComponents = components.filter((component) => component.trim() !== '');

    setDataMatrixAis(filteredComponents);

    const properGs1Datamatrix = value.replaceAll('\x1D', '');

    try {
      const svgString = bwipjs.toSVG({
        bcid: 'gs1datamatrix',
        text: properGs1Datamatrix,
        scale: 4,
        height: height / 1.4,
        width: width / 2,
        includetext: false,
        includecheck: false,
        includecheckintext: false,
      });

      const targetSvg = dataMatrixRef.current;
      if (targetSvg) {
        // Create a new SVG element
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = svgString;

        const generatedSvg = tempDiv.querySelector('svg');
        if (generatedSvg) {
          // Create a new document fragment to hold the content
          const fragment = document.createDocumentFragment();

          // Copy all child nodes to the fragment
          while (generatedSvg.firstChild) {
            fragment.appendChild(generatedSvg.firstChild);
          }

          // Clear existing content and append new content
          while (targetSvg.firstChild) {
            targetSvg.firstChild.remove();
          }

          // Set dimensions
          targetSvg.setAttribute('width', `${(width * 1.5) / 2}px`);
          targetSvg.setAttribute('height', `${(height * 2) / 1.4}px`);

          // Copy relevant attributes
          Array.from(generatedSvg.attributes).forEach((attr) => {
            if (attr.name !== 'width' && attr.name !== 'height') {
              targetSvg.setAttribute(attr.name, attr.value);
            }
          });

          // Append the new content
          targetSvg.appendChild(fragment);
        }
      }
    } catch (error) {
      setDataMatrixAis(null);
      errorHandler(error);
    }
  }, [value, width, height]);

  useEffect(() => {
    async function generate() {
      try {
        switch (type) {
          case 'barcode':
            generateBarcode();
            break;
          case 'datamatrix':
            generateDataMatrix();
            break;
          case 'gs1-datamatrix':
            generateDataMatrix();
            generateBarcode();
            break;
          default:
            generateBarcode();
            break;
        }
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    }
    generate();
  }, [type, value, width, height, options, generateBarcode, generateDataMatrix]);

  const renderBarcodeContent = () => {
    switch (type) {
      case 'barcode':
        return <svg ref={barcodeRef} />;

      case 'qrcode':
        return value ? (
          <QRCode size={width} height={height} value={value} viewBox={`0 0 ${width} ${height}`} />
        ) : null;

      case 'datamatrix':
        return (
          <div className={styles['barcodegenerator-datamatrix']}>
            <svg ref={dataMatrixRef} />
            <Space direction="vertical" className={styles['barcodegenerator-datamatrix-pd-10']}>
              {dataMatrixAis?.map((ai) => (
                <Typography.Text
                  className={styles['barcodegenerator-datamatrix-ai-fonts']}
                  key={uuidv4()}
                  strong
                >
                  {ai}
                </Typography.Text>
              ))}
            </Space>
          </div>
        );

      case 'gs1-datamatrix':
        return (
          <div className={styles['barcodegenerator-gs1-datamatrix']}>
            <div
              className={styles['barcodegenerator-gs1-datamatrix-inner']}
              style={{
                minWidth: `${Math.min(width, height) * 1.2}px`,
              }}
            >
              <svg
                ref={dataMatrixRef}
                className={styles['barcodegenerator-gs1-datamatrix-inner-mr-20']}
              />
            </div>
            <div className={styles['barcodegenerator-gs1-datamatrix-barcode']}>
              <svg ref={barcodeRef} />
            </div>
          </div>
        );

      default:
        return <svg ref={barcodeRef} />;
    }
  };

  return (
    <div
      className={
        typeOption === 'case'
          ? styles['barcodegenerato-render-content']
          : styles['barcodegenerato-render-content-pallet']
      }
    >
      {renderBarcodeContent()}
    </div>
  );
};

export default BarcodeGenerator;

// Helper function to pad numbers to required lengths
const padNumber = (num: string | number, length: number) =>
  String(num).padStart(length, '0').slice(0, length);

/**
 * Generates a valid GS1 string from the provided data
 */
export const generateGS1String = (
  data: DataItem | null | undefined,
  t?: (key: string) => string,
  applicationIdentifierLookup?: GetApplicationIdentifiersResponse,
) => {
  if (!data) return '';

  const components: string[] = [];
  const groupSeparator = '\x1D'; // GS (Group Separator) character (please don't removed, need for barcode scanners)

  // GTIN-14 (AI: 01) - GTIN is mandatory for most GS1 applications
  if (data.gtin14) {
    const gtin = padNumber(data.gtin14, 14);
    components.push(`(01)${gtin}${groupSeparator}`);
  }

  if (data.batchLotID) {
    const batch = String(data.batchLotID).slice(0, 20);
    components.push(`(10)${batch}${groupSeparator}`);
  }

  // Pack Date (AI: 13) - Format: YYMMDD
  if (data.packDate) {
    try {
      const packDate = new Date(data.packDate);
      if (Number.isNaN(packDate.getTime())) throw new Error(`${t?.('barcode.invalid_date')}`);
      components.push(`(13)${format(packDate, 'yyMMdd')}${groupSeparator}`);
    } catch (error) {
      throw new Error(`${t?.('barcode.invalid_pack_date')}`);
    }
  }

  if (data.countryOfOrigin) {
    const countryCode = padNumber(data.countryOfOrigin, 3);
    components.push(`(422)${countryCode}${groupSeparator}`);
  }

  if (data.sscc) {
    const sscc = padNumber(data.sscc, 18);
    components.push(`(00)${sscc}${groupSeparator}`);
  }

  if (data.sellByDate) {
    try {
      const sellByDate = new Date(data.sellByDate);
      if (Number.isNaN(sellByDate.getTime())) throw new Error(`${t?.('barcode.invalid_date')}`);
      components.push(`(16)${format(sellByDate, 'yyMMdd')}${groupSeparator}`);
    } catch (error) {
      throw new Error(`${t?.('barcode.invalid_sell_date')}`, error as ErrorOptions);
    }
  }

  if (data.bestBeforeDate) {
    try {
      const bestBeforeDate = new Date(data.bestBeforeDate);
      if (Number.isNaN(bestBeforeDate.getTime()))
        throw new Error(`${t?.('labels.add_label.barcode.invalid_date')}`);
      components.push(`(15)${format(bestBeforeDate, 'yyMMdd')}${groupSeparator}`);
    } catch (error) {
      throw new Error(`${t?.('barcode.invalid_best_before')}`, error as ErrorOptions);
    }
  }

  if (data?.harvestDate) {
    try {
      const harvestDate = new Date(data.harvestDate);
      if (Number.isNaN(harvestDate.getTime()))
        throw new Error(`${t?.('labels.add_label.barcode.invalid_date')}`);
      components.push(`(7007)${format(harvestDate, 'yyMMdd')}${groupSeparator}`);
    } catch (error) {
      throw new Error(`${t?.('barcode.invalid_best_before')}`, error as ErrorOptions);
    }
  }

  if (data?.newBarcodeFieldInfo && applicationIdentifierLookup) {
    try {
      data?.newBarcodeFieldInfo?.forEach((val: FieldItem) => {
        if (val?.visible && val?.value) {
          const applicationIdentifier = applicationIdentifierLookup?.find(
            (app) => `${app?.label} (AI ${app?.applicationIdentifier})` === val.title,
            [],
          )?.applicationIdentifier;
          components.push(`(${applicationIdentifier ?? ''})${val?.value}${groupSeparator}`);
        }
      });
    } catch (error) {
      throw new Error(`${t?.('barcode.invalid_application_identifier')}`, error as ErrorOptions);
    }
  }

  const result = components.join('').slice(0, -1);
  // Final validation
  if (result.length > 48 && data.barcodeType !== 'Data Matrix') {
    return {
      success: false,
      gs1String: result.substring(0, 47),
    };
  }
  return {
    success: true,
    gs1String: result,
  };
};

export function generateVoicePickCode(
  gtin: string,
  lotId: string,
  date?: string | null | undefined,
) {
  const cleanGtin = gtin.replace(/\D/g, '');
  const cleanLot = lotId;

  // Combine GTIN, lot and optional date (in YYMMDD format)
  let plainText = cleanGtin + cleanLot;
  if (date) {
    plainText += date;
  }

  const polynomial = 0xa001;
  const table: number[] = new Array(256);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 256; i++) {
    let value = 0;
    let temp = i;

    // eslint-disable-next-line no-plusplus
    for (let j = 0; j < 8; j++) {
      // eslint-disable-next-line no-bitwise
      if ((value ^ temp) & 0x0001) {
        // eslint-disable-next-line no-bitwise
        value = (value >> 1) ^ polynomial;
      } else {
        // eslint-disable-next-line no-bitwise
        value >>= 1;
      }
      // eslint-disable-next-line no-bitwise
      temp >>= 1;
    }
    table[i] = value;
  }

  // Calculate CRC-16
  let crc = 0;
  const bytes = new TextEncoder().encode(plainText);

  // we need to use the bitwise operator to align with CRC-16 algorithm
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < bytes.length; i++) {
    // eslint-disable-next-line no-bitwise
    const index = (crc ^ bytes[i]) & 0xff;
    // eslint-disable-next-line no-bitwise
    crc = (crc >> 8) ^ table[index];
  }
  // This is to get the 4 digit voice code value
  return crc % 10000;
}
