import { FilterOutlined } from '@ant-design/icons';
import { ProColumns } from '@ant-design/pro-table';
import {
  Button,
  Col,
  ColProps,
  Empty,
  Input,
  message,
  Modal,
  Row,
  RowProps,
  Select,
  Space,
  TablePaginationConfig,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Actions, GTable, ProductSelect } from 'components';
import TitleBar from 'components/TitleBar';
import { FC, useCallback, useEffect, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { GetLabelsOptionalParams, LabelResponse } from 'services/api/client/src';
import {
  useDeleteCaseLabel,
  useDeletePalletLabel,
  useDuplicateLabel,
  useGetLabels,
} from 'services/api/useLabels';
import { errorHandler } from 'utils';
import styles from './components/index.module.less';
import { LabelTitleProps, LabelToolbarProps, MainActionItemsFn, RowRenderProps } from './typings';

const col1Props: ColProps = { xs: 24, sm: 24, md: 6, lg: 6 };
const rowProps: RowProps = { gutter: [16, 16] };

type RowActionsType = {
  t?: TFunction<'pages', 'labels'>;
};
const rowActionItems = ({ t }: RowActionsType): Array<ItemType> => [
  {
    key: 'edit',
    label: t?.('actions.edit'),
  },
  {
    key: 'duplicate',
    label: t?.('actions.duplicate'),
  },
  // {
  //   key: 'print',
  //   label: t?.('actions.print'),
  // },
  // {
  //   key: 'download',
  //   label: t?.('actions.download'),
  // },
  {
    key: 'delete',
    label: t?.('actions.delete'),
  },
];
const TypeOptions = [
  { value: 'CaseLabel', label: 'Case' },
  { value: 'PalletLabel', label: 'Pallet' },
];
const mainActionItems: MainActionItemsFn = ({ /* selectedRowKeys, */ t }) => [
  {
    key: 'add_case',
    label: t('actions.add_case'),
  },
  {
    key: 'add_pallet',
    label: t('actions.add_pallet'),
  },
];

const LabelTitle: FC<LabelTitleProps> = ({ t, showFilter, setShowFilter }) => {
  const navigate = useNavigate();

  const onActionItemClick = (actionItemKey: string) => {
    switch (actionItemKey) {
      case 'add_case':
        navigate('add_label');
        break;

      case 'add_pallet':
        navigate('add_pallet');
        break;

      default:
        break;
    }
  };
  return (
    <div className={styles.titlebarwarp}>
      <TitleBar
        title={
          <Space direction="vertical">
            <Space direction="horizontal">
              <Typography.Text>{t?.('title.label')}</Typography.Text>
              <Tooltip
                color="black"
                title={<span>{t?.('title.tooltip-desc')}</span>}
                placement="bottom"
              >
                <Tag color="processing">{t?.('title.beta')}</Tag>
              </Tooltip>
            </Space>
            <div className={styles.labeldesc}>{t?.('title.desc')}</div>
          </Space>
        }
        extra={
          <Button type="link" onClick={() => setShowFilter && setShowFilter(!showFilter)}>
            <FilterOutlined />
            {showFilter === true ? t('hide_filter') : t('show_filter')}
          </Button>
        }
        actionItems={mainActionItems({ t })}
        onActionItemClick={onActionItemClick}
        actionProps={{
          actionsText: t?.('title.add_label'),
        }}
      />
    </div>
  );
};

const LabelToolbar: FC<LabelToolbarProps> = ({
  t,
  labelName,
  onlabelNameSearch,
  gtin,
  setGTIN,
  type,
  onTypeChange,
  productId,
  onProductChange,
}) => (
  <Row className={styles.rowselectwidth} {...rowProps}>
    <Col {...col1Props}>
      <Input
        value={labelName}
        onChange={(updatedValue) => onlabelNameSearch?.(updatedValue.target.value)}
        name="labelName"
        placeholder={t('col.label_name')}
        allowClear
      />
    </Col>
    <Col {...col1Props}>
      <Select
        value={type}
        onChange={onTypeChange}
        options={TypeOptions}
        placeholder={t('col.type')}
        style={{ width: '100%' }}
        allowClear
      />
    </Col>
    <Col {...col1Props}>
      <ProductSelect
        className={styles.rowselectwidth}
        placeholder={t('col.product')}
        value={productId}
        onChange={(value: any) => onProductChange?.(value)}
        allowClear
        showArrow
      />
    </Col>
    <Col {...col1Props}>
      <Input
        value={gtin}
        onChange={(updatedValue) => setGTIN?.(updatedValue.target.value)}
        name="gtin"
        placeholder={t('col.gtin')}
        allowClear
      />
    </Col>
  </Row>
);

type ColumnsType = {
  t?: TFunction<'pages', 'labels'>;
  onRowActionClick: (actionItemKey: string, record?: LabelResponse) => void;
};

const RowRender: FC<RowRenderProps> = ({ record, t }) => (
  <Row gutter={[20, 20]}>
    {/* <Col>
      <Image
        width={70}
        height={50}
        src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
      />
    </Col> */}
    <Col>
      <Space direction="vertical" align="start">
        <Space direction="horizontal" align="start">
          <Typography.Text strong>{record?.labelName}</Typography.Text>
          <Tag>{record?.labelType === 'CaseLabel' ? t?.('type.case') : t?.('type.pallet')}</Tag>
        </Space>
        <Space direction="horizontal" align="start">
          <Typography.Text type="secondary" strong>
            {record?.product?.name}
          </Typography.Text>
          <Typography.Text type="secondary">{record?.product?.gtin}</Typography.Text>
        </Space>
      </Space>
    </Col>
  </Row>
);

const columns = ({ t, onRowActionClick }: ColumnsType): Array<ProColumns<LabelResponse>> => [
  {
    dataIndex: 'id',
    hideInTable: true,
  },
  {
    dataIndex: 'rowRender',
    hideInSetting: true,
    render: (text, record) => <RowRender record={record} t={t} />,
    ellipsis: true,
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    width: 120,
    hideInSetting: true,
    render: (text, record) =>
      record?.id ? (
        <Actions
          className="actions"
          buttonProps={{
            ghost: true,
            size: 'small',
            type: 'link',
          }}
          items={rowActionItems({ t })}
          onClick={onRowActionClick}
          actionPayload={record}
        />
      ) : undefined,
  },
];

const Labels = () => {
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const { t } = useTranslation('pages', { keyPrefix: 'labels.landing' });
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const deleteCaseAPI = useDeleteCaseLabel(queryClient);
  const deletePalletAPI = useDeletePalletLabel(queryClient);
  const duplicateAPI = useDuplicateLabel(queryClient);
  const [isPrint, setIsPrint] = useState<Boolean>(false);

  const [params, setParams] = useState<GetLabelsOptionalParams>({
    pageNumber: 1,
    pageSize: 10,
    sortBy: '-LastModifiedTime',
  });

  const { data, isLoading } = useGetLabels(params);

  const deleteWarning = useCallback(
    async (labelName?: string, productName?: string) =>
      new Promise((resolve) => {
        Modal.warning({
          title: t('delete_warning.title', { labelName, productName }),
          content: t('delete_warning.content'),
          centered: true,
          okCancel: true,
          width: 500,
          cancelText: t('delete_warning.cancel'),
          cancelButtonProps: {
            type: 'primary',
            ghost: true,
            shape: 'round',
          },
          okText: t('delete_warning.delete'),
          okButtonProps: {
            type: 'primary',
            shape: 'round',
          },
          onCancel: () => resolve(false),
          onOk: () => resolve(true),
        });
      }),
    [t],
  );

  const onDeleteClick = useCallback(
    async (actionPayload?: LabelResponse) => {
      try {
        const consent = await deleteWarning(actionPayload?.labelName, actionPayload?.product?.name);
        if (consent) {
          if (actionPayload?.labelType === 'CaseLabel') {
            await deleteCaseAPI?.mutateAsync({
              id: actionPayload?.id || '',
            });
            message.success(t?.('success.delete_success', { label: actionPayload?.labelName }));
          } else {
            await deletePalletAPI?.mutateAsync({
              id: actionPayload?.id || '',
            });
            message.success(t?.('success.delete_success', { label: actionPayload?.labelName }));
          }
        }
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    [deleteCaseAPI, deletePalletAPI, deleteWarning, t],
  );

  useEffect(() => {
    const handleBeforePrint = () => {
      setIsPrint(true);
    };

    const handleAfterPrint = () => {
      setIsPrint(false);
    };

    window.addEventListener('beforeprint', handleBeforePrint);
    window.addEventListener('afterprint', handleAfterPrint);

    // Cleanup listeners
    return () => {
      window.removeEventListener('beforeprint', handleBeforePrint);
      window.removeEventListener('afterprint', handleAfterPrint);
    };
  }, []);

  useEffect(() => {
    if (!showFilter) {
      setParams({
        pageNumber: 1,
        pageSize: 10,
        sortBy: '-LastModifiedTime',
      });
    }
  }, [showFilter]);

  const onDuplicateAPI = useCallback(
    async (actionPayload?: LabelResponse) => {
      try {
        await duplicateAPI?.mutateAsync({
          options: {
            body: actionPayload?.id || '',
          },
        });
        message.success(t?.('success.duplicate_success', { label: actionPayload?.labelName }));
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    [duplicateAPI, t],
  );

  const onPrint = useCallback(
    async (actionPayload?: LabelResponse) => {
      try {
        if (actionPayload?.isReadyToPrint) {
          window.print();
        } else {
          Modal.warning({
            title: t('unable_print.title'),
            content: t('unable_print.content'),
            centered: true,
            okCancel: true,
            width: 500,
            cancelText: t('unable_print.cancel'),
            cancelButtonProps: {
              type: 'primary',
              ghost: true,
              shape: 'round',
            },
            okText: t('unable_print.complete_label'),
            okButtonProps: {
              type: 'primary',
              shape: 'round',
            },
            onOk: () => {
              navigate(
                actionPayload?.labelType === 'CaseLabel'
                  ? `${actionPayload?.id}/editCase`
                  : `${actionPayload?.id}/editPallet`,
              );
            },
          });
        }
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    [navigate, t],
  );

  const onRowActionClick = useCallback(
    (actionItemKey: string, actionPayload?: LabelResponse) => {
      switch (actionItemKey) {
        case 'edit':
          navigate(
            actionPayload?.labelType === 'CaseLabel'
              ? `editCase/${actionPayload?.id}`
              : `editPallet/${actionPayload?.id}`,
          );
          break;

        case 'delete':
          onDeleteClick(actionPayload);
          break;

        case 'print':
          onPrint(actionPayload);

          break;

        case 'duplicate':
          onDuplicateAPI(actionPayload);
          break;

        default:
          break;
      }
    },
    [navigate, onDeleteClick, onDuplicateAPI, onPrint],
  );

  const onTableChange = (pagination: TablePaginationConfig) => {
    if (pagination?.current === params?.pageNumber && pagination?.pageSize === params?.pageSize) {
      setParams({
        ...params,
        pageNumber: 1,
        sortBy: '-LastModifiedTime',
      });
    } else {
      setParams({
        ...params,
        pageNumber: pagination?.current,
        pageSize: pagination?.pageSize,
        sortBy: '-LastModifiedTime',
      });
    }
  };

  return (
    <>
      <LabelTitle t={t} showFilter={showFilter} setShowFilter={setShowFilter} />
      {isPrint && <div />}
      <GTable<LabelResponse>
        showHeader={false}
        rowKey="id"
        columns={columns({
          t,
          onRowActionClick,
        })}
        columnsState={{
          persistenceKey: 'pages-label',
        }}
        loading={isLoading}
        value={data?.results}
        recordCreatorProps={false}
        pagination={{
          defaultPageSize: params.pageSize,
          current: params?.pageNumber,
          total: data?.totalItems,
          showTotal: (total) =>
            total <= 1 ? t('labels_pagi', { total }) : t('labels_pagi_multi', { total }),
        }}
        onTableChange={onTableChange}
        options={{
          setting: false,
          reload: false,
        }}
        toolbar={{
          multipleLine: true,
          filter: showFilter ? (
            <LabelToolbar
              t={t}
              labelName={params?.labelName}
              onlabelNameSearch={(val) =>
                setParams({
                  ...params,
                  pageNumber: 1,
                  pageSize: params?.pageSize,
                  labelName: val,
                  sortBy: '-LastModifiedTime',
                })
              }
              gtin={params?.gtin}
              setGTIN={(val) =>
                setParams({
                  ...params,
                  pageNumber: 1,
                  pageSize: params?.pageSize,
                  gtin: val,
                  sortBy: '-LastModifiedTime',
                })
              }
              type={params?.labelType}
              onTypeChange={(val) =>
                setParams({
                  ...params,
                  pageNumber: 1,
                  pageSize: params?.pageSize,
                  labelType: val,
                  sortBy: '-LastModifiedTime',
                })
              }
              productId={params?.productId}
              onProductChange={(val) =>
                setParams({
                  ...params,
                  pageNumber: 1,
                  pageSize: params?.pageSize,
                  productId: val,
                  sortBy: '-LastModifiedTime',
                })
              }
            />
          ) : undefined,
        }}
        emptyContainer={
          <Empty
            className="empty-state"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            // You currently have no Labels.=> (in case of empty)
            // No results found for these filters. => (in case of search filter/check for params & then show)
            description={
              <Typography.Text type="secondary">
                {
                  Object.keys(params)?.length > 2
                    ? t?.('empty.no_filter_results') // 'No results found for these filters.'
                    : t?.('empty.no_labels') // 'You currently have no Labels'
                }
              </Typography.Text>
            }
          />
        }
      />
    </>
  );
};

export default Labels;
